// interfaces & models
import { 
    ATFeedback, IATFeedbackDto
 } from "@algo/network-manager/models/v3";
import * as TYPES from "./types";

let initialState: TYPES.FeedbackDashboardState = {
    initializing: false,
    loadingFeedback: false,
    feedback: [],
    error: null
};

export function feedbackDashboardReducer(
    state = initialState, 
    action: TYPES.FeedbackDashboardTypes
): TYPES.FeedbackDashboardState {
    switch(action.type) {
        case TYPES.INITIALIZE_FEEDBACK_DASHBOARD_BEGIN:
            return {
                ...state,
                initializing: true,
                loadingFeedback: true
            };
        case TYPES.INITIALIZE_FEEDBACK_DASHBOARD_SUCCESS:
            return {
                ...state,
                initializing: false,
                loadingFeedback: false,
                feedback: action.payload.feedback.map(
                    (feedback: IATFeedbackDto) => new ATFeedback(feedback)
                )
            };
        case TYPES.INITIALIZE_FEEDBACK_DASHBOARD_FAILURE:
            return {
                ...state,
                initializing: false,
                loadingFeedback: false,
                error: action.payload.error
            };
        case TYPES.LOAD_FEEDBACK_BEGIN:
            return {
                ...state,
                loadingFeedback: true
            };
        case TYPES.LOAD_FEEDBACK_SUCCESS:
            return {
                ...state,
                loadingFeedback: false,
                feedback: action.payload.feedback
            };
        case TYPES.LOAD_FEEDBACK_FAILURE:
            return {
                ...state,
                loadingFeedback: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_FEEDBACK_DASHBOARD_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_FEEDBACK_DASHBOARD:
            return initialState;
        default:
            return state;
    }
}