//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "./types";
//models
import { ATFacility } from "@algo/network-manager/models/v3";
import { 
    FacilityNetworkManager, IFacilityNetworkManager, IProcessedResponse
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlFacility: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;

/*
Load Facility
*/

function loadFacilityBegin(): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.LOAD_FACILITY_BEGIN,
        payload: {} as TYPES.FacilityEditorPayload
    };
}

function loadFacilitySuccess(
    response: IProcessedResponse
): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.LOAD_FACILITY_SUCCESS,
        payload: {
            facility: response.data,
            error: response.error,
            response
        } as TYPES.FacilityEditorPayload
    };
}

function loadFacilityFailure(
    error: Error
): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.LOAD_FACILITY_FAILURE,
        payload: {
            error: error
        } as TYPES.FacilityEditorPayload
    }
}

export let loadFacility = (
    id: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().facilityEditor.initializing) {
        dispatch(loadFacilityBegin());

        let manager: IFacilityNetworkManager =
            new FacilityNetworkManager(apiUrlFacility);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getById(getState().facilityEditor.lastResponse, {id})
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error) 
                                    dispatch(loadFacilityFailure(response.error))
                                else 
                                    dispatch(loadFacilitySuccess(response))
                            }
                        ).catch(
                            (error: Error) => 
                                dispatch(loadFacilityFailure(error))
                        )
                }
            ).catch(
                (error: Error) => dispatch(loadFacilityFailure(error))
            )
    }
}

function cleanupFacilityBegin(): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.CLEANUP_FACILITY,
        payload: {
            facility: new ATFacility()
        } as TYPES.FacilityEditorPayload
    }
}

export let cleanupFacility = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(cleanupFacilityBegin());
};

/*
Save Facility
*/

function saveFacilityBegin(): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.SAVE_FACILITY_BEGIN,
        payload: {} as TYPES.FacilityEditorPayload
    };
}

function saveFacilitySuccess(response: IProcessedResponse): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.SAVE_FACILITY_SUCCESS,
        payload: {
            facility: response.data,
            error: response.error,
        } as TYPES.FacilityEditorPayload
    };
}

function saveFacilityFailure(error: Error): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.SAVE_FACILITY_FAILURE,
        payload: {
            error: error
        } as TYPES.FacilityEditorPayload
    };
}

export let saveFacility = (
    facility: ATFacility
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().facilityEditor.savingFacility) {
        dispatch(saveFacilityBegin());

        let manager: IFacilityNetworkManager =
            new FacilityNetworkManager(apiUrlFacility);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.save({facility})
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error) dispatch(saveFacilityFailure(response.error))
                                else dispatch(saveFacilitySuccess(response))
                            }
                        ).catch(
                            (error: Error) => dispatch(loadFacilityFailure(error))
                        )
                }
            ).catch(
                (error: Error) => dispatch(loadFacilityFailure(error))
            )
    }
}

/*
Clear Facility Editor Error
*/

function clearFacilityEditorErrorState(): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.CLEAR_FACILITY_EDITOR_ERROR,
        payload: {} as TYPES.FacilityEditorPayload
    };
}

export let clearFacilityEditorError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().facilityEditor.error !== null) {
        dispatch(clearFacilityEditorErrorState());
    }
}

/*
Uninitialize Facility Editor
*/

function uninitializeFacilityEditorState(): TYPES.FacilityEditorTypes {
    return {
        type: TYPES.UNINITIALIZE_FACILITY_EDITOR,
        payload: {} as TYPES.FacilityEditorPayload
    };
}

export let uninitializeFacilityEditor = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(uninitializeFacilityEditorState());
}