// libraries
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../../index';
import { Action } from "redux";
import { getAccessToken } from '../../../components/template/authentication/oidcConfig';
import { FacilityNetworkManager, IFacilityNetworkManager, IProcessedResponse } from "@algo/network-manager/managers/v3";
// types
import * as TYPES from "./types";
// models & interfaces
import { ATFacility, IATFacility } from "@algo/network-manager/models/v3";
//constants
import { DEFAULT_QUERY } from "../../../utils/AppConstants";
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlFacility: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).facilities}`;
//const apiUrlFacility: string = `http://localhost:3000/facilities`;

function initializeFacilityDashboardBegin(): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.INITIALIZE_FACILITY_DASHBOARD_BEGIN,
        payload: {} as TYPES.FacilityDashboardPayload
    };
}

/*
Initialize FACILITY Dashboards
*/

function initializeFacilityDashboardSuccess(
    response: IProcessedResponse
): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.INITIALIZE_FACILITY_DASHBOARD_SUCCESS,
        payload: {
            facilities: response.data,
            response: response,
            error: response.error
        } as TYPES.FacilityDashboardPayload
    };
}

function initializeFacilityDashboardFailure(
    error: Error
): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.INITIALIZE_FACILITY_DASHBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.FacilityDashboardPayload
    };
}

export let initializeFacilityDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (
        !getState().facilityDashboard.initializing || 
        !getState().facilityDashboard.loadingFacilities
    ) {
        dispatch(initializeFacilityDashboardBegin());

        let manager: IFacilityNetworkManager = 
            new FacilityNetworkManager(apiUrlFacility);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll(
                        getState().facilityDashboard.lastInitResponse,
                        {},
                    )
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error) 
                                    dispatch(initializeFacilityDashboardFailure(response.error))
                                else 
                                    dispatch(initializeFacilityDashboardSuccess(response))
                            }
                        ).catch(
                            (error: Error) => dispatch(initializeFacilityDashboardFailure(error))
                        )
                }
            ).catch(
                (error: Error) => {
                    dispatch(initializeFacilityDashboardFailure(error))
                }
            )    
    }
}

/*
Search Facilities
*/

function searchFacilitiesBegin(): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.SEARCH_FACILITIES_BEGIN,
        payload: {} as TYPES.FacilityDashboardPayload
    };
}

function searchFacilitiesSuccess(
    response: IProcessedResponse
): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.SEARCH_FACILITIES_SUCCESS,
        payload: {
            facilities: response.data,
            response,
            error: response.error
        } as TYPES.FacilityDashboardPayload
    };
}

function searchFacilitiesFailure(
    error: Error
): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.SEARCH_FACILITIES_FAILURE,
        payload: {
            error: error
        } as TYPES.FacilityDashboardPayload
    };
}

export let searchFacilities = (
    name: string = ""
): ThunkAction<void, AppState, null, Action<string>> => async(dispatch, getState) => {
    if(!getState().facilityDashboard.initializing || !getState().facilityDashboard.loadingFacilities) {
        dispatch(searchFacilitiesBegin());

        let manager: IFacilityNetworkManager = 
            new FacilityNetworkManager(apiUrlFacility);

        console.log("search facilities");
        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll(
                        getState().facilityDashboard.lastSearchResponse,
                        {name}
                    ).then(
                        (response: IProcessedResponse) => {
                            if (response.error) dispatch(searchFacilitiesFailure(response.error));
                            else dispatch(searchFacilitiesSuccess(response));
                        }
                    ).catch(
                        (error: Error) => dispatch(searchFacilitiesFailure(error))
                    )
                }
            ).catch(
                (error: Error) => dispatch(searchFacilitiesFailure(error))
            )  
    }
}

/*
Remove FACILITY
*/

function deleteFacilityBegin(): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.DELETE_FACILITY_BEGIN,
        payload: {} as TYPES.FacilityDashboardPayload
    };
}

function deleteFacilitySuccess(
    response: IProcessedResponse
): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.DELETE_FACILITY_SUCCESS,
        payload: {
            facilities: response.data,
            error: response.error
        } as TYPES.FacilityDashboardPayload
    };
}

function deleteFacilityFailure(error: Error): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.DELETE_FACILITY_FAILURE,
        payload: {
            error: error
        } as TYPES.FacilityDashboardPayload
    };
}

export let removeFacility = (
    id: number,
    name: string = "",
    // city: string = "",
    // place: string = "",
    // skip?: number,
    // take?: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().facilityDashboard.deletingFacility) {
        dispatch(deleteFacilityBegin());

        let manager: IFacilityNetworkManager = 
            new FacilityNetworkManager(apiUrlFacility);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.delete({id})
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error) dispatch(deleteFacilityFailure(response.error))
                                else {
                                    manager.getAll(
                                        getState().facilityDashboard.lastInitResponse,
                                        {name},
                                    )
                                        .then(
                                            (getAllResponse: IProcessedResponse) => {
                                                if (getAllResponse.error) dispatch(deleteFacilityFailure(getAllResponse.error))
                                                else dispatch(deleteFacilitySuccess(getAllResponse))
                                            }
                                        ).catch(
                                            (error: Error) => dispatch(deleteFacilityFailure(error))
                                        )
                                }
                            }
                        ).catch(
                            (error: Error) => dispatch(deleteFacilityFailure(error))
                        )
                }
            ).catch(
                (error: Error) => dispatch(deleteFacilityFailure(error))
            )
    }
}

/*
Clear FACILITY Dashboard Error
*/

function clearFacilityDashboardErrorState(): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.CLEAR_FACILITY_DASHBOARD_ERROR,
        payload: {} as TYPES.FacilityDashboardPayload
    };
}

export let clearFacilityDashboardError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().facilityDashboard.error !== null) {
        dispatch(clearFacilityDashboardErrorState());
    }
}

/*
Uninitialize FACILITY Dashboard
*/

function uninitializeFacilityDashboardState(): TYPES.FacilityDashboardTypes {
    return {
        type: TYPES.UNINITIALIZE_FACILITY_DASHBOARD,
        payload: {} as TYPES.FacilityDashboardPayload
    };
}

export let uninitializeFacilityDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeFacilityDashboardState());
}