// libraries
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from './components/template/authentication/oidcConfig';
import {HereProvider} from "@algo/herecontext";

// this file is included to tell webpack config that it should be output to the build folder
// it will be necessary on the IIS server for react-routing
import "./web.config";
// components
import App from './App';
// styles
import './scss/custom.scss';
import 'bootstrap/dist/js/bootstrap.bundle';

ReactDOM.render(
  <AuthProvider { ...oidcConfig}>
    <React.StrictMode>
      <HereProvider>
        <App />
      </HereProvider>
    </React.StrictMode>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
