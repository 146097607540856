import * as TYPES from "./types";
import { ATFacility} from "@algo/network-manager/models/v3";
import { ProcessedResponse } from "@algo/network-manager/managers/v3";

let initialState: TYPES.FacilityEditorState = {
    initializing: false,
    savingFacility: false,
    facility: new ATFacility(),
    error: null,
    lastResponse: new ProcessedResponse()
};

export function facilityEditorReducer(state = initialState, action: TYPES.FacilityEditorTypes): TYPES.FacilityEditorState {
    switch(action.type) {
        case TYPES.LOAD_FACILITY_BEGIN:
            return {
                ...state,
                initializing: true
            };
        case TYPES.LOAD_FACILITY_SUCCESS:
            return {
                ...state,
                initializing: false,
                facility: action.payload.facility
            };
        case TYPES.LOAD_FACILITY_FAILURE:
            return {
                ...state,
                initializing: false,
                error: action.payload.error
            };
        case TYPES.CLEANUP_FACILITY:
            return {
                ...state,
                facility: action.payload.facility
            };
        case TYPES.SAVE_FACILITY_BEGIN:
            return {
                ...state,
                savingFacility: true
            };
        case TYPES.SAVE_FACILITY_SUCCESS:
            return {
                ...state,
                savingFacility: false,
                facility: action.payload.facility,
                error: action.payload.error
            };
        case TYPES.SAVE_FACILITY_FAILURE:
            return {
                ...state,
                savingFacility: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_FACILITY_EDITOR_ERROR:
            return {
                ...state,
                error: null
            }
        case TYPES.UNINITIALIZE_FACILITY_EDITOR:
            return initialState;
        default:
            return state;
    }
}