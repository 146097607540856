// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// models & interfaces
import { DELETE_MESSAGE, CREATE_MESSAGE } from "../../../models/Privileges";
// store
import {
    initializeMessageDashboard, loadMessages, removeMessage,
    uninitializeMessageDashboard
} from '../../../store/message/dashboard/actions';
import { AppState } from "../../../store";
import { ProfileState } from "../../../store/profile/types";
import { MessageDashboardState } from "../../../store/message/dashboard/types";
// components
import ConfirmationModal from '../../base/modals/ConfirmationModal';
import { 
    GenericTableViewSettings, GenericTableView, 
    GenericTableViewColumnDefinition
} from "../../base/layout/GenericTableView";
// constants
import {
    DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_TOTAL_PAGES,
    MESSAGE_DEFAULT_ID, DEFAULT_QUERY, DATE_STRING_FORMAT
} from '../../../utils/AppConstants';
import { ATAldotMessage } from "@algo/network-manager/models/v3";
import moment from "moment";

interface StateProps { 
    profile: ProfileState;
    dashboard: MessageDashboardState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        profile: state.profile,
        dashboard: state.messageDashboard
    };
};

interface DispatchProps {
    initialize: typeof initializeMessageDashboard;
    search: typeof loadMessages;
    remove: typeof removeMessage;
    uninitialize: typeof uninitializeMessageDashboard;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeMessageDashboard,
        search: loadMessages,
        remove: removeMessage,
        uninitialize: uninitializeMessageDashboard
    }, dispatch);
};

type MessageTableViewProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface MessageTableViewState {
    currentPageIndex: number;
    pageSize: number;
    totalPages: number;
    messageId: number;
    showDeleteConfirmModal: boolean;
    query: string;
}

class MessageTableView extends React.Component<MessageTableViewProps, MessageTableViewState> {
    constructor(props: Readonly<MessageTableViewProps>) {
        super(props);

        this.state = {
            currentPageIndex: DEFAULT_PAGE_INDEX,
            pageSize: DEFAULT_PAGE_SIZE,
            totalPages: DEFAULT_TOTAL_PAGES,
            messageId: MESSAGE_DEFAULT_ID,
            showDeleteConfirmModal: false,
            query: DEFAULT_QUERY
        } as MessageTableViewState;
    }

    componentDidMount() {
        this.props.initialize();
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        let prevIsLoading = 
            prevProps.dashboard.initializing || 
            prevProps.dashboard.loadingMessages;

        let currentIsLoading = 
            this.props.dashboard.initializing || 
            this.props.dashboard.loadingMessages;

        if (
            ( this.props.dashboard.messages?.length !== prevProps.dashboard.messages?.length ) || 
            ( this.state.pageSize !== prevState.pageSize )  || 
            ( prevIsLoading !== currentIsLoading )
        ) {
            this.setState((state, props) => {
                return {
                    ...state,
                    totalPages: (props.dashboard.messages?.length / this.state.pageSize) || 0,
                    currentPageIndex: 0
                };
            })
        }
    }
    
    searchBoxChange = (query: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            };
        });
    }

    clearFilter = (): void => {
        this.props.search(DEFAULT_QUERY);

        this.setState((state, props) => {
            return {
                ...state,
                query: DEFAULT_QUERY
            };
        });
    }

    applyFilter = (query: string, key: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            }
        })

        this.props.search(query);
    }

    goToEditor = (id: number): void => {
        this.props.history.push(`/message/${id}`);
    }

    newMessage = (): void => {
        $('[data-toggle="tooltip"]').tooltip('hide');

        if (this.props.profile.userProfile.hasPrivilege(CREATE_MESSAGE)) {
            this.props.history.push(`/message/new`);
        }
    }

    showDeleteModal = (id: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                messageId: id,
                showDeleteConfirmModal: true
            };
        });
    }

    comfirmDelete = (): void => {
        this.delete(this.state.messageId);

        this.setState((state, props) => {
            return {
                ...state,
                messageId: MESSAGE_DEFAULT_ID,
                showDeleteConfirmModal: false
            };
        });
    }

    dismissDelete = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                messageId: MESSAGE_DEFAULT_ID,
                showDeleteConfirmModal: false
            };
        });
    }

    delete = (id: number): void => {
        this.props.remove(id, this.state.query);
    }

    render() {
        let { dashboard, profile } = this.props;

        let tableSettings = {
            tableClassName: 'av-message-table',
            actionButtonClass: 'btn btn-outline-danger',
            actionButtonGlyphClass: 'delete-icon',
            columnDefinitions:  [
                {
                    propertyName: 'id',
                    isKey: true
                },
                { 
                    propertyName: 'title', 
                    displayName: 'Title', 
                    isKey: false, 
                    headerColumnClass: 'av-message-title-col' 
                },
                {
                    propertyName: 'start',
                    displayName: 'Start Date',
                    isKey: false,
                    headerColumnClass: 'av-message-start-col'
                },
                {
                    propertyName: 'end',
                    displayName: 'End Date',
                    isKey: false,
                    headerColumnClass: 'av-message-end-col'
                },
            ] as GenericTableViewColumnDefinition[],
            emptyTableMessage: 'No messages',
            showPageSizeSelector: false,
            newItemDisabled: !profile.userProfile.hasPrivilege(CREATE_MESSAGE),
            rowClickDisabled: !profile.userProfile.hasPrivilege(CREATE_MESSAGE),
            actionDisabled: !profile.userProfile.hasPrivilege(DELETE_MESSAGE)
        } as GenericTableViewSettings;

        return (
            <div style={{
                height: '100%'
            }}>
                <GenericTableView 
                    settings={tableSettings} 
                    items={dashboard.messages?.map(
                        (message: ATAldotMessage) => {
                            return {
                                ...message,
                                start: moment(message.start).format(DATE_STRING_FORMAT),
                                end: moment(message.end).format(DATE_STRING_FORMAT)
                            }
                        }
                    )} 
                    isLoading={dashboard.initializing || dashboard.loadingMessages} 
                    isProcessingAction={dashboard.deletingMessage} 
                    newItemCallback={this.newMessage} 
                    rowClickCallback={this.goToEditor}
                    actionCallback={this.showDeleteModal}
                    searchBoxChangeCallback={this.searchBoxChange}
                    clearFilterCallback={this.clearFilter}
                    applyFilterCallback={this.applyFilter}
                />
                {
                    this.state.showDeleteConfirmModal 
                        ? <ConfirmationModal 
                            confirmCallback={this.comfirmDelete} dismissCallback={this.dismissDelete} 
                            title={`Delete this message?`} type={`warning`}
                            message={`Are you sure you want to delete this message? This action cannot be reversed.`}  /> 
                        : (null)
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(MessageTableView));