//libraries
import * as React from "react";
import { newTooltipAttributes, useTooltip } from "@algo/tooltip";
import {VideoJs, DefaultConfig} from "@caps-mobile/video-js";
//enums
import { EATStreamAccessLevel } from "@algo/network-manager/models/v3";
//models & interfaces
import { IATCloudStreamTarget } from "@algo/network-manager/models/v3";
//components
import { ISelectOption, Select } from "@algo/select";
import { Icon } from "@algo/icon";
import { Container, Row, Col } from "reactstrap";
import { Body3 } from "@algo/algo-styles";
import { Center } from "@algo/capsstrap";
// constants
import { StreamAccessLevelOptions }from "../../../../utils/AppConstants";
import { videojsHtml5 } from "../../../../constants";

interface IStreamViewer {
    cloudStreamTarget?: IATCloudStreamTarget;
    videoHeight?: number;
    delete?: (id: number) => any;
    updateAccessLevel?: (newLevel: EATStreamAccessLevel) => any;
    updatingAccessLevel?: boolean;
}

export const CloudStreamTarget: React.FC<IStreamViewer> = (props) => {

    /*************************************************************
        STATE
     ************************************************************/

    /*************************************************************
        EFFECTS
     ************************************************************/
    useTooltip($, false);

    /*************************************************************
        CALCULATED VALUES & BREVITY NAMES
     ************************************************************/
    const noTarget: boolean = !props.cloudStreamTarget || !props.cloudStreamTarget.hlsPlaybackUrl;

    const streamUrlTextRef: any = React.useRef(null);                                                   // used to capture textarea text for clipboard action (this should be replaced with clipboard api soon)

    let accessLevelOptions: Array<ISelectOption> =                                                      // set up the options list for access level drop down
        StreamAccessLevelOptions.map(
            (value: any, mapIndex: any) => {
                return {
                    label: value,
                    value: value,
                    id: mapIndex,
                }
            }
        );

    const getDefaultAccessLevelOption = () => {
        if (noTarget) return 0;
        for (let i = 0; i < accessLevelOptions.length; i++) {
            if (accessLevelOptions[i].value === props.cloudStreamTarget?.accessLevel)
                return i;
        }
        return 0;
    }

    const defaultAccessLevelOption = getDefaultAccessLevelOption();
        
    const videoHeight: number = props.videoHeight ? props.videoHeight : 225;

    /*************************************************************
        CALLBACKS AND HANDLERS
     ************************************************************/
    const handleSelectChange: any = (selection: ISelectOption) => {
        if (!noTarget && props.updateAccessLevel) 
            props.updateAccessLevel(EATStreamAccessLevel[selection.label as keyof typeof EATStreamAccessLevel]);
    }

    /*************************************************************
        PRIMARY RENDER
     ************************************************************/
    return (
        <Container fluid={true} className="border" style={{borderRadius: "4px"}}>
            {/*Video Player Row*/}
            <Row>
                <Col className="p-0">
                    {(noTarget)
                        ?   <Center style={{ height: videoHeight, backgroundColor: "#000" }}>
                                <Body3 style={{color: "#fff"}}> No Target Video </Body3>
                            </Center>
                        : <VideoJs
                            id={"VideoJsId"} 
                            config={{
                                ...DefaultConfig, 
                                sources: [{
                                    src: props.cloudStreamTarget?.hlsPlaybackUrl,
                                    type: DefaultConfig.sources[0].type 
                                }],
                                html5: videojsHtml5,
                                height: videoHeight,
                                width: "100%"
                            }}
                            style={{width: "100%"}}
                            videoClassNames={""}
                            videoDimensions={{width: "100%", height: `${videoHeight}`}}
                            playButton={true} />
                    }
                </Col>
            </Row>
            {/*Information Row*/}
            <Row style={{height: "130px"}}>
                <Col xs={12} className="justify-content-center align-items-center h-100 p-3">
                    <Container fluid={true} className="h-100">
                        {/* Access Level Dropdown Row*/}
                        <Row className="h-50">
                            <Col xs={12} className="p-0 d-flex justify-content-center align-items-top">
                                <div className="w-100">
                                    <Select
                                        options={accessLevelOptions}
                                        selection={defaultAccessLevelOption}
                                        disabled={props.updatingAccessLevel || noTarget}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/* URL Copy Row */}
                        <Row className="h-50">
                            <Col xs={props.delete ? 6 : 12} className="d-flex justify-content-start align-items-end p-0">
                                <Body3 className="mr-2">
                                    {noTarget ? "No Cloud Stream Target" : "HTTP Live Streaming URL"}
                                </Body3>
                                {(!noTarget)
                                    ? [<textarea
                                        hidden={true}
                                        style={{ width: "0px", height: "0px" }}
                                        value={props.cloudStreamTarget?.hlsPlaybackUrl}
                                        readOnly={true}
                                        ref={streamUrlTextRef}
                                        key={Math.random()}
                                    ></textarea>,
                                    <Icon
                                        size="xs" iconClass="fas fa-copy"
                                        color="#828282"
                                        disabled={false}
                                        attributes={newTooltipAttributes("Copy stream URL to clipboard")}
                                        callback={() => {
                                            streamUrlTextRef.current.hidden = false;                                        // it seems you cannot copy text from hidden element, so unhide it
                                            streamUrlTextRef.current.focus();                                               // focus may not be necessary
                                            streamUrlTextRef.current.select();                                              // select the text
                                            document.execCommand('copy');                                                   // execute the copy command
                                            streamUrlTextRef.current.hidden = true;                                         // set the element back to hidden before the user ever sees it
                                        }}
                                        key={Math.random()}
                                    />]
                                    : null
                                }
                            </Col>
                            {(props.delete && !noTarget) &&
                                <Col xs={6} className="d-flex justify-content-end p-0">
                                    <Icon
                                        size="md" iconClass="fas fa-trash"
                                        color="#E53424"
                                        attributes={newTooltipAttributes("Delete Cloud Stream Target")}
                                        disabled={false}
                                        callback={
                                            () => { 
                                                (props.delete && props.cloudStreamTarget) &&
                                                    props.delete(props.cloudStreamTarget.id); 
                                            }
                                        }
                                    />
                                </Col>
                            }
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default CloudStreamTarget;