// libraries
// @ts-ignore
import { whenLargeMax } from "@caps-mobile/algo-theme/breakpoints";
import { absoluteArrayBuilder, flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledFacilityEditor = styled.div`
    width: 100%;
    height: 100%;

    position: relative;

    padding: .75rem 0 .75rem 0;
    
    ${flexSmart("center center")}
    flex-direction: column;
`;

export const ContentRow = styled.div`

    width: 100%;
    height: calc(100% - 41px);

    padding: 4px 4px 0 4px;

    box-sizing: border-box;

    ${flexSmart("flex-start center")}
    flex-direction: column;
    overflow: hidden;
`;

export const TitleContent = styled.div`
    width: 100%;

    padding: 4px 12.5% 4px 12.5%;
`;

export const DisabledOverlay = styled.div`
    ${absoluteArrayBuilder(["8.5%", "", "", 0])}
    z-index: 99;    

    width: 100%;
    height: 100%;

    ${flexSmart("center center")}
    flex-direction: column;

    border-radius: 4px;

    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    color: #1e824c;

    background-color: rgba(0,0,0,0.15);

    overflow: hidden;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const FormsContent = styled.div`
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 12.5%;
    padding-left: 12.5%;

    ${flexSmart("space-between flex-start")}
    overflow: auto;

    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    ${whenLargeMax(`
        flex-direction: column;
    `)}
`;

export const LeftColumn = styled.div`
    width: 45%;
    height: auto;

    display: flex;
    flex-direction: column;

    ${whenLargeMax(`
        width: 100%;
        height: auto;
    `)}
`;

export const RightColumn = styled.div`
    width: 45%;
    height: auto;

    display: flex;
    flex-direction: column;

    ${whenLargeMax(`
        width: 100%;
        height: auto;
    `)}
`;

export const FooterRow = styled.div`
    height: calc(42px + .75rem);
    width: 100%;

    border-top: 1px solid rgba(0,0,0,0.1);

    ${flexSmart("space-evenly end")}
`;