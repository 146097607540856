//libraries
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../../../index';
import { Action } from "redux";
import { getAccessToken } from '../../../../components/template/authentication/oidcConfig';
// interfaces & models
import { 
    ICloudStreamTargetNetworkManager, CloudStreamTargetNetworkManager, IProcessedResponse
} from "@algo/network-manager/managers/v3";
import { IATCloudStreamTarget } from "@algo/network-manager/models/v3";
import * as TYPES from "../types/types";
//enums
import { EATStreamAccessLevel } from "@algo/network-manager/models/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../../api-version-constants";
declare var __API_URL__: string;
const apiUrlCST: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).cloudStreamTargets}`;

/*******************************************************************************
                        PATCH CLOUD STREAM TARGET
*******************************************************************************/
export let patchCloudStreamTarget = (
    cst: IATCloudStreamTarget,
    accessLevel: EATStreamAccessLevel
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (
        !getState().cameraDetails.loadingCameraDevice && 
        !getState().cameraDetails.patchingCloudStreamTarget
    ) {
        
        dispatch(patchCloudStreamTargetBegin());

        let manager: ICloudStreamTargetNetworkManager = 
            new CloudStreamTargetNetworkManager(apiUrlCST);

        cst.accessLevel = accessLevel;

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.update({id: cst.id, cst})
                        .then(
                            (response: IProcessedResponse) => 
                                dispatch(
                                    patchCloudStreamTargetSuccess(response)
                                )
                        ).catch(
                            (err: Error) => 
                                dispatch(patchCloudStreamTargetFailure(err))
                        )
                }
            ).catch(
                (err: Error) => dispatch(patchCloudStreamTargetFailure(err))
            )
    }
}

function patchCloudStreamTargetBegin(): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.PATCH_CLOUD_STREAM_TARGET_BEGIN,
        payload: {} as TYPES.CameraDetailsPayload
    };
}

function patchCloudStreamTargetSuccess(response?: IProcessedResponse): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.PATCH_CLOUD_STREAM_TARGET_SUCCESS,
        payload: {
            response
        } as TYPES.CameraDetailsPayload
    };
}

function patchCloudStreamTargetFailure(error: Error): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.PATCH_CLOUD_STREAM_TARGET_FAILURE,
        payload: {
            error: error
        } as TYPES.CameraDetailsPayload
    };
}
