// libraries
import * as React from "react";
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps as IRouteProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import { isMaxSize } from "@algo/window-sizer";
import { useAuth } from "react-oidc-context";
// hooks
import { useWatchSizeClass } from "@algo/window-sizer";
// enums
// interfaces and models
import { ProfileState } from '../../../../store/profile/types';
import { EBreakpoint } from "@algo/window-sizer";
import { EATRegion, IATLegacyDatasource } from "@algo/network-manager/models/v3";
import { ALLOW_CAMERA_BULK_UPDATE } from '../../../../models/Privileges';
// redux-state-types
import {
    State as PageState
} from "../../../../store/cameraDevice/dashboard/types/pageStateTypes";
import {
    State as StreamPreviewsState
} from "../../../../store/cameraDevice/dashboard/types/streamPreviewsTypes";
import {
    State as ToolbarState
} from "../../../../store/cameraDevice/dashboard/types/toolbarTypes";
import { AppState } from '../../../../store';
//redux-actions
import {
    loadDatasources,
} from "../../../../store/cameraDevice/dashboard/actions/toolbarActions";
import {
    updatePageState,
} from "../../../../store/cameraDevice/dashboard/actions/pageStateActions";
import { loadUserProfile } from '../../../../store/profile/actions';
// local components
import AccessRow from "./AccessLevelRow";
import SelectionRow from "./SelectionRow";
// general components
import { Container, Row, Col } from "reactstrap";
import { IconLabel } from "@algo/icon-label";
import { ThemedDropdown } from "@caps-mobile/themed-dropdown";


/**************************************************************************
    COMPONENT PROPS INTERFACES
*************************************************************************/
// redux store props
interface IStateProps {
    profile: ProfileState;
    pageState: PageState;
    previewsState: StreamPreviewsState;
    toolbarState: ToolbarState;
}
// redux action props
interface IDispatchProps{
    getUserProfile: typeof loadUserProfile;
    updatePageState: typeof updatePageState;
    loadDatasources: typeof loadDatasources;
}
// redux store mapper
let mapStateToProps = (state: AppState) => {
    return { 
        profile: state.profile,
        pageState: state.streamPageState,
        previewsState: state.streamPreviews,
        toolbarState: state.streamToolbar
    }
}
// redux action mapper
let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            getUserProfile: loadUserProfile,
            updatePageState: updatePageState,
            loadDatasources: loadDatasources,
        },
        dispatch
    );
}

interface IOwnProps {
    setHeightCallback: any;
}

// combined props
type IProps = IStateProps & IOwnProps & IDispatchProps & IRouteProps;

export const CameraToolbar: React.FC<IProps> = (props) => {

    /*************************************************************************|
        CALCULATED VALUES & BREVITY NAMES                                 
    |*************************************************************************/
    const auth = useAuth();
    const user = auth.user;
    const profile: ProfileState = props.profile;
    const pageState: PageState = props.pageState;                                                               // the shared store of the entire StreamDashboard page
    const editMode: boolean = pageState.editMode;                                                               // check whether to extend toolbar graphics & functions
    const camsLoading: boolean = props.previewsState.cameraData.isLoading;                                      // check previews store for cameras loading state
    const datasources: Array<IATLegacyDatasource> = props.toolbarState.datasourceData.data;                           // get the list of datasources
    const datasourcesLoading: boolean = props.toolbarState.datasourceData.isLoading;                            // check toolbar store for datasources loading state
    const getUserProfile = props.getUserProfile;

    /*************************************************************************|
        STATE                                 
    |*************************************************************************/
    const [searchString, setSearchString] = React.useState("");
    const sizeClass = useWatchSizeClass();

    React.useEffect(() => {
        if (localStorage.cameraSearchString)
            setSearchString(JSON.parse(localStorage.cameraSearchString));
        else
            setSearchString("");
      }, [localStorage.cameraSearchString]);

    /*************************************************************************|
        HANDLERS & UTILITIES                                 
    |*************************************************************************/
    const isSmallMax = isMaxSize(sizeClass, EBreakpoint.SM);

    // assembles the list of datsources for Select element
    const buildDatasourceOptions = (): string[] => {

        let options: string[] = ["All Datasources"];

        if (datasources){
            datasources.forEach(
                (datasource, index) => {
                    options.push(datasource.displayName);
                }
            )
        }

        return options;
    }

    // fires when user changes the datasource Select element value
    const handleDatasourceSelection = (selectedIndex: number) => {

        if (!camsLoading){
            let selectedRegionIndex = (selectedIndex === 0) 
                ? 0 
                : selectedIndex;

            props.updatePageState(
                {
                    ...pageState,
                    selectedRegion: (selectedIndex === 0) 
                        ? undefined
                        : datasources[(selectedIndex - 1)].region,
                    selectedRegionIndex,
                    pageIndex: 0
                }
            )
        }
    }

    const handleSearch = () => {
        props.updatePageState({ ...pageState, search: searchString });
    }

    const userCanBulkEdit = () => {

        if (
            user && 
            !user.expired && 
            profile.userProfile.hasPrivilege(ALLOW_CAMERA_BULK_UPDATE)
        ) return true;

        return false;
    };

    /*************************************************************************|
        CALCULATED VALUES & BREVITY NAMES
    |*************************************************************************/
    let datasourceOptions: string[] = buildDatasourceOptions();

    /*************************************************************************|
        EFFECTS
    |*************************************************************************/
    React.useEffect(
        () => {
            if (!datasources && !datasourcesLoading) { props.loadDatasources() }                // initial datasources load
            
            let toolbarRow: HTMLElement | null = document.getElementById("toolbar-row");        // rendered by StreamDashboard, checked here due to Effect timing
            let toolbarHeight: number | null = toolbarRow && toolbarRow.offsetHeight;           // check if toolbar-row has been rendered
            toolbarHeight && props.setHeightCallback(toolbarHeight);                            // if so, report its height to dashboard ( dashboard will handle page sizing )
        }
    );

    React.useEffect(
        () => {
            if (!user) getUserProfile();
        }, [user, getUserProfile]
    );

    /*************************************************************************|
        PRIMARY RENDER
    |*************************************************************************/
    return (
        <StyledToolbar>
            <Container fluid={true}>
                <Row>
                    <Col xs={3} sm={3} md={2}
                        className="d-flex align-items-center mb-2 mb-md-0 pl-0">
                        <button
                            className="btn btn-outline-primary w-100"
                            disabled={camsLoading || !userCanBulkEdit()}
                            onClick={
                                () => {
                                    userCanBulkEdit() &&
                                    props.updatePageState({
                                        ...pageState,
                                        editMode: !editMode
                                    });
                                }
                            }
                        >
                            <IconLabel iconClass="fas fa-bars" />
                        </button>
                    </Col>
                    <Col xs={9} sm={9} md={4}
                        className="d-flex align-items-center mb-2 mb-md-0 pr-0 pr-md-3">
                        <ThemedDropdown 
                            options={datasourceOptions}
                            searchable={true}
                            selectedIndex={props.pageState.selectedRegionIndex}
                            placeholder={"Select Datasource"}
                            disabled={false} disabledPlaceholder={""}
                            menuAttached={false} noDropBorder={false}
                            scrollThreshold={7}
                            styleObject={{"StyledSelectionInput": "font-size: 1rem;"}}
                            callback={(newIndex: number) => {
                                handleDatasourceSelection(newIndex);
                            }}
                        />
                    </Col>
                    <Col xs={9} sm={9} md={4}
                        className="d-flex align-items-center pl-0 pl-md-3">
                        <input className="form-control w-100 rounded-sm my-input-highlight"
                            type="text" id="txt-input-query"
                            placeholder="Search..."
                            disabled={camsLoading}
                            value={searchString}
                            onChange={
                                (ev: React.ChangeEvent<HTMLInputElement>): void => {
                                    setSearchString(ev.currentTarget.value)
                                }
                            }
                            onKeyDown={(event: React.KeyboardEvent) => {
                                (event.key === "Enter") && handleSearch()
                            }}
                        />
                    </Col>
                    <Col xs={3} sm={3} md={2}
                        className="d-flex align-items-center pr-0">
                        <button
                            className="btn btn-outline-primary w-100 rounded-sm"
                            onClick={() => { handleSearch() }}
                            disabled={camsLoading}
                        >
                            <IconLabel
                                iconClass={"fas fa-search"}
                                label={!isSmallMax ? "Search" : undefined}
                                labelFirst={false}
                            />
                        </button>
                    </Col>
                </Row>
                {(editMode) && [
                    <AccessRow key={Math.random()} />,
                    <SelectionRow key={Math.random()} />
                ]}
            </Container>
        </StyledToolbar>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CameraToolbar));

/****************************************************************************** 
    STYLES
 *****************************************************************************/
export const StyledToolbar = styled.div`
    margin: 1rem 0 .75rem 0;
    padding: .5rem;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 4px;
`;

const getIndexOfEnum = (myEnum: any, key: any): number => {

    let enumKeys: any = Object.keys(myEnum);

    for (let i = 0; i < myEnum.length; i++){
        if (enumKeys === key) return i;    
    }
    
    return 0;
}