//types
import * as TYPES from "../types/types";
import { ATCameraDevice, EATStreamServerLogSource } from "@algo/network-manager/models/v3";
import { ProcessedResponse } from "@algo/network-manager/managers/v3";

let initialState: TYPES.CameraDetailsState = {
    //data
    device: new ATCameraDevice(),
    logs: {},
    checksum: 0,
    totalCount: 0,
    checksums: {
        [EATStreamServerLogSource.LocalIncomingStream]: 0,
        [EATStreamServerLogSource.DistributorIncomingStream]: 0,
        [EATStreamServerLogSource.LocalPushPublishEntry]: 0
    },
    totalCounts: {
        [EATStreamServerLogSource.LocalIncomingStream]: 0,
        [EATStreamServerLogSource.DistributorIncomingStream]: 0,
        [EATStreamServerLogSource.LocalPushPublishEntry]: 0
    },
    //data locks
    loadingCameraDevice: false,
    loadingLogs: {},
    patchingCloudStreamTarget: false,
    error: null,
    noData: false,
    lastResponse: new ProcessedResponse()
};

export function cameraDetailsReducer(state = initialState, action: TYPES.CameraDetailsTypes): TYPES.CameraDetailsState {
    switch (action.type) {
        /**************************************
                    LOAD CAMERA DEVICE
         *************************************/
        case TYPES.LOAD_CAMERA_DEVICE_BEGIN:
            return {
                ...state,
                loadingCameraDevice: true,
            };
        case TYPES.LOAD_CAMERA_DEVICE_SUCCESS:
            return {
                ...state,
                device: action.payload.device,
                loadingCameraDevice: false,
                lastResponse: action.payload.response
            };
        case TYPES.LOAD_CAMERA_DEVICE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loadingCameraDevice: false,
            };
        /**************************************
                    LOAD FILE LOGS
         *************************************/
        case TYPES.LOAD_LOGS_BEGIN:
            return {
                ...state,
                logs: {},
                loadingLogs: { ...state.loadingLogs, [action.payload.source]: true },
                error: null,
                noData: false
            };
        case TYPES.LOAD_LOGS_SUCCESS:
            return {
                ...state,
                logs: { ...state.logs, [action.payload.source]: action.payload.logs },
                checksums: { ...state.checksums, [action.payload.source]: action.payload.checksum },
                totalCounts: { ...state.totalCounts, [action.payload.source]: action.payload.totalCount },
                loadingLogs: { ...state.loadingLogs, [action.payload.source]: false },
                error: null,
                noData: !Array.isArray(action.payload.logs) || action.payload.logs.length === 0
            };
        case TYPES.LOAD_LOGS_FAILURE:
            return {
                ...state,
                logs: {},
                loadingLogs: { ...state.loadingLogs, [action.payload.source]: false },
                error: action.payload.error,
                noData: false
            };
        /**************************************
                PATCH CLOUD STREAM TARGET
        *************************************/
        case TYPES.PATCH_CLOUD_STREAM_TARGET_BEGIN:
            return {
                ...state,
                patchingCloudStreamTarget: true,
            };
        case TYPES.PATCH_CLOUD_STREAM_TARGET_SUCCESS:
            return {
                ...state,
                patchingCloudStreamTarget: false,
            };
        case TYPES.PATCH_CLOUD_STREAM_TARGET_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                patchingCloudStreamTarget: false,
            };
        default:
            return state;
    }
}