// libraries
import * as React from "react";
import { getEnumStrings } from "@caps-mobile/common-lib";
import { findSelectedIndecies } from "../dynamic-select-and-input/DynamicSelectAndInput";
// types & models
import * as FTypes from "@algo/network-manager/models/v3";
// components
import { FormCard} from "@caps-mobile/forms";
import { DynamicSelectAndInput } from "../dynamic-select-and-input/DynamicSelectAndInput";
// constants
const parkingTypeStrings = getEnumStrings(FTypes.EATParkingType);
const amenityTypeStrings = getEnumStrings(FTypes.EATAmenityType);
const phoneTypeStrings = getEnumStrings(FTypes.EATPhoneType);

export type ISelectAndInputFormProps = {
    formConfig: any;
    facilityObject: any;
    type: string;
    updateFacility: any;
    optionsList: any;
    selectVal: any;
    inputVal: any;
    updateSelectVal: any;
    updateInputVal: any;
} & React.HTMLAttributes<HTMLElement>;

export const SelectAndInputForm: React.FC<ISelectAndInputFormProps> = (props) => {

    const { 
        formConfig, facilityObject, type, 
        updateFacility, style, 
        optionsList, selectVal, inputVal,
        updateSelectVal, updateInputVal
    } = props;

    return(
        <FormCard config={formConfig} style={style}>
            <DynamicSelectAndInput 
                facilityObject={facilityObject} type={type}
                updateFacilityObject={updateFacility}
                optionsList={optionsList}
                selectVal={selectVal}
                inputVal={inputVal}
                updateSelectVal={updateSelectVal}
                updateInputVal={updateInputVal}
            />
        </FormCard>
    );
};

export type IParkingProps = {
    formConfig: any;
    facilityObject: any;
    updateFacility: any;
} & React.HTMLAttributes<HTMLElement>;

export const ParkingSpacesForm: React.FC<IParkingProps> = (props) => {

    const { formConfig, facilityObject, updateFacility, style } = props;

    return(
        <SelectAndInputForm 
            formConfig={formConfig} facilityObject={facilityObject} 
            type={"parkingSpaces"} updateFacility={updateFacility} 
            style={{width: "100%", marginTop: "12px", ...style}}
            optionsList={parkingTypeStrings}
            selectVal={
                (rowObject: FTypes.ATFacilityParking) => { 
                    return ( rowObject.type)
                        ? parkingTypeStrings.indexOf(rowObject.type)
                        : findNextUnselected(facilityObject, "parkingSpaces", parkingTypeStrings);
                }
            }
            inputVal={(rowObject: FTypes.ATFacilityParking) => { return rowObject.total}}
            updateSelectVal={(rowObject: FTypes.ATFacilityParking, newIndex: any) => {
                return { 
                    ...rowObject, 
                    type: FTypes.EATParkingType[
                        parkingTypeStrings[newIndex] as keyof typeof FTypes.EATParkingType
                    ]
                }
            }}
            updateInputVal={(rowObject: FTypes.ATFacilityParking, newValue: any) => {
                return { ...rowObject, total: parseInt(newValue)}}}
        />
    );
};

export type IAmenitiesProps = {
    formConfig: any;
    facilityObject: any;
    updateFacility: any;
} & React.HTMLAttributes<HTMLElement>;

export const AmenitiesForm: React.FC<IAmenitiesProps> = (props) => {

    const { formConfig, facilityObject, updateFacility, style } = props;

    return(
        <SelectAndInputForm 
            formConfig={formConfig} facilityObject={facilityObject} 
            type={"amenities"} updateFacility={updateFacility} 
            style={{width: "100%", marginTop: "12px", ...style}}
            optionsList={getEnumStrings(FTypes.EATAmenityType)}
            selectVal={
                (rowObject: FTypes.ATFacilityAmenity) => { 
                    return ( rowObject.type)
                        ? amenityTypeStrings.indexOf(rowObject.type)
                        : findNextUnselected(facilityObject, "amenities", amenityTypeStrings);
                }
            }
            inputVal={(rowObject: FTypes.ATFacilityAmenity) => { return rowObject.description}}
            updateSelectVal={
                (rowObject: FTypes.ATFacilityAmenity, newIndex: any) => {
                    return { 
                        ...rowObject, 
                        type: FTypes.EATAmenityType[
                            amenityTypeStrings[newIndex] as keyof typeof FTypes.EATAmenityType
                        ]
                    }
                }
            }
            updateInputVal={(rowObject: FTypes.ATFacilityAmenity, newValue: any) => {
                return { ...rowObject, description: newValue}}}
        />
    );
};

export type IPhonesProps = {
    formConfig: any;
    facilityObject: any;
    updateFacility: any;
} & React.HTMLAttributes<HTMLElement>;

export const PhonesForm: React.FC<IPhonesProps> = (props) => {

    const { formConfig, facilityObject, updateFacility, style } = props;

    return(
        <SelectAndInputForm 
            formConfig={formConfig} facilityObject={facilityObject} 
            type={"phones"} updateFacility={updateFacility} 
            style={{width: "100%", marginTop: "12px", ...style}}
            optionsList={getEnumStrings(FTypes.EATPhoneType)}
            selectVal={
                (rowObject: FTypes.ATFacilityPhone) => { 
                    return ( rowObject.type)
                        ? phoneTypeStrings.indexOf(rowObject.type)
                        : findNextUnselected(facilityObject, "phones", phoneTypeStrings);
                }
            }
            inputVal={(rowObject: FTypes.ATFacilityPhone) => { return rowObject.number}}
            updateSelectVal={
                (rowObject: FTypes.ATFacilityPhone, newIndex: any) => {
                    return { 
                        ...rowObject, 
                        type: FTypes.EATPhoneType[
                            phoneTypeStrings[newIndex] as keyof typeof FTypes.EATPhoneType
                        ]
                    }
                }
            }
            updateInputVal={(rowObject: FTypes.ATFacilityPhone, newValue: any) => {
                return { ...rowObject, number: newValue}}}
        />
    );
};

export default SelectAndInputForm;

export const findNextUnselected = (
    facilityObject: FTypes.ATFacility, 
    propertyName: string, 
    propertyList: string[]
): number | null => {

    let selectedIndecies: number[] = 
        findSelectedIndecies(facilityObject, propertyName, propertyList);

    for (let i = 0; i < propertyList.length; i++){
        if (!selectedIndecies.includes(i))
            return i;
    }

    return null;
}