//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../..";
import { Action } from "redux";
import { getAccessToken } from "../../../../components/template/authentication/oidcConfig";
//interfaces & models
import { IATCloudStreamTarget } from "@algo/network-manager/models/v3";
import {
    ICameraDeviceNetworkManager, CameraDeviceNetworkManager,
    IGetAllCameraDevices, D_GET_ALL_CAMERA_DEVICES, IProcessedResponse
} from "@algo/network-manager/managers/v3";
import {
    ICloudStreamTargetNetworkManager, CloudStreamTargetNetworkManager,
} from "@algo/network-manager/managers/v3";
import { IReduxData } from "../../../../utils/ReduxData";
import * as TYPES from "../types/pageStateTypes";
//enums
import { EATStreamAccessLevel } from "@algo/network-manager/models/v3";
//constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../../../api-version-constants";
declare var __API_URL__: string;
const apiUrlDevice: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).devices}`;
const apiUrlTarget: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).cloudStreamTargets}`;
const TOO_MANY_TO_SHOW: number = 31;

/******************************************************************************
    UPDATE PAGE STATE | ACTION CREATOR
 *****************************************************************************/

export const updatePageState = (newPageState: TYPES.State): TYPES.Actions => {
    return {
        type: TYPES.UPDATE_PAGE_STATE,
        payload: {
            pageState: newPageState,
        } as TYPES.Payload
    };
}

/******************************************************************************
    UPDATE ACCESS LEVELS | ACTION CREATOR
 *****************************************************************************/

export const updateAccessLevels = (
    targets: Array<IATCloudStreamTarget>, 
    accessLevel: EATStreamAccessLevel
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (targets.length === 0) return;

    let accessLevelData: IReduxData = getState().streamToolbar.accessLevelData;
    if (!accessLevelData.isLoading) {

        dispatch(updateAccessLevelsBegin());

        const manager: ICloudStreamTargetNetworkManager =
            new CloudStreamTargetNetworkManager(apiUrlTarget);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);

                    if (targets.length === 1) {
                        let newTarget: IATCloudStreamTarget = targets[0];
                        newTarget.accessLevel = accessLevel;

                        manager.update({id: newTarget.id, cst: newTarget})
                        .then(
                            (response: IProcessedResponse) => {
                                dispatch(
                                    updateAccessLevelsSuccess(response)
                                )
                            }
                        ).catch(
                            (err: Error) => 
                                dispatch(updateAccessLevelsFailure(err))
                        )
                    }
                    else {
                        Promise.all(
                            targets.map(
                                (target) => {
                                    let newTarget: IATCloudStreamTarget = target;
                                    newTarget.accessLevel = accessLevel;
                                    return manager.update({
                                        id: newTarget.id, 
                                        cst: newTarget
                                    })
                                }
                            )
                        )
                            .then(
                                (responseList: IProcessedResponse[]) => { 
                                    dispatch(updateAccessLevelsSuccess()); 
                                },
                                reject => {
                                    dispatch(updateAccessLevelsFailure(new Error(reject)));
                                }
                            )
                            .catch(
                                (e: Error) => {
                                    dispatch(updateAccessLevelsFailure(e));
                                }
                            )
                    }
                }
            ).catch(
                (err: Error) => dispatch(updateAccessLevelsFailure(err))
            )
        }
}

/******************************************************************************
    UPDATE ACCESS LEVELS | ACTIONS
 *****************************************************************************/

function updateAccessLevelsBegin(): TYPES.Actions {
    return {
        type: TYPES.UPDATE_ACCESS_LEVELS_BEGIN,
        payload: {} as TYPES.Payload
    };
}

function updateAccessLevelsSuccess(response?: IProcessedResponse): TYPES.Actions {
    return {
        type: TYPES.UPDATE_ACCESS_LEVELS_SUCCESS,
        payload: {
            response
        } as TYPES.Payload
    };
}

function updateAccessLevelsFailure(error: Error): TYPES.Actions {
    return {
        type: TYPES.UPDATE_ACCESS_LEVELS_FAILURE,
        payload: {
            error: error,
        } as TYPES.Payload
    };
}

/******************************************************************************
    SELECT ALL | ACTION CREATOR
 *****************************************************************************/

export const selectAll = (): ThunkAction<void, AppState, null, Action<string>> =>
    async (dispatch, getState) => {

        const req: IGetAllCameraDevices = D_GET_ALL_CAMERA_DEVICES;
        if (getState().streamPageState.search)
            req.search = getState().streamPageState.search;
        if (getState().streamPageState.selectedRegion)
            req.region = getState().streamPageState.selectedRegion;
        else req.region = undefined;

        let selectingAll: boolean = getState().streamPageState.selectingAll;

        if (!selectingAll) {                                                        // if not locked, start logic
            if (getState().streamPageState.selectedStreams.length !== 0) {          // if existing selection, just deselect all
                dispatch(selectAllDeselect());
                return;                                                             // no need to continue from here
            }

            dispatch(selectAllBegin());

            const manager: ICameraDeviceNetworkManager =
                new CameraDeviceNetworkManager(apiUrlDevice);

            getAccessToken()
                .then(
                    (token) => {
                        manager.setAccessToken(token);
                        manager.getAll(
                            getState().streamPageState.lastResponse,
                            req
                        ).then(
                            (response: any) => {
                                dispatch(selectAllSuccess(response));
                            },
                            (reject: any) => {
                                dispatch(selectAllFailure(new Error(reject)));
                            }
                        ).catch(
                            (error: Error) => {
                                dispatch(selectAllFailure(error));
                            }
                        )
                    }
                )
        }
}

/******************************************************************************
    SELECT ALL | ACTIONS
 *****************************************************************************/

function selectAllBegin(): TYPES.Actions {
    return {
        type: TYPES.SELECT_ALL_BEGIN,
        payload: {} as TYPES.Payload
    };
}

function selectAllSuccess(response: IProcessedResponse): TYPES.Actions {
    return {
        type: TYPES.SELECT_ALL_SUCCESS,
        payload: {
            selectedStreams: response.data,
            showAllSelectedStreams: ( response.data.length < TOO_MANY_TO_SHOW ),
        } as TYPES.Payload
    };
}

function selectAllDeselect(): TYPES.Actions {
    return {
        type: TYPES.SELECT_ALL_DESELECT,
        payload: {
            selectedStreams: [],
            showAllSelectedStreams: true,
        } as TYPES.Payload
    }
}

function selectAllFailure(error: Error): TYPES.Actions {
    return {
        type: TYPES.SELECT_ALL_FAILURE,
        payload: {
            error: error,
        } as TYPES.Payload
    };
}

