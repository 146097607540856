import * as TYPES from "../types/toolbarTypes";
import { ReduxData, cloneReduxData as clone } from "../../../../utils/ReduxData";
import { ProcessedResponse } from "@algo/network-manager/managers/v3";

let initialState: TYPES.State = {
    datasourceData: new ReduxData({ name: "datasources" }),
    accessLevelData: new ReduxData({ name: "accessLevelData" }),
    error: null,
    lastResponse: new ProcessedResponse()
};

export function streamToolbarReducer(
    state = initialState, 
    action: TYPES.Actions
): TYPES.State {

    let tempData: any = null;
    switch (action.type) {

        /******************************************************************************
            LOAD DATASOURCES | REDUCERS
        *****************************************************************************/
        case TYPES.LOAD_DATASOURCES_BEGIN:
            tempData = clone(state.datasourceData);
            tempData.setLoading(true);
            return {
                ...state,
                datasourceData: tempData,
            };
        case TYPES.LOAD_DATASOURCES_SUCCESS:
            tempData = clone(state.datasourceData);
            tempData.data = action.payload.datasources;
            tempData.setLoading(false);
            return {
                ...state,
                datasourceData: tempData,
                lastResponse: action.payload.response
            };
        case TYPES.LOAD_DATASOURCES_FAILURE:
            tempData = clone(state.datasourceData);
            tempData.setLoading(false);
            return {
                ...state,
                datasourceData: tempData,
                error: action.payload.error
            };
            
        /******************************************************************************
            UPDATE ACCESS LEVEL | REDUCERS
        *****************************************************************************/
        case TYPES.UPDATE_ACCESS_LEVEL_BEGIN:
            tempData = clone(state.accessLevelData);
            tempData.setLoading(true);
            return {
                ...state,
                accessLevelData: tempData,
            };
        case TYPES.UPDATE_ACCESS_LEVEL_SUCCESS:
            tempData = clone(state.accessLevelData);
            tempData.setLoading(false);
            return {
                ...state,
                accessLevelData: tempData,
                lastResponse: action.payload.response
            };
        case TYPES.UPDATE_ACCESS_LEVEL_FAILURE:
            tempData = clone(state.accessLevelData);
            tempData.setLoading(false);
            return {
                ...state,
                accessLevelData: tempData,
            };
        default:
            return state;
    }
}