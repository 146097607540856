import { 
    combineReducers, createStore, applyMiddleware, compose
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { messageDashboardReducer } from './message/dashboard/reducers';
import { messageEditorReducer } from './message/editor/reducers';
import { facilityDashboardReducer } from './facility/dashboard/reducers';
import { facilityEditorReducer } from './facility/editor/reducers';
import { feedbackDashboardReducer } from './feedback/dashboard/reducers';
import { feedbackDetailReducer } from './feedback/detail/reducers';
import { datasourceReducer } from './datasource/reducers';
import { profileReducer } from './profile/reducers';
import { organizationDashboardReducer } from './organization/dashboard/reducers';
import { organizationEditorReducer } from './organization/editor/reducers';
import { roleDashboardReducer } from './role/dashboard/reducers';
import { roleEditorReducer } from './role/editor/reducers';
import { userDashboardReducer } from './user/dashboard/reducers';
import { userEditorReducer } from './user/editor/reducers';
import { synchronizationReducer } from './synchronization/reducers';
import { streamPreviewsReducer } from "./cameraDevice/dashboard/reducers/streamPreviewsReducer";
import { streamStatusReducer } from "./cameraDevice/dashboard/reducers/streamStatusReducer";
import { streamToolbarReducer } from "./cameraDevice/dashboard/reducers/toolbarReducer";
import { pageStateReducer as streamPageStateReducer } from "./cameraDevice/dashboard/reducers/pageStateReducer";
import { cameraDetailsReducer } from './cameraDevice/details/reducers/reducers';

let rootReducer = combineReducers({
    messageDashboard: messageDashboardReducer,
    messageEditor: messageEditorReducer,
    feedbackDashboard: feedbackDashboardReducer,
    feedbackDetail: feedbackDetailReducer,
    facilityDashboard: facilityDashboardReducer,
    facilityEditor: facilityEditorReducer,
    datasource: datasourceReducer,
    profile: profileReducer,
    organizationDashboard: organizationDashboardReducer,
    organizationEditor: organizationEditorReducer,
    roleDashboard: roleDashboardReducer,
    roleEditor: roleEditorReducer,
    userDashboard: userDashboardReducer,
    userEditor: userEditorReducer,
    synchronization: synchronizationReducer,
    streamPageState: streamPageStateReducer,
    streamPreviews: streamPreviewsReducer,
    streamStatus: streamStatusReducer,
    streamToolbar: streamToolbarReducer,
    cameraDetails: cameraDetailsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

let createStoreWithMiddleware = compose(
    applyMiddleware(
        thunkMiddleware, 
    )
)(createStore);

let initialState = {} as AppState;

let store = createStoreWithMiddleware(rootReducer, initialState);

export default store;