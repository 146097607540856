// interfaces & models
import { IReduxData } from "../../../../utils/ReduxData";
import { IATCameraDeviceDto, IATCameraDeviceStreamStatusDto } from "@algo/network-manager/models/v3";
import { IProcessedResponse } from "@algo/network-manager/managers/v3";
// constants
export const LOAD_CAMERA_DEVICES_BEGIN = "LOAD_CAMERA_DEVICES_BEGIN";
export const LOAD_CAMERA_DEVICES_SUCCESS = "LOAD_CAMERA_DEVICES_SUCCESS";
export const LOAD_CAMERA_DEVICES_FAILURE = "LOAD_CAMERA_DEVICES_FAILURE";

export const LOAD_CAMERA_DEVICE_STATUS_BEGIN = "LOAD_CAMERA_DEVICE_STATUS_BEGIN";
export const LOAD_CAMERA_DEVICE_STATUS_SUCCESS = "LOAD_CAMERA_DEVICE_STATUS_SUCCESS";
export const LOAD_CAMERA_DEVICE_STATUS_FAILURE = "LOAD_CAMERA_DEVICE_STATUS_FAILURE";

export interface IStatusData {
    [key: number]: IATCameraDeviceStreamStatusDto;
}

export interface IStatusLoading {
    [key: number]: boolean;
}

export interface State {
    cameraData: IReduxData;
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface Payload {
    devices: Array<IATCameraDeviceDto>;
    totalCount: number;
    checksum: number;
    error: Error | null;
    response: IProcessedResponse;
}

/************************************************
    LOAD CAMERA DEVICES | ACTION TYPES
 ***********************************************/
interface LoadCameraDevicesBeginAction {
    type: typeof LOAD_CAMERA_DEVICES_BEGIN;
    payload: Payload;
}

interface LoadCameraDevicesSuccessAction {
    type: typeof LOAD_CAMERA_DEVICES_SUCCESS;
    payload: Payload;
}

interface LoadCameraDevicesFailureAction {
    type: typeof LOAD_CAMERA_DEVICES_FAILURE;
    payload: Payload;
}

export type Actions =
    // LOAD CAMERA DEVICES
    LoadCameraDevicesBeginAction |
    LoadCameraDevicesSuccessAction |
    LoadCameraDevicesFailureAction;

