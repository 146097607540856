import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";

import Content from '../../base/layout/Content';
import FeedbackTableView from './FeedbackTableView';
import { AppState } from "../../../store";

interface StateProps { 
}

function mapStateToProps(state: AppState) {
    return { 
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        
    }, dispatch);
}

type FeedbackListProps = 
    StateProps & 
    RouteComponentProps<any>;

class FeedbackList extends React.Component<FeedbackListProps> {

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-admin-dashboard-content fluid'>
                            <div className='av-feedback-dashboard'>
                                <FeedbackTableView
                                    showSearch={true}
                                    showPageSizeSelector={false}
                                />
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(FeedbackList));