import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { IATAldotMessage } from "@algo/network-manager/models/v3";

export let LOAD_MESSAGE_BEGIN = 'LOAD_MESSAGE_BEGIN';
export let LOAD_MESSAGE_SUCCESS = 'LOAD_MESSAGE_SUCCESS';
export let LOAD_MESSAGE_FAILURE = 'LOAD_MESSAGE_FAILURE';

export let SAVE_MESSAGE_BEGIN = 'SAVE_MESSAGE_BEGIN';
export let SAVE_MESSAGE_SUCCESS = 'SAVE_MESSAGE_SUCCESS';
export let SAVE_MESSAGE_FAILURE = 'SAVE_MESSAGE_FAILURE';

export let CLEAR_MESSAGE_EDITOR_ERROR = 'CLEAR_MESSAGE_EDITOR_ERROR';

export let UNINITIALIZE_MESSAGE_EDITOR = 'UNINITIALIZE_MESSAGE_EDITOR';

export interface MessageEditorState {
    initializing: boolean;
    savingMessage: boolean;
    message: IATAldotMessage;
    error: Error | null;
    lastMessageResponse: IProcessedResponse;
}

export interface MessageEditorPayload {
    message?: IATAldotMessage;
    error?: Error;
    response?: IProcessedResponse;
}

interface LoadMessageBeginAction {
    type: typeof LOAD_MESSAGE_BEGIN;
    payload: MessageEditorPayload
}

interface LoadMessageSuccessAction {
    type: typeof LOAD_MESSAGE_SUCCESS;
    payload: MessageEditorPayload;
}

interface LoadMessageFailureAction {
    type: typeof LOAD_MESSAGE_FAILURE;
    payload: MessageEditorPayload;
}

interface SaveMessageBeginAction {
    type: typeof SAVE_MESSAGE_BEGIN;
    payload: MessageEditorPayload;
}

interface SaveMessageSuccessAction {
    type: typeof SAVE_MESSAGE_SUCCESS;
    payload: MessageEditorPayload;
}

interface SaveMessageFailureAction {
    type: typeof SAVE_MESSAGE_FAILURE;
    payload: MessageEditorPayload;
}

interface ClearMessageEditorErrorAction {
    type: typeof CLEAR_MESSAGE_EDITOR_ERROR;
    payload: MessageEditorPayload;
}

interface UninitializeMessageEditorAction {
    type: typeof UNINITIALIZE_MESSAGE_EDITOR;
    payload: MessageEditorPayload;
}

export type MessageEditorTypes = 
    LoadMessageBeginAction |
    LoadMessageSuccessAction |
    LoadMessageFailureAction |
    SaveMessageBeginAction |
    SaveMessageSuccessAction |
    SaveMessageFailureAction |
    ClearMessageEditorErrorAction |
    UninitializeMessageEditorAction;