// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
// components
import Content from '../../base/layout/Content';
import RoleTableView from "./RoleTableView";
// store
import { AppState } from "../../../store";

interface StateProps { 
}

function mapStateToProps(state: AppState) {
    return { 
    };
}

type RoleListProps = StateProps & RouteComponentProps<any>;

class RoleList extends React.Component<RoleListProps> {
    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-admin-dashboard-content fluid'>
                            <RoleTableView />
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
)(withRouter(RoleList));