// libraries
import styled, { CSSProperties } from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledEditLocation = styled.div`
    height: 90%;
    width: 90%;

    padding: 1rem;

    background-color: ${ props => props.theme.colors["bg-primary"]["rgba"]};
    border-radius: 4px;

    ${flexSmart("flex-start center")}
    flex-direction: column;    
`;

export const StyledContent = styled.div`
    height: calc(100% - 41px);
    width: 100%;

    ${flexSmart("center center")}
    flex-direction: column;
`;

export const StyledFooter = styled.div`
    height: 41px;
    width: 100%;

    ${flexSmart("space-evenly center")}
`;

export const StyledLoadError = styled.div`
    ${flexSmart("center center")}
    flex-direction: column;  
`;

export const StyledLoadFail = styled.span`
    margin-bottom: 12px;

    color: "red";
`;

export const StyledTryAgain = styled.span`
    margin-bottom: 8px;
`;

export const StyledPrimaryContent = styled.div`
    width: 100%;
    height: 100%;

    padding: 0 2rem 0 2rem;
`;

export const StyledFormsWrapper = styled.div`
    ${flexSmart("center stretch")}
`;

export const StyledMapWrapper = styled.div`
    height: calc(100% - 180px - 1rem);
`;

/**************************************************************************
    SUPPLEMENTAL STYLE OBJECTS
*************************************************************************/

export const formCard1Styles: CSSProperties = {
    height: "auto",
    paddingTop: 0
}