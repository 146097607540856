// libraries
import * as React from "react";
import { fixedFloat } from "@caps-mobile/common-lib";
// types & models
import { 
    IATLocation, ATFacilityRoute, ATTravelTime, 
    IATFacilityMapSetting, EATDirectionAbbrev 
} from "@algo/network-manager/models/v3";
// components
import { FormCard } from "@caps-mobile/forms";
import { DynamicEditDelete } from "../dynamic-edit-delete/DynamicEditDelete";
import { FormDynamicRow } from "@caps-mobile/forms";
import { InfoBanner } from "@caps-mobile/banner";

export type IStringAndModalFormProps = {
    formConfig: any;
    facilityObject: any;
    type: string;
    displayString: any;
    editClick: any;
    updateFacility: any;
} & React.HTMLAttributes<HTMLElement>;

export const StringAndModalForm: React.FC<IStringAndModalFormProps> = (props) => {

    const { 
        formConfig, facilityObject, type, style, 
        displayString, editClick, updateFacility,
    } = props;

    return(
        <FormCard config={formConfig} style={style}>
            <DynamicEditDelete 
                facilityObject={facilityObject} type={type}
                displayString={displayString}
                editClick={editClick} updateFacilityObject={updateFacility}
            />
        </FormCard>
    );
};

export type ILocationsProps = {
    formConfig: any;
    facilityObject: any;
    editClick: any;
    updateFacility: any;
} & React.HTMLAttributes<HTMLElement>;

export const LocationsForm: React.FC<ILocationsProps> = (props) => {

    const { 
        formConfig, facilityObject, style, 
        editClick, updateFacility, 
    } = props;

    return(
        <StringAndModalForm 
            formConfig={formConfig} type={"locations"}
            facilityObject={facilityObject} updateFacility={updateFacility}
            style={{width: "100%", ...style}} editClick={editClick}
            displayString={
                (rowObject: IATLocation) => { 
                    return `(${fixedFloat(rowObject.latitude, 8)}, ${fixedFloat(rowObject.longitude, 8)})` + 
                    ` @ ${rowObject.displayRouteDesignator}` +
                    ` ${EATDirectionAbbrev[rowObject.direction]}`;
                }
            }
        />
    );
};

export type IMapSettingsProps = {
    formConfig: any;
    facilityObject: any;
    editClick: any;
    updateFacility: any;
} & React.HTMLAttributes<HTMLElement>;

export const MapSettingsForm: React.FC<IMapSettingsProps> = (props) => {

    const { 
        formConfig, facilityObject, style, 
        editClick, updateFacility, 
    } = props;

    return(
        <StringAndModalForm 
            formConfig={formConfig} type={"mapSettings"}
            facilityObject={facilityObject} updateFacility={updateFacility}
            style={{width: "100%", marginTop: "12px", ...style}} editClick={editClick}
            displayString={
                (rowObject: IATFacilityMapSetting) => { 
                    return `(${fixedFloat(rowObject.centerLatitude, 8)}, ${fixedFloat(rowObject.centerLongitude, 8)})` + 
                    ` @ ${rowObject.zoomLevel} Zoom Level`;
                }
            }
        />
    );
};

export type IRoutesOfInterestProps = {
    formConfig: any;
    facilityObject: any;
    editClick: any;
    updateFacility: any;
} & React.HTMLAttributes<HTMLElement>;

export const RoutesOfInterestForm: React.FC<IRoutesOfInterestProps> = (props) => {

    const { 
        formConfig, facilityObject, style, 
        editClick, updateFacility, 
    } = props;

    return(
        <StringAndModalForm 
            formConfig={formConfig} type={"routesOfInterest"}
            facilityObject={facilityObject} updateFacility={updateFacility}
            style={{width: "100%", marginTop: "12px", ...style}} editClick={editClick}
            displayString={
                (rowObject: ATFacilityRoute) => { 
                    return `${rowObject.place.name} ${EATDirectionAbbrev[rowObject.direction]}` + 
                    ` from MP ${rowObject.startLinearReference}` + ` to MP ${rowObject.endLinearReference}`;
                }
            }
        />
    );
};

export type ITravelTimesProps = {
    formConfig: any;
    facilityObject: any;
} & React.HTMLAttributes<HTMLElement>;

export const TravelTimesForm: React.FC<ITravelTimesProps> = (props) => {

    const { 
        formConfig, facilityObject, style, 
    } = props;

    return(
        <FormCard 
            config={formConfig} 
            style={{width: "100%", marginTop: "12px", marginBottom: "12px", ...style}}
        >
            <FormDynamicRow>
                <InfoBanner>
                    {"Routes are editable "}<b>&nbsp;only&nbsp;</b>{" in the ATMS"}
                </InfoBanner>
            </FormDynamicRow>
            {
                facilityObject.travelTimes?.map(
                    (rowObject: ATTravelTime, mapIndex: number) => {

                        let displayString: string = 
                            `${rowObject.name}` + 
                            ` (${rowObject.estimatedTimeMinutes} minutes, ` +
                            `${rowObject.averageSpeedMph} mph)`;
                            
                        return (
                            <FormDynamicRow key={`${rowObject.id}-${mapIndex}`}>
                                {displayString}
                            </FormDynamicRow>
                        )
                    }
                )
            }
        </FormCard>
    );
};

export default StringAndModalForm;