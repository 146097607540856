//libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
//enums
import { ELogType } from "./LogSummary";
import { EATStreamServerLogSource } from "@algo/network-manager/models/v3";
// @ts-ignore
import { determinePageCount } from "@algo/pager";
//redux-store
import {
    CameraDetailsState
} from "../../../store/cameraDevice/details/types/types";
import { AppState } from "../../../store";
//redux-actions
import {
    loadLogs
} from "../../../store/cameraDevice/details/actions/loadActions";
//types
import { IReactSelectOption } from "../../../types/common";
//components
import Select from "react-select";
import { Container, Row, Col } from "reactstrap";
import { NavButton } from "./NavButton";
import { PagedTab } from "./PagedTab";

interface IStateProps {
    details?: CameraDetailsState;
}

interface IDispatchProps {
    loadLogs?: typeof loadLogs;
}

let mapStateToProps = (state: AppState) => {
    return { 
        details: state.cameraDetails
    }
};

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadLogs: loadLogs,
    }, dispatch);
};

type StreamLogNavigatorTypes = IStateProps & IDispatchProps;

export const StreamLogNavigator: React.FC<StreamLogNavigatorTypes> = (props) => {

    /***********************************************************
        CALCULATED VALUES & BREVITY NAMES
    ***********************************************************/

    const selectLabels: Array<number> = [5, 10, 15, 20, 25, 50, 75, 100];                                               //string labels to fill Select dropdown objects
    const selectOptions: Array<IReactSelectOption> = selectLabels.map(                                                  //array of objects to pass as prop to Select
        (label) => { return { value: label, label: `${label}` } }
    );

        /***********************************************************
            STATE
        ***********************************************************/
        const [state, setState] = React.useState<any>({
            selectedOption: selectOptions[4],
            navIndex: EATStreamServerLogSource.LocalIncomingStream,
            pageIndex: {
                [EATStreamServerLogSource.LocalIncomingStream]: 0,
                [EATStreamServerLogSource.DistributorIncomingStream]: 0,
                [EATStreamServerLogSource.DistributorPushPublishEntry]: 0
            },
            localPageCount: determinePageCount(props.details?.totalCounts[0] ?? 0, selectOptions[0].value),
            localPageIndex: 0,
            distributorPageCount: determinePageCount(props.details?.totalCounts[1] ?? 0, selectOptions[0].value),
            distributorPageIndex: 0,
            cloudPageCount: determinePageCount(props.details?.totalCounts[2] ?? 0, selectOptions[0].value),
            cloudPageIndex: 0,
        });

    /***********************************************************
        CALLBACKS & EVENT HANDLERS
    ***********************************************************/
    const updateNavIndex = (newIndex: EATStreamServerLogSource) => {
        setState({ ...state, navIndex: newIndex });
    }

    const updatePageIndex = (propertySelector: string, newIndex: number): void => {
        setState({
            ...state,
            pageIndex: { ...state.pageIndex, [propertySelector]: newIndex },
        });
    }

    /***********************************************************
        PRIMARY RENDER
    ***********************************************************/

    return (
        <Container fluid={true}>

            {/* Nav Row */}
            <Row className="mb-2">
                <Col xs="2" className="pl-0 pr-1">
                    <NavButton
                        update={() => { updateNavIndex(EATStreamServerLogSource.LocalIncomingStream) }}
                        active={state.navIndex === EATStreamServerLogSource.LocalIncomingStream}
                        label={"Local"}
                    />
                </Col>
                <Col xs="2 px-1">
                    <NavButton
                        update={() => { updateNavIndex(EATStreamServerLogSource.DistributorIncomingStream) }}
                        active={state.navIndex === EATStreamServerLogSource.DistributorIncomingStream}
                        label={"Distributor"}
                    />
                </Col>
                <Col xs="2 pl-1">
                    <NavButton
                        update={() => { updateNavIndex(EATStreamServerLogSource.DistributorPushPublishEntry) }}
                        active={state.navIndex === EATStreamServerLogSource.DistributorPushPublishEntry}
                        label={"Cloud"}
                    />
                </Col>
                <Col xs="6" className="px-0">
                    <div className="d-flex justify-content-end align-items-center h-100">
                        <Select
                            className="w-25"
                            options={selectOptions}
                            value={state.selectedOption}
                            onChange={(newValue: any) => {
                                setState({
                                    ...state,
                                    selectedOption: newValue,
                                })
                            }}
                        />
                    </div>
                </Col>
            </Row>

            {/*Logs Row*/}
            <Row>
                <Col className="px-0">
                    {!props.details?.loadingCameraDevice && props.details?.device &&
                        <PagedTab
                            logs={props.details.logs[state.navIndex]}
                            loadLogs={props.loadLogs}
                            navIndex={state.navIndex}
                            deviceId={props.details.device.id}
                            loadingLogs={props.details.loadingLogs[state.navIndex]}
                            error={props.details.error}
                            noData={props.details.noData}
                            type={
                                (
                                    state.navIndex === EATStreamServerLogSource.DistributorPushPublishEntry ||
                                    state.navIndex === EATStreamServerLogSource.LocalPushPublishEntry
                                )
                                    ? ELogType.PushPublishEntry
                                    : ELogType.IncomingStream
                            }
                            perPage={state.selectedOption.value}
                            pageCount={determinePageCount(props.details.totalCounts[state.navIndex], state.selectedOption.value)}
                            pageIndex={state.pageIndex[state.navIndex]}
                            updatePageIndex={(newIndex: number) => {
                                updatePageIndex(state.navIndex, newIndex)
                            }}
                        />
                    }
                </Col>
            </Row>

        </Container>  
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StreamLogNavigator);