// libraries
// interfaces & models
import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { 
    IATIncomingStreamLog, IATStreamFile, IATCameraDevice,
    EATStreamServerLogSource, IATPushPublishEntryLog
} from "@algo/network-manager/models/v3";
import { 
    IStringBoolDictionary, IStringNumberDictionary 
} from "../../../../types/common";

export const LOAD_CAMERA_DEVICE_BEGIN = 'LOAD_CAMERA_DEVICE_BEGIN';
export const LOAD_CAMERA_DEVICE_SUCCESS = 'LOAD_CAMERA_DEVICE_SUCCESS';
export const LOAD_CAMERA_DEVICE_FAILURE = 'LOAD_CAMERA_DEVICE_FAILURE';

export const LOAD_LOGS_BEGIN = "LOAD_LOGS_BEGIN";
export const LOAD_LOGS_SUCCESS = "LOAD_LOGS_SUCCESS";
export const LOAD_LOGS_FAILURE = "LOAD_LOGS_FAILURE";

export const PATCH_CLOUD_STREAM_TARGET_BEGIN = 'PATCH_CLOUD_STREAM_TARGET_BEGIN';
export const PATCH_CLOUD_STREAM_TARGET_SUCCESS = 'PATCH_CLOUD_STREAM_TARGET_SUCCESS';
export const PATCH_CLOUD_STREAM_TARGET_FAILURE = 'PATCH_CLOUD_STREAM_TARGET_FAILURE';

export interface CameraDetailsState {
    //data
    device: IATCameraDevice;
    logs: any;
    checksum: number;
    totalCount: number;
    checksums: IStringNumberDictionary;
    totalCounts: IStringNumberDictionary;
    //data locks
    loadingCameraDevice: boolean;
    loadingLogs: IStringBoolDictionary;
    patchingCloudStreamTarget: boolean;
    error: Error | null;
    noData: boolean;
    lastResponse: IProcessedResponse;
}

export interface CameraDetailsPayload {
    device: IATCameraDevice;
    logs: Array<IATIncomingStreamLog | IATPushPublishEntryLog>;
    source: EATStreamServerLogSource;
    checksum: number;
    totalCount: number;
    checksums: Array<any>;
    totalCounts: Array<any>;
    streamFiles: Array<IATStreamFile>;
    error: Error | null;
    response: IProcessedResponse;
}

/**************************************
            LOAD CAMERA DEVICE
*************************************/
interface LoadCameraDeviceBeginAction {
    type: typeof LOAD_CAMERA_DEVICE_BEGIN,
    payload: CameraDetailsPayload
}

interface LoadCameraDeviceSuccessAction {
    type: typeof LOAD_CAMERA_DEVICE_SUCCESS,
    payload: CameraDetailsPayload
}

interface LoadCameraDeviceFailureAction {
    type: typeof LOAD_CAMERA_DEVICE_FAILURE,
    payload: CameraDetailsPayload
}

/**************************************
            LOAD LOGS
*************************************/
interface LoadLogsBeginAction {
    type: typeof LOAD_LOGS_BEGIN,
    payload: CameraDetailsPayload
}

interface LoadLogsSuccessAction {
    type: typeof LOAD_LOGS_SUCCESS,
    payload: CameraDetailsPayload
}

interface LoadLogsFailureAction {
    type: typeof LOAD_LOGS_FAILURE,
    payload: CameraDetailsPayload
}

/**************************************
        PATCH CLOUD STREAM TARGET
*************************************/
interface PatchCloudStreamTargetBeginAction {
    type: typeof PATCH_CLOUD_STREAM_TARGET_BEGIN,
    payload: CameraDetailsPayload
}

interface PatchCloudStreamTargetSuccessAction {
    type: typeof PATCH_CLOUD_STREAM_TARGET_SUCCESS,
    payload: CameraDetailsPayload
}

interface PatchCloudStreamTargetFailureAction {
    type: typeof PATCH_CLOUD_STREAM_TARGET_FAILURE,
    payload: CameraDetailsPayload
}

export type CameraDetailsTypes =
    LoadCameraDeviceBeginAction |
    LoadCameraDeviceSuccessAction |
    LoadCameraDeviceFailureAction |
    LoadLogsBeginAction |
    LoadLogsSuccessAction |
    LoadLogsFailureAction |
    PatchCloudStreamTargetBeginAction |
    PatchCloudStreamTargetSuccessAction |
    PatchCloudStreamTargetFailureAction;
