// libraries
import * as React from 'react';
// components
import Content from '../base/layout/Content';

export default class Welcome extends React.Component {

    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-message-content'>
                            <h1 className='sr-only'>Welcome</h1>
                            <div className='message-container'>
                                <div className='av-center-alert-container'>
                                    <div className='av-welcome-alert'>
                                        Select a tool from the sidebar
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}