import { nuon } from "@caps-mobile/common-lib";
import { ReactSelectNumberKeyListItem, ReactSelectStringKeyListItem } from "../models/ReactSelectListItems";

export let DATE_STRING_FORMAT = 'MM/DD/YYYY hh:mm A z';
export let DATE_PART_STRING_FORMAT = 'MMMM DD, YYYY';
export let TIME_PART_STRING_FORMAT = 'hh:mm A z';

export let STREAM_TIMEOUT_INTERVAL: number = 3600000;
export let PROFILE_REFRESH_INTERVAL: number = 60000;
export let SERVICE_SYNCHRONIZATION_REFRESH_INTERVAL: number = 60000;
export let DEFAULT_SERVICE_SYNCHRONIZATION_HISTORY_COUNT: number = 10;
export let DEFAULT_ORGANIZATION_ID: number = 0;
export let DEFAULT_USER_SUB: string = '00000000-0000-0000-0000-000000000000';
export let DEFAULT_QUERY: string = '';
export let DEFAULT_DATASOURCE_ID = 0;
export let DEFAULT_PAGE_INDEX: number = 0;
export let DEFAULT_PAGE_SIZE: number = 25;
export let DEFAULT_TOTAL_PAGES: number = 0;
export let ALL_ORGANIZATION_ID: number = 0;
export let DEFAULT_SELECTOR_KEY: number = 0;

export let ORGANIZATION_NAME = 'ORGANIZATION_NAME';
export let ORGANIZATION_DESCRIPTION = 'ORGANIZATION_DESCRIPTION';
export let ORGANIZATION_ROLES = 'ORGANIZATION_ROLES';
export let ORGANIZATION_DATASOURCES = 'ORGANIZATION_DATASOURCES';
export let ORGANIZATION_PARENT_ORGANIZATION = 'ORGANIZATION_PARENT_ORGANIZATION';
export let ORGANIZATION_CHILD_ORGANIZATION = 'ORGANIZATION_CHILD_ORGANIZATION';
export let ORGANIZATION_ADD_USER = 'ORGANIZATION_ADD_USER';
export let ORGANIZATION_COLUMNS_NUMBER = 3;

// export all org constants as a brevity package
export let ORG_CONS = {
    id: DEFAULT_ORGANIZATION_ID,
    name: ORGANIZATION_NAME,
    description: ORGANIZATION_DESCRIPTION,
    roles: ORGANIZATION_ROLES,
    datasources: ORGANIZATION_DATASOURCES,
    parent: ORGANIZATION_PARENT_ORGANIZATION,
    child: ORGANIZATION_CHILD_ORGANIZATION,
    addUser: ORGANIZATION_ADD_USER,
    colNum: ORGANIZATION_COLUMNS_NUMBER
}

export let USER_GIVEN_NAME = 'USER_FORM_GIVEN_NAME';
export let USER_MIDDLE_NAME = 'USER_MIDDLE_NAME';
export let USER_FAMILY_NAME = 'USER_FAMILY_NAME';
export let USER_USER_NAME = 'USER_USER_NAME';
export let USER_EMAIL = 'USER_EMAIL';
export let USER_PASSWORD = 'USER_PASSWORD';
export let USER_PASSWORD_CONFIRM = 'USER_PASSWORD_CONFIRM';
export let USER_ORGANIZATION_ID = 'USER_ORGANIZATION_ID';
export let USER_ROLE_ID = 'USER_ROLE_ID';
export let USER_STATUS_ID = 'USER_STATUS_ID';
export let USER_STATUS_OPTIONS = [
    {
        value: 0,
        label: 'Active'
    },
    {
        value: 1,
        label: 'Locked'
    },
    {
        value: 2,
        label: 'Password Expired'
    },
    {
        value: 3,
        label: 'Inactive'
    },
    {
        value: 4,
        label: 'Deleted'
    }
] as ReactSelectNumberKeyListItem[];
export let USER_TYPE = 'USER_TYPE';
export let USER_COLUMNS_NUMBER: number = 4;

// export all the user constants as a brevity package
export let USER_CONS = {
    sub: DEFAULT_USER_SUB,
    givenName: USER_GIVEN_NAME,
    middleName: USER_MIDDLE_NAME,
    familyName: USER_FAMILY_NAME,
    userName: USER_USER_NAME,
    email: USER_EMAIL,
    password: USER_PASSWORD,
    passwordConfirm: USER_PASSWORD_CONFIRM,
    orgId: USER_ORGANIZATION_ID,
    roleId: USER_ROLE_ID,
    statusId: USER_STATUS_ID,
    statusOptions: USER_STATUS_OPTIONS,
    type: USER_TYPE,
    colNum: USER_COLUMNS_NUMBER
};

export let MESSAGE_DEFAULT_ID: number = 0;
export let MESSAGE_TITLE = 'MESSAGE_TITLE';
export let MESSAGE_BODY = 'MESSAGE_TEXT';
export let MESSAGE_DATE_START = 'MESSAGE_DATE_START';
export let MESSAGE_DATE_END = 'MESSAGE_DATE_END';
export let MESSAGE_COLUMNS_NUMBER: number = 5;
export let MESSAGE_AUDIENCES = 'MESSAGE_AUDIENCES';
export let MESSAGE_AUDIENCE_OPTIONS = [
    {
        value: 'Admin',
        label: 'Admin'
    },
    {
        value: 'Dashboard',
        label: 'Dashboard'
    },
    {
        value: 'Traffic',
        label: 'Traffic'
    },
    {
        value: 'Video',
        label: 'Video'
    },
    {
        value: 'None',
        label: 'None'
    }
] as ReactSelectStringKeyListItem[];

export const FACILITY_CONSTANTS = {
    defaultId: 0,
    formInputStyles: {
        labelWidth: "25%",
        inputWidth: "calc(75% - 12px)",
        inputHeight: "41px"
    },
    WARN_DUPLICATE_PROP: `
        Facilities may only possess one of each 
        'Type' of Parking, Amenity, or Phone.`,
    WARN_RESET: `
        Are you sure you want to undo changes? 
        All changes since last save will be lost.`,
    WARN_DUPLICATE_MAP_SETTING: `
        Facilities may only possess one Map View Setting.
    `
}
export let FEEDBACK_DEFAULT_ID: number = 0;
export let FEEDBACK_RESPONSE_MESSAGE = 'FEEDBACK_RESPONSE_MESSAGE';
export let FEEDBACK_COLUMNS_NUMBER: number = 4;

export let ROLE_COLUMNS_NUMBER: number = 2;
export let ROLE_DEFAULT_ID: number = 0;
export let ROLE_DESCRIPTION = 'ROLE_DESCRIPTION';
export let ROLE_PRIVILEGES = 'ROLE_PRIVILEGES';
export let ROLE_ORGANIZATIONS = 'ROLE_ORGANIZATIONS';

export let CAMERA_DEFAULT_ID: number = 0;
export let CAMERA_ACCESS_LEVEL_ID:string = "CAMERA_ACCESS_LEVEL_ID";
export let CAMERA_DATASOURCE_ID: string = "CAMERA_DATASOURCE_ID";
export let CAMERA_ACCESS_LEVEL_OPTIONS = [
    {
        value: 1,
        label: 'Full Access'
    },
    {
        value: 2,
        label: 'Media'
    },
    {
        value: 3,
        label: 'First Responder'
    },
    {
        value: 4,
        label: 'Private Sector'
    },
    {
        value: 5,
        label: 'TMC Operator'
    }
] as ReactSelectNumberKeyListItem[];

export let CAMERA_ACCESS_LEVEL_LEGEND = [
    {
        label: "Full Access",
        info: "ALDOT, TMC, First Responders, Media, and Private Sector users and the public via ALGO Traffic",
    },
    {
        label: "Media",
        info: "ALDOT, TMC, First Responders, and Media users"
    },
    {
        label: "First Responders",
        info: "ALDOT, TMC, and First Responders users"
    },
    {
        label: "Private Sector",
        info: "ALDOT, TMC, First Responders, Media, and Private Sector users"
    },
    {
        label: "TMC Operator",
        info: "ALDOT and TMC users"
    }
] as any;

export const CAMERA_PREVIEWS_MAX_SELECTION = 25;
export const CAMERA_PREVIEWS_PER_PAGE = 30;

export let ASPECT_RATIO_16_9: number = 16 / 9; // 1.7777777777
export let ASPECT_RATIO_16_10: number = 16 / 10; // 1.6
export let ASPECT_RATIO_3_2: number = 3 / 2; // 1.5
export let ASPECT_RATIO_22_15: number = 22 / 15; // 1.4666666667 
export let ASPECT_RATIO_4_3: number = 4 / 3; // 1.3333333333

// Webpack Define Plugin will change these values on build
export var __AUTHORITY_URL__: string; 
export var __CLIENT_URL__: string;
export var __VIDEO_URL__: string;

export let ACCESS_LEVEL_TOOLTIP = 
    "This operation will update the Access Level of all currently selected Camera Streams";

// List that can be directly utilized in front-end access level selection
export const StreamAccessLevelOptions: Array<string> = [
    "Public",
    "Media",
    "FirstResponder",
    "Stakeholder",
    "ALDOT",
    "Hidden",
    "Unknown"
];

export const isNewMode = (id: string | number, valueIfNew: string | number): boolean => {

    // if there is no given id, or the comparison value for new is falsey - then new
    if (!id || !nuon(valueIfNew)) return true;

    // if the given id is absolutely equal to comparison value - then new
    if (id === valueIfNew) return true;

    // in all other cases, the page should render in Edit mode
    return false;
}