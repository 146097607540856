import { IProcessedResponse } from '@algo/network-manager/managers/v3';
import { ATSynchronization } from '@algo/network-manager/models/v3';

export let FETCH_LATEST_SYNCHRONIZATION_BEGIN = 'FETCH_LATEST_SYNCHRONIZATION_BEGIN';
export let FETCH_LATEST_SYNCHRONIZATION_SUCCESS = 'FETCH_LATEST_SYNCHRONIZATION_SUCCESS';
export let FETCH_LATEST_SYNCHRONIZATION_FAILURE = 'FETCH_LATEST_SYNCHRONIZATION_FAILURE';

export let FETCH_HISTORY_SYNCHRONIZATION_BEGIN = 'FETCH_HISTORY_SYNCHRONIZATION_BEGIN';
export let FETCH_HISTORY_SYNCHRONIZATION_SUCCESS = 'FETCH_HISTORY_SYNCHRONIZATION_SUCCESS';
export let FETCH_HISTORY_SYNCHRONIZATION_FAILURE = 'FETCH_HISTORY_SYNCHRONIZATION_FAILURE';
export let FETCH_HISTORY_SYNCHRONIZATION_NULL_SERVICE = "FETCH_HISTORY_SYNCHRONIZATION_NULL_SERVICE";

export let CLEAR_SYNCHRONIZATION_ERROR = 'CLEAR_SYNCHRONIZATION_ERROR';

export let UNINITIALIZE_SYNCHRONIZATION_STATE = 'UNINITIALIZE_SYNCHRONIZATION_STATE';

export interface SynchronizationState {
    latest: ATSynchronization[];
    history: ATSynchronization[];
    loadingLatest: boolean;
    loadingHistory: boolean;
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface SynchronizationPayload {
    latest: ATSynchronization[];
    history: ATSynchronization[] | [];
    error: Error | null;
    response: IProcessedResponse;
}

interface FetchLatestSynchronizationBeginAction {
    type: typeof FETCH_LATEST_SYNCHRONIZATION_BEGIN;
    payload: SynchronizationPayload;
}

interface FetchLatestSynchronizationSuccessAction {
    type: typeof FETCH_LATEST_SYNCHRONIZATION_SUCCESS;
    payload: SynchronizationPayload;
}

interface FetchLatestSynchronizationFailureAction {
    type: typeof FETCH_LATEST_SYNCHRONIZATION_FAILURE;
    payload: SynchronizationPayload;
}

interface FetchHistorySynchronizationBeginAction {
    type: typeof FETCH_HISTORY_SYNCHRONIZATION_BEGIN;
    payload: SynchronizationPayload;
}

interface FetchHistorySynchronizationSuccessAction {
    type: typeof FETCH_HISTORY_SYNCHRONIZATION_SUCCESS;
    payload: SynchronizationPayload;
}

interface FetchHistorySynchronizationFailureAction {
    type: typeof FETCH_HISTORY_SYNCHRONIZATION_FAILURE;
    payload: SynchronizationPayload;
}

interface ClearSynchronizationErrorAction {
    type: typeof CLEAR_SYNCHRONIZATION_ERROR;
    payload: SynchronizationPayload;
}

interface UninitializeSynchronizationAction {
    type: typeof UNINITIALIZE_SYNCHRONIZATION_STATE;
    payload: SynchronizationPayload;
}

export type SynchronizationTypes = 
    FetchLatestSynchronizationBeginAction | 
    FetchLatestSynchronizationSuccessAction | 
    FetchLatestSynchronizationFailureAction | 
    FetchHistorySynchronizationBeginAction | 
    FetchHistorySynchronizationSuccessAction | 
    FetchHistorySynchronizationFailureAction | 
    ClearSynchronizationErrorAction | 
    UninitializeSynchronizationAction;