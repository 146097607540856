//types
import * as TYPES from "./types";

let initialState: TYPES.UserDashboardState = {
    initializing: false,
    loadingUsers: false,
    deletingUser: false,
    organizations: [],
    users: [],
    error: null
};

export function userDashboardReducer(state = initialState, action: TYPES.UserDashboardTypes): TYPES.UserDashboardState {
    switch(action.type) {
        case TYPES.INITIALIZE_USER_DASHBOARD_BEGIN:
            return {
                ...state,
                initializing: true
            };
        case TYPES.INITIALIZE_USER_DASHBOARD_SUCCESS:
            return {
                ...state,
                initializing: false,
                organizations: action.payload.organizations
            };
        case TYPES.INITIALIZE_USER_DASHBOARD_FAILURE:
            return {
                ...state,
                initializing: false,
                error: action.payload.error
            };
        case TYPES.LOAD_USERS_BEGIN: 
            return {
                ...state,
                loadingUsers: true
            };
        case TYPES.LOAD_USERS_SUCCESS: 
            return {
                ...state,
                loadingUsers: false,
                users: action.payload.users
            };
        case TYPES.LOAD_USERS_FAILURE:
            return {
                ...state,
                loadingUsers: false,
                error: action.payload.error
            };
        case TYPES.DELETE_USER_BEGIN:
            return {
                ...state,
                deletingUser: true
            };
        case TYPES.DELETE_USER_SUCCESS:
            return {
                ...state,
                deletingUser: false
            };
        case TYPES.DELETE_USER_FAILURE:
            return {
                ...state,
                deletingUser: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_USER_DASHBOARD_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_USER_DASHBOARD:
            return initialState;
        default:
            return state;
    }
}