import { IATFeedback } from "@algo/network-manager/models/v3";

export let LOAD_FEEDBACK_DETAIL_BEGIN = 'LOAD_FEEDBACK_DETAIL_BEGIN';
export let LOAD_FEEDBACK_DETAIL_SUCCESS = 'LOAD_FEEDBACK_DETAIL_SUCCESS';
export let LOAD_FEEDBACK_DETAIL_FAILURE = 'LOAD_FEEDBACK_DETAIL_FAILURE';

export let SEND_RESPONSE_BEGIN = 'SEND_RESPONSE_BEGIN';
export let SEND_RESPONSE_SUCCESS = 'SEND_RESPONSE_SUCCESS';
export let SEND_RESPONSE_FAILURE = 'SEND_RESPONSE_FAILURE';

export let CLEAR_FEEDBACK_DETAIL_ERROR = 'CLEAR_FEEDBACK_DETAIL_ERROR';

export let UNINITIALIZE_FEEDBACK_DETAIL = 'UNINITIALIZE_FEEDBACK_DETAIL';

export interface FeedbackDetailState {
    loading: boolean,
    feedback: IATFeedback;
    sendingResponse: boolean;
    error: Error | null;
}

export interface FeedbackDetailPayload {
    feedback: IATFeedback,
    error: Error | null;
}

interface LoadFeedbackDetailBeginAction {
    type: typeof LOAD_FEEDBACK_DETAIL_BEGIN,
    payload: FeedbackDetailPayload
}

interface LoadFeedbackDetailSuccessAction {
    type: typeof LOAD_FEEDBACK_DETAIL_SUCCESS,
    payload: FeedbackDetailPayload
}

interface LoadFeedbackDetailFailureAction {
    type: typeof LOAD_FEEDBACK_DETAIL_FAILURE,
    payload: FeedbackDetailPayload
}

interface SendResponseBeginAction {
    type: typeof SEND_RESPONSE_BEGIN,
    payload: FeedbackDetailPayload
}

interface SendResponseSuccessAction {
    type: typeof SEND_RESPONSE_SUCCESS,
    payload: FeedbackDetailPayload
}

interface SendResponseFailureAction {
    type: typeof SEND_RESPONSE_FAILURE,
    payload: FeedbackDetailPayload
}

interface ClearFeedbackDetailErrorAction {
    type: typeof CLEAR_FEEDBACK_DETAIL_ERROR,
    payload: FeedbackDetailPayload
}

interface UninitializeFeedbackDetailAction {
    type: typeof UNINITIALIZE_FEEDBACK_DETAIL,
    payload: FeedbackDetailPayload
}

export type FeedbackDetailTypes = 
    LoadFeedbackDetailBeginAction | 
    LoadFeedbackDetailSuccessAction | 
    LoadFeedbackDetailFailureAction | 
    SendResponseBeginAction | 
    SendResponseSuccessAction | 
    SendResponseFailureAction | 
    ClearFeedbackDetailErrorAction | 
    UninitializeFeedbackDetailAction;
