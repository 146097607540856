// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// models & interfaces
import { ICASUserProfile } from "@algo/network-manager/models/v3/cas";
import { IAAOrganization } from '@algo/network-manager/models/v3/admin';
// store
import {
    initializeUserDashboard, loadUsers, removeUser,
    uninitializeUserDashboard
} from '../../../store/user/dashboard/actions';
import { AppState } from '../../../store';
import { ProfileState } from '../../../store/profile/types';
import { UserDashboardState } from '../../../store/user/dashboard/types';
// components
import ConfirmationModal from '../../base/modals/ConfirmationModal';
import { 
    GenericTableViewSelectFilterItem, GenericTableViewSettings, 
    GenericTableView, GenericTableViewColumnDefinition
} from '../../base/layout/GenericTableView';
// constants
import { 
    DEFAULT_ORGANIZATION_ID, DEFAULT_QUERY,
    DEFAULT_USER_SUB, DEFAULT_PAGE_SIZE,
} from '../../../utils/AppConstants';
import { DELETE_USER, CREATE_USER } from '../../../models/Privileges';

interface OwnProps {
    showSearch: boolean; 
    userList?: ICASUserProfile[];
    pageSize?: number;
    actionCallback?: ((key: any) => void);
}

interface StateProps { 
    profile: ProfileState;
    dashboard: UserDashboardState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        profile: state.profile,
        dashboard: state.userDashboard
    };
}

interface DispatchProps {
    initialize: typeof initializeUserDashboard;
    search: typeof loadUsers;
    remove: typeof removeUser;
    uninitialize: typeof uninitializeUserDashboard;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeUserDashboard,
        search: loadUsers,
        remove: removeUser,
        uninitialize: uninitializeUserDashboard
    }, dispatch);
}

type UserTableViewProps = 
    OwnProps & 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface UserTableViewState {
    sub: string;
    showDeleteConfirmModal: boolean;
    organizationId: number;
    query: string;
}

class UserTableView extends React.Component<UserTableViewProps, UserTableViewState> {
    constructor(props: UserTableViewProps) {
        super(props);

        this.state = {
            organizationId: DEFAULT_ORGANIZATION_ID,
            query: DEFAULT_QUERY,
            showDeleteConfirmModal: false,
            sub: DEFAULT_USER_SUB
        };
    }

    componentDidMount() {
        this.props.initialize();

        if (!this.props.userList) {
            this.applyFilter(DEFAULT_QUERY, DEFAULT_ORGANIZATION_ID);
        }

        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (!this.props.dashboard.deletingUser && prevProps.dashboard.deletingUser) {
            console.warn("UPDATE LIST");
            this.applyFilter(this.state.query, this.state.organizationId);
        }

        if (this.state.organizationId !== prevState.organizationId) {
            this.applyFilter(this.state.query, this.state.organizationId);
        }
    }

    clearFilter = (): void => {
        this.props.search(DEFAULT_QUERY, this.state.organizationId);

        this.setState((state, props) => {
            return {
                ...state,
                query: DEFAULT_QUERY
            };
        });
    }

    applyFilter = (query: string, key: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query,
                organizationId: key
            };
        });

        this.props.search(query, key);
    }

    searchBoxChange = (query: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            };
        });
    }

    organizationChange = (key: number): void => {
        this.setState((state, props) =>{
            return {
                ...state,
                organizationId: key
            };
        });
    }

    newUser = (): void => {
        $('[data-toggle="tooltip"]').tooltip('hide');

        this.props.history.push(`/user/new`);
    }

    viewUser = (sub: string): void => {
        if (this.props.profile.userProfile.hasPrivilege(CREATE_USER)) {
            this.props.history.push(`/user/${sub}`);
        }
    }

    showDeleteModal = (sub: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                sub: sub,
                showDeleteConfirmModal: true
            };
        });
    }

    confirmDelete = (): void => {
        this.deleteUser(this.state.sub);

        this.setState((state, props) => {
            return {
                ...state,
                sub: DEFAULT_USER_SUB,
                showDeleteConfirmModal: false
            };
        });
    }

    dismissDelete = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                sub: DEFAULT_USER_SUB,
                showDeleteConfirmModal: false
            };
        });
    }

    deleteUser = (sub: string): void => {
        if (!this.props.dashboard.deletingUser) {
            this.props.remove(sub, this.state.query, this.state.organizationId);
        }
    }

    render() {
        let { dashboard, profile, showSearch, userList, actionCallback, pageSize } = this.props;

        let tableSettings = {
            tableClassName: 'av-user-table',
            actionButtonClass: 'btn btn-outline-danger',
            actionButtonGlyphClass: 'delete-icon',
            columnDefinitions: [
                {
                    propertyName: 'sub',
                    isKey: true
                },
                {
                    propertyName: 'givenName',
                    displayName: 'Given Name',
                    isKey: false,
                    headerColumnClass: 'av-user-user-col'
                },
                {
                    propertyName: 'familyName',
                    displayName: 'Family Name',
                    isKey: false,
                    headerColumnClass: 'av-user-user-col'
                },
                {
                    propertyName: 'userName',
                    displayName: 'Username',
                    isKey: false,
                    headerColumnClass: 'av-user-username-col'
                },
                {
                    propertyName: 'email',
                    displayName: 'Email',
                    isKey: false,
                    headerColumnClass: 'av-user-email-col'
                }
            ] as GenericTableViewColumnDefinition[],
            selectFilterPlaceholder: 'Organizations',
            selectFilterItems: [
                {
                    value: DEFAULT_ORGANIZATION_ID,
                    label: 'All Organizations'
                },
                ...dashboard.organizations
                .map((org: IAAOrganization) => {
                    return {
                        value: org.organizationId,
                        label: org.name
                    } as GenericTableViewSelectFilterItem;
                })
            ] as GenericTableViewSelectFilterItem[],
            emptyTableMessage: 'No users',
            showPageSizeSelector: false,
            pageSize: pageSize ? pageSize : DEFAULT_PAGE_SIZE,
            newItemDisabled: !profile.userProfile.hasPrivilege(CREATE_USER),
            actionDisabled: !profile.userProfile.hasPrivilege(DELETE_USER),
            rowClickDisabled: !profile.userProfile.hasPrivilege(CREATE_USER)
        } as GenericTableViewSettings;

        return (
            <div style={{
                height: '100%'
            }}>
                <GenericTableView
                settings={tableSettings}
                items={userList ? userList: dashboard.users}
                rowClickCallback={this.viewUser}
                newItemCallback={this.newUser}
                searchBoxChangeCallback={showSearch ? this.searchBoxChange : undefined}
                selectorChangeCallback={showSearch ? this.organizationChange: undefined}
                clearFilterCallback={showSearch ? this.clearFilter : undefined}
                applyFilterCallback={showSearch ? this.applyFilter : undefined}
                actionCallback={actionCallback ? actionCallback : this.showDeleteModal}
                isLoading={userList ? false : (dashboard.initializing || dashboard.loadingUsers)}
                isProcessingAction={userList ? false : dashboard.deletingUser}
                />
                {
                    this.state.showDeleteConfirmModal 
                        ?   <ConfirmationModal 
                                confirmCallback={this.confirmDelete} 
                                dismissCallback={this.dismissDelete} 
                                title={`Delete this user?`} 
                                message={`Are you sure you want to delete this user? This action cannot be reversed.`} 
                                type={`warning`} 
                            /> 
                        : (null)
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(UserTableView));