// libraries
import { filterNaN } from "@caps-mobile/common-lib";
// interfaces
// @ts-ignore
import * as FTypes from "@algo/network-manager/models/ATFacility";

// What to do when User clicks "Update & Center button"
// Or when user presses "Enter" in the "Zoom Level" input field
export const handleUpdateAndCenter = (
    coords: H.geo.IPoint,
    mapConfig: any,  
    zoomLevelValue: string,
    setMapConfig: any
) => {
    setMapConfig({
        ...mapConfig, 
        center: coords,
        zoom: parseFloat(zoomLevelValue)
    })
}

// What to do when User Presses "Enter" key in "Zoom Level" input field
export const handleZoomKeyPress = (
    e: React.KeyboardEvent<HTMLElement>,
    coords: H.geo.IPoint,
    mapConfig: any,
    zoomLevelValue: string,
    setMapConfig: any
) => {
    let keyPressed: any = e.key;

    switch(keyPressed){
        case "Enter":
            !isInvalidZoomLevel(zoomLevelValue) && 
                handleUpdateAndCenter(
                    coords, mapConfig, zoomLevelValue, setMapConfig
                );
            break;
        default: 
            break;
    }
};

// What to do when User edits "Zoom Level" input field
// Filter non-number values to prevent errors
export const handleUpdateZoomState = (event: any, setZoomLevelValue: any) => {
    setZoomLevelValue(filterNaN(event.currentTarget.value));
};

// determine if the current zoomLevelValue is a valid float between 2 and 22
export const isInvalidZoomLevel = (zoomLevel: string) => {
    let parseResult: number = parseFloat(zoomLevel);
    if (isNaN(parseResult)) 
        return true;
    else if (parseResult < 2 || parseResult > 22)
        return true;
    else return false;
}

// Data object returned to parent when User clicks "Save" button.
export const buildReturnValue = (
    zoomLevelValue: string, 
    coords: H.geo.IPoint
): FTypes.ATFacilityMapSetting => {
    return ({   
        zoomLevel: parseFloat(zoomLevelValue),
        centerLatitude: coords.lat, 
        centerLongitude: coords.lng
    });
};