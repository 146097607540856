import * as React from "react";
import styled from "styled-components";
import { Center } from "@algo/capsstrap";
import { Body1 } from "@algo/algo-styles";
 
export interface IStreamLogMessage {
    type?: string;
    message?: string;
}

export const StreamLogMessage: React.FC<IStreamLogMessage> = (props) => {
    return (
        <Center>
            <StyledLogMessage type={props.type ? props.type : "null"}>
                <Body1> {`${props.message ? props.message : "No Logs"}`}</Body1>
            </StyledLogMessage>
        </Center>
    );
}

interface IStyledLogMessage {
    type: string;
}

const StyledLogMessage = styled.div<IStyledLogMessage>`
    background-color: ${ props => props.type === "null" ? "#fff3cd" : "#f8d7da"};
    color: ${ props => props.type === "null" ? "#856404" : "#721c24"};
    border-radius: 4px;
    width: 75%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 1rem;
`;