// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import { isMomentEventValue } from '../../../utils/typeCheckers';
// interfaces & models
// needed to make Datetime component work
import 'moment-timezone';
import { Moment } from 'moment';
import Select from 'react-select';
import reactSelectStyles from '../../../utils/reactSelectStyles';
import { 
    ATAldotMessage, EATAudience, IATAldotMessage 
} from '@algo/network-manager/models/v3';
// components
import Datetime from 'react-datetime';
import Content from '../../base/layout/Content';
import Loading from '../Loading';
// store
import {
    loadMessage, uninitializeMessageEditor, saveMessage
} from '../../../store/message/editor/actions';
import { MessageEditorState } from '../../../store/message/editor/types';
import { AppState } from '../../../store';
// constants
import  * as MESSAGE_CONS from '../../../utils/AppConstants';
import moment from 'moment';
import { ReactSelectStringKeyListItem } from '../../../models/ReactSelectListItems';

interface StateProps { 
    editor: MessageEditorState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        editor: state.messageEditor
    };
};

interface DispatchProps {
    initialize: typeof loadMessage;
    save: typeof saveMessage;
    uninitialize: typeof uninitializeMessageEditor;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: loadMessage,
        save: saveMessage,
        uninitialize: uninitializeMessageEditor
    }, dispatch);
};

type MessageEditorPageProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface MessageEditorPageState {
    messageId: number;
    message: IATAldotMessage;
    selectedAudienceOptions: ReactSelectStringKeyListItem[]
}

class MessageEditor extends React.Component<MessageEditorPageProps, MessageEditorPageState> 
{
    constructor(props: Readonly<MessageEditorPageProps>) {
        super(props);

        this.state = {
            messageId: ( this.props.match.params.messageId ) 
                ? this.props.match.params.messageId 
                : MESSAGE_CONS.MESSAGE_DEFAULT_ID,
            message: new ATAldotMessage()
        } as MessageEditorPageState;
    }

    componentDidMount() {
        if (this.state.messageId !== MESSAGE_CONS.MESSAGE_DEFAULT_ID) {
            this.props.initialize(this.state.messageId);
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

        if (
            (this.props.editor.initializing !== prevProps.editor.initializing) && 
            !this.props.editor.initializing
        ) {
            this.setState((prevState, props) => {
                return {
                    message: props.editor.message,
                    selectedAudienceOptions: props.editor.message.audiences.map((aud: EATAudience) => 
                        {
                            return {
                                value: aud,
                                label: aud
                            } as ReactSelectStringKeyListItem;
                        })
                };
            });
        }

        if (
            ( this.props.editor.savingMessage !== prevProps.editor.savingMessage ) && 
            !this.props.editor.savingMessage &&
            ( this.props.editor.error === null )
        )  this.props.history.replace(`/message/${this.props.editor.message.id}`);

    }

    setFormItemValue = (
        property: string, 
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ): void => {

        event.preventDefault();

        let currentValue = event.currentTarget.value;

        this.setState((state, props) => {
            var updatedMessage = state.message;

            switch (property) {
                case MESSAGE_CONS.MESSAGE_TITLE:
                    updatedMessage.title = currentValue;
                    break;
                case MESSAGE_CONS.MESSAGE_BODY:
                    updatedMessage.body = currentValue;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                message: updatedMessage
            };
        });
    }

    setMomentFormItemValue = (
        property: string,
        event: string | Moment
    ): void => {

        if (isMomentEventValue(event)) {
            let currentValue = event as Moment;

            this.setState((state, props) => {
                var updatedMessage = state.message;
    
                switch (property) {
                    case MESSAGE_CONS.MESSAGE_DATE_START:
                        updatedMessage.start = 
                            currentValue.toString();
                        break;
                    case MESSAGE_CONS.MESSAGE_DATE_END:
                        updatedMessage.end = 
                            currentValue.toString();
                        break;
                }
    
                return {
                    ...state,
                    message: updatedMessage
                };
            });
        }
    }

    setReactSelectFormItemValue = (property: string, event: any): void => {
        this.setState((state, props) => {
            var updatedMessage = this.state.message;
            switch (property) {
                case MESSAGE_CONS.MESSAGE_AUDIENCES:
                    let audienceValues = event as ReactSelectStringKeyListItem[];

                    if (audienceValues !== null) {
                        updatedMessage.audiences = audienceValues
                        .map((item: ReactSelectStringKeyListItem) => {
                            return item.value as EATAudience;
                        });
                    }

                    return {
                        ...state,
                        message: updatedMessage,
                        selectedAudienceOptions: audienceValues
                    };
                default:
                    break;
            }
        });
    }

    back = (): void => {
        this.props.history.goBack();
    }

    submit = (
        event?: React.FormEvent<HTMLFormElement>
    ): void => {
        if (event) {
            event.preventDefault();
        }

        if (!this.props.editor.savingMessage) {
            this.props.save(this.state.message);
        }
    }

    render() {
        let { editor } = this.props;

        let content = ( editor.initializing ) 
            ? <Loading />
            : (
            <div className='av-admin-editor-content'>
                <h1 className='sr-only'>Message Form</h1>
                <div className='av-message-editor'>
                    <div className='av-feedback-detail-section titlebar'>
                        <div className='av-back-action'>
                            <div className='col-2'>
                                <button 
                                    className='btn btn-outline-primary' 
                                    onClick={this.back}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                            </div>
                            <div className='av-toolbar-title'>
                                <h1>
                                {(MESSAGE_CONS.isNewMode(
                                    this.state.message?.id, 
                                    MESSAGE_CONS.MESSAGE_DEFAULT_ID
                                    )) 
                                        ? `New Message` 
                                        : `Edit Message`}
                                </h1>
                            </div>
                            <div className='col-2 text-right'>
                            </div>
                        </div>
                    </div>
                    <div className='av-admin-form'>
                        <form onSubmit={this.submit}>
                            <h2>Message Information</h2>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='txtTitle' 
                                    className='av-admin-editor-form-label'
                                >
                                    Title
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <input
                                        id='txtTitle'
                                        type='text'
                                        className='form-control'
                                        placeholder='Title'
                                        value={this.state.message?.title}
                                        onChange={
                                            (event) => 
                                                this.setFormItemValue(MESSAGE_CONS.MESSAGE_TITLE, event)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='txtDescription' 
                                    className='av-admin-editor-form-label'
                                >
                                    Description
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <textarea
                                        id='txtDescription'
                                        className='av-form-textarea'
                                        rows={
                                            (this.state.message?.body) 
                                                ? ( (this.state.message?.body.length / 100) + 3.9) 
                                                : 4
                                        }
                                        placeholder={`Description`}
                                        value={this.state.message?.body}
                                        onChange={
                                            (event) => 
                                                this.setFormItemValue(MESSAGE_CONS.MESSAGE_BODY, event)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='txtStartDate' 
                                    className='av-admin-editor-form-label'
                                >
                                    Start Date
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <Datetime
                                        value={
                                            moment(this.state.message?.start)
                                                .format(MESSAGE_CONS.DATE_STRING_FORMAT)
                                        }
                                        displayTimeZone={'America/Chicago'}
                                        onChange={
                                            (event: any) => 
                                                this.setMomentFormItemValue(MESSAGE_CONS.MESSAGE_DATE_START, event)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='txtStartDate' 
                                    className='av-admin-editor-form-label'
                                >
                                    End Date
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <Datetime
                                        value={
                                            moment(this.state.message?.end)
                                                .format(MESSAGE_CONS.DATE_STRING_FORMAT)
                                        }
                                        displayTimeZone={'America/Chicago'}
                                        onChange={
                                            (event: any) => 
                                                this.setMomentFormItemValue(MESSAGE_CONS.MESSAGE_DATE_END, event)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                            <label htmlFor='ddlAudience' className='av-admin-editor-form-label'>Audiences</label>
                            <div className='av-admin-editor-form-item'>
                                <Select
                                    id='ddlAudience'
                                    isMulti
                                    className={`av-selector-container`}
                                    classNamePrefix={`av-selector`}
                                    styles={reactSelectStyles}
                                    menuPlacement={`top`}
                                    options={MESSAGE_CONS.MESSAGE_AUDIENCE_OPTIONS}
                                    placeholder={`Audiences`}
                                    value={this.state.selectedAudienceOptions}
                                    onChange={(event) => this.setReactSelectFormItemValue(MESSAGE_CONS.MESSAGE_AUDIENCES, event)}
                                />
                            </div>
                        </div>
                            <div className='av-admin-editor-form-row'>
                                <button
                                    type={`submit`}
                                    className='btn btn-block btn-outline-primary'
                                    disabled={editor.savingMessage}
                                >
                                    <span className={`save-icon ${editor.savingMessage ? 'saving' : ''}`}>
                                    </span> 
                                    &nbsp;Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );

        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        {content}
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageEditor)); 