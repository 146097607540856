// constants
import * as mapPinIcon from "../../../../../images/map-pins/pin-region-96.png";

/**************************************************************************
    STRINGS
*************************************************************************/
export const placesApiPath: string = 
    "Places?type=Interstate&type=USHighway&type=StateHighway";

export const countiesApiPath: string =
    "Counties";

export const citiesApiPath: string =
    "Cities";

export const loadFailed: string = 
    "Error! Failed to load data.";

export const tryAgain: string = 
    "Try canceling and re-opening this modal.";

export const bannerText: string = 
    `Place a marker indicating the entry point for the facility on the map below.`;

export const cityLabel: string = "City";
export const placeLabel: string = "Roadway";
export const countyLabel: string = "County";
export const directionLabel: string = "Direction";
export const mileLabel: string = "Mile Marker";

export const cancelLabel: string = "Cancel";
export const saveLabel: string = "Save";

/**************************************************************************
    NUMBERS
*************************************************************************/

/**************************************************************************
    DATA OBJECTS
*************************************************************************/
export const mapPinOptions = {
    type: 0,
    position: {lat: 0, lng: 0},
    options: {
        icon: { 
            iconSrc: mapPinIcon.default, 
            size: {w: 42, h: 42}
        } 
    }
};

export const D_MAP_CONFIG = {
    center: {
        lat: 32.411663269199124, 
        lng: -86.26686300858245
    },
    zoom: 10,
};