// libraries
// interfaces & models
import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import { 
    IATCameraDeviceStreamStatusDto,
    ATIncomingStreamLog, D_ATIncomingStreamLog,
    EATLogStatus,
    ATPushPublishEntryLog, D_ATPushPublishEntryLog,
} from "@algo/network-manager/models/v3";
import * as TYPES from "../types/streamStatusTypes";

let initialState: TYPES.State = {
    statusData: {},
    statusLoading: {},
    error: null,
    lastResponse: new ProcessedResponse()
};

const badStatus: IATCameraDeviceStreamStatusDto = {
    cameraDeviceId: 0,
    localIncomingStreamLogs: [ new ATIncomingStreamLog({ ...D_ATIncomingStreamLog, status: EATLogStatus.Unknown}) ],
    distributorIncomingStreamLogs: [ new ATIncomingStreamLog({ ...D_ATIncomingStreamLog, status: EATLogStatus.Unknown}) ],
    pushPublishEntryLogs: [ new ATPushPublishEntryLog({ ...D_ATPushPublishEntryLog, status: EATLogStatus.Unknown}) ],
}

export function streamStatusReducer(
    state = initialState, 
    action: TYPES.Actions
): TYPES.State {

    switch (action.type) {

        /******************************************************************************
            LOAD CAMERA DEVICE STATUS | REDUCERS
        *****************************************************************************/
        case TYPES.LOAD_CAMERA_DEVICE_STATUS_BEGIN:
            return {
                ...state,
                statusLoading: {
                    ...state.statusLoading,
                    [action.payload.id]: true
                }
            };
        case TYPES.LOAD_CAMERA_DEVICE_STATUS_SUCCESS:
            return {
                ...state,
                statusLoading: {
                    ...state.statusLoading,
                    [action.payload.id]: false
                },
                statusData: {
                    ...state.statusData,
                    [action.payload.id]: action.payload.status
                },
                lastResponse: action.payload.response
            };
        case TYPES.LOAD_CAMERA_DEVICE_STATUS_FAILURE:
            return {
                ...state,
                statusLoading: {
                    ...state.statusLoading,
                    [action.payload.id]: false
                },
                statusData: {
                    ...state.statusData,
                    [action.payload.id]: badStatus
                },
                error: action.payload.error
            };

        default:
            return state;
    }
}