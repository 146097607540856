//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
// interfaces & models
import * as TYPES from "./types";
import { 
    IAAPrivilege, IAARole, IAAOrganization, AARole 
} from "@algo/network-manager/models/v3/admin";
import { 
    IOrganizationNetworkManager, OrganizationNetworkManager,
    IRoleNetworkManager, RoleNetworkManager,
    IPrivilegeNetworkManager, PrivilegeNetworkManager, IProcessedResponse
} from "@algo/network-manager/managers/v3";
//constants
import { ROLE_DEFAULT_ID } from "../../../utils/AppConstants";
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlOrganization: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).organizations}`;
const apiUrlPrivilege: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).privileges}`;
const apiUrlRole: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).roles}`;

function loadRoleBegin(): TYPES.RoleEditorTypes {
    return {
        type: TYPES.LOAD_ROLE_BEGIN,
        payload: {} as TYPES.RoleEditorPayload
    } ;
}

function loadRoleSuccess(
    role: IAARole, 
    privilegeList: IAAPrivilege[], 
    organizationList: IAAOrganization[]
): TYPES.RoleEditorTypes {
    return {
        type: TYPES.LOAD_ROLE_SUCCESS,
        payload: {
            role: role,
            privilegeList: privilegeList,
            organizationList: organizationList
        } as TYPES.RoleEditorPayload
    };
}

function loadRoleFailure(error: Error): TYPES.RoleEditorTypes {
    return {
        type: TYPES.LOAD_ROLE_FAILURE,
        payload: {
            error: error
        } as TYPES.RoleEditorPayload
    };
}

export let initializeRoleEditor = (
    id: number = ROLE_DEFAULT_ID
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().roleEditor.initializing) {
        dispatch(loadRoleBegin());

        getAccessToken().then(
            (token) => {
                let orgManager: IOrganizationNetworkManager = 
                    new OrganizationNetworkManager(apiUrlOrganization);

                let privManager: IPrivilegeNetworkManager = 
                    new PrivilegeNetworkManager(apiUrlPrivilege);

                let roleManager: IRoleNetworkManager = 
                    new RoleNetworkManager(apiUrlRole);
                    
                orgManager.setAccessToken(token);
                privManager.setAccessToken(token);
                roleManager.setAccessToken(token);

                if (id === ROLE_DEFAULT_ID) {
                    Promise.all([
                        privManager.getAll(),
                        orgManager.getAll()
                    ])
                        .then(response => {
                            if (response[0].error)
                                dispatch(loadRoleFailure(response[0].error))
                            else if (response[1].error)
                                dispatch(loadRoleFailure(response[1].error))
                            else
                                dispatch(loadRoleSuccess(new AARole(), response[0].data, response[1].data))
                        }, reject => {
                            dispatch(loadRoleFailure(new Error(reject)));
                        })
                        .catch(error => {
                            dispatch(loadRoleFailure(new Error(error)));
                        });
                }
                else {
                    Promise.all([
                        roleManager.getById({id}),
                        privManager.getAll(),
                        orgManager.getAll()
                    ])
                        .then(
                            (response: IProcessedResponse[]) => {
                                if (response[0].error) dispatch(loadRoleFailure(response[0].error))
                                else if (response[1].error) dispatch(loadRoleFailure(response[1].error))
                                else if (response[2].error) dispatch(loadRoleFailure(response[2].error))
                                else dispatch(loadRoleSuccess(response[0].data, response[1].data, response[2].data))
                        }, failure => {
                            dispatch(loadRoleFailure(failure));
                        })
                        .catch(error => {
                            dispatch(loadRoleFailure(error));
                        });
                }
            }
        )
    }
}

/*
Save Role
*/

function saveRoleBegin(): TYPES.RoleEditorTypes {
    return {
        type: TYPES.SAVE_ROLE_BEGIN,
        payload: {} as TYPES.RoleEditorPayload
    };
}

function saveRoleSuccess(role: IAARole): TYPES.RoleEditorTypes {
    return {
        type: TYPES.SAVE_ROLE_SUCCESS,
        payload: {
            role: role
        } as TYPES.RoleEditorPayload
    };
}

function saveRoleFailure(error: Error): TYPES.RoleEditorTypes {
    return {
        type: TYPES.SAVE_ROLE_FAILURE,
        payload: {
            error: error
        } as TYPES.RoleEditorPayload
    };
}

export let saveRole = (
    role: IAARole
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().roleEditor.savingRole) {
        dispatch(saveRoleBegin());

        let manager: IRoleNetworkManager =
            new RoleNetworkManager(apiUrlRole);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.save({role, id: role.id})
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error) dispatch(saveRoleFailure(response.error))
                                else dispatch(saveRoleSuccess(response.data))
                            }
                        ).catch(
                            (err: Error) => 
                                dispatch(saveRoleFailure(err))
                        )
                }
            ).catch(
                (err: Error) => 
                    dispatch(saveRoleFailure(err))
            )
    }
}

/*
Clear Role Editor Error
*/

function clearRoleEditorErrorState(): TYPES.RoleEditorTypes {
    return {
        type: TYPES.CLEAR_ROLE_EDITOR_ERROR,
        payload: {} as TYPES.RoleEditorPayload
    };
}

export let clearRoleEditorError = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().roleEditor.error !== null) {
        dispatch(clearRoleEditorErrorState());
    }
}

/*
Uninitialize Role Editor
*/

function uninitializeRoleEditorState(): TYPES.RoleEditorTypes {
    return {
        type: TYPES.UNINITIALIZE_ROLE_EDITOR,
        payload: {} as TYPES.RoleEditorPayload
    }
}

export let uninitializeRoleEditor = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeRoleEditorState());
}