// used to validate whether a User currently has access to a Route, based on
// their User data and privilege

import { IAAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
import { User } from "oidc-client-ts";
import { ProfileState } from "../../store/profile/types";
import { UserManager } from "oidc-client-ts";

// return True only if non-expired user exists and has ANY of the given privileges
export const checkRouteAccess = (user: User | undefined | null, profileState: ProfileState, privileges: string[] = []) => {
    
    if (!user) return false;
    if (user.expired) return false;
    if (!privileges || privileges.length === 0) return true;
    
    // confirm user has this privilege
    return (
        (privileges !== null && privileges.length > 0) && 
        hasAnyPrivilege(profileState.userProfile, privileges)
    );
    
};

export const hasAnyPrivilege = (userProfile: IAAUserAuthorizationProfile, privileges: string[]): boolean => {
    for (const priv of privileges){
        if (userProfile.hasPrivilege(priv)){
            return true;
        }
    }

    return false;
};


// handles oidc redirect overhead
export const checkForRedirect = (location: any, userManager: UserManager, serviceWorker: any): void => {

    let doNotCheckForUser: string[] = ['/callback', '/silent_renew', '/session_ended'];

    // Redirect to capture active session in new tab/window
    if (location && !doNotCheckForUser.includes(location.pathname)) {
        userManager.getUser()
        .then((user: any) => {
            if (!user || user.expired) {
                userManager.signinRedirect();
            }
            else {
                /*
                 * ACCESS TOKEN MESSAGE SENDING TO SERVICE WORKER
                 * 
                 * If the service worker is in control of the client
                 * then post a message to the SW that includes user access token.
                 * 
                 */
                if (serviceWorker) {
                    serviceWorker.postMessage({
                        accessToken: user.access_token
                    });
                }
            }
        }, (reject: any) => {
            throw reject;
        })
        .catch((error: any) => {
            throw error;
        });
    }
};