//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "./types";
//models
import { 
    IProcessedResponse, IRoleNetworkManager,
    OrganizationNetworkManager, RoleNetworkManager
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlOrganization: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).organizations}`;
const apiUrlRole: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).roles}`;

/*
Initialize Role Dashboard
*/

function initializeDashboardBegin(): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.INITIALIZE_ROLE_DASHBOARD_BEGIN,
        payload: {} as TYPES.RoleDashboardPayload
    };
}

function initializeDashboardSuccess(
    response: IProcessedResponse
): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.INITIALIZE_ROLE_DASHBOARD_SUCCESS,
        payload: {
            organizations: response.data,
            response
        } as TYPES.RoleDashboardPayload
    };
}

function initializeDashboardFailure(
    error: Error
): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.INITIALIZE_ROLE_DASHBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.RoleDashboardPayload
    };
}

export let initializeRoleDashboard = (
    //
): ThunkAction<
    void, 
    AppState, 
    null, 
    Action<string>
> => async (dispatch, getState) => {

    if (!getState().roleDashboard.initializing) {
        dispatch(initializeDashboardBegin());

        let manager: OrganizationNetworkManager = 
            new OrganizationNetworkManager(apiUrlOrganization);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll()
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error)
                                    dispatch(initializeDashboardFailure(response.error))
                                else 
                                    dispatch(initializeDashboardSuccess(response))
                            }
                        ).catch(
                            (err: Error) => dispatch(
                                initializeDashboardFailure(err)
                            )
                        )
                }
            ).catch(
                (err: Error) => dispatch(initializeDashboardFailure(err))
            )
    }
}

/*
Load Roles
*/

function loadRolesBegin(): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.LOAD_ROLES_BEGIN,
        payload: {} as TYPES.RoleDashboardPayload
    };
}

function loadRolesSuccess(
    response: IProcessedResponse
): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.LOAD_ROLES_SUCCESS,
        payload: { 
            roles: response.data,
            response
         } as TYPES.RoleDashboardPayload
    };
}

function loadRolesFailure(
    error: Error
): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.LOAD_ROLES_FAILURE,
        payload: { 
            error: error
        } as TYPES.RoleDashboardPayload
    };
}

export let searchRoles = (
    search: string, 
    organizationId: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().roleDashboard.loadingRoles) {
        dispatch(loadRolesBegin());

        let manager: IRoleNetworkManager = 
            new RoleNetworkManager(apiUrlRole);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll({
                        search,
                        organizationId
                    })
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error)
                                    dispatch(loadRolesFailure(response.error))
                                else
                                    dispatch(loadRolesSuccess(response))
                            }
                        ).catch(
                            (err: Error) => dispatch(loadRolesFailure(err))
                        )
                }
            ).catch(
                (err: Error) => dispatch(loadRolesFailure(err))
            )
    }
}

/*
Delete Role
*/

function deleteRoleBegin(): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.DELETE_ROLE_BEGIN,
        payload: {} as TYPES.RoleDashboardPayload
    };
}

function deleteRoleSuccess(
    response: IProcessedResponse
): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.DELETE_ROLE_SUCCESS,
        payload: { 
            roles: response.data,
            response
         } as TYPES.RoleDashboardPayload
    };
}

function deleteRoleFailure(
    error: Error
): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.DELETE_ROLE_FAILURE,
        payload: { 
            error: error
        } as TYPES.RoleDashboardPayload
    };
}

export let removeRole = (
    roleId: number, 
    organizationId: number = 0, 
    search: string = ""
): ThunkAction<
    void, 
    AppState, 
    null, 
    Action<string>
> => async (dispatch, getState) => {

    if (!getState().roleDashboard.deletingRole) {

        dispatch(deleteRoleBegin());

        let manager: RoleNetworkManager = 
            new RoleNetworkManager(apiUrlRole);

        getAccessToken()
            .then(
                (token) => {
                    manager.setAccessToken(token);
                    manager.delete({id: roleId})
                        .then(
                            (deleteResponse: IProcessedResponse) => {
                                manager.getAll({
                                    organizationId, search
                                })
                                    .then(
                                        (getAllResponse: any) => {
                                            if (getAllResponse.error)
                                                dispatch(deleteRoleFailure(getAllResponse.error))
                                            else
                                                dispatch(deleteRoleSuccess(getAllResponse));
                                    }, (reject: any) => {
                                        dispatch(deleteRoleFailure(new Error(reject)));
                                    })
                                    .catch(
                                        (error: any) => dispatch(
                                            deleteRoleFailure(new Error(error)
                                        )
                                    )
                                );
                        }, (reject: any) => {
                            dispatch(deleteRoleFailure(new Error(reject)));
                        })
                        .catch((error: any) => {
                            dispatch(deleteRoleFailure(new Error(error)));
                        });
                }
            )
    }
}

/*
Clear Role Dashboard Error
*/

function clearRoleDashboardErrorFromState(): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.CLEAR_ROLE_DASHBOARD_ERROR,
        payload: {} as TYPES.RoleDashboardPayload
    };
}

export let clearRoleDashboardError = (

): ThunkAction<
    void, 
    AppState, 
    null, 
    Action<string>
> => async (dispatch, getState) => {
    if (getState().roleDashboard.error !== null) {
        dispatch(clearRoleDashboardErrorFromState());
    }
}

/*
Uninitialize Role Dashboard
*/

function uninitializeRoleDashboardState(
    //
): TYPES.RoleDashboardTypes {
    return {
        type: TYPES.UNINITIALIZE_ROLE_DASHBOARD,
        payload: {} as TYPES.RoleDashboardPayload
    };
}

export let uninitializeRoleDashboard = (
    //
): ThunkAction<
    void, 
    AppState, 
    null, 
    Action<string>
> => async (dispatch, getState) => {
    dispatch(uninitializeRoleDashboardState());
}