//libraries
import * as React from "react";
import Moment from "moment";
//interfaces and models
import { 
    IATStreamServerApplication, IATIncomingStreamLog, IATPushPublishEntryLog
} from "@algo/network-manager/models/v3";
//components
import { Container, Row, Col } from "reactstrap";
import { Body2, Caption1 } from "@algo/algo-styles";
import { Icon } from "@algo/icon";
import { Pill } from "@algo/pill";
//constants

const statusIcon: any = {
    "Ok": "fas fa-check-circle",
    "Warning": "fas fa-exclamation-circle",
    "Error": "fas fa-exclamation-triangle",
    "Unknown": "fas fa-question-circle"
}

const statusColor: any = {
    "Ok": "#01B420",
    "Warning": "#ffc107",
    "Error": "#E53424",
    "Unknown": "#000"
}

const pillStyleObject: React.CSSProperties = {
    color: "#000", backgroundColor: "#C4C4C4",
    borderRadius: "4px", padding: "4px 8px 4px 8px",
    marginRight: "6px"
}

export enum ELogType {
    IncomingStream = "IncomingStream",
    PushPublishEntry = "PushPublishEntry",
}

interface ILogSummaryProps {
    log: IATIncomingStreamLog | IATPushPublishEntryLog;
    type: ELogType;
    last: boolean;
}

export const LogSummary: React.FC<ILogSummaryProps> = (props) => {

    /*******************************************************************
            CALCULATED VALUES & BREVITY NAMES
    *******************************************************************/

    const status: string = props.log.status;

    const timeStamp: Moment.Moment = Moment(props.log.timestamp);
    const formattedTime: string = timeStamp.format(`L LT`);

    const statusName: string = 
        ( (props.type === ELogType.IncomingStream)
                ? (props.log as IATIncomingStreamLog).incomingStreamName
                : (props.log as IATPushPublishEntryLog).entryName
        ) || "";

    const application: IATStreamServerApplication = props.log.application;

    /******************************************************************
          NULL & ERROR RENDER                      
    ******************************************************************/

    if (!props.log) {
        return <div>Error! No log object was supplied to LogSummary!</div>
    }
    else if (props.type !== ELogType.IncomingStream && props.type !== ELogType.PushPublishEntry) {
        return <div>The Log Type did not match "Incoming Stream" or "Push Publish Entry" types.</div>
    }

    /******************************************************************
          PRIMARY RENDER
    ******************************************************************/
    else return (
        <Container fluid={true}>
            <Row className={`border p-2 mb-${props.last ? 0 : 2}`} style={{ borderRadius: "4px" }}>
                <Col
                    xs={12} sm={12} md={5} lg={4} xl={4}
                    className="pt-0 pr-md-0 pl-md-2 pb-0 d-flex justify-content-center justify-content-md-start align-items-center"
                >
                    <Container fluid={true}>
                        <Row className="mb-md-0 mb-2">
                            <Col
                                xs={2} sm={4} md={1} lg={1}
                                className="pr-0 p-md-0 d-flex justify-content-end justify-content-md-center align-items-center"
                            >
                                <Icon
                                    size="md" iconClass={statusIcon[status] ? statusIcon[status] : "fas fa-exclamation-circle"}
                                    color={statusColor[status] ? statusColor[status] : "#000"}
                                    disabled={false}
                                    attributes={{
                                        "data-toggle": "tooltip",
                                        "data-position": "bottom",
                                        "title": props.log.status,
                                    }}
                                />
                            </Col>
                            <Col xs={10} sm={6} md={11} lg={11} className="">
                                <Container fluid={true}>
                                    <Row className="mb-2">
                                        <Col className="p-0">
                                            <div className="d-flex justify-content-start">
                                                <Body2
                                                    weight="bold"
                                                    className="d-inline align-text-top">
                                                    {statusName}
                                                </Body2>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="p-0">
                                            <div className="d-flex justify-content-start">
                                                <Caption1>
                                                    {formattedTime}
                                                </Caption1>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={0} sm={0} md={2} lg={4} xl={5}
                    className="p-0"
                >

                </Col>
                <Col xs={12} sm={12} md={5} lg={4} xl={3}
                    className="d-flex justify-content-md-end justify-content-center align-items-center"
                >
                    <Container fluid={true} className="p-0">
                        <Row className="mb-2">
                            <Col
                                xs={12}
                                className="p-0 d-flex justify-content-center justify-content-md-start align-items-center"
                            >
                                <div className="d-flex align-items-center">
                                    <Pill pillStyleObject={pillStyleObject}>
                                        <Caption1>{application?.streamServer?.name}</Caption1>
                                    </Pill>
                                    <Pill pillStyleObject={pillStyleObject}>
                                        <Caption1>{application?.name}</Caption1>
                                    </Pill>
                                    <Pill pillStyleObject={pillStyleObject}>
                                        <Caption1>{props.log.loggingSource}</Caption1>
                                    </Pill>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col
                                xs={12}
                                className="p-0 d-flex justify-content-center justify-content-md-start"
                            >
                                <div className="d-flex align-items-end">
                                    <Caption1>
                                        {props.log.message || "Message is missing"}
                                    </Caption1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}