// libraries
import * as React from "react";

// handles triggering a page refresh function on given interval
export const useRefresher = (refreshFunction: () => any, interval: number) => {

    const [refresher, setRefresher] = React.useState<any>(refreshFunction);

    React.useEffect(

        () => {
            setRefresher(setInterval(refreshFunction, interval))

            return () => {
                // cleanup
                clearInterval(refresher);
            };

        }, []
    );

    return refresher;
};

export default useRefresher;