// libraries
import * as React from 'react';
import { 
    withRouter, NavLink, RouteComponentProps
 } from 'react-router-dom';
import { connect } from 'react-redux';
// styles
import * as SC from "./Styled";
// store
import { AppState } from '../../../../store';
import { ProfileState } from '../../../../store/profile/types';
// models & interfaces
// components
import AuthenticationButton from '../AuthenticationButton';
// resources
import { algoLogo } from '../../../../images';
import { 
    ReactComponent as ToggleSidebar 
} from "../../../../images/algo-nav/icon-toggle-sidebar.svg";
import { AuthContextProps, useAuth } from 'react-oidc-context';

const navStyles = {
    height: "100%", 
    display: "flex", 
    alignItems: "center"
};

const altText: string = 
    `Algo Logo: A green circle with the` + 
    `word 'algo' centered in script-like font.`;

interface OwnProps {
    showSidebar: boolean;
    toggleShowSidebar: () => any;
    setRootNavIndex: () => any;
};

interface StateProps {
    profile: ProfileState;
}

let mapStateToProps = (store: AppState) => {
    return {
        profile: store.profile
    };
}

type HeaderProps = 
    OwnProps & 
    StateProps & 
    RouteComponentProps<any>;

const Header: React.FC<HeaderProps> = (props) => {

    let { showSidebar, toggleShowSidebar } = props;
    const auth = useAuth();
    
    return (
        <SC.StyledHeaderWrapper>

            <SC.MenuToggleSection showSidebar={showSidebar}>
                <SC.ToggleSidebarWrapper>
                    <ToggleSidebar 
                        fill={"red"} color={"red"} 
                        onClick={toggleShowSidebar} style={{color: "red"}}
                    />
                </SC.ToggleSidebarWrapper>
            </SC.MenuToggleSection>
            
            <SC.HeaderSection showSidebar={showSidebar}>
                
                <NavLink to={'/'} style={navStyles} onClick={() => props.setRootNavIndex()}>
                    <img 
                        width={36} height={36}
                        src={algoLogo.default} className='av-logo'
                        alt={altText}
                    />
                </NavLink>

                <AuthenticationButton auth={auth} />

            </SC.HeaderSection>
        </SC.StyledHeaderWrapper>
    );
}

export default connect(
    mapStateToProps
)(withRouter(Header));