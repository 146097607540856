// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// models & interfaces
// store
import {
    initializeFeedbackDashboard, loadFeedback,
    uninitializeFeedbackDashboard
} from '../../../store/feedback/dashboard/actions';
import { AppState } from "../../../store";
import { FeedbackDashboardState } from "../../../store/feedback/dashboard/types";
import { ProfileState } from "../../../store/profile/types";
// components
import { 
    GenericTableView, GenericTableViewSettings
 } from "../../base/layout/GenericTableView";
// constants
import { DATE_STRING_FORMAT, DEFAULT_QUERY } from '../../../utils/AppConstants';
import { IATFeedback } from "@algo/network-manager/models/v3";
import moment from "moment";

interface OwnProps {
    showPageSizeSelector: boolean;
    showSearch: boolean;
}

interface StateProps { 
    dashboard: FeedbackDashboardState
    profile: ProfileState
}

let mapStateToProps = (state: AppState) => {
    return { 
        dashboard: state.feedbackDashboard,
        profile: state.profile
    };
};

interface DispatchProps {
    initialize: typeof initializeFeedbackDashboard;
    search: typeof loadFeedback;
    uninitialize: typeof uninitializeFeedbackDashboard;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeFeedbackDashboard,
        search: loadFeedback,
        uninitialize: uninitializeFeedbackDashboard
    }, dispatch);
};

type FeedbackTableViewProps = 
    OwnProps & 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface FeedbackTableViewState {
    query: string;
}

class FeedbackTableView 
    extends React.Component<FeedbackTableViewProps, FeedbackTableViewState> 
{
    constructor(props: Readonly<FeedbackTableViewProps>) {
        super(props);
        
        this.state = {
            query: DEFAULT_QUERY
        } as FeedbackTableViewState;
    }

    componentDidMount() {
        this.props.initialize();

        $('[data-toggle="tooltip"]').tooltip();
    }
    
    searchBoxChange = (query: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            };
        });
    }

    clearFilter = (): void => {
        this.props.search(DEFAULT_QUERY);

        this.setState((state, props) => {
            return {
                ...state,
                query: DEFAULT_QUERY
            };
        });
    }

    applyFilter = (query: string, key: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            };
        });

        this.props.search(this.state.query);
    }

    goToDetail = (id: number): void => {
        this.props.history.push(`/feedback/${id}`);
    }

    render() {
        let { dashboard } = this.props;

        let tableSettings = {
            tableClassName: 'av-feedback-table',
            booleanIndicatorClass: 'av-feedback-bool-indicator',
            columnDefinitions: [
                {
                    propertyName: 'id',
                    isKey: true
                },
                {
                    propertyName: 'unread',
                    displayName: '',
                    isKey: false,
                    headerColumnClass: ''
                },
                {
                    propertyName: 'name',
                    displayName: 'Name',
                    isKey: false,
                    headerColumnClass: 'av-feedback-name-col'
                },
                {
                    propertyName: 'subject',
                    displayName: 'Subject',
                    isKey: false,
                    headerColumnClass: 'av-feedback-subject-col'
                },
                {
                    propertyName: 'dateCreated',
                    displayName: 'Date Received',
                    isKey: false,
                    headerColumnClass: 'av-feedback-received-col'
                }
            ],
            emptyTableMessage: 'No feedback',
            showPageSizeSelector: false,
            newItemDisabled: true,
            actionDisabled: true,
            rowClickDisabled: false
        } as GenericTableViewSettings;

        return (
            <GenericTableView
                settings={tableSettings}
                items={dashboard.feedback.map(
                    (feedback: IATFeedback) => {
                        return {
                            ...feedback,
                            dateCreated: moment(feedback.dateCreated)
                                            .format(DATE_STRING_FORMAT)
                        }
                    }
                )}
                searchBoxChangeCallback={this.searchBoxChange}
                clearFilterCallback={this.clearFilter}
                applyFilterCallback={this.applyFilter}
                rowClickCallback={this.goToDetail}
                isLoading={dashboard.initializing || dashboard.loadingFeedback}
                isProcessingAction={false}
            />
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(FeedbackTableView)); 