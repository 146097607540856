import { IAAOrganization } from "@algo/network-manager/models/v3/admin";

export let INITIALIZE_ORGANIZATION_DASHBOARD_BEGIN = 'INITIALIZE_ORGANIZATION_DASHBOARD_BEGIN';
export let INITIALIZE_ORGANIZATION_DASHBOARD_SUCCESS = 'INITIALIZE_ORGANIZATION_DASHBOARD_SUCCESS';
export let INITIALIZE_ORGANIZATION_DASHBOARD_FAILURE = 'INITIALIZE_ORGANIZATION_DASHBOARD_FAILURE';

export let LOAD_ORGANIZATIONS_BEGIN = 'LOAD_ORGANIZATIONS_BEGIN';
export let LOAD_ORGANIZATIONS_SUCCESS = 'LOAD_ORGANIZATIONS_SUCCESS';
export let LOAD_ORGANIZATIONS_FAILURE = 'LOAD_ORGANIZATIONS_FAILURE';

export let DELETE_ORGANIZATION_BEGIN = 'DELETE_ORGANIZATION_BEGIN';
export let DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export let DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';

export let CLEAR_ORGANIZATION_DASHBOARD_ERROR = 'CLEAR_ORGANIZATION_DASHBOARD_ERROR';

export let UNINITIALIZE_ORGANIZATION_DASHBOARD = 'UNINITIALIZE_ORGANIZATION_DASHBOARD';

export interface OrganizationDashboardState {
    initializing: boolean;
    loadingOrganizations: boolean;
    deletingOrganization: boolean;
    organizations: IAAOrganization[];
    error: Error | null;
}

export interface OrganizationDashboardPayload {
    organizations: IAAOrganization[];
    error: Error | null;
}

interface InitializeOrganizationDashboardBeginAction {
    type: typeof INITIALIZE_ORGANIZATION_DASHBOARD_BEGIN;
    payload: OrganizationDashboardPayload;
}

interface InitializeOrganizationDashboardSuccessAction {
    type: typeof INITIALIZE_ORGANIZATION_DASHBOARD_SUCCESS;
    payload: OrganizationDashboardPayload;
}

interface InitializeOrganizationDashboardFailureAction {
    type: typeof INITIALIZE_ORGANIZATION_DASHBOARD_FAILURE;
    payload: OrganizationDashboardPayload;
}

interface LoadOrganizationsBeginAction {
    type: typeof LOAD_ORGANIZATIONS_BEGIN;
    payload: OrganizationDashboardPayload;
}

interface LoadOrganizationsSuccessAction {
    type: typeof LOAD_ORGANIZATIONS_SUCCESS;
    payload: OrganizationDashboardPayload;
}

interface LoadOrganizationsFailureAction {
    type: typeof LOAD_ORGANIZATIONS_FAILURE;
    payload: OrganizationDashboardPayload;
}

interface DeleteOrganizationBeginAction {
    type: typeof DELETE_ORGANIZATION_BEGIN;
    payload: OrganizationDashboardPayload;
}

interface DeleteOrganizationSuccessAction {
    type: typeof DELETE_ORGANIZATION_SUCCESS;
    payload: OrganizationDashboardPayload;
}

interface DeleteOrganizationFailureAction {
    type: typeof DELETE_ORGANIZATION_FAILURE;
    payload: OrganizationDashboardPayload;
}

interface ClearOrganizationDashboardErrorAction {
    type: typeof CLEAR_ORGANIZATION_DASHBOARD_ERROR;
    payload: OrganizationDashboardPayload;
}

interface UninitializeOrganizationDashboardAction {
    type: typeof UNINITIALIZE_ORGANIZATION_DASHBOARD;
    payload: OrganizationDashboardPayload;
}

export type OrganizationDashboardTypes = 
    InitializeOrganizationDashboardBeginAction | 
    InitializeOrganizationDashboardSuccessAction | 
    InitializeOrganizationDashboardFailureAction | 
    LoadOrganizationsBeginAction | 
    LoadOrganizationsSuccessAction | 
    LoadOrganizationsFailureAction | 
    DeleteOrganizationBeginAction | 
    DeleteOrganizationSuccessAction | 
    DeleteOrganizationFailureAction | 
    ClearOrganizationDashboardErrorAction | 
    UninitializeOrganizationDashboardAction;
