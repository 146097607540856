// libraries
import * as React from "react";
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
// interfaces & models
import { 
    IATCameraDevice
} from "@algo/network-manager/models/v3";
import { Pill } from "@algo/pill";
import { getSiteValue, siteColors, siteSizes } from "@algo/algo-styles";
import { Body1, Body2 } from "@algo/algo-styles";
import { Row, Col } from "reactstrap";
import { Center } from "@algo/capsstrap";
// redux-state-types
import {
    State as PageState
} from "../../../../store/cameraDevice/dashboard/types/pageStateTypes";
import {
    State as StreamPreviewsState
} from "../../../../store/cameraDevice/dashboard/types/streamPreviewsTypes";
import {
    State as ToolbarState
} from "../../../../store/cameraDevice/dashboard/types/toolbarTypes";
import { AppState } from '../../../../store';
//redux-actions
import {
    loadDatasources,
} from "../../../../store/cameraDevice/dashboard/actions/toolbarActions";
import {
    updatePageState,
    selectAll
} from "../../../../store/cameraDevice/dashboard/actions/pageStateActions";
// constants
import { CAMERA_PREVIEWS_MAX_SELECTION } from "../../../../utils/AppConstants";

/**************************************************************************
    COMPONENT PROPS INTERFACES
*************************************************************************/
// redux store props
interface StateProps {
    pageState: PageState;
    previewsState: StreamPreviewsState;
    toolbarState: ToolbarState;
}
// redux action props
interface DispatchProps {
    updatePageState: typeof updatePageState;
    loadDatasources: typeof loadDatasources;
    selectAll: typeof selectAll;
}
// redux store mapper
let mapStateToProps = (state: AppState) => {
    return {
        pageState: state.streamPageState,
        previewsState: state.streamPreviews,
        toolbarState: state.streamToolbar
    }
}
// redux action mapper
let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            updatePageState: updatePageState,
            loadDatasources: loadDatasources,
            selectAll: selectAll,
        },
        dispatch
    );
}

// combined props
type IProps = StateProps & DispatchProps & RouteComponentProps;

export let SelectionRow = (props: IProps) => {

    /*************************************************************************|
        CALCULATED VALUES & BREVITY NAMES                                 
    |*************************************************************************/
    const pageState: any = props.pageState;
    const camerasLoading: boolean = props.previewsState.cameraData.isLoading;
    const selectedStreams: any = props.pageState.selectedStreams;
    const showAllSelectedStreams: boolean = props.pageState.showAllSelectedStreams;

    let streamsSelected: Array<IATCameraDevice> = props.pageState.selectedStreams;                          // brevity
    let streamsSelectedCount: number = streamsSelected.length;                                              // brevity
    let streamString: string =                                                                              // account for single or plural 
        (streamsSelectedCount === 1)
            ? "Stream"
            : "Streams";
    let toggleTextSelect: string =                                                                          // if any device(s) selected, the function becomes de-select
        (streamsSelectedCount === 0)
            ? "Select All"
            : "Deselect All";
    let toggleTextShow: string =                                                                            // account for hiding or showing
        (showAllSelectedStreams)
            ? "Hide Selected Cameras"
            : "Show Selected Camera Names";

    const showHideTip: string =
        `Selected camera names are hidden. ` +
        `Click "Show Selected Camera Names" ` +
        `above to see your selected cameras.`;

    //some style overrides for pill component
    let pillStyle: React.CSSProperties = {
        cursor: "pointer",
        backgroundColor: getSiteValue(siteColors, "old-algo-green"), color: "#fff",
        padding: "2px 1px 2px 4px", margin: "6px 4px 0 4px",
        borderRadius: "25px", fontSize: ".75rem"
    }
    let iconBackground: React.CSSProperties = {
        color: "#fff", fontSize: "1.15rem",
        padding: "0px",
        marginLeft: "4px", marginRight: "1px"
    }
    let iconForeground: React.CSSProperties = {
        color: "rgba(0,0,0,.60)", fontSize: "1rem",
        padding: "0px",
        marginLeft: "4px", marginRight: "1px"
    }

    /*************************************************************************|
        HANDLERS & UTILITIES
     *************************************************************************/

    const selectAllCameras = () => {
        if (props.pageState.selectingAll) return;                                                           // check lock
        props.selectAll();                                                                                  // select all cameras for current datasource
    }

    /* Used to add or remove a camera in the selected list */
    const toggleCameraInList = (camera: IATCameraDevice): void => {

        let index: number = selectedStreams.indexOf(camera);
        if (index === -1) {                                                                                 // if the camera is not in the currently selected list...
            let newSelectedStreams: Array<IATCameraDevice> =                                                // make a new list that includes the camera (prepend to reduce search times)
                [camera, ...selectedStreams];                  

            props.updatePageState({
                ...pageState,
                selectedStreams: newSelectedStreams,
                showAllSelectedStreams: (selectedStreams.length < CAMERA_PREVIEWS_MAX_SELECTION)                         // update whether selected cameras are shown
                    ? true                                                                                  // if there are few enough now, show
                    : false                                                                                 // if there are too many, hide
            });
        }
        else {                                                                                              // if the camera is already in the selected list...
            let newSelectedStreams: Array<IATCameraDevice> = selectedStreams;
            newSelectedStreams.splice(index, 1);
            props.updatePageState({
                ...pageState,
                selectedStreams: newSelectedStreams,
                showAllSelectedStreams: (selectedStreams.length < CAMERA_PREVIEWS_MAX_SELECTION)                         // update whether selected cameras are shown
                    ? true                                                                                  // if there are few enough now, show
                    : pageState.showAllSelectedStreams,                                                     // if there are too many, keep the current state
            });
        }
    }

    /*************************************************************************|
        PRIMARY RENDER
     *************************************************************************/
    return (
        <Row>
            <Col xs={12} className="px-0">
                <Center>
                    <StyledSelectionRow>
                        <Body1 className="mr-2">
                            {`${streamsSelectedCount} ${streamString} Selected`}
                        </Body1>
                        <StyledClickableText
                            disabled={camerasLoading}
                            selectAll={streamsSelectedCount === 0}
                            onClick={() => { selectAllCameras() }}
                        >
                            <Body2>
                                {toggleTextSelect}
                            </Body2>
                        </StyledClickableText>
                        {
                            (streamsSelectedCount >= CAMERA_PREVIEWS_MAX_SELECTION) &&
                            <StyledClickableText
                                disabled={camerasLoading}
                                selectAll={streamsSelectedCount === 0}
                                showCameras={true}
                                onClick={(!camerasLoading)
                                    ? () => {
                                        props.updatePageState({
                                            ...props.pageState,
                                            showAllSelectedStreams: !showAllSelectedStreams,
                                        })
                                    }
                                    : undefined
                                }
                            >
                                <Body2>
                                    {toggleTextShow}
                                </Body2>
                            </StyledClickableText>
                        }
                    </StyledSelectionRow>
                </Center>
            </Col>
            {showAllSelectedStreams
                ?
                streamsSelected.map(
                    (camera) => {
                        return (
                            <StyledSelectedDevices key={Math.random()}>
                                <span
                                    key={Math.random()}
                                    onClick={() => { toggleCameraInList(camera) }}
                                >
                                    <Pill label={camera.name}
                                        pillStyleObject={pillStyle}
                                        iconBackgroundStyleObject={iconBackground}
                                        iconForegroundStyleObject={iconForeground}
                                        dismissable={true}
                                    />
                                </span>
                            </StyledSelectedDevices>
                        );
                    }
                )
                :
                <Col xs={12}>
                    <Center>
                        <Body1 className="mt-1">
                            {showHideTip}
                        </Body1>
                    </Center>
                </Col>
            }
        </Row>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SelectionRow));


/*************************************************************************|
    STYLED COMPONENTS                                 
|*************************************************************************/

interface IStyledClickableTextProps {
    disabled?: boolean;
    selectAll?: boolean;
    showCameras?: boolean;
}

const StyledClickableText = styled.div<IStyledClickableTextProps>`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .5rem 0 .5rem;
    font-weight: bold;

    color: ${
    props =>
        (props.disabled)
            ? getSiteValue(siteColors, "dark-grey")
            : (props.selectAll || props.showCameras)
                ? getSiteValue(siteColors, "old-algo-green")
                : getSiteValue(siteColors, "off")
    };
`;

const StyledSelectionRow = styled.div`
    border-radius: ${getSiteValue(siteSizes, "border-radius")};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    padding: .5rem;
    background-color: ${getSiteValue(siteColors, "default-grey")};
    width: 100%;
`;

const StyledSelectedDevices = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
`;