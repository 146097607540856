// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// models & interfaces
import {
    ATFacility, EFacilityPropertyType 
}from "@algo/network-manager/models/v3";
// components
import { 
    FormSelect, FormDynamicRow, FormTextInput 
} from "@caps-mobile/forms";

export type IDynamicSelectAndInput = {
    facilityObject: ATFacility,
    type: string,
    optionsList: any,
    selectVal: (rowObject: any) => any,
    inputVal: (rowObject: any) => any,
    updateSelectVal: (rowObject: any, newValue: any) => any,
    updateInputVal: (rowObject: any, newValue: any) => any,
    updateFacilityObject: (facilityObject: ATFacility) => any;
};

export const DynamicSelectAndInput: React.FC<IDynamicSelectAndInput> = (props) => {

    const { 
        facilityObject, type, optionsList, 
        selectVal, inputVal, updateFacilityObject ,
        updateSelectVal, updateInputVal
    } = props;

    const selectOptions: string[] = optionsList;

    if (!facilityObject || !type || !facilityObject[type as keyof ATFacility]) return null;

    return (facilityObject[type as keyof ATFacility] as any)?.map(
        (rowObject: any, mapIndex: number) => {
            return (
                <FormDynamicRow
                    key={`${type}-${mapIndex}`}
                    deleteButton={true} 
                    deleteClick={() => {
                        if (facilityObject[type as keyof ATFacility]){
                            facilityObject.removeListProperty(
                                EFacilityPropertyType[type as keyof typeof EFacilityPropertyType], 
                                mapIndex
                            );
                            updateFacilityObject(new ATFacility(facilityObject));
                        }
                    }}
                >
                    <SC.SelectAndInputWrapper>
                        <FormSelect
                            styleObject={{"StyledDropdown": `width: 44.5%; margin-right: .5%;`}}
                            options={selectOptions}
                            disabledIndecies={findSelectedIndecies(facilityObject, type, selectOptions)}
                            selectedIndex={selectVal(rowObject)}
                            callback={(newIndex: number) => {
                                facilityObject.updateListProperty(
                                    EFacilityPropertyType[type as keyof typeof EFacilityPropertyType], 
                                    mapIndex,
                                    updateSelectVal(rowObject, newIndex)
                                );
                                updateFacilityObject(new ATFacility(facilityObject));
                            }}
                        />
                        <FormTextInput width={"55%"} height={"41px"} 
                            value={inputVal(rowObject)}
                            onChange={(newValue: any) => {
                                facilityObject.updateListProperty(
                                    EFacilityPropertyType[type as keyof typeof EFacilityPropertyType], 
                                    mapIndex,
                                    updateInputVal(rowObject, newValue.currentTarget.value)
                                );
                                updateFacilityObject(new ATFacility(facilityObject));
                            }}
                        />
                    </SC.SelectAndInputWrapper>
                </FormDynamicRow>
            )
        }
    )
};

export const findSelectedIndecies = (
    facilityObject: ATFacility, 
    propertyName: string, 
    propertyList: string[]
) => {

    // disallow uons
    if (
        !facilityObject || 
        !propertyName || 
        !propertyList || 
        propertyList.length === 0
    ) return [];

    let selectedIndecies: number[] = [];

    // get the list of objects of the given property
    let objectsList: any = facilityObject[propertyName as keyof ATFacility];
    // create a list of just the type values of these objects
    let typesList: string[] = objectsList.map(
        (object: any) => {
            return object.type || null
        }
    )

    if (typesList && typesList.length > 0){
        for (let i = 0; i < propertyList.length; i++){
            if (typesList.includes(propertyList[i])){
                selectedIndecies.push(i);
            }
        }
    }

    return selectedIndecies;
}