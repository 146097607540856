// interfaces & models
import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { IATCameraDeviceStreamStatusDto } from "@algo/network-manager/models/v3";
// constants
export const LOAD_CAMERA_DEVICE_STATUS_BEGIN = "LOAD_CAMERA_DEVICE_STATUS_BEGIN";
export const LOAD_CAMERA_DEVICE_STATUS_SUCCESS = "LOAD_CAMERA_DEVICE_STATUS_SUCCESS";
export const LOAD_CAMERA_DEVICE_STATUS_FAILURE = "LOAD_CAMERA_DEVICE_STATUS_FAILURE";


export interface IStatusData {
    [key: number]: IATCameraDeviceStreamStatusDto;
}

export interface IStatusLoading {
    [key: number]: boolean;
}

export interface State {
    statusData: IStatusData;
    statusLoading: IStatusLoading;
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface Payload {
    id: number;
    status: IATCameraDeviceStreamStatusDto;
    error: Error | null;
    response: IProcessedResponse;
}

/******************************************************************************
    LOAD CAMERA DEVICE STATUS | ACTION TYPES
 *****************************************************************************/
interface LoadCameraDeviceStatusBeginAction {
    type: typeof LOAD_CAMERA_DEVICE_STATUS_BEGIN;
    payload: Payload;
}

interface LoadCameraDeviceStatusSuccessAction {
    type: typeof LOAD_CAMERA_DEVICE_STATUS_SUCCESS;
    payload: Payload;
}

interface LoadCameraDeviceStatusFailureAction {
    type: typeof LOAD_CAMERA_DEVICE_STATUS_FAILURE;
    payload: Payload;
}

export type Actions =
    // LOAD CAMERA DEVICE STATUS
    LoadCameraDeviceStatusBeginAction |
    LoadCameraDeviceStatusSuccessAction |
    LoadCameraDeviceStatusFailureAction;

