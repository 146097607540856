// libraries
import * as React from "react";
import { 
    filterNaInt, getEnumStrings, searchObjectListByProperty 
} from "@caps-mobile/common-lib";
// hooks
import { useGetRoadways } from "./hooks";
// styles
import * as SC from "./Styled";
// interfaces & models
import { 
    ATFacilityRoute, EATDirection, EATKioskMarkerType, IATFacilityRoute, IATPlace 
} from "@algo/network-manager/models/v3";
// components
import { 
    FormButton, FormCard, FormTextInputLabeled, FormSelectLabeled 
} from "@caps-mobile/forms";
import Select from "react-select";
import reactSelectStyles from "../../../../../utils/reactSelectStyles";
import { ReactSelectStringKeyListItem } from "../../../../../models/ReactSelectListItems";
import { kMaxLength } from "buffer";

// constants
const DIRECTIONS = ["North", "South", "East", "West"];
const MARKER_TYPES = [
    {
        value: "TrafficEvent",
        label: "Traffic Event"
    },
    {
        value: "Facility",
        label: "Facility"
    },
    {
        value: "Camera",
        label: "Camera"
    }
] as ReactSelectStringKeyListItem[];

const directionStrings = getEnumStrings(EATDirection);

const determineDefaultDirection = (direction: EATDirection) => {

    if (direction === EATDirection.Unknown) return null;

    let defaultDirection: EATDirection = EATDirection.North;
    let directionIndex: number = directionStrings.indexOf(EATDirection[direction]);

    if (directionIndex < 4 && directionIndex >= 0)
        defaultDirection = direction;

    return EATDirection[defaultDirection];
};

const determineDefaultMarkerTypes = (route: IATFacilityRoute) : ReactSelectStringKeyListItem[] => {
    if (route.markerTypes) {
        let defaultSelectValues : ReactSelectStringKeyListItem[] = [];

        (route.markerTypes as EATKioskMarkerType[]).forEach(element => {
            let foundType : ReactSelectStringKeyListItem | undefined = MARKER_TYPES.find(mt => mt.value === element);

            if (foundType) {
                defaultSelectValues.push(foundType);
            }
        });
    
        return defaultSelectValues;
    }

    return [];
};

export type IEditRoute = {
    route: IATFacilityRoute;
    routeIndex: number;
    currentRoutes: IATFacilityRoute[];
    cancelCallback: () => any;
    saveCallback: (route: IATFacilityRoute, routeIndex: number) => any;
} & React.HTMLAttributes<HTMLElement>;


export const EditRoute: React.FC<IEditRoute> = (props) => {

    const { route, routeIndex, currentRoutes } = props;

    const [placeValue, setPlaceValue] = 
        React.useState<IATPlace>(route.place);

    const [selectedPlaceIndex, setSelectedPlaceIndex] = 
        React.useState<number | null>(null);

    const [directionValue, setDirectionValue] = 
        React.useState<string | null>(determineDefaultDirection(route.direction));

    const [startValue, setStartValue] = 
        React.useState<string>(`${route.startLinearReference}`);

    const [endValue, setEndValue] = 
        React.useState<string>(`${route.endLinearReference}`);

    const [markerValues, setMarkerValues] = React.useState<ReactSelectStringKeyListItem[]>(determineDefaultMarkerTypes(route));

    const {roadways, loading, loaded, error } = useGetRoadways();

    const [roadwayStrings, setRoadwayStrings] = React.useState<string[]>();

    const [disabledDirections, setDisabledDirections] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (roadways && roadways.length > 0){
            let roadwayStrings: string[] = roadways.map(
                (place: IATPlace, index: number) => {
                    return place.name || ""
                }
            );

            let selectedIndex: number = 
                searchObjectListByProperty(roadways, "name", (route?.place.name));

            setRoadwayStrings(roadwayStrings);
            setSelectedPlaceIndex(selectedIndex !== -1 ? selectedIndex : null);
        }
    }, [roadways]);

    /* React.useEffect(
        () => {
            disableDuplicateDirections();
        }, [placeValue]
    )

    const disableDuplicateDirections = () => {
        for (let i = 0; i < currentRoutes.length - 1; i++){
            if (currentRoutes[i].place.id === placeValue.id){
                disabledDirections.push(EATDirection[currentRoutes[i].direction]);
            }
        }

        setDisabledDirections([...disabledDirections]);
    }
 */
    const buildReturnValue = (): IATFacilityRoute => {
        return (
            new ATFacilityRoute({
                id: route.id,
                place: placeValue, 
                direction: EATDirection[directionValue as keyof typeof EATDirection],
                startLinearReference: parseFloat(startValue) || 0, 
                endLinearReference: parseFloat(endValue) || 0,
                markerTypes: markerValues.map(kvp => EATKioskMarkerType[kvp.value as keyof typeof EATKioskMarkerType])
            })
        )
    };

    return (
        <SC.StyledEditRoute>

            <SC.StyledContent>
                { (!loaded && loading) &&
                    <div>
                        Loading Places...
                    </div>
                }
                {
                    (!loaded && !loading) &&
                    <SC.StyledLoadError>
                        <span style={{color: "red", marginBottom: "12px"}}>Error! Failed to load roadways.</span>
                        <span style={{marginBottom: "8px"}}>Try refreshing the facility editor page.</span>
                        <span>*If you have made other changes, you may want to save before reloading.*</span>
                    </SC.StyledLoadError>
                }
                { loaded &&
                    <FormCard>
                        <FormSelectLabeled 
                            options={roadwayStrings || []}
                            label={"Roadway"} searchable={true}
                            selectedIndex={roadwayStrings ? selectedPlaceIndex : null}
                            callback={(newIndex: any) => { 
                                setPlaceValue(roadways[newIndex]);
                                setSelectedPlaceIndex(newIndex);
                            }}
                        />
                        <FormSelectLabeled style={{marginTop: "12px"}}
                            options={DIRECTIONS} label={"Direction"}
                            disabled={!placeValue.id}
                            disabledIndecies={
                                disabledDirections.map(
                                    (direction) => {
                                        return DIRECTIONS.indexOf(direction);
                                    }
                                )
                            }
                            selectedIndex={directionValue ? directionStrings.indexOf(directionValue) : null}
                            callback={(newIndex: number) => {
                                setDirectionValue(directionStrings[newIndex]);
                            }}
                        />
                        <FormTextInputLabeled 
                            labelWidth={"25%"} inputWidth={"calc(75% - 12px)"} inputHeight={"41px"} 
                            label={"Start MP"} value={`${startValue}`} 
                            onChange={(newValue: any) => setStartValue(filterNaInt(newValue.currentTarget.value))}
                        />
                        <FormTextInputLabeled 
                            labelWidth={"25%"} inputWidth={"calc(75% - 12px)"} inputHeight={"41px"} 
                            label={"End MP"} value={`${endValue}`} 
                            onChange={(newValue: any) => setEndValue(filterNaInt(newValue.currentTarget.value))}
                        />


                        <SC.StyledMarkerFormItemRow >
                            <SC.StyledMarkerLabel htmlFor="ddlMarkerTypes">
                                Markers
                            </SC.StyledMarkerLabel>
                            <SC.StyledMarkerSelect>
                                <Select
                                id={"ddlMarkerTypes"}
                                isMulti={true}
                                className={`av-selector-container`}
                                classNamePrefix={`av-selector`}
                                styles={reactSelectStyles}
                                menuPlacement={"top"}
                                options={MARKER_TYPES}
                                placeholder="Markers"
                                value={markerValues}
                                onChange={(event) => setMarkerValues([...event])}
                                />
                            </SC.StyledMarkerSelect>
                        </SC.StyledMarkerFormItemRow>
                        
                        
                    </FormCard>
                }
            </SC.StyledContent>

            <SC.StyledFooter>

                <FormButton onClick={() => props.cancelCallback()} 
                    color={"#DF3143"} width={"350px"}>
                    <i className="fas fa-window-close" style={{marginRight: "8px", color: "#DF3143"}}></i>
                    <span>{"Cancel"}</span>
                </FormButton>

                <FormButton
                    onClick={
                        () => {
                            props.saveCallback(buildReturnValue(), routeIndex);
                        }
                    } 
                    color={"#1E824C"} width={"350px"}>
                    <i className="fas fa-save" style={{marginRight: "8px", color: "#1E824C"}}></i>
                    <span>{"Save"}</span>
                </FormButton>

            </SC.StyledFooter>

        </SC.StyledEditRoute>
    )
};

export default EditRoute;

