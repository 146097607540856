// libraries
import * as React from "react";
import styled from "styled-components";
// components
import { IATCameraDevice } from "@algo/network-manager/models/v3";
import { Container, Row, Col } from "reactstrap";
// @ts-ignore
import { Body1 } from "@algo/algo-styles/AlgoTypography";
// @ts-ignore
import { IconLabel } from "@algo/icon-label/IconLabel";
// @ts-ignore
import { Pill } from "@algo/pill";
// styles
// @ts-ignore
import { CssCustomScrollbar } from "@algo/algo-styles/AlgoStyled";
import { IStringStringDictionary } from "../../../../types/common";
// constants
declare var __API_URL__: string;                                                // build-time variable for snapshots endpoint
const snapshotURI: string = `${__API_URL__}/v3.0/Cameras/`;              // completes the snapshot enpoint address
const pillStyle: React.CSSProperties = {
    fontSize: "12px",
    letterSpacing: "-0.01em",
    lineHeight: "1.3341"
}
export interface IProps {
    camera: IATCameraDevice;
    snapshotHeight: number;
    gmap?: React.ReactNode;
    pills?: IStringStringDictionary;
}

export const CameraCard: React.FC<IProps> = (props) => {

    /**************************************************
        CALCULATED VALUES & BREVITY NAMES
     *************************************************/
    const camera = props.camera;                                                // the camera whose data we display
    const pills = props.pills || null;                                          // the status pills (cloud, local, etc...)

    const infoHeight: number = (props.gmap) ? 131 : 187;                        // height of section beneath snapshot ( different when used with gmap )
    const roadNamesMargin: number = (props.gmap) ? 4 : 24;                      // margin under road names section ( different when used with gmap )

    let infoStyle: React.CSSProperties = {                                      // brevity
        height: `${infoHeight}px`, padding: "16px",
    };

    let roadNamesStyle: React.CSSProperties = {                                 // brevity
        height: "64px", marginBottom: `${roadNamesMargin}px`,
    };

    /**************************************************
        NULL RENDER
     *************************************************/
    if (!camera) return (                                                       // should never happen, parent would fail at null camera     
        <StyledNullCamera height={props.snapshotHeight + infoHeight}>
            <IconLabel
                iconClass={"fas fa-exclamation-triangle"}
                iconStyle={{ fontSize: "32px" }}
            />
            <Body1>Invalid Camera Object</Body1>
        </StyledNullCamera>
    );

    /**************************************************
        PRIMARY RENDER
     *************************************************/

    return (
        <StyledCameraCard className={`container-fluid`}>
            <Row>
                <StyledGraphicCol className="col">
                    {props.gmap
                        ? props.gmap
                        : <CameraSnapshot
                            height={props.snapshotHeight}
                            camera={camera}
                        />
                    }
                </StyledGraphicCol>
            </Row>
            <StyledInfoRow className="row">
                <Col className="w-100">
                    <Container fluid={true} style={infoStyle}>
                        <Row style={roadNamesStyle}>
                            <Col className="p-0">
                                <Container fluid={true}>
                                    <Row>
                                        <Col className="p-0 mb-1">
                                            <div>
                                                <Body1
                                                    weight="bold"
                                                    className="line-clamp m-0">
                                                    {camera.routeDesignator}
                                                </Body1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex justify-content-left p-0">
                                            <Body1 style={{ color: "#6c757d" }}>
                                                {camera.crossStreet}
                                            </Body1>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="p-0">
                                <StyledPillsWrapper height={props.gmap ? 2 : 4}>
                                    {props.pills && Object.keys(props.pills).map(
                                        (key) => {
                                            return (
                                                <Pill
                                                    key={Math.random()}
                                                    pillClass={pills ? pills[key] : ""}
                                                    pillStyleObject={pillStyle}
                                                    label={`${key}`}
                                                />
                                            )
                                        }
                                    )}
                                </StyledPillsWrapper>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </StyledInfoRow>
        </StyledCameraCard>
    );
}

export default React.memo(CameraCard);

/*****************************************************************************
    SECONDARY LOCAL COMPONENTS
 ****************************************************************************/
export interface ICameraSnapshot {
    height: number;
    camera: IATCameraDevice;
}

export const CameraSnapshot: React.FC<ICameraSnapshot> = (props) => {

    const { height, camera } = props;

    return (
        <img
            width={"100%"}
            height={height}
            src={`${snapshotURI}${camera.id}/snapshot.jpg`}
            alt={`Camera Snapshot Preview for: ${camera.name}`}
            style={{borderRadius: "4px 4px 0 0"}}
        />
    );
}

/*****************************************************************************
    STYLE-ONLY LOCAL COMPONENTS
 ****************************************************************************/
// Styled NullCamera
interface IStyledNullCamera {
    height: number;
}

const StyledNullCamera = styled.div<IStyledNullCamera>`
    width: 100%;
    height: ${ props => props.height}px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #771b13;
    background: #fad6d3 linear-gradient(180deg, #fbdcda, #fad6d3) repeat-x;
    border-color: #f8c6c2;
`;

// Styled CameraCard
export const StyledCameraCard = styled.div`
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    width: 100%;
    border-radius: 4px;
    border: 0;
`;

// Styled GraphicCol
export interface IStyledGraphicCol {
    height?: number;
}

export const StyledGraphicCol = styled.div<IStyledGraphicCol>`
    height: ${props => props.height};
    border-radius: 4px 4px 0 0;
    margin: 0;
    padding: 0;
    background-color: #efeff0;
    width: 100%;
`;


// Styled InfoRow
export interface IStyledInfoRow {
    height?: number;
}

export const StyledInfoRow = styled.div<IStyledInfoRow>`
    border-radius: 0 0 4px 4px;
    border: 1px solid #dee2e6;
    border-top: 0;
`;

// Styled PillsWrapper
export interface IStyledPillsWrapper {
    height?: number;
};

export const StyledPillsWrapper = styled.div<IStyledPillsWrapper>`
    height: ${props => props.height ? `${props.height}rem` : "auto"};
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;

    ${CssCustomScrollbar}
`;