//libraries
import { nuon } from "@caps-mobile/common-lib";
// interfaces & models
import { IATLocation, EATDirection, EATPlaceType } from "@algo/network-manager/models/v3";


export const buildReturnValue = (
    location: any, cityNameValue: string, countyNameValue: string,
    directionValue: any, markerCoords: any, mileMarkerValue: any, 
    placeValue: any
): IATLocation => {
    return (
        {
            ...location,
            city: cityNameValue || location.city,
            county: countyNameValue || location.county,
            latitude: markerCoords?.lat || location.latitude,
            longitude: markerCoords?.lng || location.longitude,
            direction: directionValue,
            linearReference: parseFloat(mileMarkerValue) || location.linearReference,
            routeDesignator: (placeValue?.name) ? placeValue.name : location.displayRouteDesignator,
            displayRouteDesignator: (placeValue?.name) ? placeValue.name : location.displayRouteDesignator,
            routeDesignatorType: (placeValue?.type) ? placeValue.type : location.routeDesignatorType
        }
    )
};

export const getSelectOptions = (objectArray: any[]): string[] => {
    if (!objectArray) return [];

    return objectArray.map(
        (object: any) => {
            return object.name || `${object.id}`
        }
    )
};