//types
import * as TYPES from "./types";
//models
import { AARole } from "@algo/network-manager/models/v3/admin";
import { ProcessedResponse } from "@algo/network-manager/managers/v3";

let initialState: TYPES.RoleEditorState = {
    initializing: false,
    roleLoaded: false,
    savingRole: false,
    role: new AARole(),
    privilegeList: [],
    organizationList: [],
    error: null,
    lastResponse: new ProcessedResponse()
}

export function roleEditorReducer(
    state = initialState,
    action: TYPES.RoleEditorTypes
): TYPES.RoleEditorState {
    switch(action.type) {
        case TYPES.LOAD_ROLE_BEGIN:
            return {
                ...state,
                initializing: true
            };
        case TYPES.LOAD_ROLE_SUCCESS:
            return {
                ...state,
                initializing: false,
                roleLoaded: true,
                role: action.payload.role,
                privilegeList: action.payload.privilegeList,
                organizationList: action.payload.organizationList
            };
        case TYPES.LOAD_ROLE_FAILURE:
            return {
                ...state,
                initializing: false,
                roleLoaded: false,
                error: action.payload.error
            };
        case TYPES.SAVE_ROLE_BEGIN:
            return {
                ...state,
                savingRole: true
            };
        case TYPES.SAVE_ROLE_SUCCESS:
            return {
                ...state,
                savingRole: false,
                role: action.payload.role
            };
        case TYPES.SAVE_ROLE_FAILURE:
            return {
                ...state,
                savingRole: false,
                error: action.payload.error
            };
        case TYPES.UNINITIALIZE_ROLE_EDITOR:
            return initialState;
        default:
            return state;
    }
}