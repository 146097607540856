// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledEditRoute = styled.div`

    height: 50%;
    width: 50%;

    padding: 1rem;

    background-color: ${ props => props.theme.colors["bg-primary"]["rgba"]};
    border-radius: 4px;

    ${flexSmart("flex-start center")}
    flex-direction: column;    
`;

export const StyledContent = styled.div`
    height: calc(100% - 41px);
    width: 100%;

    ${flexSmart("center center")}
    flex-direction: column;
`;

export const StyledFooter = styled.div`

    height: 41px;
    width: 100%;

    ${flexSmart("space-evenly center")}
`;

export const StyledLoadError = styled.div`

    ${flexSmart("center center")}
    flex-direction: column;
`;


export const StyledMarkerFormItemRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
`;

export const StyledMarkerLabel = styled.label`
    width: 25%;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    text-align: end;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: rgb(33, 37, 41);
`;

export const StyledMarkerSelect = styled.div`
    width: calc(75% - 12px);
`;