// interfaces & models
import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { IATLegacyDatasourceDto } from "@algo/network-manager/models/v3";
import { IReduxData } from "../../../../utils/ReduxData";

export const LOAD_DATASOURCES_BEGIN = "LOAD_DATASOURCES_BEGIN";
export const LOAD_DATASOURCES_SUCCESS = "LOAD_DATASOURCES_SUCCESS";
export const LOAD_DATASOURCES_FAILURE = "LOAD_DATASOURCES_FAILURE";

export const UPDATE_ACCESS_LEVEL_BEGIN = "UPDATE_ACCESS_LEVEL_BEGIN";
export const UPDATE_ACCESS_LEVEL_SUCCESS = "UPDATE_ACCESS_LEVEL_SUCCESS";
export const UPDATE_ACCESS_LEVEL_FAILURE = "UPDATE_ACCESS_LEVEL_FAILURE";

export interface State {
    datasourceData: IReduxData;                             
    accessLevelData: IReduxData;                            
    error: Error | null;
    lastResponse: IProcessedResponse;                                   
}


export interface Payload {
    datasources: Array<IATLegacyDatasourceDto>;
    totalCount: number;
    checksum: number;
    error: Error | null;
    response: IProcessedResponse;
}


/******************************************************************************
    LOAD DATASOURCES | ACTION TYPES
 *****************************************************************************/
interface LoadDatasourcesBeginAction {
    type: typeof LOAD_DATASOURCES_BEGIN;
    payload: Payload;
}

interface LoadDatasourcesSuccessAction {
    type: typeof LOAD_DATASOURCES_SUCCESS;
    payload: Payload;
}

interface LoadDatasourcesFailureAction {
    type: typeof LOAD_DATASOURCES_FAILURE;
    payload: Payload;
}

/******************************************************************************
    UPDATE ACCESS LEVEL | ACTION TYPES
 *****************************************************************************/
interface UpdateAccessLevelsBeginAction {
    type: typeof UPDATE_ACCESS_LEVEL_BEGIN;
    payload: Payload;
}

interface UpdateAccessLevelsSuccessAction {
    type: typeof UPDATE_ACCESS_LEVEL_SUCCESS;
    payload: Payload;
}

interface UpdateAccessLevelsFailureAction {
    type: typeof UPDATE_ACCESS_LEVEL_FAILURE;
    payload: Payload;
}

export type Actions =
    //LOAD DATASOURCES
    LoadDatasourcesBeginAction |
    LoadDatasourcesSuccessAction |
    LoadDatasourcesFailureAction |
    //UPDATE ACCESS LEVEL
    UpdateAccessLevelsBeginAction |
    UpdateAccessLevelsSuccessAction |
    UpdateAccessLevelsFailureAction;