import {ReactComponent as IconSync} from "../../images/algo-nav/icon-sync.svg";
import {ReactComponent as IconUsers} from "../../images/algo-nav/icon-profile.svg";
import {ReactComponent as IconOrganizations} from "../../images/algo-nav/icon-group.svg";
import {ReactComponent as IconRoles} from "../../images/algo-nav/icon-profile-tag.svg";
import {ReactComponent as IconAlerts} from "../../images/algo-nav/icon-alert-triangle.svg";
import {ReactComponent as IconCameras} from "../../images/algo-nav/icon-camera.svg";
import {ReactComponent as IconFacilities} from "../../images/algo-nav/icon-house.svg";
import {ReactComponent as IconFeedback} from "../../images/algo-nav/icon-mail.svg";
import * as PRIVS from "../../models/Privileges";

// this function handles building the hierarchy of literal routing strings 
// (not to be confused with the link label text)
export const navStringsPrivilegeFilter = (privileges: string[]) => {

    // navStringsArray is an array of arrays of routing strings
    // each nested array represents the routes for the given 'section' of nav
    let navStringsArray: string[][] = [];

    // build "TOOLS" section

    let toolsStrings: string[] = [];

    // check privs for "Synchronization" page
    if (privileges.includes(PRIVS.VIEW_SERVICE_STATUS))
        toolsStrings.push("/synchronization");

    if (toolsStrings.length > 0)
        navStringsArray.push(toolsStrings);

    // check privs for "USER ADMINISTRATION" section

    let userAdminStrings: string[] = [];

    if (privileges.includes(PRIVS.VIEW_USER))
        userAdminStrings.push("/user");

    // check privs to view "Organizations" page
    if (privileges.includes(PRIVS.VIEW_ORGANIZATION))
        userAdminStrings.push("/organization");

    // check privs to view "Roles" page
    if (privileges.includes(PRIVS.VIEW_ROLE))
        userAdminStrings.push("/role");

    // append all authorized "USER ADMINISTRATION" strings
    if (userAdminStrings.length > 0)
        navStringsArray.push(userAdminStrings);

    // check privs for "SITE ADMINISTRATION" section
    let siteAdminStrings: string[] = [];

    // check privs to view "Alerts" page (for Admin: "alerts" === "messages")
    if (privileges.includes(PRIVS.VIEW_MESSAGE))
        siteAdminStrings.push("/message");

    // check privs to view "Cameras" page
    if (privileges.includes(PRIVS.EDIT_CAMERA))
        siteAdminStrings.push("/camera");

    // check privs to view "Facilities" page
    //if User has ANY of the privs associated with Facilities, they may VIEW
    for (let priv of PRIVS.FACILITY_PRIVILEGES){
        if (privileges.includes(priv)){
            siteAdminStrings.push("/facility");
            break;
        }
    }

    // check privs to view "Feedback" page
    if (privileges.includes(PRIVS.VIEW_FEEDBACK))
        siteAdminStrings.push("/feedback");
    
    // append all authorized "USER ADMINISTRATION" strings
    if (siteAdminStrings.length > 0)
        navStringsArray.push(siteAdminStrings);

    return navStringsArray;
};

export const getNavString = (navIndex: number[], navStrings: string[][]) => {

    let navSection: number = navIndex[0];
    let navLink: number = navIndex[1];

    if (!navStrings[navSection] || !navStrings[navSection][navLink])
        return "/";

    else 
        return navStrings[navSection][navLink];
};

export const navConfigPrivilegeFilter = (privileges: string[]) => {

    // the nav configuration is an array of objects representing nav sections
    let navConfigArray: any = [];

    // each nav section object has a text title and a list of its labeled buttons

    // TOOLS SECTION
    let toolsNavLinks = [];

    if (privileges.includes(PRIVS.VIEW_SERVICE_STATUS)) {
        toolsNavLinks.push({
            text: "Sync Services", 
            icon: <IconSync />
        });
    }

    if (toolsNavLinks.length > 0){
        navConfigArray.push(
            {
                text: "Tools",
                navLinks: toolsNavLinks
            }
        )
    }
    
    // USER ADMINISTRATION SECTION
    let userAdminNavLinks: any = [];

    if (privileges.includes(PRIVS.VIEW_USER)) {
        userAdminNavLinks.push({
            text: "Users", 
            icon: <IconUsers />
        });
    }

    if (privileges.includes(PRIVS.VIEW_ORGANIZATION)) {
        userAdminNavLinks.push({
            text: "Organizations", 
            icon: <IconOrganizations />
        });
    }

    if (privileges.includes(PRIVS.VIEW_ROLE)) {
        userAdminNavLinks.push({
            text: "Roles", 
            icon: <IconRoles />
        });
    }

    if (userAdminNavLinks.length > 0){
        navConfigArray.push({
            text: "User Administration",
            navLinks: userAdminNavLinks
        });
     }

    // SITE ADMINISTRATION SECTION
    let siteAdminNavLinks: any = [];

    if (privileges.includes(PRIVS.VIEW_MESSAGE))
        siteAdminNavLinks.push({
            text: "Alerts",
            icon: <IconAlerts />            
        });

    if (privileges.includes(PRIVS.EDIT_CAMERA))
        siteAdminNavLinks.push({
            text: "Cameras",
            icon: <IconCameras />            
        });

    if (
        privileges.includes(PRIVS.CREATE_FACILITY) || 
        privileges.includes(PRIVS.UPDATE_FACILITY) ||
        privileges.includes(PRIVS.DELETE_FACILITY)
    )
        siteAdminNavLinks.push({
            text: "Facilities",
            icon: <IconFacilities />            
        });

    if (privileges.includes(PRIVS.VIEW_FEEDBACK))
        siteAdminNavLinks.push({
            text: "Feedback",
            icon: <IconFeedback />           
        });

    if (siteAdminNavLinks.length > 0){
        navConfigArray.push({
            text: "Site Administration",
            navLinks: siteAdminNavLinks
        })
    }
    
    return navConfigArray;
};