// libraries
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {ThemeProvider} from "styled-components";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { 
  ThemeContext, getTheme, EThemeOptions 
} from "@caps-mobile/algo-theme";
import { ToastProvider } from "@caps-mobile/toast";
// store
import store from './store';
// components
import Template from "./components/template/Template";

const App: React.FC<any> = () => {

  const [themeMode, setThemeMode] = 
    React.useState<EThemeOptions>(EThemeOptions.Light);

  const [themeObject, setThemeObject] =
    React.useState<any>(getTheme(themeMode));

  // The point of this script is to capture the session storage'd user 
  // and silently renew the access token.
  const [hasTriedSignin, setHasTriedSignin] = React.useState<boolean>(false);
  const auth = useAuth();
  
  React.useEffect(() => {
      if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator 
          && !auth.isLoading && !hasTriedSignin
      ) {
          // console.log("signinSilent: ATTEMPTED \nlocation.pathname: %s \nauth.isAuthenticated: %s \nauth.activeNavigator: %s \nauth.isLoading: %s \nhasTriedSignin: %s \nhereContext.HEREScriptLoaded: %s \nhereContext.HARPScriptLoaded: %s", 
          // location.pathname, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin, hereContext.HEREScriptLoaded, hereContext.HARPScriptLoaded);
          auth.signinSilent();
          
          setHasTriedSignin(true);
      }
      // else {
      //     console.log("signinSilent: NOT ATTEMPTED \nlocation.pathname: %s \nauth.isAuthenticated: %s \nauth.activeNavigator: %s \nauth.isLoading: %s \nhasTriedSignin: %s \nhereContext.HEREScriptLoaded: %s \nhereContext.HARPScriptLoaded: %s", 
      //     location.pathname, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin, hereContext.HEREScriptLoaded, hereContext.HARPScriptLoaded);
      // }
  }, [auth, hasTriedSignin]);

  return (
      <Provider store={store}>
            <ThemeProvider theme={themeObject}>
                <ThemeContext.Provider value={themeObject}>
                    <BrowserRouter>
                      <ToastProvider>
                        <Template />
                      </ToastProvider>
                    </BrowserRouter>
                </ThemeContext.Provider>
            </ThemeProvider>
      </Provider>
  );

}

export default App;
