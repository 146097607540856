// libraries
import { useContext, useEffect } from "react";

// triggers navigation to the given route when the session_ended component renders
export const useNavigateAfterSignout = (routeName: string = "/") => {

    useEffect(
        () => {
            window.location.replace('/');
        }, []
    );
}