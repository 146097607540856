// libraries
import * as React from 'react';
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// models & interfaces
import { 
    ATFeedbackResponse,
    IATFeedbackResponse 
} from "@algo/network-manager/models/v3";
// store
import {
    initializeFeedbackDetail, sendResponse, uninitializeFeedbackDetail
} from '../../../store/feedback/detail/actions';
import { AppState } from '../../../store';
import { ProfileState } from '../../../store/profile/types';
import { FeedbackDetailState } from '../../../store/feedback/detail/types';
// components
import Loading from '../Loading';
import DismissableModal from '../../base/modals/DismissableModal';
// constants
import {
    FEEDBACK_DEFAULT_ID, FEEDBACK_RESPONSE_MESSAGE
} from "../../../utils/AppConstants";
import { EMAIL_FEEDBACK_RESPONSE } from '../../../models/Privileges';

interface OwnProps {
    id: number;
}

interface StateProps { 
    profile: ProfileState;
    detail: FeedbackDetailState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        profile: state.profile,
        detail: state.feedbackDetail
    };
}

interface DispatchProps {
    initialize: typeof initializeFeedbackDetail;
    sendResponse: typeof sendResponse;
    uninitialize: typeof uninitializeFeedbackDetail;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeFeedbackDetail,
        sendResponse: sendResponse,
        uninitialize: uninitializeFeedbackDetail
    }, dispatch);
}

type FeedbackDetailViewProps = 
    StateProps & 
    DispatchProps & 
    OwnProps & 
    RouteComponentProps<any>;

interface FeedbackDetailViewState {
    id: number;
    feedbackResponse: IATFeedbackResponse;
    showSuccessModal: boolean;
}

class FeedbackDetailView 
    extends React.Component<
        FeedbackDetailViewProps, 
        FeedbackDetailViewState
    > 
{
    constructor(props: Readonly<FeedbackDetailViewProps>) {
        super(props);

        this.state = {
            id: this.props.id ? this.props.id : FEEDBACK_DEFAULT_ID,
            feedbackResponse: new ATFeedbackResponse({
                feedbackId: this.props.id ? this.props.id : FEEDBACK_DEFAULT_ID,
                responseMessage: ''
            }),
            showSuccessModal: false
        };
    }

    componentDidMount() {
        this.props.initialize(this.state.id);
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (
            ( this.props.detail.sendingResponse !== prevProps.detail.sendingResponse ) && 
            !this.props.detail.sendingResponse
        ) {
            this.setState((prevState, props) => {
                var updatedResponse = prevState.feedbackResponse;
                updatedResponse.responseMessage = '';

                return {
                    feedbackResponse: updatedResponse,
                    showSuccessModal: props.detail.error === null
                };
            });
        }
    }

    componentWillUnmount() {
        this.props.uninitialize();
    }

    back = (): void => {
        this.props.history.goBack();
    }

    setFeedbackItemValue = (property: any, event: any): void => {
        event.preventDefault();

        var currentValue = event.currentTarget.value;

        this.setState((prevState, props) => {
            var feedbackResponse = prevState.feedbackResponse;

            switch (property) {
                case FEEDBACK_RESPONSE_MESSAGE:
                    feedbackResponse.responseMessage = currentValue;
                    break;
            }

            return {
                feedbackResponse: feedbackResponse
            };
        });
    }

    submit = (): void => {
        if (!this.props.detail.sendingResponse) {
            this.props.sendResponse(this.state.feedbackResponse);
        }
    }

    closeModal = (): void => {
        this.setState((prevState, props) => {
            return {
                showSuccessModal: false
            };
        });
    }

    render() {
        let { detail, profile } = this.props;

        let feedbackContact = (
            <div 
                className='av-feedback-contact'
            >
                {
                    ( detail.feedback.email !== '' ) 
                        ?   `${detail.feedback.name}` + 
                            `${
                                detail.feedback.company && 
                                detail.feedback.company !== '' 
                                    ? `, ${detail.feedback.company}` 
                                    : ``
                            } <${detail.feedback.email}>` 
                        : `${detail.feedback.name}`
                        
                }
            </div>
        );

        let successModal = 
            ( this.state.showSuccessModal )
                ?   (
                        <DismissableModal 
                            title={`Message Sent`} type={`success`} 
                            message={`Response message was sent to ${detail.feedback.name}.`} 
                            dismissCallback={this.closeModal} 
                        />
                    ) 
                : null;

        if (detail.loading) {
            return <Loading />;
        }
        else {
            return (
                <div className='av-admin-editor-content'>
                    <div className='av-feedback-detail'>
                        <div className='av-feedback-detail-section titlebar'>
                            <div className='av-back-action'>
                                <div className='col-2'>
                                    <button 
                                        className='btn btn-outline-primary' 
                                        onClick={this.back}
                                    >
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                </div>
                                <div className='av-toolbar-title'>
                                    <h1>{detail.feedback.subject}</h1>
                                    {feedbackContact}
                                    <div 
                                        className='av-feedback-created'
                                    >
                                        {detail.feedback.formattedDateCreated()}
                                    </div>
                                </div>
                                <div className='col-2 text-right'>

                                </div>
                            </div>
                        </div>
                        <div className='av-feedback-detail-section'>
                            <h2 className='av-feedback-section-header'>Comment</h2>
                            <p>{detail.feedback.comments}</p>
                        </div>
                        <div className='av-feedback-detail-section'>
                            <h2 className='av-feedback-section-header'>Respond to Feedback</h2>
                            <div className='av-admin-editor-form-row'>
                                <div className='av-admin-editor-form-item fluid'>
                                    <textarea
                                        id='txtFeedbackResponse'
                                        className='av-form-textarea'
                                        placeholder={`Enter feedback response here...`}
                                        value={this.state.feedbackResponse.responseMessage}
                                        onChange={
                                            (event) => 
                                                this.setFeedbackItemValue(
                                                    FEEDBACK_RESPONSE_MESSAGE, 
                                                    event
                                                )
                                        }
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <button
                                    className='btn btn-block btn-outline-primary'
                                    disabled={
                                        detail.sendingResponse || 
                                        !profile.userProfile.hasPrivilege(EMAIL_FEEDBACK_RESPONSE)
                                    }
                                    onClick={this.submit}
                                >
                                    <span 
                                        className={
                                            `send-icon ${detail.sendingResponse ? 'sending' : ''}`
                                        }
                                    ></span> 
                                    Send
                            </button>
                            </div>
                        </div>
                    </div>
                    {successModal}
                </div>
            );
        }
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(FeedbackDetailView));