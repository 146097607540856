// constants
import * as mapPinIcon from "../../../../../images/map-pins/pin-region-96.png";

/**************************************************************************
    STRINGS
*************************************************************************/
export const tipString1: string = 
    `Place a marker indicating the center point for the facility on the map below.`;

export const tipString2: string =
    `Set zoom level by scrolling on the map, or by entering a floating point value between 2 and 22 (inclusive).`;

export const zoomLabel: string = `Zoom Level`;
export const updateZoomLabel: string = `Update & Center`;

export const cancel: string = "Cancel";
export const save: string = "Save";

/**************************************************************************
    NUMBERS
*************************************************************************/

/**************************************************************************
    DATA OBJECTS
*************************************************************************/
export const mapPinOptions = {
    type: 0,
    position: {lat: 0, lng: 0},
    options: {
        icon: { 
            iconSrc: mapPinIcon.default, 
            size: {w: 42, h: 42}
        } 
    }
};

export const D_MAP_CONFIG = {
    center: {lat: 32.411663269199124, lng: -86.26686300858245},
    zoom: 10,
};