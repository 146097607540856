// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// models & interfaces
import { 
    EATDirection, getDirectionLetter, IATFacilityDto, IATLocationDto 
} from "@algo/network-manager/models/v3";
// store
import {
    initializeFacilityDashboard, removeFacility, searchFacilities,
    uninitializeFacilityDashboard
} from '../../../../store/facility/dashboard/actions';
import { AppState } from "../../../../store";
import { ProfileState } from "../../../../store/profile/types";
import { FacilityDashboardState } from "../../../../store/facility/dashboard/types";
// components
import ConfirmationModal from '../../../base/modals/ConfirmationModal';
import { 
    GenericTableViewSettings, GenericTableView, 
    GenericTableViewColumnDefinition
} from "../../../base/layout/GenericTableView";
// constants
import { 
    DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_QUERY,
    DEFAULT_TOTAL_PAGES, FACILITY_CONSTANTS as FAC_CONST
} from '../../../../utils/AppConstants';

interface StateProps { 
    profile: ProfileState;
    dashboard: FacilityDashboardState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        profile: state.profile,
        dashboard: state.facilityDashboard
    };
};

interface DispatchProps {
    initialize: typeof initializeFacilityDashboard;
    search: typeof searchFacilities;
    remove: typeof removeFacility;
    uninitialize: typeof uninitializeFacilityDashboard;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeFacilityDashboard,
        search: searchFacilities,
        remove: removeFacility,
        uninitialize: uninitializeFacilityDashboard
    }, dispatch);
};

type FacilityTableViewProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface FacilityTableViewState {
    currentPageIndex: number;
    pageSize: number;
    totalPages: number;
    facilityId: number;
    showDeleteConfirmModal: boolean;
    query: string;
}

class FacilityTableView extends React.Component<FacilityTableViewProps, FacilityTableViewState> {
    constructor(props: Readonly<FacilityTableViewProps>) {
        super(props);

        this.state = {
            currentPageIndex: DEFAULT_PAGE_INDEX,
            pageSize: DEFAULT_PAGE_SIZE,
            totalPages: DEFAULT_TOTAL_PAGES,
            facilityId: FAC_CONST.defaultId,
            showDeleteConfirmModal: false,
            query: DEFAULT_QUERY
        } as FacilityTableViewState;
    }

    componentDidMount() {
        this.props.initialize();
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

        let prevIsLoading = prevProps.dashboard.initializing || prevProps.dashboard.loadingFacilities;
        let currentIsLoading = this.props.dashboard.initializing || this.props.dashboard.loadingFacilities;

        if (
            ( this.props.dashboard.facilities?.length !== prevProps.dashboard.facilities?.length ) || 
            ( this.state.pageSize !== prevState.pageSize ) || 
            ( prevIsLoading !== currentIsLoading ) 
        ){
            this.setState((state, props) => {
                return {
                    ...state,
                    totalPages: props.dashboard.facilities ? props.dashboard.facilities.length / this.state.pageSize : 0,
                    currentPageIndex: 0
                };
            })
        }
    }
    
    searchBoxChange = (query: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            };
        });
    }

    clearFilter = (): void => {
        this.props.search(DEFAULT_QUERY);

        this.setState((state, props) => {
            return {
                ...state,
                query: DEFAULT_QUERY
            };
        });
    }

    applyFilter = (query: string, key: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            }
        })

        console.log("apply filter");
        this.props.search(query);
    }

    goToEditor = (id: number): void => {
        this.props.history.push(`/facility/${id}`);
    }

    newFacility = (): void => {

        $('[data-toggle="tooltip"]').tooltip('hide');
        this.props.history.push(`/facility/new`);
    }

    showDeleteModal = (id: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                facilityId: id,
                showDeleteConfirmModal: true
            };
        });
    }

    comfirmDelete = (): void => {
        this.delete(this.state.facilityId);

        this.setState((state, props) => {
            return {
                ...state,
                facilityId: FAC_CONST.defaultId,
                showDeleteConfirmModal: false
            };
        });
    }

    dismissDelete = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                facilityId: FAC_CONST.defaultId,
                showDeleteConfirmModal: false
            };
        });
    }

    delete = (id: number): void => {

        this.props.remove(id, this.state.query);
    }

    render() {
        let { dashboard, profile } = this.props;

        let tableSettings = {
            tableClassName: 'av-facility-table',
            actionButtonClass: 'btn btn-outline-danger',
            actionButtonGlyphClass: 'delete-icon',
            columnDefinitions:  [
                {
                    propertyName: 'id',
                    isKey: true
                },
                { 
                    propertyName: 'name', 
                    displayName: 'Name', 
                    isKey: false, 
                    headerColumnClass: 'av-facility-name-col' 
                },
                { 
                    propertyName: 'direction', 
                    displayName: 'Direction', 
                    isKey: false, 
                    headerColumnClass: 'av-facility-direction-col' 
                },
                {
                    propertyName: 'code',
                    displayName: 'Code',
                    isKey: false,
                    headerColumnClass: 'av-facility-code-col'
                },
                {
                    propertyName: 'type',
                    displayName: 'Type',
                    isKey: false,
                    headerColumnClass: 'av-facility-type-col'
                },
                {
                    propertyName: 'open',
                    displayName: 'Open',
                    isKey: false,
                    headerColumnClass: 'av-facility-open-col'
                },
            ] as GenericTableViewColumnDefinition[],
            emptyTableMessage: 'No facilities',
            showPageSizeSelector: false,
            newItemDisabled: false,//!profile.userProfile.hasPrivilege(CREATE_FACILITY),
            rowClickDisabled: false,//!profile.userProfile.hasPrivilege(CREATE_FACILITY),
            actionDisabled: false//!profile.userProfile.hasPrivilege(DELETE_FACILITY)
        } as GenericTableViewSettings;

        return (
            <div style={{
                height: '100%'
            }}>
                <GenericTableView 
                    settings={tableSettings} 
                    items={getUpdatedFacilities(dashboard.facilities)} 
                    isLoading={dashboard.initializing || dashboard.loadingFacilities} 
                    isProcessingAction={dashboard.deletingFacility} 
                    newItemCallback={this.newFacility} 
                    rowClickCallback={this.goToEditor}
                    actionCallback={this.showDeleteModal}
                    searchBoxChangeCallback={this.searchBoxChange}
                    clearFilterCallback={this.clearFilter}
                    applyFilterCallback={this.applyFilter}
                />
                {
                    this.state.showDeleteConfirmModal 
                        ? <ConfirmationModal 
                            confirmCallback={this.comfirmDelete} dismissCallback={this.dismissDelete} 
                            title={`Delete this facility?`} type={`warning`}
                            message={`Are you sure you want to delete this facility? This action cannot be reversed.`}  /> 
                        : (null)
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(FacilityTableView));


// Helper function to add direction property to facility objects
const getUpdatedFacilities = (facilities: IATFacilityDto[]) => {

    return facilities.map(
        (facility: IATFacilityDto) => {
            return { ...facility, direction: deriveDirection(facility)};
        }
    )
}

// Helper function for deriving facility direction string
const deriveDirection = (facility: IATFacilityDto): string => {

    let locations: IATLocationDto[] = facility.locations || [];

    // if there are no locations, just return UNKNOWN direction
    if (locations.length === 0) 
        return `${EATDirection.Unknown}`;

    // iterate over the locations and get a list of directions
    let directions: EATDirection[] = locations.map(
        (location: IATLocationDto) => {
            return location.direction;
        }
    )

    // if there are no directions, just return UNKNOWN direction
    if (directions.length === 0) 
        return `${EATDirection.Unknown}`;

    // iterate over directions and build a unique-value-only list
    // of the analogous direction abbreviates, separated by "/"
    let directionString: string = "";

    directions.forEach(
        (direction: EATDirection, index: number) => {
            let directionAbbrev: string = getDirectionLetter(direction);
            if (directionString.indexOf(directionAbbrev) === -1){
                directionString += directionAbbrev;
                if (index !== directions.length - 1)
                    directionString += "/";
            }
        }
    )

    return directionString || `${EATDirection.Unknown}`;
}

