import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { ATFacility } from "@algo/network-manager/models/v3";

export let LOAD_FACILITY_BEGIN = 'LOAD_FACILITY_BEGIN';
export let LOAD_FACILITY_SUCCESS = 'LOAD_FACILITY_SUCCESS';
export let LOAD_FACILITY_FAILURE = 'LOAD_FACILITY_FAILURE';

export let CLEANUP_FACILITY = 'CLEANUP_FACILITY';

export let SAVE_FACILITY_BEGIN = 'SAVE_FACILITY_BEGIN';
export let SAVE_FACILITY_SUCCESS = 'SAVE_FACILITY_SUCCESS';
export let SAVE_FACILITY_FAILURE = 'SAVE_FACILITY_FAILURE';

export let CLEAR_FACILITY_EDITOR_ERROR = 'CLEAR_FACILITY_EDITOR_ERROR';

export let UNINITIALIZE_FACILITY_EDITOR = 'UNINITIALIZE_FACILITY_EDITOR';

export interface FacilityEditorState {
    initializing: boolean;
    savingFacility: boolean;
    facility: ATFacility;
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface FacilityEditorPayload {
    facility: ATFacility;
    error: Error;
    response: IProcessedResponse;
}

interface LoadFacilityBeginAction {
    type: typeof LOAD_FACILITY_BEGIN;
    payload: FacilityEditorPayload
}

interface LoadFacilitySuccessAction {
    type: typeof LOAD_FACILITY_SUCCESS;
    payload: FacilityEditorPayload;
}

interface LoadFacilityFailureAction {
    type: typeof LOAD_FACILITY_FAILURE;
    payload: FacilityEditorPayload;
}

interface CleanupFacilityAction {
    type: typeof CLEANUP_FACILITY;
    payload: FacilityEditorPayload
}

interface SaveFacilityBeginAction {
    type: typeof SAVE_FACILITY_BEGIN;
    payload: FacilityEditorPayload;
}

interface SaveFacilitySuccessAction {
    type: typeof SAVE_FACILITY_SUCCESS;
    payload: FacilityEditorPayload;
}

interface SaveFacilityFailureAction {
    type: typeof SAVE_FACILITY_FAILURE;
    payload: FacilityEditorPayload;
}

interface ClearFacilityEditorErrorAction {
    type: typeof CLEAR_FACILITY_EDITOR_ERROR;
    payload: FacilityEditorPayload;
}

interface UninitializeFacilityEditorAction {
    type: typeof UNINITIALIZE_FACILITY_EDITOR;
    payload: FacilityEditorPayload;
}

export type FacilityEditorTypes = 
    LoadFacilityBeginAction |
    LoadFacilitySuccessAction |
    LoadFacilityFailureAction |
    CleanupFacilityAction |
    SaveFacilityBeginAction |
    SaveFacilitySuccessAction |
    SaveFacilityFailureAction |
    ClearFacilityEditorErrorAction |
    UninitializeFacilityEditorAction;