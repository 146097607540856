//libraries
import { ThunkAction } from 'redux-thunk'
import { AppState } from "../../index";
import { Action } from "redux";
import { getAccessToken } from '../../../components/template/authentication/oidcConfig';
// interfaces & models
import * as TYPES from './types';
import { ATFeedbackResponse } from '@algo/network-manager/models/v3';
import {
    FeedbackNetworkManager, IFeedbackNetworkManager, IProcessedResponse 
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlFeedback: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).feedbacks}`;
/*
Load Feedback Detail
*/

function loadFeedbackBegin(): TYPES.FeedbackDetailTypes {
    return {
        type: TYPES.LOAD_FEEDBACK_DETAIL_BEGIN,
        payload: {} as TYPES.FeedbackDetailPayload
    };
}

function loadFeedbackSuccess(response: IProcessedResponse): TYPES.FeedbackDetailTypes {
    return {
        type: TYPES.LOAD_FEEDBACK_DETAIL_SUCCESS,
        payload: {
            feedback: response.data
        } as TYPES.FeedbackDetailPayload
    };
}

function loadFeedbackFailure(error: Error): TYPES.FeedbackDetailTypes {
    return {
        type: TYPES.LOAD_FEEDBACK_DETAIL_FAILURE,
        payload: {
            error: error
        } as TYPES.FeedbackDetailPayload
    };
}

export let initializeFeedbackDetail = (
    id: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().feedbackDetail.loading) {
        dispatch(loadFeedbackBegin());

        let manager: IFeedbackNetworkManager =
            new FeedbackNetworkManager(apiUrlFeedback);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getById({id})
                        .then(
                            (response: IProcessedResponse) => {
                                dispatch(loadFeedbackSuccess(response))
                            }
                        ).catch(
                            (err: Error) => {
                                dispatch(loadFeedbackFailure(err))
                            }
                        )
                }
            ).catch(
                (err: Error) => 
                    dispatch(loadFeedbackFailure(err))
            )
    }
}

function sendResponseBegin(): TYPES.FeedbackDetailTypes {
    return {
        type: TYPES.SEND_RESPONSE_BEGIN,
        payload: {} as TYPES.FeedbackDetailPayload
    };
}

function sendResponseSuccess(): TYPES.FeedbackDetailTypes {
    return {
        type: TYPES.SEND_RESPONSE_SUCCESS,
        payload: {} as TYPES.FeedbackDetailPayload
    };
}

function sendResponseFailure(error: Error): TYPES.FeedbackDetailTypes {
    return {
        type: TYPES.SEND_RESPONSE_FAILURE,
        payload: {
            error: error
        } as TYPES.FeedbackDetailPayload
    };
}

/*
Send Response
*/

export let sendResponse = (
    response: ATFeedbackResponse
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().feedbackDetail.sendingResponse) {
        dispatch(sendResponseBegin());

        let manager: IFeedbackNetworkManager = 
            new FeedbackNetworkManager(apiUrlFeedback);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.sendFeedbackResponse({id: response.feedbackId, response})
                        .then(
                            (emailCreationResponse: IProcessedResponse) => {
                                if (emailCreationResponse.error){
                                    dispatch(sendResponseFailure(emailCreationResponse.error));
                                }
                                else 
                                    dispatch(sendResponseSuccess());
                            }
                        ).catch(
                            (err: Error) => {
                                dispatch(sendResponseFailure(err))
                            }
                        )
                }
            ).catch(
                (err: Error) => dispatch(sendResponseFailure(err))
            )
    }
}

/*
Clear Feedback Detail Error
*/

function clearFeedbackDetailErrorState(): TYPES.FeedbackDetailTypes {
    return {
        type: TYPES.CLEAR_FEEDBACK_DETAIL_ERROR,
        payload: {} as TYPES.FeedbackDetailPayload
    };
}

export let clearFeedbackDetailError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().feedbackDetail.error !== null) {
        dispatch(clearFeedbackDetailErrorState());
    }
}

/*
Uninitialize Feedback Detail
*/

function uninitializeFeedbackDetailState() {
    return {
        type: TYPES.UNINITIALIZE_FEEDBACK_DETAIL,
        payload: {} as TYPES.FeedbackDetailPayload
    };
}

export let uninitializeFeedbackDetail = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async dispatch =>  {
    dispatch(uninitializeFeedbackDetailState());
}