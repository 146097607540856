//libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
//redux-actions
import {
    initializeRoleDashboard,
    searchRoles,
    removeRole,
    uninitializeRoleDashboard
} from '../../../store/role/dashboard/actions';
//constants
import { DEFAULT_ORGANIZATION_ID } from "../../../utils/AppConstants";
import {
    DEFAULT_PAGE_INDEX,
    DEFAULT_PAGE_SIZE,
    DEFAULT_TOTAL_PAGES,
    ROLE_DEFAULT_ID,
    DEFAULT_QUERY
} from '../../../utils/AppConstants';

import ConfirmationModal from '../../base/modals/ConfirmationModal';
import { DELETE_ROLE, CREATE_ROLE } from '../../../models/Privileges';
import { ProfileState } from '../../../store/profile/types';
import { RoleDashboardState } from '../../../store/role/dashboard/types';
import { AppState } from '../../../store';
import {
    GenericTableViewColumnDefinition, GenericTableViewSettings,
    GenericTableView, GenericTableViewSelectFilterItem
} from '../../base/layout/GenericTableView';
import { 
    IAAOrganization, AAOrganization 
} from '@algo/network-manager/models/v3/admin';

interface StateProps { 
    profile: ProfileState;
    dashboard: RoleDashboardState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        profile: state.profile,
        dashboard: state.roleDashboard
    };
}

interface DispatchProps {
    initialize: typeof initializeRoleDashboard;
    search: typeof searchRoles;
    remove: typeof removeRole;
    uninitialize: typeof uninitializeRoleDashboard;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeRoleDashboard,
        search: searchRoles,
        remove: removeRole,
        uninitialize: uninitializeRoleDashboard
    }, dispatch);
}

type RoleTableViewProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface RoleTableViewState {
    currentPageIndex: number;
    pageSize: number;
    totalPages: number;
    roleId: number;
    showDeleteConfirmModal: boolean;
    organizationId: number;
    query: string;
}

class RoleTableView 
    extends React.Component<
        RoleTableViewProps, 
        RoleTableViewState
    > 
{
    constructor(props: Readonly<RoleTableViewProps>) {
        super(props);

        this.state = {
            currentPageIndex: DEFAULT_PAGE_INDEX,
            pageSize: DEFAULT_PAGE_SIZE,
            totalPages: DEFAULT_TOTAL_PAGES,
            roleId: ROLE_DEFAULT_ID,
            showDeleteConfirmModal: false,
            organizationId: DEFAULT_ORGANIZATION_ID,
            query: DEFAULT_QUERY
        } as RoleTableViewState;
    }

    componentDidMount() {

        this.props.initialize();

        this.applyFilter(DEFAULT_QUERY, DEFAULT_ORGANIZATION_ID);

        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        let previousIsLoading = prevProps.dashboard.initializing || prevProps.dashboard.loadingRoles;
        let currentIsLoading = this.props.dashboard.initializing || this.props.dashboard.loadingRoles;

        if (this.props.dashboard.roles.length !== prevProps.dashboard.roles.length || this.state.pageSize !== prevState.pageSize || previousIsLoading !== currentIsLoading) {
            this.setState((state, props) => {
                return {
                    totalPages: props.dashboard.roles.length / this.state.pageSize,
                    currentPageIndex: 0
                };
            })
        }

        if (this.state.organizationId !== prevState.organizationId) {
            this.applyFilter(this.state.query, this.state.organizationId);
        }
    }
    
    searchBoxChange = (query: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            };
        });
    }

    clearFilter = (): void => {
        this.props.search(DEFAULT_QUERY, this.state.organizationId);

        this.setState((state, props) => {
            return {
                ...state,
                query: DEFAULT_QUERY
            };
        });
    }

    applyFilter = (query: string, key: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query,
                organizationId: key
            };
        });

        this.props.search(query, key);
    }

    organizationChange = (key: number): void => {
        this.setState((state, props) =>{
            return {
                ...state,
                organizationId: key
            };
        });
    }

    newRole = (): void => {
        $('[data-toggle="tooltip"]').tooltip('hide');
        this.props.history.push(`/role/new`);
    }

    viewRole = (id: number): void => {
        if (this.props.profile.userProfile.hasPrivilege(CREATE_ROLE)) {
            this.props.history.push(`/role/${id}`);
        }
    }

    showDeleteModal = (id: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                roleId: id,
                showDeleteConfirmModal: true
            };
        });
    }

    confirmDelete = (): void => {
        this.deleteRole(this.state.roleId);

        this.setState((state, props) => {
            return {
                ...state,
                roleId: ROLE_DEFAULT_ID,
                showDeleteConfirmModal: false
            };
        });
    }

    dismissDelete = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                roleId: ROLE_DEFAULT_ID,
                showDeleteConfirmModal: false
            };
        });
    }

    deleteRole = (id: number): void => {
        if (!this.props.dashboard.deletingRole) {
            this.props.remove(
                id, 
                this.state.organizationId, 
                this.state.query
            );
        }
    }

    render() {
        let { dashboard, profile } = this.props;

        let tableSettings = {
            tableClassName: 'av-role-table',
            actionButtonClass: 'btn btn-outline-danger',
            actionButtonGlyphClass: 'delete-icon',
            columnDefinitions: [
                {
                    propertyName: 'id',
                    isKey: true
                },
                {
                    propertyName: 'description',
                    displayName: 'Description',
                    isKey: false,
                    headerColumnClass: 'av-role-description-col'
                }
            ] as GenericTableViewColumnDefinition[],
            selectFilterPlaceholder: 'Organizations',
            selectFilterItems: [
                {
                    value: DEFAULT_ORGANIZATION_ID,
                    label: 'All Organizations'
                },
                ...dashboard.organizations
                .map((org: IAAOrganization) => {
                    return {
                        value: org.organizationId,
                        label: org.name
                    } as GenericTableViewSelectFilterItem;
                })
            ] as GenericTableViewSelectFilterItem[],
            emptyTableMessage: 'No roles',
            showPageSizeSelector: false,
            newItemDisabled: !profile.userProfile.hasPrivilege(CREATE_ROLE),
            actionDisabled: !profile.userProfile.hasPrivilege(DELETE_ROLE),
            rowClickDisabled: !profile.userProfile.hasPrivilege(CREATE_ROLE)
        } as GenericTableViewSettings;

        return (
            <div style={{
                height: '100%'
            }}>
                <GenericTableView
                settings={tableSettings}
                items={dashboard.roles}
                rowClickCallback={this.viewRole}
                newItemCallback={this.newRole}
                searchBoxChangeCallback={this.searchBoxChange}
                selectorChangeCallback={this.organizationChange}
                clearFilterCallback={this.clearFilter}
                applyFilterCallback={this.applyFilter}
                isLoading={dashboard.initializing || dashboard.loadingRoles}
                isProcessingAction={dashboard.deletingRole}
                />
                {
                    this.state.showDeleteConfirmModal 
                        ?   <ConfirmationModal 
                                confirmCallback={this.confirmDelete} 
                                dismissCallback={this.dismissDelete} 
                                title={`Delete this role?`} 
                                message={
                                    `Are you sure you want to delete this role?
                                    All user and organization assignments to this
                                    role will be removed. This action cannot be reversed.`
                                } 
                                type={`warning`} 
                            /> 
                        : (null)}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(RoleTableView)); 