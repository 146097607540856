//libraries
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../../../index';
import { Action } from "redux";
import { getAccessToken } from '../../../../components/template/authentication/oidcConfig';
// interfaces & models
import * as TYPES from "../types/types";
import {
    EATStreamServerLogSource
} from "@algo/network-manager/models/v3";
import { 
    CameraDeviceNetworkManager, ICameraDeviceNetworkManager, 
    IGetCameraDeviceLogs, IProcessedResponse, ProcessedResponse 
} from "@algo/network-manager/managers/v3";
//constants
import TestLogs from "../../../../test/api-data/logs-distributor-tusc-cam-5-168-8.json";
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../../api-version-constants";

declare var __API_URL__: string;
const apiUrlDevice: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).devices}`;

/*******************************************************************************
                                INITIALIZE 
*******************************************************************************/

export let initializeDetails = (
    deviceId: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
    dispatch(loadCameraDevice(deviceId));
}

/*******************************************************************************
                                LOAD CAMERA DEVICE
*******************************************************************************/

export let loadCameraDevice = (
    deviceId: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().cameraDetails.loadingCameraDevice) {
        dispatch(loadCameraDeviceBegin());

        getAccessToken()
            .then(
                (token) => {
                    let manager: CameraDeviceNetworkManager = 
                        new CameraDeviceNetworkManager(apiUrlDevice);
                    manager.setAccessToken(token);

                    manager.getById(
                        getState().cameraDetails.lastResponse, 
                        {id: deviceId}
                    )
                        .then(
                            (response: any) => {
                                dispatch(loadCameraDeviceSuccess(response));
                            }, (reject: any) => {
                                dispatch(loadCameraDeviceFailure(new Error(reject)));
                            })
                        .catch((error: any) => {
                            dispatch(loadCameraDeviceFailure(error));
                        });
                }
        )
    }
}

function loadCameraDeviceBegin(): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.LOAD_CAMERA_DEVICE_BEGIN,
        payload: {} as TYPES.CameraDetailsPayload
    };
}

function loadCameraDeviceSuccess(response: IProcessedResponse): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.LOAD_CAMERA_DEVICE_SUCCESS,
        payload: {
            device: response.data,
            response
        } as TYPES.CameraDetailsPayload
    };
}

function loadCameraDeviceFailure(error: Error): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.LOAD_CAMERA_DEVICE_FAILURE,
        payload: {
            error: error
        } as TYPES.CameraDetailsPayload
    };
}

/*******************************************************************************
                        LOAD STREAM FILE LOGS
*******************************************************************************/

export let loadLogs = (
    req: IGetCameraDeviceLogs
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (!getState().cameraDetails.loadingLogs[req.source || "SOURCE-UNKNOWN"] && req.id) {

        let source: EATStreamServerLogSource = 
            EATStreamServerLogSource[
                req.source as keyof typeof EATStreamServerLogSource
            ];

        dispatch(loadLogsBegin(source));

        let manager: ICameraDeviceNetworkManager =
            new CameraDeviceNetworkManager(apiUrlDevice);

        getAccessToken()
            .then(
                (token) => {
                    manager.setAccessToken(token);
                    
                    manager.getLogs(
                        getState().cameraDetails.lastResponse, 
                        req
                    )
                        .then(
                            (response: IProcessedResponse) => {
                                dispatch(
                                    loadLogsSuccess(response, source)
                                );
                            },
                            (reject: any) => {
                                dispatch(loadLogsFailure(new Error(reject), source))
                            }
                        )
                        .catch(
                            (error: Error) => {
                                dispatch(loadLogsFailure(error, source));
                            }
                        )
                },
                (reject) => {
                    dispatch(loadLogsFailure(new Error(reject), source));
                }
            )
        .catch(
            (error: Error) => {
                dispatch(loadLogsFailure(error, source));
            }
        )
    }
}

export let loadLogs_test = (
    req: IGetCameraDeviceLogs
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (!getState().cameraDetails.loadingLogs[req.source || "SOURCE-UNKNOWN"] && req.id) {

        let source: EATStreamServerLogSource = 
            EATStreamServerLogSource[
                req.source as keyof typeof EATStreamServerLogSource
            ];

        dispatch(loadLogsBegin(source));

        let response = new ProcessedResponse();
        if (req.source === EATStreamServerLogSource.DistributorPushPublishEntry){
            response.data = [];
            //response.headers = new Headers();
            (response as any).totalCount = 0;
            dispatch(
                loadLogsSuccess(response, source)
            );
        }
        else {
            response.data = TestLogs.slice(req.skip || 0, req.take || TestLogs.length - 1);
            //response.headers = new Headers();
            (response as any).totalCount = 25;
            dispatch(
                loadLogsSuccess(response, source)
            );
        }
        
    }
}

function loadLogsBegin(
    source: EATStreamServerLogSource
): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.LOAD_LOGS_BEGIN,
        payload: {
            source
        } as TYPES.CameraDetailsPayload
    };
}

function loadLogsSuccess(
    response: IProcessedResponse,
    source: EATStreamServerLogSource
): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.LOAD_LOGS_SUCCESS,
        payload: {
            logs: response.data,
            totalCount: response.getCount() || 0,
            checksum: parseInt(response.getChecksum()) || 0,
            source,
        } as TYPES.CameraDetailsPayload
    };
}

function loadLogsFailure(
    error: Error, 
    source: EATStreamServerLogSource
): TYPES.CameraDetailsTypes {
    return {
        type: TYPES.LOAD_LOGS_FAILURE,
        payload: {
            error: error,
            source: source,
        } as TYPES.CameraDetailsPayload
    };
}