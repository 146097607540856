// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
// components
import { RedirectContent } from "./RedirectContent";
// store
import { AppState } from "../../../store";

interface StateProps { 
}

let mapStateToProps = (state: AppState) => {
    return { 
    };
}

type RedirectProps = 
    StateProps & 
    RouteComponentProps<any>;

class Redirect extends React.Component<RedirectProps> {
    
    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <RedirectContent />
                </div>
            </div>            
            );
    }
}

export default connect(
    mapStateToProps,
)(withRouter(Redirect));