// libraries
import * as React from "react";
// types & models
import {
    ATFacility, EFacilityPropertyType 
}from "@algo/network-manager/models/v3";
// components
import { FormDynamicRow } from "@caps-mobile/forms";

export type IDyncamicEditDelete = {
    facilityObject: ATFacility;
    type: string;
    displayString: (rowObject: any) => any;
    editClick: (type: EFacilityPropertyType, mapIndex: number) => any;
    updateFacilityObject: (facilityObject: ATFacility) => any;
};

export const DynamicEditDelete: React.FC<IDyncamicEditDelete> = (props) => {

    const { facilityObject, type, displayString, editClick, updateFacilityObject } = props;

    if (!facilityObject || !type || !facilityObject[type as keyof ATFacility]) return null;

    return (facilityObject[type as keyof ATFacility] as any)?.map(
        (rowObject: any, mapIndex: number) => {
            return (
                <FormDynamicRow
                    key={`${type}-${mapIndex}`}
                    deleteButton={true}
                    editButton={true}
                    deleteClick={() => {
                        if (facilityObject[type as keyof ATFacility]){
                            facilityObject.removeListProperty(
                                EFacilityPropertyType[type as keyof typeof EFacilityPropertyType], 
                                mapIndex
                            );
                            updateFacilityObject(new ATFacility(facilityObject));
                        }
                    }}
                    editClick={() => {
                        editClick(EFacilityPropertyType[type as keyof typeof EFacilityPropertyType], mapIndex);
                    }}
                >
                    {displayString(rowObject)}
                </FormDynamicRow>
            )
        }
    )
};

export default DynamicEditDelete;