import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { IATAldotMessage } from "@algo/network-manager/models/v3";

export let INITIALIZE_MESSAGE_DASHBOARD_BEGIN = 
    'INITIALIZE_MESSAGE_DASHBOARD_BEGIN';
export let INITIALIZE_MESSAGE_DASHBOARD_SUCCESS = 
    'INITIALIZE_MESSAGE_DASHBOARD_SUCCESS';
export let INITIALIZE_MESSAGE_DASHBOARD_FAILURE = 
    'INITIALIZE_MESSAGE_DASHBOARD_FAILURE';

export let LOAD_MESSAGES_BEGIN = 'LOAD_MESSAGES_BEGIN';
export let LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS';
export let LOAD_MESSAGES_FAILURE = 'LOAD_MESSAGES_FAILURE';

export let DELETE_MESSAGE_BEGIN = 'DELETE_MESSAGE_BEGIN';
export let DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export let DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';

export let CLEAR_MESSAGE_DASHBOARD_ERROR = 
    'CLEAR_MESSAGE_DASHBOARD_ERROR';

export let UNINITIALIZE_MESSAGE_DASHBOARD = 
    'UNINITIALIZE_MESSAGE_DASHBOARD';

export interface MessageDashboardState {
    initializing: boolean;
    loadingMessages: boolean;
    deletingMessage: boolean;
    messages: IATAldotMessage[];
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface MessageDashboardPayload {
    messages: IATAldotMessage[];
    error: Error;
    response: IProcessedResponse;
}

interface InitializeMessageDashboardBeginAction {
    type: typeof INITIALIZE_MESSAGE_DASHBOARD_BEGIN,
    payload: MessageDashboardPayload
}

interface InitializeMessageDashboardSuccessAction {
    type: typeof INITIALIZE_MESSAGE_DASHBOARD_SUCCESS,
    payload: MessageDashboardPayload
}

interface InitializeMessageDashboardFailureAction {
    type: typeof INITIALIZE_MESSAGE_DASHBOARD_FAILURE,
    payload: MessageDashboardPayload
}

interface LoadMessagesBeginAction {
    type: typeof LOAD_MESSAGES_BEGIN,
    payload: MessageDashboardPayload
}

interface LoadMessagesSuccessAction {
    type: typeof LOAD_MESSAGES_SUCCESS,
    payload: MessageDashboardPayload
}

interface LoadMessagesFailureAction {
    type: typeof LOAD_MESSAGES_FAILURE,
    payload: MessageDashboardPayload
}

interface DeleteMessageBeginAction {
    type: typeof DELETE_MESSAGE_BEGIN,
    payload: MessageDashboardPayload
}

interface DeleteMessageSuccessAction {
    type: typeof DELETE_MESSAGE_SUCCESS,
    payload: MessageDashboardPayload
}

interface DeleteMessageFailureAction {
    type: typeof DELETE_MESSAGE_FAILURE,
    payload: MessageDashboardPayload
}

interface ClearMessageDashboardErrorAction {
    type: typeof CLEAR_MESSAGE_DASHBOARD_ERROR,
    payload: MessageDashboardPayload
}

interface UninitializeMessageDashboardAction {
    type: typeof UNINITIALIZE_MESSAGE_DASHBOARD,
    payload: MessageDashboardPayload
}

export type MessageDashboardTypes = 
    InitializeMessageDashboardBeginAction |
    InitializeMessageDashboardSuccessAction |
    InitializeMessageDashboardFailureAction |
    LoadMessagesBeginAction |
    LoadMessagesSuccessAction |
    LoadMessagesFailureAction |
    DeleteMessageBeginAction |
    DeleteMessageSuccessAction |
    DeleteMessageFailureAction |
    ClearMessageDashboardErrorAction |
    UninitializeMessageDashboardAction;
