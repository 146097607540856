//libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
//redux-state-types
import {
    State as CameraPreviewState
} from '../../../../store/cameraDevice/dashboard/types/streamPreviewsTypes';
import {
    State as StatusState
} from '../../../../store/cameraDevice/dashboard/types/streamStatusTypes';
import { AppState } from '../../../../store';
// redux-actions
import {
    loadCameraDeviceStatus
} from "../../../../store/cameraDevice/dashboard/actions/streamStatusActions";
// interfaces & models
import { IATCameraDevice } from "@algo/network-manager/models/v3";
//enums
import { EATStreamAccessLevel } from "@algo/network-manager/models/v3";
//components
import CameraCard from "./CameraCard";
// @ts-ignore
import { Selectable } from "@algo/selectable/Selectable";

interface StateProps {
    preview: CameraPreviewState;                                                            // (store) camera data
    status: StatusState;                                                                    // (store) camera status data
}

let mapStateToProps = (state: AppState) => {
    return {
        preview: state.streamPreviews,                                                      // (store) camera data
        status: state.streamStatus,                                                         // (store) camera status data
    };
}

interface DispatchProps {
    loadStatus: typeof loadCameraDeviceStatus;                                              // (action creator) load camera status data
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadStatus: loadCameraDeviceStatus                                                  // (action creator) load camera status data
    }, dispatch);
}

interface IProps {
    camera: IATCameraDevice;                                                                // camera device
    selected: boolean;                                                                      // (flag) is this preview in selected list / highlighted
    editMode: boolean;                                                                      // (flag) is the toolbar expanded / previews selectable
    snapshotHeight: number;                                                                 // calculated height value for snapshot (maintains image ratio)
    cameraClick: (id: number) => void;                                                      // (callback) logic when User clicks preview (!editMode)
    toggleCameraInList: (camera: IATCameraDevice) => void;                                  // (callback) logic when User clicks preview (editMode)
}

type CameraDashboardPreviewProps = StateProps & DispatchProps & IProps;

export let CameraPreview: React.FC<CameraDashboardPreviewProps> = (props) => {

    /***********************************************************************************|
        CALCULATED VALUES & BREVITY NAMES
    |***********************************************************************************/
    const camera: IATCameraDevice = props.camera;                                           // camera data

    const accessLevel: EATStreamAccessLevel =                                               // current access level of camera
        (camera.cloudStreamTargets.length > 0)
            ? camera.cloudStreamTargets[0].accessLevel
            : EATStreamAccessLevel.Unknown

    const pillsObject = React.useMemo(
        () => ({                                                                            // used to map status pill styles in render
            [`${camera.name}`]: `pill-secondary`,
            [accessLevel]: 'pill-secondary',
        }), [camera, accessLevel]
    )

    /***********************************************************************************|
        PRIMARY RENDER
    |***********************************************************************************/
    return (
        <div
            onClick={() => {props.cameraClick(camera.id)}}
            style={{ cursor: "pointer" }}>
            <Selectable
                active={props.selected}
                callback={props.editMode
                    ? () => { props.toggleCameraInList(camera) }
                    : null
                }
            >
                <CameraCard
                    camera={camera}
                    snapshotHeight={props.snapshotHeight}
                    pills={pillsObject}
                />
            </Selectable>
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraPreview);