//types
import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import * as TYPES from "./types";

let initialState: TYPES.MessageDashboardState = {
    initializing: false,
    loadingMessages: false,
    deletingMessage: false,
    messages: [],
    error: null,
    lastResponse: new ProcessedResponse()
};

export function messageDashboardReducer(
    state = initialState, 
    action: TYPES.MessageDashboardTypes
): TYPES.MessageDashboardState {
    
    switch(action.type) {
        case TYPES.INITIALIZE_MESSAGE_DASHBOARD_BEGIN:
            return {
                ...state,
                initializing: true,
                loadingMessages: true
            };
        case TYPES.INITIALIZE_MESSAGE_DASHBOARD_SUCCESS:
            return {
                ...state,
                messages: action.payload.messages || [],
                initializing: false,
                loadingMessages: false            
            };
        case TYPES.INITIALIZE_MESSAGE_DASHBOARD_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                initializing: false,
                loadingMessages: false
            };
        case TYPES.LOAD_MESSAGES_BEGIN:
            return {
                ...state,
                loadingMessages: true
            };
        case TYPES.LOAD_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: action.payload.messages,
                loadingMessages: false
            };
        case TYPES.LOAD_MESSAGES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case TYPES.DELETE_MESSAGE_BEGIN:
            return {
                ...state,
                deletingMessage: true
            };
        case TYPES.DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                deletingMessage: false,
                messages: action.payload.messages
            };
        case TYPES.DELETE_MESSAGE_FAILURE:
            return {
                ...state,
                deletingMessage: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_MESSAGE_DASHBOARD_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_MESSAGE_DASHBOARD:
            return initialState;
        default:
            return state;
    }
}