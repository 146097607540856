import * as TYPES from "./types";
import { ATAldotMessage } from "@algo/network-manager/models/v3";
import { ProcessedResponse } from "@algo/network-manager/managers/v3";

let initialState: TYPES.MessageEditorState = {
    initializing: false,
    savingMessage: false,
    message: new ATAldotMessage(),
    error: null,
    lastMessageResponse: new ProcessedResponse()
};

export function messageEditorReducer(
    state = initialState, 
    action: TYPES.MessageEditorTypes
): TYPES.MessageEditorState {
    
    switch(action.type) {
        case TYPES.LOAD_MESSAGE_BEGIN:
            return {
                ...state,
                initializing: true
            };
        case TYPES.LOAD_MESSAGE_SUCCESS:
            return {
                ...state,
                initializing: false,
                message: action.payload.message || new ATAldotMessage(),
                lastMessageResponse: action.payload.response || new ProcessedResponse()
            };
        case TYPES.LOAD_MESSAGE_FAILURE:
            return {
                ...state,
                initializing: false,
                error: action.payload.error || new Error()
            };
        case TYPES.SAVE_MESSAGE_BEGIN:
            return {
                ...state,
                savingMessage: true
            };
        case TYPES.SAVE_MESSAGE_SUCCESS:
            return {
                ...state,
                savingMessage: false,
                message: action.payload.message || new ATAldotMessage()
            };
        case TYPES.SAVE_MESSAGE_FAILURE:
            return {
                ...state,
                savingMessage: false,
                error: action.payload.error || new Error()
            };
        case TYPES.CLEAR_MESSAGE_EDITOR_ERROR:
            return {
                ...state,
                error: null
            }
        case TYPES.UNINITIALIZE_MESSAGE_EDITOR:
            return initialState;
        default:
            return state;
    }
}