// libraries
import * as React from "react";
import * as HELP from "./library";
// hooks
import { useGetData } from "../../hooks/useGetData";
// styles
import * as SC from "./Styled";
// types & models
import { 
    EATDirection, IATLocation, IATPlace 
} from "@algo/network-manager/models/v3";
// components
import { FACancel, FASave } from "@caps-mobile/faicon";
import { SuccessBanner } from "@caps-mobile/banner";
import { 
    HereMap, addMarkerAtPointer, removeCurrentMarkers, 
    IHereMarkerInit, IHereMapListener 
} from "@algo/here-maps";
import { 
    FormButton, FormCard, FormTextInputLabeled, FormSelectLabeled 
} from "@caps-mobile/forms";
import { 
    getEnumStrings, searchObjectListByProperty, filterNaN, nuon
} from "@caps-mobile/common-lib";
import { Loading } from "@algo/loading";
// constants
import * as CONST from "./constants";
const directionStrings: string[] = getEnumStrings(EATDirection);

export type IEditLocation = {
    location: IATLocation;
    locationIndex: number;
    cancelCallback: () => any;
    saveCallback: (
        location: IATLocation, 
        locationIndex: number
    ) => any;
} & React.HTMLAttributes<HTMLElement>;

export const EditLocation: React.FC<IEditLocation> = (props) => {

    /**************************************************************************
        VALIDATION & BREVITY NAMES
     *************************************************************************/
    const { location, locationIndex } = props;

    const currentMapCenter = {
        lat: location.latitude || CONST.D_MAP_CONFIG.center.lat,
        lng: location.longitude || CONST.D_MAP_CONFIG.center.lng
    };

    /**************************************************************************
        STATE
     *************************************************************************/
    const [cityNameValue, setCityNameValue] = 
        React.useState<string>(location.city || "");

    const [selectedCityIndex, setSelectedCityIndex] = 
        React.useState<number | null>(null);

    const [countyNameValue, setCountyNameValue] = 
        React.useState<string>(location.county || "");

    const [selectedCountyIndex, setSelectedCountyIndex] = 
        React.useState<number | null>(null);

    const [placeValue, setPlaceValue] = 
        React.useState<IATPlace>();

    const [selectedPlaceIndex, setSelectedPlaceIndex] = 
        React.useState<number | null>(null);

    const [mileMarkerValue, setMileMarkerValue] = 
        React.useState<string>(`${location.linearReference}` || "");

    const [directionValue, setDirectionValue] = 
        React.useState<EATDirection>(location.direction || EATDirection.Unknown);

    const [markerCoords, setMarkerCoords] = 
        React.useState<{lat: number, lng: number}>();

    const [existingMarkers, setExistingMarkers] =
        React.useState<IHereMarkerInit[][]>([[{
            ...CONST.mapPinOptions, 
            position: currentMapCenter
        }]]);

    const [mapConfig, setMapConfig] = React.useState<any>(
        { ...CONST.D_MAP_CONFIG, center: currentMapCenter }
    );

    /**************************************************************************
        EFFECTS
     *************************************************************************/
    const {
        data: cities, loading: citiesLoading, 
        loaded: citiesLoaded, error: citiesError 
    } = useGetData(CONST.citiesApiPath);

    const {
        data: counties, loading: countiesLoading, 
        loaded: countiesLoaded, error: countiesError 
    } = useGetData(CONST.countiesApiPath);

    const {
        data: roadways, loading: roadwaysLoading, 
        loaded: roadwaysLoaded, error: roadwaysError 
    } = useGetData(CONST.placesApiPath);

    React.useEffect(() => {
        let indexOfObject: number | null = 
            searchObjectListByProperty(cities, "name", location?.city);

        if (indexOfObject === -1) indexOfObject = null;

        setSelectedCityIndex(indexOfObject);
    }, [cities])

    React.useEffect(() => {
        let indexOfObject: number | null = 
            searchObjectListByProperty(counties, "name", location?.county);

        if (indexOfObject === -1) indexOfObject = null;

        setSelectedCountyIndex(indexOfObject);
    }, [counties])

    React.useEffect(() => {
        let indexOfObject: number | null = 
            searchObjectListByProperty(
                roadways, "name", location?.displayRouteDesignator
            );

        if (indexOfObject === -1) indexOfObject = null;
        setSelectedPlaceIndex(indexOfObject);

    }, [roadways])

    /**************************************************************************
        HERE MAPS LISTENER OBJECTS (STATE-DEPENDANT) 
     *************************************************************************/
    const addMarkerEventObject: IHereMapListener = {
        type: "tap",
        action: (evt: H.mapevents.Event, map: H.Map) => {
            removeCurrentMarkers(map);
            addMarkerAtPointer(map, evt, CONST.mapPinOptions);
            let eventX: number = evt.currentPointer.viewportX;
            let eventY: number = evt.currentPointer.viewportY;
            let coords = map.screenToGeo(eventX, eventY);
            setMarkerCoords(coords);
        }
    };

    /**************************************************************************
        SENTINEL VALUES (DETERMINE IF LOADING OR ERROR)
     *************************************************************************/
    const dataLoaded = () => {
        return citiesLoaded && countiesLoaded && roadwaysLoaded;
    };

    const dataLoading = () => {
        return citiesLoading || countiesLoading || roadwaysLoading;
    };

    const dataError = () => {
        let error = null;

        if (!citiesLoading && citiesError) error = citiesError;
        if (!countiesLoading && countiesError) error = countiesError;
        if (!roadwaysLoading && roadwaysError) error = roadwaysError;

        return error;
    };

    /**************************************************************************
        PRIMARY RENDER
     *************************************************************************/
    return (
        <SC.StyledEditLocation>

             <SC.StyledContent>
                {   (!dataLoaded() && dataLoading()) &&
                        <Loading />
                }
                
                {   (!dataLoaded() && !dataLoading()) &&
                        <SC.StyledLoadError>
                            <SC.StyledLoadFail>
                                {CONST.loadFailed}
                            </SC.StyledLoadFail>
                            <SC.StyledTryAgain>
                                {CONST.tryAgain}
                            </SC.StyledTryAgain>
                        </SC.StyledLoadError>
                }

                { dataLoaded() &&
                    <SC.StyledPrimaryContent>

                        <SC.StyledFormsWrapper>

                            <FormCard 
                                noBorder={true} style={SC.formCard1Styles}>

                                <FormSelectLabeled 
                                    options={HELP.getSelectOptions(cities)}
                                    label={CONST.cityLabel} searchable={true}
                                    selectedIndex={nuon(selectedCityIndex) ? selectedCityIndex : null}
                                    callback={(newIndex: number) => {
                                        setCityNameValue(cities[newIndex].name);
                                        setSelectedCityIndex(newIndex);
                                    }}
                                />
                                <FormSelectLabeled 
                                    options={HELP.getSelectOptions(roadways)} 
                                    selectedIndex={nuon(selectedPlaceIndex) ? selectedPlaceIndex : null} 
                                    label={CONST.placeLabel} searchable={true}
                                    callback={(newIndex: number) => { 
                                        setPlaceValue({...roadways[newIndex]});
                                        setSelectedPlaceIndex(newIndex);
                                    }}
                                />

                            </FormCard>

                            <FormCard noBorder={true} style={{ paddingTop: 0}}>
                                <FormSelectLabeled 
                                    options={HELP.getSelectOptions(counties)} 
                                    label={CONST.countyLabel} searchable={true}
                                    selectedIndex={nuon(selectedCountyIndex) ? selectedCountyIndex : null}
                                    callback={(newIndex: any) => {
                                        setCountyNameValue(counties[newIndex].name);
                                        setSelectedCountyIndex(newIndex);
                                    }}
                                />
                                <FormSelectLabeled 
                                    options={directionStrings} 
                                    label={CONST.directionLabel}
                                    selectedIndex={directionStrings.indexOf(EATDirection[directionValue])}
                                    callback={
                                        (newIndex: any) => 
                                            {   setDirectionValue(
                                                    EATDirection[directionStrings[newIndex] as keyof typeof EATDirection]
                                                )
                                            }
                                    }
                                />
                            </FormCard>

                            <FormCard noBorder={true} style={{ paddingTop: 0}}>
                                <FormTextInputLabeled
                                    label={CONST.mileLabel} labelWidth={"25%"} 
                                    inputWidth={"calc(75% - 12px)"} inputHeight={"41px"}  
                                    value={`${mileMarkerValue}`} 
                                    onChange={
                                        (newValue: any) => 
                                            setMileMarkerValue(
                                                filterNaN(newValue.currentTarget.value)
                                            )
                                    }
                                />
                            </FormCard>

                        </SC.StyledFormsWrapper>

                        <SuccessBanner style={{marginBottom: "12px"}}>
                            {CONST.bannerText}
                        </SuccessBanner>

                        <SC.StyledMapWrapper>
                            <HereMap 
                                mapConfig={mapConfig} rounded={"4px"}
                                listeners={[addMarkerEventObject]}
                                markers={existingMarkers}
                            />
                        </SC.StyledMapWrapper>

                    </SC.StyledPrimaryContent>
                }
            </SC.StyledContent>

            <SC.StyledFooter>

                <FormButton onClick={() => props.cancelCallback()} 
                    color={"#DF3143"} width={"350px"}>
                    <FACancel style={{marginRight: "8px"}} />
                    <span>{CONST.cancelLabel}</span>
                </FormButton>

                <FormButton 
                    color={"#1E824C"} width={"350px"}
                    onClick={() => 
                        props.saveCallback(
                            HELP.buildReturnValue(
                                location, cityNameValue, countyNameValue, 
                                directionValue, markerCoords, mileMarkerValue, 
                                placeValue
                            ), 
                            locationIndex
                        )
                    }
                >
                    <FASave style={{marginRight: "8px"}} />
                    <span>{CONST.saveLabel}</span>
                </FormButton>

            </SC.StyledFooter>

        </SC.StyledEditLocation>
    )
};

export default EditLocation;
