// libraries
import styled, { CSSProperties } from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";


/******************************************************************************
    STYLED COMPONENTS
 *****************************************************************************/
export const StyledEditMapSettings = styled.div`

    height: 90%;
    width: 90%;

    padding: 1rem;

    background-color: ${ props => props.theme.colors["bg-primary"]["rgba"]};
    border-radius: 4px;

    ${flexSmart("flex-start center")}
    flex-direction: column;    
`;

export const StyledContent = styled.div`
    height: calc(100% - 41px);
    width: 100%;

    ${flexSmart("center center")}
    flex-direction: column;
`;

export const StyledZoomWrapper = styled.div`
    ${flexSmart("space-between center")}
`;

export const StyledMapAndBannerWrapper = styled.div`
    width: 100%;
    height: 100%;

    padding: 0 2rem 0 2rem;
`;

export const StyledMapWrapper = styled.div`
    height: calc(100% - 86px);
`;

export const StyledFooter = styled.div`

    height: 41px;
    width: 100%;

    ${flexSmart("space-evenly center")}
`;

export const StyledLoadError = styled.div`

    ${flexSmart("center center")}
    flex-direction: column;
    
`;

/******************************************************************************
    SUPPLEMENTAL STYLE OBJECTS
 *****************************************************************************/

export const formCardStyles: CSSProperties = {
    height: "auto",
    paddingTop: 0
};

export const faDoneStyles: CSSProperties = {
    fontSize: "16px",
    marginRight: "8px"
};

export const successBannerStyles: CSSProperties = {
    marginBottom: "12px", 
    fontSize: 16, 
    padding: "8px", 
    display: "flex", 
    flexDirection: 'column'
};

export const styledMapWrapperStyles: CSSProperties = {
    height: "calc(100% - 86px"
};