//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "./types";
import { LOAD_MESSAGES_FAILURE } from "../dashboard/types";
//models
import { IATAldotMessage } from "@algo/network-manager/models/v3";
import { 
    IAldotMessageNetworkManager, AldotMessageNetworkManager, IProcessedResponse 
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";
import moment from "moment";

declare var __API_URL__: string;
const apiUrlMessage: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).aldotMessages}`;

/*
Load Message
*/

function loadMessageBegin(): TYPES.MessageEditorTypes {
    return {
        type: TYPES.LOAD_MESSAGE_BEGIN,
        payload: {} as TYPES.MessageEditorPayload
    };
}

function loadMessageSuccess(response: IProcessedResponse): TYPES.MessageEditorTypes {

    let payload: TYPES.MessageEditorPayload = 
        response.data
            ?   { message: response.data, response}
            :   { response }

    return {
        type: TYPES.LOAD_MESSAGE_SUCCESS,
        payload: payload as TYPES.MessageEditorPayload
    };
}

function loadMessageFailure(error: Error): TYPES.MessageEditorTypes {
    return {
        type: LOAD_MESSAGES_FAILURE,
        payload: {
            error: error
        } as TYPES.MessageEditorPayload
    }
}

export let loadMessage = (
    id: number
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().messageEditor.initializing) {
        dispatch(loadMessageBegin());

        let manager: IAldotMessageNetworkManager = 
            new AldotMessageNetworkManager(apiUrlMessage);
        
        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getById(
                        getState().messageEditor.lastMessageResponse, 
                        {id}
                    )
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error)
                                    dispatch(loadMessageFailure(response.error))
                                else 
                                    dispatch(loadMessageSuccess(response))
                            }
                        ).catch(
                            (err: Error) =>
                                dispatch(loadMessageFailure(err))
                        )
                }
            ).catch(
                (err: Error) => 
                    dispatch(loadMessageFailure(err))
            )
    }
}

/*
Save Message
*/

function saveMessageBegin(): TYPES.MessageEditorTypes {
    return {
        type: TYPES.SAVE_MESSAGE_BEGIN,
        payload: {} as TYPES.MessageEditorPayload
    };
}

function saveMessageSuccess(response: IProcessedResponse): TYPES.MessageEditorTypes {
    return {
        type: TYPES.SAVE_MESSAGE_SUCCESS,
        payload: {
            message: response.data,
            response
        } as TYPES.MessageEditorPayload
    };
}

function saveMessageFailure(error: Error): TYPES.MessageEditorTypes {
    return {
        type: TYPES.SAVE_MESSAGE_FAILURE,
        payload: {
            error: error
        } as TYPES.MessageEditorPayload
    };
}

export let saveMessage = (
    message: IATAldotMessage
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().messageEditor.savingMessage) {
        dispatch(saveMessageBegin());

        let manager: IAldotMessageNetworkManager = 
            new AldotMessageNetworkManager(apiUrlMessage);

        console.log(message);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.save({
                        message: {
                            ...message, 
                            start: moment(message.start) as any,
                            end: moment(message.end) as any
                        }
                    })
                        .then(
                            (response: IProcessedResponse) =>
                                dispatch(saveMessageSuccess(response))
                        ).catch(
                            (err: Error) => 
                                dispatch(saveMessageFailure(err))
                        )
                }
            ).catch(
                (err: Error) => 
                    dispatch(saveMessageFailure(err))
            )
    }
}

/*
Clear Message Editor Error
*/

function clearMessageEditorErrorState(

): TYPES.MessageEditorTypes {
    return {
        type: TYPES.CLEAR_MESSAGE_EDITOR_ERROR,
        payload: {} as TYPES.MessageEditorPayload
    };
}

export let clearMessageEditorError = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().messageEditor.error !== null) {
        dispatch(clearMessageEditorErrorState());
    }
}

/*
Uninitialize Message Editor
*/

function uninitializeMessageEditorState(): TYPES.MessageEditorTypes {
    return {
        type: TYPES.UNINITIALIZE_MESSAGE_EDITOR,
        payload: {} as TYPES.MessageEditorPayload
    };
}

export let uninitializeMessageEditor = (

): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(uninitializeMessageEditorState());
}