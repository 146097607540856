export let CAMERA_ADMINISTRATOR = 'CameraAdministrator';
export let ALLOW_CAMERA_BULK_UPDATE = 'AllowCameraBulkUpdate';
export let VIEW_VIDEOBOARD = 'ViewVideoboard';
export let CREATE_VIDEOBOARD = 'CreateVideoboard';
export let EDIT_VIDEOBOARD = 'EditVideoboard';
export let DELETE_VIDEOBOARD = 'DeleteVideoboard';
export let VIEW_CAMERA = 'ViewCamera';
export let EDIT_CAMERA = 'EditCamera';
export let SYSTEM_ADMINISTRATOR = 'SystemAdministrator';

export let RESET_USER_PASSWORD = 'ResetUserPassword';
export let MEDIA_STREAM_ACCESS = 'MediaStreamAccess';
export let FIRST_RESPONDER_STREAM_ACCESS = 'FirstResponderStreamAccess';
export let PRIVATE_SECTOR_STREAM_ACCESS = 'PrivateSectorLimitedStreamAccess';
export let INTERNAL_STREAM_ACCESS = 'InternalStreamAccess';
export let UNLIMITED_STREAMING = 'UnlimitedStreaming';

export let VIEW_USER = 'ViewUser';
export let CREATE_USER = 'CreateUser';
export let DELETE_USER = 'DeleteUser';

export let VIEW_ORGANIZATION = 'ViewOrganization';
export let CREATE_ORGANIZATION = 'CreateOrganization';
export let DELETE_ORGANIZATION = 'DeleteOrganization';

export let VIEW_ROLE = 'ViewRole';
export let CREATE_ROLE = 'CreateRole';
export let DELETE_ROLE = 'DeleteRole';

export let VIEW_MESSAGE = 'ViewAlert';
export let CREATE_MESSAGE = 'CreateAlert';
export let DELETE_MESSAGE = 'DeleteAlert';

export let CREATE_FACILITY = 'CreateFacility';
export let UPDATE_FACILITY = 'UpdateFacility';
export let DELETE_FACILITY = 'DeleteFacility';

export let FACILITY_PRIVILEGES = [CREATE_FACILITY, UPDATE_FACILITY, DELETE_FACILITY];

export let VIEW_FEEDBACK = 'ViewFeedback';
export let EMAIL_FEEDBACK_RESPONSE = 'EmailFeedbackResponse';

export let VIEW_SERVICE_STATUS = "ViewServiceStatus";