import { IATFeedback } from "@algo/network-manager/models/v3";

export let INITIALIZE_FEEDBACK_DASHBOARD_BEGIN = 'INITIALIZE_FEEDBACK_DASHBOARD_BEGIN';
export let INITIALIZE_FEEDBACK_DASHBOARD_SUCCESS = 'INITIALIZE_FEEDBACK_DASHBOARD_SUCCESS';
export let INITIALIZE_FEEDBACK_DASHBOARD_FAILURE = 'INITIALIZE_FEEDBACK_DASHBOARD_FAILURE';

export let LOAD_FEEDBACK_BEGIN = 'LOAD_FEEDBACK_BEGIN';
export let LOAD_FEEDBACK_SUCCESS = 'LOAD_FEEDBACK_SUCCESS';
export let LOAD_FEEDBACK_FAILURE = 'LOAD_FEEDBACK_FAILURE';

export let CLEAR_FEEDBACK_DASHBOARD_ERROR = 'CLEAR_FEEDBACK_DASHBOARD_ERROR';

export let UNINITIALIZE_FEEDBACK_DASHBOARD = 'UNINITIALIZE_FEEDBACK_DASHBOARD';

export interface FeedbackDashboardState {
    initializing: boolean;
    loadingFeedback: boolean;
    feedback: IATFeedback[];
    error: Error | null;
}

export interface FeedbackDashboardPayload {
    feedback: IATFeedback[];
    error: Error | null;
}

interface InitializeFeedbackDashboardBeginAction {
    type: typeof INITIALIZE_FEEDBACK_DASHBOARD_BEGIN,
    payload: FeedbackDashboardPayload
}

interface InitializeFeedbackDashboardSuccessAction {
    type: typeof INITIALIZE_FEEDBACK_DASHBOARD_SUCCESS,
    payload: FeedbackDashboardPayload
} 

interface InitializeFeedbackDashboardFailureAction {
    type: typeof INITIALIZE_FEEDBACK_DASHBOARD_FAILURE,
    payload: FeedbackDashboardPayload
} 

interface LoadFeedbackBeginAction {
    type: typeof LOAD_FEEDBACK_BEGIN,
    payload: FeedbackDashboardPayload
} 

interface LoadFeedbackSuccessAction {
    type: typeof LOAD_FEEDBACK_SUCCESS,
    payload: FeedbackDashboardPayload
} 

interface LoadFeedbackFailureAction {
    type: typeof LOAD_FEEDBACK_FAILURE,
    payload: FeedbackDashboardPayload
} 

interface ClearFeedbackDashboardErrorAction {
    type: typeof CLEAR_FEEDBACK_DASHBOARD_ERROR,
    payload: FeedbackDashboardPayload
} 

interface UninitializeFeedbackDashboardAction {
    type: typeof UNINITIALIZE_FEEDBACK_DASHBOARD,
    payload: FeedbackDashboardPayload
} 

export type FeedbackDashboardTypes = 
    InitializeFeedbackDashboardBeginAction |
    InitializeFeedbackDashboardSuccessAction | 
    InitializeFeedbackDashboardFailureAction |
    LoadFeedbackBeginAction | 
    LoadFeedbackSuccessAction | 
    LoadFeedbackFailureAction | 
    ClearFeedbackDashboardErrorAction | 
    UninitializeFeedbackDashboardAction;