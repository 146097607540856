//libraries
import * as React from "react";
import styled from "styled-components";
//components
import { Body1 } from "@algo/algo-styles";
import { Center } from "@algo/capsstrap";
import { getSiteValue, siteSizes, siteColors } from "@algo/algo-styles";

interface INavButtonProps {
    update: (newValue: number) => any;
    active: boolean;
    label: string;
}

export const NavButton: React.FC<INavButtonProps> = (props) => {

    /***********************************************************
        PRIMARY RENDER
    ***********************************************************/
    return (
        <Center>
            <StyledNavButton   
                onClick={() => { props.update(0) }}
                active={props.active}
            >
                <Center className="h-100">
                    <Body1>{props.label}</Body1>
                </Center>
            </StyledNavButton>
        </Center>
    );
}

export default NavButton;


/***********************************************************
    STYLED COMPONENTS
***********************************************************/
interface IStyledNavButtonProps {
    active?: boolean;
}

const StyledNavButton = styled.div<IStyledNavButtonProps>`
    height: 42px;
    width: 100%;
    border-radius: ${getSiteValue(siteSizes, "border-radius")};
    background-color: ${props => props.active ? getSiteValue(siteColors, "old-algo-green") : getSiteValue(siteColors, "medium-grey")};
    color: ${props => props.active ? "#fff" : "#000"};
    cursor: pointer;
`;