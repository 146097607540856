import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import {
    DatasourceState, DatasourceTypes,
    CLEAR_DATASOURCES_ERROR,
    FETCH_DATASOURCES_BEGIN, FETCH_DATASOURCES_SUCCESS, FETCH_DATASOURCES_FAILURE
} from "./types";

let initialState: DatasourceState = {
    loading: false,
    datasources: [],
    error: null,
    lastResponse: new ProcessedResponse()
}

export function datasourceReducer(state = initialState, action: DatasourceTypes): DatasourceState {
    switch(action.type) {
        case FETCH_DATASOURCES_BEGIN:
            return {
                ...state,
                loading: true
            };
        case FETCH_DATASOURCES_SUCCESS:
            return {
                ...state,
                loading: false,
                datasources: action.payload.datasources,
                lastResponse: action.payload.response
            };
        case FETCH_DATASOURCES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case CLEAR_DATASOURCES_ERROR:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}