export const propertyBooleanMap: {[key: string]: boolean} = {
    "parkingSpaces": false, "amenities": false, "phones": false,
    "locations": false, "mapSettings": false, "routesOfInterest": false,
    "travelTimes": false
};

export const propertyNumberMap: {[key: string]: number} = {
    "parkingSpaces": 0, "amenities": 0, "phones": 0,
    "locations": 0, "mapSettings": 0, "routesOfInterest": 0,
    "travelTimes": 0
};