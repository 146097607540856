// libraries
import * as React from "react";
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps as IRouteProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// hooks
import { usePrevious } from "@algo/hooks";
import { usePreserveRatio } from "@algo/window-sizer";
// interfaces & models
import { EATRegion, IATCameraDevice } from "@algo/network-manager/models/v3";
import { D_GET_ALL_CAMERA_DEVICES } from "@algo/network-manager/managers/v3";
import { IReduxData } from "../../../../utils/ReduxData";
// enums
import { EAspectRatio } from "@algo/window-sizer";
// local components
import { NoCameras } from "./NoCameras";
import CameraPreview from "./CameraPreview";
// general components
import { Col } from "reactstrap";
import { Center } from "@algo/algo-styles";
import { Loading } from "@algo/loading";
// redux-state-types
import { ProfileState } from '../../../../store/profile/types';
import {
    State as PageState
} from "../../../../store/cameraDevice/dashboard/types/pageStateTypes";
import {
    State as StreamPreviewsState,
} from '../../../../store/cameraDevice/dashboard/types/streamPreviewsTypes';
import { AppState} from '../../../../store';
// redux-actions
import {
    loadCameraDevices,
} from '../../../../store/cameraDevice/dashboard/actions/streamPreviewsActions';
import {
    updatePageState,
} from "../../../../store/cameraDevice/dashboard/actions/pageStateActions";
// constants
import { EDIT_CAMERA } from '../../../../models/Privileges';
import { CAMERA_PREVIEWS_MAX_SELECTION, CAMERA_PREVIEWS_PER_PAGE } from "../../../../utils/AppConstants";

/**************************************************************************
    COMPONENT PROPS INTERFACES
*************************************************************************/
// store props
interface StateProps {
    profile: ProfileState;                                                                  // (store) used to check for user's EDIT_CAMERA permission to see details page
    pageState: PageState;                                                                   // (store) general page data shared by many dashboard elements
    streamPreviews: StreamPreviewsState;                                                    // (store) camera data for rendering previews
}
// action props
interface DispatchProps {
    loadCameraDevices: typeof loadCameraDevices;                                            // (action creator) to get camera devices from api
    updatePageState: typeof updatePageState;                                                // (action creator) to update pageState store
}
// store mapper
let mapStateToProps = (state: AppState) => {
    return {
        profile: state.profile,                                                             // (store) used to check for user's EDIT_CAMERA permission to see details page
        pageState: state.streamPageState,                                                   // (store) general page data shared by many dashboard elements
        streamPreviews: state.streamPreviews,                                               // (store) camera data for rendering previews
    };
}
// action mapper
let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadCameraDevices: loadCameraDevices,                                               // (action creator) to get camera devices from api
        updatePageState: updatePageState,                                                   // (action creator) to update pageState store
    }, dispatch);
}
// combined props
type IProps = 
    StateProps & 
    DispatchProps & 
    IRouteProps;

export const CameraPreviews: React.FC<IProps> = (props): any => {

    const {
        loadCameraDevices, updatePageState
    } = {...props};

    const { height } = usePreserveRatio(                                                    // get the dimensions of... 
        "camera-preview-column",                                                            // the given element by id or class[0]...
        EAspectRatio["16x9"]                                                                // such that height is calculated with the given ratio
    );

    const snapshotHeight = height;                                                          // height applied to snapshot image to maintain 16x9 ratio

    /**************************************************************************
        CALCULATED VALUES & BREVITY NAMES
     *************************************************************************/
    const pageState: PageState = props.pageState;                                           // (store) current page state
    const prevPageState: PageState =                                                        // (store) previous page state
        usePrevious(pageState) || pageState;

    const editMode: boolean = pageState.editMode;                                           // is toolbar expanded / are previews selectable

    const region: EATRegion | undefined = pageState.selectedRegion;                            
    const prevRegion: EATRegion | undefined = prevPageState.selectedRegion;
    const datasourceChange: boolean = (region !== prevRegion);                              // check if datasource changed since last render

    const search: string = pageState.search;
    const prevSearch: string = prevPageState.search;
    const searchChange: boolean = (search !== prevSearch);                                  // check if search value changed since last render

    const pageIndex: number = pageState.pageIndex;
    const prevIndex: number = prevPageState.pageIndex;
    const indexChange: boolean = (pageIndex !== prevIndex);                                 // check if pager page changed since last render

    const accessLevelLoading: boolean = pageState.accessLevelData.isLoading;
    const prevAccessLevelLoading: boolean = prevPageState.accessLevelData.isLoading;
    const accessLevelChange: boolean =                                                      // check if access level change was triggered last render
        (accessLevelLoading !== prevAccessLevelLoading) || pageState.accessChange;

    const cameras: IReduxData = props.streamPreviews.cameraData;                            // (store) camera store object
    const devices: Array<IATCameraDevice> = cameras.data;                                   // (store) camera data
    const loading: boolean = cameras.isLoading;
    const nullContent: boolean = (!devices) || (devices.length === 0);                      // indicates when no cameras are loaded

    const skip: number = ((pageIndex) * CAMERA_PREVIEWS_PER_PAGE);                          // (pager) get-start index
    const take: number = CAMERA_PREVIEWS_PER_PAGE;                                          // (pager) get-end index

    /**************************************************************************
        EFFECTS
     *************************************************************************/

    React.useEffect(
        () => {
            loadCameraDevices({
                search: search ? search : undefined,
                region: region ? region : undefined,
                skip, take
            })
        }, []
    )

    React.useEffect(
        () => {
            if (
                (!loading) &&
                (
                    datasourceChange ||                                                     // User select
                    searchChange ||                                                         // User input
                    indexChange ||                                                          // Pager event
                    accessLevelChange                                                       // User select
                )
            ) {
                loadCameraDevices({                                                         // load new previews
                    ...D_GET_ALL_CAMERA_DEVICES,
                    search,
                    region: (pageState.selectedRegion ? pageState.selectedRegion : undefined),
                    skip: datasourceChange ? 0 : skip,                                      // if the datasource just changed, start at get index 0
                    take
                });
                updatePageState({ ...pageState, accessChange: false });                     // update pageState to indicate access level change
            }
        }, [
            loadCameraDevices, updatePageState, loading, 
            datasourceChange, searchChange, indexChange, 
            accessLevelChange, cameras, pageState,
            region, search, skip, take, devices
        ]
    );

    /**************************************************************************
        HANDLERS & UTILITIES
     *************************************************************************/

    // triggered when User clicks a Camera preview element when not in Edit Mode
    const handleCameraClick = (id: number) => {                                             // handles navigation to camera details page for clicked preview
        if (!editMode && props.profile.userProfile.hasPrivilege(EDIT_CAMERA)) {
            props.history.push(`/camera/${id}`);
        }
    }

    // triggered when User clicks a Camera preview in Edit Mode
    const toggleCameraInList = (camera: IATCameraDevice): void => {
        let pageState: any = props.pageState;                                               // brevity

        let selectedStreams: Array<IATCameraDevice> = pageState.selectedStreams;
        let index: number = indexOfSelected(camera, selectedStreams);
        if (index === -1) {                                                                 // if the camera is not in the currently selected list...
            let newSelectedStreams: Array<IATCameraDevice> =                                // make a new list that includes the camera (prepend to reduce search times)
                [camera, ...selectedStreams];                  
            props.updatePageState({
                ...pageState,
                selectedStreams: newSelectedStreams,
                showAllSelectedStreams: 
                    (selectedStreams.length <= (CAMERA_PREVIEWS_MAX_SELECTION - 1))         // update whether selected cameras are shown
                        ? true                                                              // if there are few enough now, show
                        : false                                                             // if there are too many, hide
            });
        }
        else {                                                                              // if the camera is already in the selected list...
            let newSelectedStreams: Array<IATCameraDevice> = selectedStreams;
            newSelectedStreams.splice(index, 1);
            props.updatePageState({
                ...pageState,
                selectedStreams: newSelectedStreams,
                showAllSelectedStreams: 
                    (selectedStreams.length <= CAMERA_PREVIEWS_MAX_SELECTION)               // update whether selected cameras are shown
                        ? true                                                              // if there are few enough now, show
                        : pageState.showAllSelectedStreams,                                 // if there are too many, keep the current state
            });
        }
    }

    /**************************************************************************
        RENDER LOADING
     *************************************************************************/
    if (loading) { return (<Col xs={12}><Center><Loading /></Center></Col>)}                // loading ani while getting cameras

    /**************************************************************************
        RENDER NULL CONTENT
     *************************************************************************/
    if (nullContent) { return (<Col xs={12}><NoCameras /></Col>)}                           // No Cameras display when no cameras and no error

    /**************************************************************************
        RENDER CONTENT
     *************************************************************************/
    return devices.map(
        (device, mapIndex) => {
            const selected: boolean =                                                       // indicates whether the current camera is in selected list (highlighted)
                isSelected(device, props.pageState.selectedStreams);

            return (
                <Col
                    xs={12} md={6} lg={4} xl={3}
                    key={`key-camera-preview-${mapIndex}`}
                    style={{ marginBottom: "30px" }}
                    className="camera-preview-column pb-1"
                >
                    <CameraPreview
                        camera={device}
                        selected={selected}
                        editMode={editMode}
                        cameraClick={handleCameraClick}
                        toggleCameraInList={toggleCameraInList}
                        snapshotHeight={snapshotHeight || 100}
                    />
                </Col>
            );
        }
    )  
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CameraPreviews));


/*****************************************************************************
    UTILITIES
*****************************************************************************/
function isSelected(device: IATCameraDevice, selectedList: Array<IATCameraDevice>) {
    for (let i = 0; i < selectedList.length; i++) {
        if (selectedList[i].id === device.id) return true;
    }
    return false;
}

function indexOfSelected(device: IATCameraDevice, selectedList: Array<IATCameraDevice>) {
    for (let i = 0; i < selectedList.length; i++) {
        if (selectedList[i].id === device.id) return i;
    }
    return -1;
}