// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";

export type IProps = {
    title: string;
    action: (...args: any[]) => any;
} & React.HTMLAttributes<HTMLDivElement>;

export const EditorTitleRow: React.FC<IProps> = (props) => {

    return (
        <SC.StyledEditorTitleRow style={props.style}>
            <SC.SideSection>
                <button 
                    className="btn btn-outline-primary"
                    onClick={() => props.action()}
                >
                    <i className="fas fa-arrow-left"></i>
                </button>
            </SC.SideSection>
            
            <SC.StyledTitle>{props.title}</SC.StyledTitle>

            <SC.SideSection></SC.SideSection>
        </SC.StyledEditorTitleRow>
    )
};