//types
import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import * as TYPES from "./types";

let initialState: TYPES.FacilityDashboardState = {
    initializing: false,
    loadingFacilities: false,
    deletingFacility: false,
    facilities: [],
    error: null,
    lastInitResponse: new ProcessedResponse(),
    lastSearchResponse: new ProcessedResponse()
};

export function facilityDashboardReducer(
    state = initialState, 
    action: TYPES.FacilityDashboardTypes
): TYPES.FacilityDashboardState {
    
    switch(action.type) {
        case TYPES.INITIALIZE_FACILITY_DASHBOARD_BEGIN:
            return {
                ...state,
                initializing: true,
                loadingFacilities: true
            };
        case TYPES.INITIALIZE_FACILITY_DASHBOARD_SUCCESS:
            return {
                ...state,
                facilities: action.payload.facilities,
                initializing: false,
                loadingFacilities: false,
                lastInitResponse: action.payload.response         
            };
        case TYPES.INITIALIZE_FACILITY_DASHBOARD_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                initializing: false,
                loadingFacilities: false
            };
        case TYPES.SEARCH_FACILITIES_BEGIN:
            return {
                ...state,
                loadingFacilities: true
            };
        case TYPES.SEARCH_FACILITIES_SUCCESS:
            return {
                ...state,
                facilities: action.payload.facilities,
                loadingFacilities: false,
                lastSearchResponse: action.payload.response
            };
        case TYPES.SEARCH_FACILITIES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case TYPES.DELETE_FACILITY_BEGIN:
            return {
                ...state,
                deletingFacility: true
            };
        case TYPES.DELETE_FACILITY_SUCCESS:
            return {
                ...state,
                deletingFacility: false,
                facilities: action.payload.facilities,
                error: action.payload.error
            };
        case TYPES.DELETE_FACILITY_FAILURE:
            return {
                ...state,
                deletingFacility: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_FACILITY_DASHBOARD_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_FACILITY_DASHBOARD:
            return initialState;
        default:
            return state;
    }
}