//libraries
import { Action } from "redux";
import { AppState } from "../..";
import { ThunkAction } from "redux-thunk";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "./types";
//models
import { IATFeedback } from '@algo/network-manager/models/v3';
import { 
    EHttpRequestMethod, FeedbackNetworkManager, IFeedbackNetworkManager, 
    INetworkManager, IProcessedResponse, NetworkManager 
} from "@algo/network-manager/managers/v3";
//constants
import { DEFAULT_QUERY } from "../../../utils/AppConstants";
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlFeedback: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).feedbacks}`;

// const apiUrlFeedbackTest: string = "http://localhost:3000/feedbacks";
/*
Initialize Feedback Dashboard
*/
function initializeFeedbackDashboardBegin(): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.INITIALIZE_FEEDBACK_DASHBOARD_BEGIN,
        payload: {} as TYPES.FeedbackDashboardPayload
    };
}

function initializeFeedbackDashboardSuccess(
    response: IProcessedResponse
): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.INITIALIZE_FEEDBACK_DASHBOARD_SUCCESS,
        payload: {
            feedback: response.data
        } as TYPES.FeedbackDashboardPayload 
    };
}

function initializeFeedbackDashboardFailure(
    error: Error
): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.INITIALIZE_FEEDBACK_DASHBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.FeedbackDashboardPayload
    };
}

export let initializeFeedbackDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(initializeFeedbackDashboardBegin());

    let manager: IFeedbackNetworkManager =
        new FeedbackNetworkManager(apiUrlFeedback);

    getAccessToken()
        .then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.getAll()
                    .then(
                        (response: IProcessedResponse) => {
                            dispatch(initializeFeedbackDashboardSuccess(response))
                        }
                    ).catch(
                        (err: Error) => 
                            dispatch(initializeFeedbackDashboardFailure(err))
                    )
            }
        ).catch(
            (err: Error) =>
                dispatch(initializeFeedbackDashboardFailure(err))
        )


    // SOME API TEST CODE
    // let manager: INetworkManager = 
    //     new NetworkManager(apiUrlFeedbackTest);

    // manager.processedFetch(apiUrlFeedbackTest, { method: EHttpRequestMethod.GET }, false)
    //     .then(
    //         (response: any) => {
    //             console.error("response from feedbacks", response);
    //             dispatch(initializeFeedbackDashboardSuccess(response))
    //         }
    //     )
}

/*
Load Feedback
*/

function loadFeedbackBegin(): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.LOAD_FEEDBACK_BEGIN,
        payload: {} as TYPES.FeedbackDashboardPayload
    };
}

function loadFeedbackSuccess(
    response: IProcessedResponse
): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.LOAD_FEEDBACK_SUCCESS,
        payload: {
            feedback: response.data
        } as TYPES.FeedbackDashboardPayload
    }
}

function loadFeedbackFailure(
    error: Error
): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.LOAD_FEEDBACK_FAILURE,
        payload: {
            error: error
        } as TYPES.FeedbackDashboardPayload
    };
}

export let loadFeedback = (
    search: string = DEFAULT_QUERY
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().feedbackDashboard.initializing) {
        dispatch(loadFeedbackBegin());

        let manager: IFeedbackNetworkManager = 
            new FeedbackNetworkManager(apiUrlFeedback);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll({
                        search
                    }).then(
                        (response: IProcessedResponse) => {
                            dispatch(loadFeedbackSuccess(response))
                        }
                    ).catch(
                        (err: Error) => {
                            dispatch(loadFeedbackFailure(err))
                        }
                    )
                }
            ).catch(
                (err: Error) => 
                    dispatch(loadFeedbackFailure(err))
            )


        // SOME API TEST CODE
        // let manager: INetworkManager = 
        //     new NetworkManager(apiUrlFeedbackTest);

        // manager.processedFetch(apiUrlFeedbackTest, { method: EHttpRequestMethod.GET }, false)
        //     .then(
        //         (response: any) => {
        //             console.error("response from feedbacks", response);
        //             dispatch(loadFeedbackSuccess(response))
        //         }
        //     )
    }
}

/*
Clear Feedback Dashboard Error
*/

function clearFeedbackDashboardErrorState(
    //
): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.CLEAR_FEEDBACK_DASHBOARD_ERROR,
        payload: {} as TYPES.FeedbackDashboardPayload
    };
}

export let clearFeedbackDashboardError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().feedbackDashboard.error !== null) {
        dispatch(clearFeedbackDashboardErrorState());
    }
}

/*
Uninitialize Feedback Dashboard
*/

function uninitializeFeedbackDashboardState(
    //
): TYPES.FeedbackDashboardTypes {
    return {
        type: TYPES.UNINITIALIZE_FEEDBACK_DASHBOARD,
        payload: {} as TYPES.FeedbackDashboardPayload
    };
}

export let uninitializeFeedbackDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(uninitializeFeedbackDashboardState());
}