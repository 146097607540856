// libraries
import * as React from "react";
// styles
import * as SC from "../../styled/Globals";
// components
import Content from '../../base/layout/Content';
// store

type RedirectProps = any;

export const RedirectContent: React.FC<RedirectProps> = (props) => {
    
    return (
        <Content pageHasSidebar={false}>
            <div className='av-message-content'>
                <h1 className='sr-only'>
                    Authenticating
                </h1>
                <SC.StyledMessageContainer>
                    <SC.StyledAlertContainer>
                        <div className='av-warning-alert'>
                            Please wait, you will be redirected shortly...
                        </div>
                        <SC.StyledSubtext>
                            If it has been more than 10 seconds and you have still not redirected, click <SC.StyledRootLink href="/">here</SC.StyledRootLink>.
                        </SC.StyledSubtext>
                    </SC.StyledAlertContainer>
                </SC.StyledMessageContainer>
            </div>
        </Content>
    );
}

export default RedirectContent;