// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
// components
import Content from '../../base/layout/Content';
import OrganizationTableView from "./OrganizationTableView";
// store
import { AppState } from "../../../store";

interface StateProps { 
}

function mapStateToProps(state: AppState) {
    return { 
    };
}

type OrganizationListProps = 
    StateProps & 
    RouteComponentProps<any>;

class OrganizationList 
    extends React.Component<
        OrganizationListProps
    > 
{
    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-admin-dashboard-content fluid'>
                            <OrganizationTableView />
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
)(withRouter(OrganizationList));