//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { Action } from "redux";
import { getAccessToken } from "../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "./types";
//models
import { 
    AAUserAuthorizationProfile
} from "@algo/network-manager/models/v3/admin";
import { 
    IProcessedResponse,
    IUserNetworkManager, UserNetworkManager 
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../api-version-constants";

declare var __API_URL__: string;
const apiUrlUser: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).profiles}`;

/*
Get User Profile
*/
function loadProfileBegin(): TYPES.ProfileTypes {
    return {
        type: TYPES.LOAD_PROFILE_BEGIN,
        payload: {} as TYPES.ProfilePayload
    };
}

function loadProfileSuccess(response: IProcessedResponse): TYPES.ProfileTypes {
    return {
        type: TYPES.LOAD_PROFILE_SUCCESS,
        payload: {
            userProfile: response.data,
            error: response.error
        } as TYPES.ProfilePayload
    };
}

function loadProfileFailure(error: Error): TYPES.ProfileTypes {
    return {
        type: TYPES.LOAD_PROFILE_FAILURE,
        payload: {
            error: error
        } as TYPES.ProfilePayload
    };
}

export let loadUserProfile = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().profile.loading) {
        dispatch(loadProfileBegin());

        let manager: IUserNetworkManager =
            new UserNetworkManager(apiUrlUser);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getProfile()
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error) 
                                    dispatch(loadProfileFailure(response.error));
                                else 
                                    dispatch(loadProfileSuccess(response))
                            }
                        ).catch(
                            (error: Error) => {
                                dispatch(loadProfileFailure(error))
                            }
                        )
                }
            ).catch(
                (error: Error) => {
                    dispatch(loadProfileFailure(error))
                }
            )
    }
}

// export let updateUserProfile = (
//     // 
// ): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
//     if (getState().profile.initialized) {
//         dispatch(loadProfileBegin());

//         let manager: IUserNetworkManager = 
//             new UserNetworkManager(apiUrlUser);

//         getAccessToken()
//             .then(
//                 (token: string) => {
//                     manager.setAccessToken(token);
//                     manager.getProfile()
//                         .then(
//                             (response: IProcessedResponse) => {
//                                 if (response.error) 
//                                     dispatch(loadProfileFailure(response.error));
//                                 else 
//                                     dispatch(loadProfileSuccess(response));
//                             }
//                         ).catch(
//                             (error: Error) => {
//                                 dispatch(loadProfileFailure(error))
//                             }
//                         )
//                 }
//             ).catch(
//                 (error: Error) => {
//                     dispatch(loadProfileFailure(error))
//                 }
//             )
//     }
// }

/*
Unload User Profile
*/

function unloadProfileState(): TYPES.ProfileTypes {
    return {
        type: TYPES.UNLOAD_PROFILE,
        payload: {} as TYPES.ProfilePayload
    };
}

export let unloadUserProfile = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(unloadProfileState());
}

/*
Clear User Profile Error
*/

function clearUserProfileErrorState(): TYPES.ProfileTypes {
    return {
        type: TYPES.CLEAR_PROFILE_ERROR,
        payload: {} as TYPES.ProfilePayload
    };
}

export let clearUserProfileError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
    dispatch(clearUserProfileErrorState());
}