// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledHeaderWrapper = styled.div`
    height: 64px;
    width: 100%;

    ${flexSmart("flex-start center")}

    border-bottom: 1px solid rgba(0,0,0,0.1);
`;

export const MenuToggleSection = styled.div<{showSidebar: boolean}>`
    height: 100%;
    //width: ${props => props.showSidebar ? "248px" : "248px"};
    width: 16rem;

    display: flex;
    justify-content: ${props => props.showSidebar ? "center" : "center"};
    align-items: center;

    background: ${props => props.showSidebar ? "rgba(0,0,0,0.1)" : props.theme.colors["bg-primary"]["rgba"]};

    background: linear-gradient(to right, rgba(0,0,0,0.1) 50%, ${props => props.theme.colors["bg-primary"]["rgba"]} 50%) right;
    background-size: 200%;
    transition: .5s ease-out;

    ${props => props.showSidebar 
        ? `
            background-position: left;
        `
        : `
            
        `
    }
`;

export const ToggleSidebarWrapper = styled.div`

    &:hover{
        cursor: pointer;
    }
`;

export const HeaderSection = styled.div<{showSidebar: boolean}>`
    height: 100%;
    //width: calc(100% - 248px);
    width: calc(100% - 16rem);

    padding: 1.7rem;

    ${flexSmart("space-between center")}
`;