//types
import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import * as TYPES from "./types";

let initialState: TYPES.RoleDashboardState = {
    initializing: false,
    loadingRoles: false,
    deletingRole: false,
    roles: [],
    organizations: [],
    error: null,
    lastResponse: new ProcessedResponse()
}

export function roleDashboardReducer(
    state = initialState, 
    action: TYPES.RoleDashboardTypes
): TYPES.RoleDashboardState {

    switch(action.type) {
        case TYPES.INITIALIZE_ROLE_DASHBOARD_BEGIN:
                return {
                    ...state,
                    initializing: true
                };
        case TYPES.INITIALIZE_ROLE_DASHBOARD_SUCCESS:
            return {
                ...state,
                initializing: false,
                loadingRoles: false,
                organizations: action.payload.organizations
            };
        case TYPES.INITIALIZE_ROLE_DASHBOARD_FAILURE:
            return {
                ...state,
                initializing: false,
                loadingRoles: false,
                error: action.payload.error
            };
        case TYPES.LOAD_ROLES_BEGIN:
            return {
                ...state,
                loadingRoles: true
            };
        case TYPES.LOAD_ROLES_SUCCESS:
            return {
                ...state,
                loadingRoles: false,
                roles: action.payload.roles
            };
        case TYPES.LOAD_ROLES_FAILURE:
            return {
                ...state,
                loadingRoles: false,
                error: action.payload.error
            };
        case TYPES.DELETE_ROLE_BEGIN:
            return {
                ...state,
                deletingRole: true
            };
        case TYPES.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                deletingRole: false,
                roles: action.payload.roles
            };
        case TYPES.DELETE_ROLE_FAILURE:
            return {
                ...state,
                deletingRole: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_ROLE_DASHBOARD_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_ROLE_DASHBOARD:
            return initialState;
        default:
            return state;
    }
}