import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { IATLegacyDatasource } from "@algo/network-manager/models/v3";

export let FETCH_DATASOURCES_BEGIN = 'FETCH_DATASOURCES_BEGIN';
export let FETCH_DATASOURCES_SUCCESS = 'FETCH_DATASOURCES_SUCCESS';
export let FETCH_DATASOURCES_FAILURE = 'FETCH_DATASOURCES_FAILURE';
export let CLEAR_DATASOURCES_ERROR = 'CLEAR_DATASOURCES_ERROR';

export interface DatasourceState {
    datasources: IATLegacyDatasource[];
    loading: boolean;
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface DatasourcePayload {
    datasources: IATLegacyDatasource[];
    error: Error | null;
    response: IProcessedResponse;
}

interface FetchDatasourcesBeginAction {
    type: typeof FETCH_DATASOURCES_BEGIN;
    payload: DatasourcePayload;
}

interface FetchDatasourcesSuccessAction {
    type: typeof FETCH_DATASOURCES_SUCCESS;
    payload: DatasourcePayload;
}

interface FetchDatasourcesFailureAction {
    type: typeof FETCH_DATASOURCES_FAILURE;
    payload: DatasourcePayload;
}

interface ClearDatasourcesErrorAction {
    type: typeof CLEAR_DATASOURCES_ERROR;
    payload: DatasourcePayload;
}

export type DatasourceTypes = 
    FetchDatasourcesBeginAction | 
    FetchDatasourcesSuccessAction | 
    FetchDatasourcesFailureAction | 
    ClearDatasourcesErrorAction;
