// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// interface & models
import { ATSynchronization, IATSynchronization } from "@algo/network-manager/models/v3";
// store
import { AppState } from "../../../store";
import { SynchronizationState } from "../../../store/synchronization/types";
import { 
    loadLatestSynchronizations, loadSynchronizationHistory, 
    uninitializeSynchronization 
} from "../../../store/synchronization/actions";
// constants
import { 
    SERVICE_SYNCHRONIZATION_REFRESH_INTERVAL,
    DEFAULT_SERVICE_SYNCHRONIZATION_HISTORY_COUNT
} from "../../../utils/AppConstants";
const OKAY_STATUS_NAME: string = 'Okay';
const WARNING_STATUS_NAME: string = 'Warning';
const ERROR_STATUS_NAME: string = 'Error';

interface StateProps { 
    synchronization: SynchronizationState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        synchronization: state.synchronization
    };
}

interface DispatchProps {
    loadLatestSynchronizations: typeof loadLatestSynchronizations,
    loadSynchronizationHistory: typeof loadSynchronizationHistory,
    uninitialize: typeof uninitializeSynchronization
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadLatestSynchronizations: loadLatestSynchronizations,
        loadSynchronizationHistory: loadSynchronizationHistory,
        uninitialize: uninitializeSynchronization
    }, dispatch);
}

type SynchronizationProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface OwnState {
    selectedServiceName: string;
}

class Synchronization extends React.Component<SynchronizationProps, OwnState> {
    pageRefresher: any;

    constructor(props: Readonly<SynchronizationProps>) {
        super(props);

        this.pageRefresher = setInterval(
            this.refreshPageData, 
            SERVICE_SYNCHRONIZATION_REFRESH_INTERVAL
        );

        this.state = {
            selectedServiceName: ""
        } as OwnState;
    }

    componentDidMount() {
        this.props.loadLatestSynchronizations();
    }

    componentDidUpdate(
        prevProps: SynchronizationProps, prevState: OwnState, 
        snapshot: any
    ) {
        if (this.state.selectedServiceName !== prevState.selectedServiceName) {
            this.props.loadSynchronizationHistory(
                this.state.selectedServiceName, 
                DEFAULT_SERVICE_SYNCHRONIZATION_HISTORY_COUNT
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.pageRefresher);
        this.props.uninitialize();
    }

    refreshPageData = (): void => {
        this.props.loadLatestSynchronizations();

        if (this.state.selectedServiceName !== null) {
            this.props.loadSynchronizationHistory(
                this.state.selectedServiceName, 
                DEFAULT_SERVICE_SYNCHRONIZATION_HISTORY_COUNT
            );
        }
    }

    setServiceName = (serviceName: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                selectedServiceName: serviceName
            };
        });
    }

    renderStatusOrb = (status: string): React.ReactNode => {
        switch (status) {
            case OKAY_STATUS_NAME:
                return (<i className="fas fa-circle text-success"></i>);
            case WARNING_STATUS_NAME:
                return (<i className="fas fa-circle text-warning"></i>);
            case ERROR_STATUS_NAME:
                return (<i className="fas fa-circle text-danger"></i>);
            default:
                return (<i className="fas fa-circle text-primary"></i>);
        }
    }

    renderStatusItems = (): React.ReactNode => {
        if (this.props.synchronization.latest?.length > 0) {
            let list = (
                    this.props.synchronization.latest.map(
                        (item: IATSynchronization) => {
                            return (
                                <li 
                                    key={Math.random()} 
                                    className="aa-status-item" 
                                    onClick={() => this.setServiceName(item.serviceName || "")}
                                >
                                    <div className='aa-status-item-content'>
                                        <div className='aa-status-service-name'>
                                            {item.serviceName}
                                        </div>
                                        <div className='aa-status-indicator'>
                                            {this.renderStatusOrb(item.status)}
                                        </div>
                                    </div>
                                </li>
                            );
                        }
                    )
            );

            return (
                <ul className="list-group list-group-flush">
                    {list}
                </ul>
            );
        }
        else {
            return (
                <div className='card-body'>
                    <div className='av-primary-alert'>
                        Loading...
                    </div>
                </div>
                );
        }
    }

    renderHistoryItems = (): React.ReactNode => {
        if (
            this.props.synchronization.loadingHistory && 
            ( this.props.synchronization.history.length === 0)
        ) {
            return (
                <div className='card-body'>
                    <div className='av-primary-alert'>
                        Loading...
                    </div>
                </div>
            );
        }
        else if (this.state.selectedServiceName == null) {
            return (
                <div className='card-body'>
                    <div className='av-primary-alert'>
                        No synchronizer selected
                    </div>
                </div>
            );
        }
        else if (
            ( this.state.selectedServiceName !== null ) && 
            this.props.synchronization.history.length === 0
        ) {
            return (
                <div className='card-body'>
                    <div className='av-warning-alert'>
                        No history available
                    </div>
                </div>
            );
        }
        else {
            return this.props.synchronization.history.map((
                item: IATSynchronization) => {
                    return this.renderHistoryItem(new ATSynchronization(item));
                }
            );
        }
    }

    renderHistoryItem = (item: IATSynchronization): React.ReactNode => {

        return (
            <div className='aa-history-item-card-body' key={Math.random()}>
                <div className='aa-history-item'>
                    <div className='aa-history-item-timestamp'>
                        <div className='aa-history-item-datepart'>{item.formattedDatePart()}</div>
                        <div className='aa-history-item-timepart'>{item.formattedTimePart()}</div>
                    </div>
                    <div className='aa-history-item-message'>
                        <h5>{this.renderHistoryItemHeader(item.status, (item.serviceName || "SERVICE_NAME_MISSING"))}</h5>
                        <p>
                            {item.message}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    renderHistoryItemHeader = (status: string, serviceName: string): string => {
        var statusHeader: string;

        switch (status) {
            case OKAY_STATUS_NAME:
                statusHeader = 'Success';
                break;
            case WARNING_STATUS_NAME:
                statusHeader = 'Warning';
                break;
            case ERROR_STATUS_NAME:
                statusHeader = 'Failure';
                break;
            default: 
                statusHeader =  'Unknown';
                break;    
        }

        return `${statusHeader} - ${serviceName} Sychronization`;;
    }

    render() {
        return (
            <div className='av-dashboard-content'>
                <div className='aa-service-status-content'>
                    <h1 className='aa-header'>Synchronization Status</h1>
                    <div className='aa-service-status-history'>
                        <div className='card'>
                            <div className='card-header'>
                                Status History
                            </div>
                            {this.renderHistoryItems()}
                        </div>
                    </div>
                    <div className='aa-service-status-latest'>
                        <div className='card'>
                            <div className='card-header'>
                                Synchronizers
                            </div>
                            {this.renderStatusItems()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(Synchronization));