export interface IReduxData {
    name: string;
    data: any;
    totalCount: number | null;
    checksum: number | null;
    isLoading: any;
    toggleLoading: () => void;
    setLoading: (newValue: boolean) => void;
    needsUpdate: (newChecksum: number) => boolean;
}

export interface IReduxDataInitObject {
    name?: string;                                                              // a string to identify this data
    data?: any;                                                                 // the data you want to hold in store
    totalCount?: number | null;                                                        // total count of data that can be requested 
    checksum?: number | null;                                                          // checksum to compare to future calls to detect data changes
    isLoading?: boolean;                                                        // indicate whether the data is being loaded
}

export class ReduxData {

    //class properties / attributes
    name: string;
    data: any;
    totalCount: number | null;
    checksum: number | null;
    isLoading: boolean

    constructor(initObject: IReduxDataInitObject) {
        this.name = initObject.name ? initObject.name : "AnonymousData";
        this.data = initObject.data ? initObject.data : null;
        this.totalCount = initObject.totalCount ? initObject.totalCount : null;
        this.checksum = initObject.checksum ? initObject.checksum : null;
        this.isLoading = initObject.isLoading ? initObject.isLoading : false;
    }

    toggleLoading() {
        this.isLoading = !this.isLoading;
    }

    setLoading(newValue: boolean) {
        this.isLoading = newValue;
    }

    needsUpdate(newChecksum: number) {
        return (newChecksum !== this.checksum);
    }
}

export const cloneReduxData = (oldData: IReduxData) => {
    return new ReduxData(
        {
            name: oldData.name,
            data: oldData.data,
            totalCount: oldData.totalCount,
            checksum: oldData.checksum,
            isLoading: oldData.isLoading,
        }
    );
}