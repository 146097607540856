// libraries
import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { determinePageCount } from "@algo/pager";
// interfaces & models
import { IReduxData } from "../../../utils/ReduxData";
import {
    IATCameraDevice, IATCloudStreamTarget
} from "@algo/network-manager/models/v3";
// hooks
import { useGetHeight } from "@algo/hooks";
// redux-state-types
import {
    State as PreviewsState
} from "../../../store/cameraDevice/dashboard/types/streamPreviewsTypes";
import {
    State as PageState
} from "../../../store/cameraDevice/dashboard/types/pageStateTypes";
import { AppState } from '../../../store';
// redux-actions
import {
    updatePageState, updateAccessLevels
} from "../../../store/cameraDevice/dashboard/actions/pageStateActions";
// local components
import CameraPreviews from "./previews/CameraPreviews";
import CameraToolbar from "./toolbar/CameraToolbar";
import { CameraDashboardModal as DashboardModal } from "./Modal";
// general components
import { Container, Row, Col } from "reactstrap";
import { Center } from "@algo/algo-styles";
import { RemoveScrollbar } from "@algo/algo-styles";
import { Pager } from "@algo/pager";
import { ToTopButton } from "./ToTopButton";

const NAV_BAR_HEIGHT: number = 64;
const DEFAULT_TOOLBAR_HEIGHT: number = 84;
const ITEMS_PER_PAGE: number = 30;

/**************************************************************************
    COMPONENT PROPS INTERFACES
*************************************************************************/
// redux store props
interface StateProps {
    previewsState: PreviewsState;
    pageState: PageState;
}
// redux action props
interface DispatchProps {
    updatePageState: typeof updatePageState;
    updateAccessLevels: typeof updateAccessLevels
}
// redux action mapper
let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        updatePageState: updatePageState,
        updateAccessLevels: updateAccessLevels
    }, dispatch);
}
// redux store mapper
let mapStateToProps = (state: AppState) => {
    return {
        previewsState: state.streamPreviews,
        pageState: state.streamPageState
    };
}
// combined props
type IProps = StateProps & DispatchProps & RouteComponentProps;

export const CameraDashboard: React.FC<IProps> = (props) => {

    /**************************************************************************
        STATE
     *************************************************************************/
    const [showToTop, setShowToTop] = React.useState(false);
    const [toolbarHeight, setToolbarHeight] = React.useState(DEFAULT_TOOLBAR_HEIGHT);

    /**************************************************************************
        CALCULATED VALUES & BREVITY NAMES
     *************************************************************************/
    const pageState: PageState = props.pageState;
    const rootHeight: number = useGetHeight("id", "root");                                  // get full root height
    const navBarHeight: number = NAV_BAR_HEIGHT;                                            // get height of site navbar
    const containerHeight: number =                                                         // set height of top-level container
        rootHeight - navBarHeight;

    const pagerContent: number = 42;                                                        // set height of pager content
    const pagerMargin: number = 32;                                                         // account for toolbar margin
    const pagerHeight: number =                                                             // brevity
        pagerContent + pagerMargin;

    const previewsHeight: number =                                                          // set height of previews to remainder
        containerHeight - toolbarHeight - pagerHeight;

    const cameraData: IReduxData = props.previewsState.cameraData;

    const pageCount: number =
        determinePageCount(cameraData.totalCount ?? 0, ITEMS_PER_PAGE) ||
        props.pageState.pageCount ||
        1;

    /**************************************************************************
        HANDLERS & UTILITIES
     *************************************************************************/

    /* when window is scrolled, check if scroll is greater than 300 pixels, 
     * if so, activate ToTop button in state */
    const checkScrollHeight = (): void => {
        let cameraList: HTMLElement | null = document.getElementById("cameraList");
        let scroll: number | undefined = cameraList?.scrollTop;

        if (scroll === undefined) return;
        if (scroll === 0 && showToTop) {
            setShowToTop(false); 
            return;
        }

        if (scroll && (scroll >= 300) && !showToTop) {
            setShowToTop(true)
        }
        else if (scroll && (scroll < 300) && showToTop) {
            setShowToTop(false);
        }
    }

    /* Sets the scroll height of the cameraList div back to the top */
    const setScrollHeight = () => {
        let cameraList: HTMLElement | null = document.getElementById("cameraList");
        if (cameraList)
            cameraList.scrollTop = 0;
    }

    const getCloudStreamTargets =
        (devices: Array<IATCameraDevice>): Array<IATCloudStreamTarget> => {
            let targetsList: Array<IATCloudStreamTarget> = [];
            devices.forEach(
                (device) => {
                    if (device.cloudStreamTargets[0])
                        targetsList.push(device.cloudStreamTargets[0])
                }
            );
            return targetsList;
        }

    /**************************************************************************
        PRIMARY RENDER
     *************************************************************************/

    return (
        <Container
            fluid={true}
            style={{ height: `${containerHeight}px`}}
        >

            {/* Modal anchor points */}
            <DashboardModal
                renderOption={"update"}
                show={pageState.showUpdateModal}
                updateParentState={() => {
                    props.updatePageState({
                        ...pageState,
                        showUpdateModal: false,
                    })
                }}
                updateStore={() => {
                    props.updateAccessLevels(
                        getCloudStreamTargets(pageState.selectedStreams),
                        pageState.selectedAccessLevel
                    )
                }}
                selectedAccessLevel={pageState.selectedAccessLevel}
            />

            {/* Toolbar Section */}
            <Row id="toolbar-row">
                { /*    
                        toolbar-row id is used in StreamToolbar to watch height 
                        and notify StreamDashboard of change to maintain page heights 
                */ }
                <Col xs={12}>
                    <CameraToolbar
                        setHeightCallback={
                            (height: number) => {
                                if (height !== toolbarHeight) setToolbarHeight(height);
                            }
                        } />
                </Col>
            </Row>

            {/* Stream Preview Section */}
            <RemoveScrollbar
                className={`row pt-1`}
                style={{ height: `${previewsHeight}px` }}
                id="cameraList"
                onScroll={checkScrollHeight}
            >
                <CameraPreviews />
                <ToTopButton show={showToTop} onClick={setScrollHeight} />
            </RemoveScrollbar>

            {/* Pager Section */}
            <Row className="my-3" style={{ height: `${pagerContent}px` }}>
                <Col xs={12}>
                    <Center>
                        <Pager
                            pageCount={pageCount}
                            pageIndex={pageState.pageIndex}
                            size={"xs"}
                            updatePageIndex={(newIndex: number) => {
                                props.updatePageState({
                                    ...pageState,
                                    pageIndex: newIndex,
                                    pageCount
                                })
                            }}
                            ellipsisThreshold={10} ellipsisOffset={1}
                            backgroundColorActive={"old-algo-green"}
                        />
                    </Center>
                </Col>
            </Row>
        </Container>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CameraDashboard));