// libraries
// interfaces & models
import { 
    AAOrganization 
} from "@algo/network-manager/models/v3/admin";
import * as TYPES from "./types";

let initialState: TYPES.OrganizationEditorState = {
    initializing: false,
    organizationLoaded: false,
    loadingUserSearch: false,
    savingOrganization: false,
    addingUser: false,
    removingUser: false,
    organization: new AAOrganization(),
    organizationList: [],
    roleList: [],
    organizationUserList: [],
    userSearchList: [],
    datasourceList: [],
    error: null
}

export function organizationEditorReducer(
    state = initialState, 
    action: TYPES.OrganizationEditorTypes
): TYPES.OrganizationEditorState {
    switch(action.type) {
        case TYPES.LOAD_ORGANIZATION_BEGIN:
            return {
                ...state,
                initializing: true,
                error: null
            };
        case TYPES.LOAD_ORGANIZATION_SUCCESS:
            return {
                ...state,
                initializing: false,
                organizationLoaded: true,
                organization: action.payload.organization,
                organizationList: action.payload.organizationList,
                roleList: action.payload.roleList,
                organizationUserList: action.payload.organizationUserList,
                datasourceList: action.payload.datasourceList,
                error: null
            }
        case TYPES.LOAD_ORGANIZATION_FAILURE:
            return {
                ...state,
                initializing: false,
                error: action.payload.error
            };
        case TYPES.LOAD_USER_SEARCH_BEGIN:
            return {
                ...state,
                loadingUserSearch: true,
                error: null
            };
        case TYPES.LOAD_USER_SEARCH_SUCCESS:
            return {
                ...state,
                loadingUserSearch: false,
                userSearchList: action.payload.userSearchList,
                error: null
            };
        case TYPES.LOAD_USER_SEARCH_FAILURE:
            return {
                ...state,
                loadingUserSearch: false,
                error: action.payload.error
            };
        case TYPES.SAVE_ORGANIZATION_BEGIN:
            return {
                ...state,
                savingOrganization: true,
                error: null
            };
        case TYPES.SAVE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                savingOrganization: false,
                organization: action.payload.organization,
                error: null
            };
        case TYPES.SAVE_ORGANIZATION_FAILURE:
            return {
                ...state,
                savingOrganization: false,
                error: action.payload.error
            };
        case TYPES.ADD_USER_TO_ORGANIZATION_BEGIN:
            return {
                ...state,
                addingUser: true
            };
        case TYPES.ADD_USER_TO_ORGANIZATION_SUCCESS:
            return {
                ...state,
                addingUser: false,
                organizationUserList: action.payload.organizationUserList,
                error: null
            };
        case TYPES.ADD_USER_TO_ORGANIZATION_FAILURE:
            return {
                ...state,
                addingUser: false,
                error: action.payload.error
            };
        case TYPES.REMOVE_USER_FROM_ORGANIZATION_BEGIN:
            return {
                ...state,
                removingUser: true,
                error: null
            };
        case TYPES.REMOVE_USER_FROM_ORGANIZATION_SUCCESS:
            return {
                ...state,
                removingUser: false,
                organizationUserList: action.payload.organizationUserList,
                error: null
            };
        case TYPES.REMOVE_USER_FROM_ORGANIZATION_FAILURE:
            return {
                ...state,
                removingUser: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_ORGANIZATION_EDITOR_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_ORGANIZATION_EDITOR:
            return initialState;
        default:
            return state;
    }
}