//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../../";
import { Action } from "redux";
import { getAccessToken } from "../../../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "../types/streamStatusTypes";
// interfaces & models
import { 
    ICameraDeviceNetworkManager, CameraDeviceNetworkManager, IProcessedResponse 
} from "@algo/network-manager/managers/v3";
// constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../../../api-version-constants";
declare var __API_URL__: string;
const apiUrlDevice: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).devices}`;

/******************************************************************************
    LOAD CAMERA DEVICE STATUS | ACTION CREATOR
 *****************************************************************************/
export const loadCameraDeviceStatus =
    (id: number): ThunkAction<void, AppState, null, Action<string>> =>
        async (dispatch, getState) => {
            let statusLoading: TYPES.IStatusLoading =
                getState().streamStatus.statusLoading;

            if (!statusLoading[id]) {
                dispatch(loadCameraDeviceStatusBegin(id));

                const manager: ICameraDeviceNetworkManager =
                    new CameraDeviceNetworkManager(apiUrlDevice);

                getAccessToken().then(
                    (token) => {
                        manager.setAccessToken(token);
                        manager.getStatus(
                            getState().streamStatus.lastResponse,
                            {id}
                        ).then(
                            (response: IProcessedResponse) => {
                                dispatch(loadCameraDeviceStatusSuccess(id, response));
                            },
                            (reject: any) => {
                                dispatch(loadCameraDeviceStatusFailure(id, new Error(reject)));
                            }
                        ).catch(
                            (error: Error) => {
                                dispatch(loadCameraDeviceStatusFailure(id, error));
                            }
                        )
                    }
                )
            }
        };

/******************************************************************************
    LOAD CAMERA DEVICE STATUS | ACTIONS
 *****************************************************************************/

function loadCameraDeviceStatusBegin(id: number): TYPES.Actions {
    return {
        type: TYPES.LOAD_CAMERA_DEVICE_STATUS_BEGIN,
        payload: {
            id
        } as TYPES.Payload
    };
}

function loadCameraDeviceStatusSuccess(
    id: number,
    response: IProcessedResponse
): TYPES.Actions {
    return {
        type: TYPES.LOAD_CAMERA_DEVICE_STATUS_SUCCESS,
        payload: {
            id,
            status: response.data,
            response
        } as TYPES.Payload
    };
}

function loadCameraDeviceStatusFailure(
    id: number, 
    error: Error
): TYPES.Actions {
    return {
        type: TYPES.LOAD_CAMERA_DEVICE_STATUS_FAILURE,
        payload: { id, error } as TYPES.Payload
    };
}