//libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import { getAccessToken } from '../../template/authentication/oidcConfig';
//redux-types
import { OrganizationEditorState } from '../../../store/organization/editor/types';
import { AppState } from '../../../store';
//redux-actions
import {
    initializeOrganizationEditor, saveOrganization,
    addUserToOrganization, removeUserFromOrganization,
    uninitializeOrganizationEditor
} from '../../../store/organization/editor/actions';
//interfaces & models
import { 
    AAOrganization, IAAOrganization, IAARole
} from '@algo/network-manager/models/v3/admin';
import { IATLegacyDatasource } from "@algo/network-manager/models/v3";
import { 
    UserNetworkManager, IUserNetworkManager, IGetAllUsers, IProcessedResponse 
} from "@algo/network-manager/managers/v3";
//components
import AsyncSelect from 'react-select/async';
import reactSelectStyles from '../../../utils/reactSelectStyles';
import Content from '../../base/layout/Content';
import Loading from '../Loading';
import UserTableView from '../user/UserTableView';
import { 
    ReactSelectNumberKeyListItem, ReactSelectStringKeyListItem 
} from '../../../models/ReactSelectListItems';
//constants
import { isNewMode, ORG_CONS } from '../../../utils/AppConstants';
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../../store/api-version-constants";

declare var __API_URL__: string;
const apiUrlUser: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).users}`;

interface StateProps { 
    editor: OrganizationEditorState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        editor: state.organizationEditor
    };
};

interface DispatchProps {
    initialize: typeof initializeOrganizationEditor;
    save: typeof saveOrganization;
    addUser: typeof addUserToOrganization;
    removeUser: typeof removeUserFromOrganization;
    uninitialize: typeof uninitializeOrganizationEditor;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeOrganizationEditor,
        save: saveOrganization,
        addUser: addUserToOrganization,
        removeUser: removeUserFromOrganization,
        uninitialize: uninitializeOrganizationEditor
    }, dispatch);
};

type OrganizationEditorPageProps = StateProps & DispatchProps & RouteComponentProps<any>;

interface OrganizationEditorPageState {
    organizationId: number;
    organizationForm: IAAOrganization;
    selectedOrganizationRoles: ReactSelectNumberKeyListItem[];
    selectedOrganizationDatasources: ReactSelectNumberKeyListItem[];
    selectedOrganizationParent: ReactSelectNumberKeyListItem[];
    selectedOrganizationChild: ReactSelectNumberKeyListItem[];
    selectedOrganizationUsers: ReactSelectStringKeyListItem[];
    initialized: boolean;
}

class OrganizationEditor extends React.Component<OrganizationEditorPageProps, OrganizationEditorPageState> {
    constructor(props: Readonly<OrganizationEditorPageProps>) {
        super(props);

        this.state = {
            organizationId: 
                (this.props.match.params.organizationId) 
                    ? (this.props.match.params.organizationId as number) 
                    : ORG_CONS.id,
            organizationForm: new AAOrganization(),
            selectedOrganizationRoles: [],
            selectedOrganizationDatasources: [],
            selectedOrganizationParent: [],
            selectedOrganizationChild: [],
            selectedOrganizationUsers: [],
            initialized: !props.match.params.organizationId
        } as OrganizationEditorPageState;
    }

    componentDidMount(){
        this.props.initialize(this.state.organizationId);
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (
                ( prevProps.editor.savingOrganization !== this.props.editor.savingOrganization ) && 
                !this.props.editor.savingOrganization && 
                ( this.props.editor.error === null )
        ) {
            if (this.props.location.pathname === "/organization/new") {
                this.props.history.replace(`/organization/${this.props.editor.organization.organizationId}`);
            }
        }

        if (this.props.editor.organizationLoaded && !this.state.initialized && !this.props.editor.savingOrganization) {
            this.setState((state, props) => {
                let orgRoles: ReactSelectNumberKeyListItem[] = props.editor.roleList
                .filter(
                    (role: IAARole) => {
                        return props.editor.organization.roleId?.includes(role.id);
                    }
                )
                .map((role: IAARole) => {
                    return {
                        value: role.id,
                        label: role.description
                    } as ReactSelectNumberKeyListItem;
                });

                let orgDatasources: ReactSelectNumberKeyListItem[] = props.editor.datasourceList
                .filter((datasource: IATLegacyDatasource) => {
                    return props?.editor.organization.datasources?.includes(datasource.id);
                })
                .map((datasource: IATLegacyDatasource) => {
                    return {
                        value: datasource.id,
                        label: datasource.displayName
                    } as ReactSelectNumberKeyListItem;
                });

                let orgParents: ReactSelectNumberKeyListItem[] = props.editor.organizationList
                .filter((organization: IAAOrganization) => {
                    return props.editor.organization.parentOrganizationId?.includes(organization.organizationId);
                })
                .map((organization: IAAOrganization) => {
                    return {
                        value: organization.organizationId,
                        label: organization.name
                    } as ReactSelectNumberKeyListItem;
                });

                let orgChildren: ReactSelectNumberKeyListItem[] = props.editor.organizationList
                .filter((organization: IAAOrganization) => {
                    return props.editor.organization.childrenOrganizationId?.includes(organization.organizationId);
                })
                .map((organization: IAAOrganization) => {
                    return {
                        value: organization.organizationId,
                        label: organization.name
                    } as ReactSelectNumberKeyListItem;
                });

                return {
                    ...state,
                    initialized: true,
                    organizationId: 
                        ( props.editor.organization.organizationId !== ORG_CONS.id ) 
                            ? props.editor.organization.organizationId 
                            : ORG_CONS.id,
                    organizationForm: props.editor.organization,
                    selectedOrganizationParent: orgParents,
                    selectedOrganizationChild: orgChildren,
                    selectedOrganizationRoles: orgRoles,
                    selectedOrganizationDatasources: orgDatasources
                };
            });
        }
    }

    componentWillUnmount() {
        this.props.uninitialize();
    }

    setReactSelectFormItemValue = (property: string, event: any): void => {
        var orgForm = this.state.organizationForm;

        switch (property) {
            case ORG_CONS.parent:
                let parentOrgValues = event as ReactSelectNumberKeyListItem[];

                if (parentOrgValues !== null) {
                    orgForm.parentOrganizationId = parentOrgValues.map(item => { return item.value; });
                }

                this.setState((state, props) => {
                    return {
                        ...state,
                        organizationForm: orgForm,
                        selectedOrganizationParent: parentOrgValues
                    }
                });
                break;
            case ORG_CONS.child:
                let childOrgValues = event as ReactSelectNumberKeyListItem[];

                if (childOrgValues !== null) {
                    orgForm.childrenOrganizationId = childOrgValues.map(item => { return item.value; });
                }

                this.setState((state, props) => {
                    return {
                        ...state,
                        organizationForm: orgForm,
                        selectedOrganizationChild: childOrgValues
                    };
                });
                break;
            case ORG_CONS.roles:
                let orgRoleValues = event as ReactSelectNumberKeyListItem[];
                
                if (orgRoleValues !== null) {
                    orgForm.roleId = orgRoleValues.map(item => { return item.value; });
                }

                this.setState((state, props) => {
                    return {
                        ...state,
                        organizationForm: orgForm,
                        selectedOrganizationRoles: orgRoleValues
                    };
                });
                break;
            case ORG_CONS.datasources:
                let orgDatasourceValues = event as ReactSelectNumberKeyListItem[];

                if (orgDatasourceValues !== null) {
                    orgForm.datasources = orgDatasourceValues.map(item => { return item.value; });
                }
                this.setState((state, props) => {
                    return {
                        ...state,
                        organizationForm: orgForm,
                        selectedOrganizationDatasources: orgDatasourceValues
                    };
                });
                break;
            case ORG_CONS.addUser:
                let selectedOrgUserValues = event as ReactSelectStringKeyListItem[];

                this.setState((state, props) => {
                    return {
                        ...state,
                        selectedOrganizationUsers: selectedOrgUserValues
                    };
                });
                break;
            default:
                break;
        }
    }

    setFormItemValue = (
        property: string, 
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ): void => {

        event.preventDefault();

        let currentValue = event.currentTarget.value;
        var orgForm = this.state.organizationForm;

        switch (property) {
            case ORG_CONS.name:
                orgForm.name = currentValue;

                this.setState((state, props) => {
                    return {
                        ...state,
                        organizationForm: orgForm
                    };
                });
                break;
            case ORG_CONS.description:
                orgForm.description = currentValue;

                this.setState((state, props) => {
                    return {
                        ...state,
                        organizationForm: orgForm
                    };
                });
                break;
            default:
                break;
        }
    }

    submit = (event?: React.FormEvent<HTMLFormElement>): void => {
        if (event) {
            event.preventDefault();
        }

        this.props.save(this.state.organizationForm);
    }

    fetchUserSearch = (inputValue: string): Promise<ReactSelectStringKeyListItem[]> => {
        return new Promise<ReactSelectStringKeyListItem[]>((resolve) => {
            getAccessToken()
                .then(
                    (token: string) => {

                        let manager: IUserNetworkManager = 
                            new UserNetworkManager(apiUrlUser);

                        manager.setAccessToken(token);

                        let getAllUsers: IGetAllUsers = { search: inputValue };

                        manager.getAllUsers(getAllUsers)
                            .then((response: IProcessedResponse) => {
                                let userOptions = response.data
                                    .map((user: any) => {
                                        return {
                                            value: user.sub,
                                            label: user.userName
                                        };
                                    });

                                resolve(userOptions);
                            }, (err: any) => {
                                resolve(err)
                            })
                            .catch((err: any) => {
                                resolve(err);
                            });
                    }
                )                
        });
    }

    fetchOrganizationSearch = (inputValue: string): Promise<ReactSelectNumberKeyListItem[]> => {
        let { editor } = this.props;

        return new Promise<ReactSelectNumberKeyListItem[]>((resolve) => {
            try {
                let orgs = editor.organizationList
                .filter(org => {
                    return (
                        org.name?.toUpperCase().includes(inputValue.toUpperCase()) || 
                        org.description?.toUpperCase().includes(inputValue.toUpperCase())
                    );
                })
                .map(org => {
                    return {
                        value: org.organizationId,
                        label: org.name || ""
                    };
                });
                resolve(orgs);
            }
            catch (err: any) {
                resolve(err);
            }
        })
    }

    fetchRoleSearch = (inputValue: string): Promise<ReactSelectNumberKeyListItem[]> => {
        let { editor } = this.props;

        return new Promise<ReactSelectNumberKeyListItem[]>((resolve) => {
            try {
                let roles = editor.roleList
                .filter(role => {
                    return role.name?.toUpperCase().includes(inputValue.toUpperCase());
                })
                .map(role => {
                    return {
                        value: role.id,
                        label: role.description || ""
                    };
                });

                resolve(roles);
            }
            catch (err: any) {
                resolve(err);
            }
        });
    }

    fetchDatasourceSearch = (inputValue: string): Promise<ReactSelectNumberKeyListItem[]> => {
        let { editor } = this.props;
        const datasources: Array<IATLegacyDatasource> = editor.datasourceList;

        return new Promise<ReactSelectNumberKeyListItem[]>((resolve) => {
            try {
                //filter the list based on the current user search value
                let filteredDatasources =
                    (inputValue === "")
                        ? datasources
                        : datasources.filter(datasource => {
                            return (
                                datasource.displayName.toUpperCase()
                                    .includes(inputValue.toUpperCase())
                            );
                        });
                //create a list of select option objects
                let selectionOptions = filteredDatasources.map(datasource => {
                    return {
                        value: datasource.id,
                        label: datasource.displayName
                    };
                });

                resolve(selectionOptions);
            }
            catch (err: any) {
                resolve(err);
            }
        });
    }

    assignUsers = (): void => {
        if (this.state.selectedOrganizationUsers.length > 0) {
            let subs = this.state.selectedOrganizationUsers.map(item => {
                return item.value;
            });

            this.props.addUser(this.state.organizationId, subs);

            this.setState({
                selectedOrganizationUsers: []
            });
        }
    }

    unassignUser = (sub: string): void => {
        this.props.removeUser(this.state.organizationId, sub);
    }

    back = (): void => {
        this.props.history.goBack();
    }

    render() {
        let { editor } = this.props;
        let addUserSpanClass: string = 
            `add-icon ${editor.addingUser ? "saving": ""}`;

        let organizationUserView = this.state.organizationId !== ORG_CONS.id ? (
            <div className='av-admin-form'>
                <h2>Organization Membership</h2>
                <div className='av-add-org-user'>
                    <div>
                        <AsyncSelect
                            id={`ddlAddUsers`}
                            className={`av-selector-container`}
                            classNamePrefix={`av-selector`}
                            styles={reactSelectStyles}
                            placeholder={`Find User by name, username, email`}
                            isMulti
                            cacheOptions
                            menuPlacement={`auto`}
                            loadOptions={this.fetchUserSearch}
                            value={this.state.selectedOrganizationUsers}
                            onChange={(event) => 
                                this.setReactSelectFormItemValue(ORG_CONS.addUser, event)}
                        />
                    </div>
                    <div>
                        <button
                            className='btn btn-block btn-outline-primary'
                            onClick={this.assignUsers}
                            disabled={
                                this.state.selectedOrganizationUsers.length === 0 || 
                                editor.addingUser
                            }
                        >
                            <span className={addUserSpanClass}></span>
                             Add Users
                        </button>
                    </div>
                </div>
                <UserTableView
                    userList={editor.organizationUserList}
                    pageSize={5}
                    actionCallback={this.unassignUser}
                    showSearch={false}
                />
            </div>
        ) : (null);

        let content = (!editor.initializing) ? (
            <div className='av-admin-editor-content'>
                <h1 className='sr-only'>Organization Form</h1>
                <div className='av-organization-editor'>
                    <div className='av-feedback-detail-section titlebar'>
                        <div className='av-back-action'>
                            <div className='col-2'>
                                <button 
                                    className='btn btn-outline-primary' 
                                    onClick={this.back}>
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                            </div>
                            <div className='av-toolbar-title'>
                                <h1>
                                    {(isNewMode(
                                        this.state.organizationForm.organizationId, 
                                        ORG_CONS.id
                                        )) 
                                            ? `New Organization` 
                                            : `Edit Organization`}
                                </h1>
                            </div>
                            <div className='col-2 text-right'>

                            </div>
                        </div>
                    </div>
                    <div className='av-admin-form'>
                        <form onSubmit={this.submit}>
                            <h2>Organization Information</h2>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='txtName' 
                                    className='av-admin-editor-form-label'
                                >
                                    Organization Name
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <input
                                        id='txtName'
                                        type='text'
                                        className='form-control'
                                        placeholder='Organization'
                                        value={this.state.organizationForm.name}
                                        onChange={(event) => 
                                            this.setFormItemValue(ORG_CONS.name, event)}
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='txtDescription' 
                                    className='av-admin-editor-form-label'
                                >
                                    Description
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <input
                                        id='txtDescription'
                                        type='text'
                                        className='form-control'
                                        placeholder='Description'
                                        value={this.state.organizationForm.description}
                                        onChange={(event) => 
                                            this.setFormItemValue(ORG_CONS.description, event)}
                                    />
                                </div>
                            </div>
                            <h2>Organization Settings</h2>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='ddlParentOrganizations' 
                                    className='av-admin-editor-form-label'
                                >
                                    Parents
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <AsyncSelect
                                        id={`ddlParentOrganizations`}
                                        className={`av-selector-container`}
                                        classNamePrefix={`av-selector`}
                                        styles={reactSelectStyles}
                                        placeholder={`Parent Organizations`}
                                        isMulti
                                        cacheOptions
                                        defaultOptions
                                        isDisabled={this.state.organizationId !== ORG_CONS.id} 
                                        menuPlacement={`auto`}
                                        loadOptions={this.fetchOrganizationSearch}
                                        value={this.state.selectedOrganizationParent}
                                        onChange={(event) => this.setReactSelectFormItemValue(ORG_CONS.parent, event)}
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label htmlFor='ddlParentOrganizations' className='av-admin-editor-form-label'>Children</label>
                                <div className='av-admin-editor-form-item'>
                                    <AsyncSelect
                                        id={`ddlChildrenOrganizations`}
                                        className={`av-selector-container`}
                                        classNamePrefix={`av-selector`}
                                        styles={reactSelectStyles}
                                        placeholder={`Children Organizations`}
                                        isMulti
                                        cacheOptions
                                        defaultOptions
                                        menuPlacement={`auto`}
                                        loadOptions={this.fetchOrganizationSearch}
                                        value={this.state.selectedOrganizationChild}
                                        onChange={(event) => this.setReactSelectFormItemValue(ORG_CONS.child, event)}
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label htmlFor='ddlRoles' className='av-admin-editor-form-label'>Roles</label>
                                <div className='av-admin-editor-form-item'>
                                    <AsyncSelect
                                        id={`ddlRoles`}
                                        className={`av-selector-container`}
                                        classNamePrefix={`av-selector`}
                                        styles={reactSelectStyles}
                                        placeholder={`Organization Roles`}
                                        isMulti
                                        cacheOptions
                                        defaultOptions
                                        menuPlacement={`auto`}
                                        loadOptions={this.fetchRoleSearch}
                                        value={this.state.selectedOrganizationRoles}
                                        onChange={(event) => this.setReactSelectFormItemValue(ORG_CONS.roles, event)}
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label htmlFor='ddlDatasources' className='av-admin-editor-form-label'>Datasources</label>
                                <div className='av-admin-editor-form-item'>
                                    <AsyncSelect
                                        id={`ddlDatasources`}
                                        className={`av-selector-container`}
                                        classNamePrefix={`av-selector`}
                                        styles={reactSelectStyles}
                                        placeholder={`Organization Datasources`}
                                        isMulti
                                        cacheOptions
                                        defaultOptions
                                        menuPlacement={`auto`}
                                        loadOptions={this.fetchDatasourceSearch}
                                        value={this.state.selectedOrganizationDatasources}
                                        onChange={(event) => this.setReactSelectFormItemValue(ORG_CONS.datasources, event)}
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <button
                                    type={`submit`}
                                    className='btn btn-block btn-outline-primary'
                                    disabled={editor.savingOrganization}
                                >
                                    <span className={`save-icon ${editor.savingOrganization  ? 'saving' : ''}`}></span> Save
                                </button>
                            </div>
                        </form>
                    </div>
                    {organizationUserView}
                </div>
            </div>
        ) : (<Loading />);

        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        {content}
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(OrganizationEditor)); 