import { IAAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";

export let LOAD_PROFILE_BEGIN = 'FETCH_PROFILE_BEGIN';
export let LOAD_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export let LOAD_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';
export let UNLOAD_PROFILE = 'UNLOAD_PROFILE';
export let CLEAR_PROFILE_ERROR = 'CLEAR_PROFILE_ERROR';

export interface ProfileState {
    userProfile: IAAUserAuthorizationProfile;
    loading: boolean;
    initialized: boolean;
    error: Error | null;
}

export interface ProfilePayload {
    userProfile: IAAUserAuthorizationProfile;
    error: Error;
}

interface FetchProfileBeginAction {
    type: typeof LOAD_PROFILE_BEGIN;
    payload: ProfilePayload;
}

interface FetchProfileSuccessAction {
    type: typeof LOAD_PROFILE_SUCCESS;
    payload: ProfilePayload;
}

interface FetchProfileFailureAction {
    type: typeof LOAD_PROFILE_FAILURE;
    payload: ProfilePayload;
}

interface UnloadProfileAction {
    type: typeof UNLOAD_PROFILE;
    payload: ProfilePayload;
}

interface ClearProfileErrorAction {
    type: typeof CLEAR_PROFILE_ERROR;
    payload: ProfilePayload;
}

export type ProfileTypes = 
    FetchProfileBeginAction | 
    FetchProfileSuccessAction | 
    FetchProfileFailureAction | 
    UnloadProfileAction | 
    ClearProfileErrorAction;