// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledVisitDashboardRow = styled.div`
    margin-bottom: 10px;
`;

export const CopyBtnWrapper = styled.div`
    height: 41px;
    width: 10%;
    
    ${flexSmart("center center")}
`;