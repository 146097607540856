// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";

export type IFullPageModal = {
    showCloseButton?: boolean;
    closeCallback?: () => any;
} & React.HTMLAttributes<HTMLElement>;

const iconStyle = {
    height: "100%",
    display: "flex",
    alignItems: "center"
}

export const FullPageModal: React.FC<IFullPageModal> = (props) => {

    return (
        <SC.StyledFullPageModal>

            { props.showCloseButton &&
                <SC.CloseButtonWrapper>
                    <SC.CloseButton 
                        onClick={() => props.closeCallback && props.closeCallback()}
                    >
                        <i className="fas fa-times" style={iconStyle} />
                    </SC.CloseButton>
                </SC.CloseButtonWrapper>
            }

            {props.children}

        </SC.StyledFullPageModal>
    );
};

export default FullPageModal;