// libraries
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../../index';
import { Action } from "redux";
import { getAccessToken } from '../../../components/template/authentication/oidcConfig';
import { 
    AldotMessageNetworkManager, IAldotMessageNetworkManager, 
    IProcessedResponse, 
    ProcessedResponse
} from "@algo/network-manager/managers/v3";
// types
import * as TYPES from "./types";
// models & interfaces
import { 
    ATAldotMessage, IATAldotMessage
} from "@algo/network-manager/models/v3";
//constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS 
} from "../../api-version-constants";

declare var __API_URL__: string;

const apiUrlMessage: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).aldotMessages}`;

function initializeMessageDashboardBegin(): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.INITIALIZE_MESSAGE_DASHBOARD_BEGIN,
        payload: {} as TYPES.MessageDashboardPayload
    };
}

/*
Initialize Message Dashboards
*/

function initializeMessageDashboardSuccess(
    response: IProcessedResponse
): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.INITIALIZE_MESSAGE_DASHBOARD_SUCCESS,
        payload: {
            messages: response.data,
            response
        } as TYPES.MessageDashboardPayload
    };
}

function initializeMessageDashboardFailure(
    error: Error
): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.INITIALIZE_MESSAGE_DASHBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.MessageDashboardPayload
    };
}

export let initializeMessageDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (
        !getState().messageDashboard.initializing || 
        !getState().messageDashboard.loadingMessages
    ) {
        dispatch(initializeMessageDashboardBegin());

        let manager: IAldotMessageNetworkManager = 
            new AldotMessageNetworkManager(apiUrlMessage);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll(
                        getState().messageDashboard.lastResponse, 
                        {showExpired: true}
                    )
                        .then(
                            (response: IProcessedResponse) => 
                                dispatch(initializeMessageDashboardSuccess(response))
                        ).catch(
                            (err: Error) =>
                                dispatch(initializeMessageDashboardFailure(err))
                        )
                }
            ).catch(
                (err: Error) => 
                    dispatch(initializeMessageDashboardFailure(err))
            )  
    }
}

/*
Load Messages
*/

function loadMessagesBegin(): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.LOAD_MESSAGES_BEGIN,
        payload: {} as TYPES.MessageDashboardPayload
    };
}

function loadMessagesSuccess(response: IProcessedResponse): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.LOAD_MESSAGES_SUCCESS,
        payload: {
            messages: response.data,
            response
        } as TYPES.MessageDashboardPayload
    };
}

function loadMessagesFailure(error: Error): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.LOAD_MESSAGES_FAILURE,
        payload: {
            error: error
        } as TYPES.MessageDashboardPayload
    };
}

export let loadMessages = (
    search: string = ""
): ThunkAction<void, AppState, null, Action<string>> => async(dispatch, getState) => {
    if(!getState().messageDashboard.initializing || !getState().messageDashboard.loadingMessages) {
        dispatch(loadMessagesBegin());

        let manager: IAldotMessageNetworkManager = 
            new AldotMessageNetworkManager(apiUrlMessage);
            
        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll(
                        getState().messageDashboard.lastResponse, 
                        {search}
                    )
                        .then(
                            (response: IProcessedResponse) => 
                                dispatch(loadMessagesSuccess(response))
                        ).catch(
                            (err: Error) => 
                                dispatch(loadMessagesFailure(err))
                        )
                }
            ).catch(
                (err: Error) => dispatch(loadMessagesFailure(err))
            )
    }
}

/*
Remove Message
*/

function deleteMessageBegin(): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.DELETE_MESSAGE_BEGIN,
        payload: {} as TYPES.MessageDashboardPayload
    };
}

function deleteMessageSuccess(response: IProcessedResponse): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.DELETE_MESSAGE_SUCCESS,
        payload: {
            messages: response.data
        } as TYPES.MessageDashboardPayload
    };
}

function deleteMessageFailure(error: Error): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.DELETE_MESSAGE_FAILURE,
        payload: {
            error: error
        } as TYPES.MessageDashboardPayload
    };
}

export let removeMessage = (
    id: number,
    search: string = ""
): ThunkAction<
    void, 
    AppState, 
    null, 
    Action<string>
> => async (dispatch, getState) => {

    if (!getState().messageDashboard.deletingMessage) {
        dispatch(deleteMessageBegin());

        let manager: IAldotMessageNetworkManager = 
            new AldotMessageNetworkManager(apiUrlMessage);

        getAccessToken()
            .then(
                (token) => {    
                    manager.setAccessToken(token);
                    manager.delete({id})
                        .then(() => {
                            manager.getAll(
                                new ProcessedResponse(), 
                                { search }
                            )
                                .then((response: any) => {
                                    dispatch(deleteMessageSuccess(response));
                                }, (reject: any) => {
                                    dispatch(deleteMessageFailure(new Error(reject)));
                                })
                                .catch((error: any) => {
                                    dispatch(deleteMessageFailure(new Error(error)));
                                });
                        }, (reject: any) => {
                            dispatch(deleteMessageFailure(new Error(reject)));
                        })
                        .catch((error: any) => {
                            dispatch(deleteMessageFailure(new Error(error)));
                        });
                }
            ).catch(
                (err: Error) => 
                    dispatch(deleteMessageFailure(err))
            )
    }
}

/*
Clear Message Dashboard Error
*/

function clearMessageDashboardErrorState(): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.CLEAR_MESSAGE_DASHBOARD_ERROR,
        payload: {} as TYPES.MessageDashboardPayload
    };
}

export let clearMessageDashboardError = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().messageDashboard.error !== null) {
        dispatch(clearMessageDashboardErrorState());
    }
}

/*
Uninitialize Message Dashboard
*/

function uninitializeMessageDashboardState(): TYPES.MessageDashboardTypes {
    return {
        type: TYPES.UNINITIALIZE_MESSAGE_DASHBOARD,
        payload: {} as TYPES.MessageDashboardPayload
    };
}

export let uninitializeMessageDashboard = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeMessageDashboardState());
}