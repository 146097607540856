// interfaces & models
import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { 
    EATRegion, EATStreamAccessLevel, IATCameraDevice 
} from "@algo/network-manager/models/v3";
import { IReduxData } from "../../../../utils/ReduxData";

export const UPDATE_PAGE_STATE = "UPDATE_PAGE_STATE";
export const UPDATE_ACCESS_LEVELS_BEGIN = "UPDATE_ACCESS_LEVELS_BEGIN";
export const UPDATE_ACCESS_LEVELS_SUCCESS = "UPDATE_ACCESS_LEVELS_SUCCESS";
export const UPDATE_ACCESS_LEVELS_FAILURE = "UPDATE_ACCESS_LEVELS_FAILURE";
export const SELECT_ALL_BEGIN = "SELECT_ALL_BEGIN";
export const SELECT_ALL_SUCCESS = "SELECT_ALL_SUCCESS";
export const SELECT_ALL_FAILURE = "SELECT_ALL_FAILURE";
export const SELECT_ALL_DESELECT = "SELECT_ALL_DESELECT";

export interface State {
    accessLevelData: IReduxData;
    accessChange: boolean;
    selectingAll: boolean;
    selectedStreams: Array<IATCameraDevice>;
    showAllSelectedStreams: boolean;
    pageCount: number;
    pageIndex: number;
    editMode: boolean;
    selectedRegion?: EATRegion;
    selectedAccessLevel: EATStreamAccessLevel;
    selectedRegionIndex: number;
    search: string;
    showUpdateModal: boolean;
    showDeleteModal: boolean;
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface Payload {
    pageState: State,
    showAllSelectedStreams: boolean,
    selectedStreams: Array<IATCameraDevice> | [],
    error: Error | null;
    response: IProcessedResponse;
}

/************************************************
    UPDATE PAGE STATE | ACTION TYPES
 ***********************************************/
interface UpdatePageStateAction {
    type: typeof UPDATE_PAGE_STATE;
    payload: Payload;
}

/************************************************
    UPDATE ACCESS LEVELS | ACTION TYPES
 ***********************************************/
interface UpdateAccessLevelsBeginAction {
    type: typeof UPDATE_ACCESS_LEVELS_BEGIN;
    payload: Payload;
}

interface UpdateAccessLevelsSuccessAction {
    type: typeof UPDATE_ACCESS_LEVELS_SUCCESS;
    payload: Payload;
}

interface UpdateAccessLevelsFailureAction {
    type: typeof UPDATE_ACCESS_LEVELS_FAILURE;
    payload: Payload;
}

/************************************************
    SELECT ALL | ACTION TYPES
 ***********************************************/
interface SelectAllBeginAction {
    type: typeof SELECT_ALL_BEGIN;
    payload: Payload;
}

interface SelectAllSuccessAction {
    type: typeof SELECT_ALL_SUCCESS;
    payload: Payload;
}

interface SelectAllDeselectAction {
    type: typeof SELECT_ALL_DESELECT;
    payload: Payload;
}

interface SelectAllFailureAction {
    type: typeof SELECT_ALL_FAILURE;
    payload: Payload;
}

export type Actions =
    UpdateAccessLevelsBeginAction |
    UpdateAccessLevelsSuccessAction |
    UpdateAccessLevelsFailureAction |
    UpdatePageStateAction |
    SelectAllBeginAction |
    SelectAllSuccessAction |
    SelectAllDeselectAction |
    SelectAllFailureAction;

