import { IAAOrganization } from '@algo/network-manager/models/v3/admin';
import { CASUserProfile } from "@algo/network-manager/models/v3/cas";

export let INITIALIZE_USER_DASHBOARD_BEGIN = 'INITIALIZE_USER_DASHBOARD_BEGIN';
export let INITIALIZE_USER_DASHBOARD_SUCCESS = 'INITIALIZE_USER_DASHBOARD_SUCCESS';
export let INITIALIZE_USER_DASHBOARD_FAILURE = 'INITIALIZE_USER_DASHBOARD_FAILURE';

export let LOAD_USERS_BEGIN = 'LOAD_USERS_BEGIN';
export let LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export let LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';

export let DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export let DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export let DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export let CLEAR_USER_DASHBOARD_ERROR = 'CLEAR_USER_DASHBOARD_ERROR';

export let CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';

export let UNINITIALIZE_USER_DASHBOARD = 'UNINITIALIZE_USER_DASHBOARD';

export interface UserDashboardState {
    initializing: boolean;
    loadingUsers: boolean;
    deletingUser: boolean;
    organizations: IAAOrganization[];
    users: CASUserProfile[];
    error: Error | null;
}

export interface UserDashboardPayload {
    organizations: IAAOrganization[];
    users: CASUserProfile[];
    error: Error | null;
}

interface InitializeUserDashboardBeginAction {
    type: typeof INITIALIZE_USER_DASHBOARD_BEGIN;
    payload: UserDashboardPayload;
}

interface InitializeUserDashboardSuccessAction {
    type: typeof INITIALIZE_USER_DASHBOARD_SUCCESS;
    payload: UserDashboardPayload;
}

interface InitializeUSerDashboardFailureAction {
    type: typeof INITIALIZE_USER_DASHBOARD_FAILURE;
    payload: UserDashboardPayload;
}

interface LoadUsersBeginAction {
    type: typeof LOAD_USERS_BEGIN;
    payload: UserDashboardPayload;
}

interface LoadUsersSuccessAction {
    type: typeof LOAD_USERS_SUCCESS;
    payload: UserDashboardPayload;
}

interface LoadUsersFailureAction {
    type: typeof LOAD_USERS_FAILURE;
    payload: UserDashboardPayload;
}

interface DeleteUserBeginAction {
    type: typeof DELETE_USER_BEGIN;
    payload: UserDashboardPayload;
}

interface DeleteUserSuccessAction {
    type: typeof DELETE_USER_SUCCESS;
    payload: UserDashboardPayload;
}

interface DeleteUserFailureAction {
    type: typeof DELETE_USER_FAILURE;
    payload: UserDashboardPayload;
}

interface ClearUserDashboardErrorAction {
    type: typeof CLEAR_USER_DASHBOARD_ERROR;
    payload: UserDashboardPayload;
}

interface UninitializeUserDashboardAction {
    type: typeof UNINITIALIZE_USER_DASHBOARD;
    payload: UserDashboardPayload;
}

export type UserDashboardTypes = 
    InitializeUserDashboardBeginAction | 
    InitializeUserDashboardSuccessAction | 
    InitializeUSerDashboardFailureAction |
    LoadUsersBeginAction | 
    LoadUsersSuccessAction | 
    LoadUsersFailureAction | 
    DeleteUserBeginAction | 
    DeleteUserSuccessAction | 
    DeleteUserFailureAction |
    ClearUserDashboardErrorAction | 
    UninitializeUserDashboardAction;
