import { OidcClientSettings, User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { useEffect } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";

declare var __AUTHORITY_URL__: string;
declare var __CLIENT_URL__: string;

export const oidcConfig: any = {
    client_id: 'algoadmin',
    redirect_uri: `${__CLIENT_URL__}/callback`,
    response_type: 'code',
    scope: 'openid profile offline_access algoadmin_api',
    authority: __AUTHORITY_URL__,
    post_logout_redirect_uri: `${__CLIENT_URL__}/session_ended`,
    silent_redirect_uri: `${__CLIENT_URL__}/silent_renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    // this function removes the payload from the successful login URL
    onSigninCallback: (user: User | void) => {
        let path = (user && user.state) ? (user as any).state.path : '/';
        window.location.replace(path);
    },
    userStore: new WebStorageStateStore({ store: localStorage})
};

export const userManager = new UserManager(oidcConfig);

export function getAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        userManager.getUser()
            .then((user: any) => {
                if (user) {
                    resolve(user.access_token);
                }
                else {
                    reject('Access denied due to no user credential');
                }
            }, (reason: any) => {
                reject(reason);
            })
            .catch((error: any) => {
                throw error;
            });
    });
}