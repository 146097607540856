import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { 
    IAARole, IAAPrivilege, IAAOrganization 
} from "@algo/network-manager/models/v3/admin";

export let LOAD_ROLE_BEGIN = 'LOAD_ROLE_BEGIN';
export let LOAD_ROLE_SUCCESS = 'LOAD_ROLE_SUCCESS';
export let LOAD_ROLE_FAILURE = 'LOAD_ROLE_FAILURE';

export let SAVE_ROLE_BEGIN = 'SAVE_ROLE_BEGIN';
export let SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS';
export let SAVE_ROLE_FAILURE = 'SAVE_ROLE_FAILURE';

export let CLEAR_ROLE_EDITOR_ERROR = 'CLEAR_ROLE_EDITOR_ERROR';

export let UNINITIALIZE_ROLE_EDITOR = 'UNINITIALIZE_ROLE_EDITOR';

export interface RoleEditorState {
    initializing: boolean;
    roleLoaded: boolean;
    savingRole: boolean;
    role: IAARole;
    privilegeList: IAAPrivilege[];
    organizationList: IAAOrganization[];
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface RoleEditorPayload {
    role: IAARole;
    privilegeList: IAAPrivilege[];
    organizationList: IAAOrganization[];
    error: Error | null;
    response: IProcessedResponse;
}

interface LoadRoleBeginAction {
    type: typeof LOAD_ROLE_BEGIN;
    payload: RoleEditorPayload;
}

interface LoadRoleSuccessAction {
    type: typeof LOAD_ROLE_SUCCESS;
    payload: RoleEditorPayload;
}

interface LoadRoleFailureAction {
    type: typeof LOAD_ROLE_FAILURE;
    payload: RoleEditorPayload;
}

interface SaveRoleBeginAction {
    type: typeof SAVE_ROLE_BEGIN;
    payload: RoleEditorPayload;
}

interface SaveRoleSuccessAction {
    type: typeof SAVE_ROLE_SUCCESS;
    payload: RoleEditorPayload;
}

interface SaveRoleFailureAction {
    type: typeof SAVE_ROLE_FAILURE;
    payload: RoleEditorPayload;
}

interface ClearRoleEditorErrorAction {
    type: typeof CLEAR_ROLE_EDITOR_ERROR;
    payload: RoleEditorPayload;
}

interface UninitializeRoleEditorAction {
    type: typeof UNINITIALIZE_ROLE_EDITOR;
    payload: RoleEditorPayload;
}

export type RoleEditorTypes = 
    LoadRoleBeginAction | 
    LoadRoleSuccessAction | 
    LoadRoleFailureAction | 
    SaveRoleBeginAction | 
    SaveRoleSuccessAction | 
    SaveRoleFailureAction | 
    ClearRoleEditorErrorAction | 
    UninitializeRoleEditorAction;