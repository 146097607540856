// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
// store
import { AppState } from "../../../store";
// components
import Content from '../../base/layout/Content';
import MessageTableView from './MessageTableView';

interface StateProps { 
}

let mapStateToProps = (state: AppState) => {
    return { 
    };
};

type MessageListProps = StateProps & RouteComponentProps<any>;

class MessageList extends React.Component<MessageListProps> {

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <div className='av-admin-dashboard-content fluid'>
                            <div className='av-feedback-dashboard'>
                                <MessageTableView />
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
)(withRouter(MessageList));