import { AAUserForm } from "@algo/network-manager/models/v3/admin";
import { 
    CASComplexityRequirement, CASPasswordValidation 
} from "@algo/network-manager/models/v3/cas";
import  * as TYPES from "./types";

let initialState: TYPES.UserEditorState = {
    initializing: false,
    userLoaded: false,
    savingUser: false,
    validatingPassword: false,
    changingPassword: false,
    changePasswordSuccess: false,
    sendingResetPasswordEmail: false,
    sendResetPasswordEmailSuccess: false,
    userForm: new AAUserForm(),
    roleList: [],
    organizationList: [],
    complexityRequirements: new CASComplexityRequirement(),
    passwordValidation: new CASPasswordValidation(),
    error: null
}

export function userEditorReducer(
    state = initialState, 
    action: TYPES.UserEditorTypes
): TYPES.UserEditorState {
    
    switch (action.type) {
        case TYPES.LOAD_USER_EDITOR_BEGIN:
            return {
                ...state,
                initializing: true
            };
        case TYPES.LOAD_USER_EDITOR_SUCCESS:
            return {
                ...state,
                initializing: false,
                userLoaded: true,
                userForm: action.payload.userForm,
                roleList: action.payload.roleList,
                organizationList: action.payload.organizationList,
                complexityRequirements: action.payload.complexityRequirements
            };
        case TYPES.LOAD_USER_EDITOR_FAILURE:
            return {
                ...state,
                initializing: false,
                error: action.payload.error
            };
        case TYPES.SAVE_USER_BEGIN:
            return {
                ...state,
                savingUser: true
            };
        case TYPES.SAVE_USER_SUCCESS:
            return {
                ...state,
                savingUser: false,
                userForm: action.payload.userForm
            };
        case TYPES.SAVE_USER_FAILURE:
            return {
                ...state,
                savingUser: false,
                error: action.payload.error
            };
        case TYPES.CHANGE_PASSWORD_BEGIN:
            return {
                ...state,
                changingPassword: true,
                changePasswordSuccess: false
            };
        case TYPES.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword: false,
                changePasswordSuccess: true
            };
        case TYPES.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changingPassword: false,
                changePasswordSuccess: false,
                error: action.payload.error
            };
        case TYPES.CHANGE_PASSWORD_CLEAR:
            return {
                ...state,
                changingPassword: false,
                changePasswordSuccess: false
            };
        case TYPES.VALIDATE_PASSWORD_BEGIN:
            return {
                ...state,
                validatingPassword: true
            };
        case TYPES.VALIDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                validatingPassword: false,
                passwordValidation: action.payload.passwordValidation
            };
        case TYPES.VALIDATE_PASSWORD_FAILURE:
            return {
                ...state,
                validatingPassword: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_USER_EDITOR_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.RESET_PASSWORD_EMAIL_BEGIN:
            return {
                ...state,
                sendingResetPasswordEmail: true
            };
        case TYPES.RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                sendingResetPasswordEmail: false,
                sendResetPasswordEmailSuccess: true
            };
        case TYPES.RESET_PASSWORD_EMAIL_FAILURE:
            return {
                ...state,
                sendingResetPasswordEmail: false,
                error: action.payload.error
            };
        case TYPES.RESET_PASSWORD_EMAIL_CLEAR:
            return {
                ...state,
                sendingResetPasswordEmail: false,
                sendResetPasswordEmailSuccess: false
            };
        case TYPES.UNINITIALIZE_USER_EDITOR:
            return initialState;
        default:
            return state;
    }
}