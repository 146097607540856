//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { Action } from "redux";
import { getAccessToken } from "../../components/template/authentication/oidcConfig";
// interfaces & models
import * as TYPES from './types';
import { IProcessedResponse, ISynchronizationNetworkManager, SynchronizationNetworkManager } from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../api-version-constants";

declare var __API_URL__: string;
const apiUrlSynchronization: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).synchronizations}`;

/*
 * Fetch Latest Synchronizations
 */

function fetchLatestSynchronizationBegin(): TYPES.SynchronizationTypes {
    return {
        type: TYPES.FETCH_LATEST_SYNCHRONIZATION_BEGIN,
        payload: {} as TYPES.SynchronizationPayload
    };
}

function fetchLatestSynchronizationSuccess(response: IProcessedResponse): TYPES.SynchronizationTypes {
    return {
        type: TYPES.FETCH_LATEST_SYNCHRONIZATION_SUCCESS,
        payload: {
            latest: response.data,
            response
        } as TYPES.SynchronizationPayload
    };
}

function fetchLatestSynchronizationFailure(error: Error): TYPES.SynchronizationTypes {
    return {
        type: TYPES.FETCH_LATEST_SYNCHRONIZATION_FAILURE,
        payload: {
            error: error
        } as TYPES.SynchronizationPayload
    };
}

export let loadLatestSynchronizations = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().synchronization.loadingLatest) {
        dispatch(fetchLatestSynchronizationBegin());

        let manager: ISynchronizationNetworkManager =
            new SynchronizationNetworkManager(apiUrlSynchronization);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getLatest({})
                        .then(
                            (response: IProcessedResponse) => 
                                dispatch(fetchLatestSynchronizationSuccess(response))
                        ).catch(
                            (err: Error) => 
                                dispatch(fetchLatestSynchronizationFailure(err))
                        )
                }
            ).catch(
                (err: Error) => dispatch(fetchLatestSynchronizationFailure(err))
            )
    }
}

/*
 * Fetch History Synchronizations
 */

function fetchHistorySynchronizationBegin(): TYPES.SynchronizationTypes {
    return {
        type: TYPES.FETCH_HISTORY_SYNCHRONIZATION_BEGIN,
        payload: {} as TYPES.SynchronizationPayload
    };
}

function fetchHistorySynchronizationSuccess(response: IProcessedResponse): TYPES.SynchronizationTypes {
    return {
        type: TYPES.FETCH_HISTORY_SYNCHRONIZATION_SUCCESS,
        payload: {
            history: response.data,
            response
        } as TYPES.SynchronizationPayload
    };
}

function fetchHistorySynchronizationNullService(): TYPES.SynchronizationTypes {
    return {
        type: TYPES.FETCH_HISTORY_SYNCHRONIZATION_NULL_SERVICE,
        payload: {
            history: [],
        } as TYPES.SynchronizationPayload
    };
}

function fetchHistorySynchronizationFailure(error: Error): TYPES.SynchronizationTypes {
    return {
        type: TYPES.FETCH_HISTORY_SYNCHRONIZATION_FAILURE,
        payload: {
            error: error
        } as TYPES.SynchronizationPayload
    };
}

export let loadSynchronizationHistory = (serviceName: string, take: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().synchronization.loadingHistory) {
        dispatch(fetchHistorySynchronizationBegin());

        if (serviceName == null) {
            dispatch(fetchHistorySynchronizationNullService());
        }

        else {
            getAccessToken()
                .then(
                    (token: string) => {
                        let manager: ISynchronizationNetworkManager = 
                            new SynchronizationNetworkManager(apiUrlSynchronization);

                        manager.setAccessToken(token);
                        manager.getAll({name: serviceName, take})
                            .then((response: IProcessedResponse) => {
                                dispatch(fetchHistorySynchronizationSuccess(response));
                            }, (reject: any) => {
                                dispatch(fetchHistorySynchronizationFailure(new Error(reject)));
                            })
                            .catch((error: any) => {
                                dispatch(fetchHistorySynchronizationFailure(new Error(error)));
                            });
                    }
            )
        }
    }
}

/*
 * Clear Error
 */ 

function clearSynchronizationErrorState(): TYPES.SynchronizationTypes {
    return {
        type: TYPES.CLEAR_SYNCHRONIZATION_ERROR,
        payload: {} as TYPES.SynchronizationPayload
    };
}

export let clearSynchronizationError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().synchronization.error !== null) {
        dispatch(clearSynchronizationErrorState());
    }
}

/*
 * Uninitialize State
 */ 

function uninitializeSynchronizationState(): TYPES.SynchronizationTypes {
    return {
        type: TYPES.UNINITIALIZE_SYNCHRONIZATION_STATE,
        payload: {} as TYPES.SynchronizationPayload
    };
}

export let uninitializeSynchronization = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeSynchronizationState());
}