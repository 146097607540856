// libraries
import * as React from "react";
import { newTooltipAttributes, useTooltip } from "@algo/tooltip";
// styles
import * as SC from "./Styled";
// components
import { Icon } from "@algo/icon";
import { FormButton, FormCard } from "@caps-mobile/forms";
import { useParams } from "react-router-dom";
// constants
declare var __API_URL__: string;

export type IProps = {

};

export const VisitDashboardRow: React.FC<IProps> = (props) => {

    const { facilityId } = useParams<{[key: string]: string}>();
    useTooltip($, false);

    // used to capture textarea text for clipboard action
    const streamUrlTextRef: any = React.useRef(null); 

    const dashboardUrl: string = (__API_URL__.includes("caps"))
        ? `https://algodashboard.caps.ua.edu/facility/${facilityId}`
        : `https://dashboard.algotraffic.com/facility/${facilityId}`;
    
    return (
        <SC.StyledVisitDashboardRow>

            <FormCard 
                style={{width: "100%", flexDirection: "row"}}
            >

                <FormButton 
                    style={{marginBottom: "0"}} 
                    onClick={() => {window.open(dashboardUrl);}}
                >
                    {"Visit Dashboard"}
                </FormButton>

                <SC.CopyBtnWrapper>

                    <textarea
                        hidden={true}
                        style={{ width: "0px", height: "0px" }}
                        value={dashboardUrl}
                        readOnly={true}
                        ref={streamUrlTextRef}
                        key={Math.random()}
                    ></textarea>

                    <Icon
                        size="sm" iconClass="fas fa-copy"
                        color="#828282"
                        disabled={false}
                        attributes={newTooltipAttributes("Copy stream URL to clipboard")}
                        callback={() => {
                            streamUrlTextRef.current.hidden = false;                                        // it seems you cannot copy text from hidden element, so unhide it
                            streamUrlTextRef.current.focus();                                               // focus may not be necessary
                            streamUrlTextRef.current.select();                                              // select the text
                            document.execCommand('copy');                                                   // execute the copy command
                            streamUrlTextRef.current.hidden = true;                                         // set the element back to hidden before the user ever sees it
                        }}
                        key={Math.random()}
                    />

                </SC.CopyBtnWrapper>

            </FormCard>

        </SC.StyledVisitDashboardRow>
    )
};

export default VisitDashboardRow;