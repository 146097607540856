// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from "redux";
// store
import { AppState } from '../../../store';
import { ProfileState } from '../../../store/profile/types';
import { 
    OrganizationDashboardState 
} from '../../../store/organization/dashboard/types';
import {
    initializeOrganizationDashboard,
    loadOrganizations,
    removeOrganization,
    uninitializeOrganizationDashboard
} from '../../../store/organization/dashboard/actions';
// components
import ConfirmationModal from '../../base/modals/ConfirmationModal';
import { 
    GenericTableView, GenericTableViewSettings, GenericTableViewColumnDefinition
} from '../../base/layout/GenericTableView';
// constants
import {
    DEFAULT_QUERY,
    DEFAULT_PAGE_INDEX,
    DEFAULT_PAGE_SIZE,
    DEFAULT_TOTAL_PAGES,
    DEFAULT_ORGANIZATION_ID
} from '../../../utils/AppConstants';
import { 
    DELETE_ORGANIZATION, CREATE_ORGANIZATION 
} from '../../../models/Privileges';

interface StateProps {
    profile: ProfileState;
    dashboard: OrganizationDashboardState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        profile: state.profile,
        dashboard: state.organizationDashboard
    };
};

interface DispatchProps {
    initialize: typeof initializeOrganizationDashboard;
    remove: typeof removeOrganization;
    search: typeof loadOrganizations;
    uninitialize: typeof uninitializeOrganizationDashboard;
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        initialize: initializeOrganizationDashboard,
        remove: removeOrganization,
        search: loadOrganizations,
        uninitialize: uninitializeOrganizationDashboard
    }, dispatch);
}

type OrganizationTableViewProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface OrganizationTableViewState {
    currentPageIndex: number;
    pageSize: number;
    totalPages: number;
    query: string;
    organizationId: number;
    showDeleteConfirmModal: boolean;
}

class OrganizationTableView 
    extends React.Component<
        OrganizationTableViewProps, 
        OrganizationTableViewState
    > 
{
    constructor(props: Readonly<OrganizationTableViewProps>) {
        super(props);

        this.state = {
            currentPageIndex: DEFAULT_PAGE_INDEX,
            pageSize: DEFAULT_PAGE_SIZE,
            totalPages: DEFAULT_TOTAL_PAGES,
            query: DEFAULT_QUERY,
            organizationId: DEFAULT_ORGANIZATION_ID,
            showDeleteConfirmModal: false
        } as OrganizationTableViewState;
    }

    componentDidMount() {
        this.props.initialize();

        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (
            ( this.props.dashboard.deletingOrganization !== prevProps.dashboard.deletingOrganization ) && 
            !this.props.dashboard.deletingOrganization
        ) this.applyFilter(this.state.query);
    }
    
    searchBoxChange = (query: string): void => {
        this.setState((state, props) => {
            return {
                ...state,
                query: query
            };
        });
    }

    clearFilter = (): void => {
      this.props.search(DEFAULT_QUERY);

        this.setState((state, props) => {
            return {
                ...state,
                query: DEFAULT_QUERY
            };
        });
    }

    applyFilter = (query: string, key?: number): void => {
        this.props.search(this.state.query);
    }

    newOrganization = (): void => {
        $('[data-toggle="tooltip"]').tooltip('hide');

        this.props.history.push(`/organization/new`);
    }

    viewOrganization = (id: number): void => {
        if (this.props.profile.userProfile.hasPrivilege(CREATE_ORGANIZATION)) {
            this.props.history.push(`/organization/${id}`);
        }
    }

    showDeleteModal = (id: number): void => {
        this.setState((state, props) => {
            return {
                ...state,
                organizationId: id,
                showDeleteConfirmModal: true
            };
        });
    }

    confirmDelete = (): void => {
        this.deleteOrganization(this.state.organizationId);

        this.setState((state, props) => {
            return {
                ...state,
                organizationId: DEFAULT_ORGANIZATION_ID,
                showDeleteConfirmModal: false
            };
        });
    }

    dismissDelete = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                organizationId: DEFAULT_ORGANIZATION_ID,
                showDeleteConfirmModal: false
            };
        });
    }

    deleteOrganization = (id: number): void => {
        if (!this.props.dashboard.deletingOrganization) {
            this.props.remove(id);
        }
    }

    render() {
        let { dashboard, profile } = this.props;

        let tableSettings = {
            tableClassName: 'av-organization-table',
            actionButtonClass: 'btn btn-outline-danger',
            actionButtonGlyphClass: 'delete-icon',
            columnDefinitions: [
                {
                    propertyName: 'organizationId',
                    isKey: true
                },
                {
                    propertyName: 'name',
                    displayName: 'Name',
                    isKey: false,
                    headerColumnClass: 'av-organization-description-col'
                },
                {
                    propertyName: 'description',
                    displayName: 'Description',
                    isKey: false,
                    headerColumnClass: 'av-organization-description-col'
                }
            ] as GenericTableViewColumnDefinition[],
            emptyTableMessage: 'No organizations',
            showPageSizeSelector: false,
            newItemDisabled: !profile.userProfile.hasPrivilege(CREATE_ORGANIZATION),
            actionDisabled: !profile.userProfile.hasPrivilege(DELETE_ORGANIZATION),
            rowClickDisabled: !profile.userProfile.hasPrivilege(CREATE_ORGANIZATION)
        } as GenericTableViewSettings;

        return (
            <div style={{
                height: '100%'
            }}>
                <GenericTableView
                    settings={tableSettings}
                    items={dashboard.organizations}
                    rowClickCallback={this.viewOrganization}
                    newItemCallback={this.newOrganization}
                    searchBoxChangeCallback={this.searchBoxChange}
                    clearFilterCallback={this.clearFilter}
                    applyFilterCallback={this.applyFilter}
                    isLoading={dashboard.initializing || dashboard.loadingOrganizations}
                    isProcessingAction={dashboard.deletingOrganization}
                />
                {
                    this.state.showDeleteConfirmModal 
                        ?   <ConfirmationModal 
                                confirmCallback={this.confirmDelete} 
                                dismissCallback={this.dismissDelete}
                                title={`Delete this organization?`} 
                                message={
                                    `Are you sure you want to delete this organization? 
                                    All user and role assignments to this organization will be removed. 
                                    This action cannot be reversed.`
                                } 
                                type={`warning`} 
                            /> 
                        : (null)
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(OrganizationTableView));