//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
// interface & models
import * as TYPES from "./types";
import { 
    IOrganizationNetworkManager, IProcessedResponse, OrganizationNetworkManager 
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlOrganization: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).organizations}`;

/*
Initialize Organization Dashboard
*/

function initializeOrganizationDashboardBegin(
    //
): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.INITIALIZE_ORGANIZATION_DASHBOARD_BEGIN,
        payload: {} as TYPES.OrganizationDashboardPayload
    };
}

function initializeOrganizationDashboardSuccess(
    response: IProcessedResponse
): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.INITIALIZE_ORGANIZATION_DASHBOARD_SUCCESS,
        payload: {
            organizations: response.data
        } as TYPES.OrganizationDashboardPayload
    }
}

function initializeOrganizationDashboardFailure(
    error: Error
): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.INITIALIZE_ORGANIZATION_DASHBOARD_FAILURE,
        payload: {
            error: error
        } as TYPES.OrganizationDashboardPayload
    };
}

export let initializeOrganizationDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().organizationDashboard.initializing || !getState().organizationDashboard.loadingOrganizations) {
        dispatch(initializeOrganizationDashboardBegin());

        let manager: IOrganizationNetworkManager =
            new OrganizationNetworkManager(apiUrlOrganization);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll()
                        .then(
                            (response: IProcessedResponse) => {
                                if (response.error)
                                    dispatch(initializeOrganizationDashboardFailure(response.error));
                                else
                                    dispatch(initializeOrganizationDashboardSuccess(response))
                            }
                        ).catch(
                            (err: Error) => 
                                dispatch(initializeOrganizationDashboardFailure(err))
                        )
                }
            ).catch(
                (err: Error) => dispatch(initializeOrganizationDashboardFailure(err))
            )
    }    
}

/*
Load Organizations
*/

function loadOrganizationsBegin(): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.LOAD_ORGANIZATIONS_BEGIN,
        payload: {} as TYPES.OrganizationDashboardPayload
    };
}

function loadOrganizationsSuccess(
    response: IProcessedResponse
): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.LOAD_ORGANIZATIONS_SUCCESS,
        payload: {
            organizations: response.data
        } as TYPES.OrganizationDashboardPayload
    };
}

function loadOrganizationsFailure(
    error: Error
): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.LOAD_ORGANIZATIONS_FAILURE,
        payload: {
            error: error
        } as TYPES.OrganizationDashboardPayload
    };
} 

export let loadOrganizations = (
    search: string = ""
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    
    if (
        !getState().organizationDashboard.initializing || 
        !getState().organizationDashboard.loadingOrganizations
    ) {
        dispatch(loadOrganizationsBegin());

        let manager: IOrganizationNetworkManager = 
            new OrganizationNetworkManager(apiUrlOrganization);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll({search})
                        .then(
                            (response: IProcessedResponse) => 
                                dispatch(loadOrganizationsSuccess(response))
                        ).catch(
                            (err: Error) => 
                                dispatch(loadOrganizationsFailure(err))
                        )
                }
            ).catch(
                (err: Error) => dispatch(loadOrganizationsFailure(err))
            )
    }
}

/*
Delete Organization
*/

function deleteOrganizationBegin(): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.DELETE_ORGANIZATION_BEGIN,
        payload: {} as TYPES.OrganizationDashboardPayload
    };
}

function deleteOrganizationSuccess(response: IProcessedResponse): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.DELETE_ORGANIZATION_SUCCESS,
        payload: {
            organizations: response.data
        } as TYPES.OrganizationDashboardPayload
    };
}

function deleteOrganizationFailure(error: Error): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.DELETE_ORGANIZATION_FAILURE,
        payload: {
            error: error
        } as TYPES.OrganizationDashboardPayload
    }
}

export let removeOrganization = (
    id: number, 
    search: string = ""
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().organizationDashboard.deletingOrganization) {
        dispatch(deleteOrganizationBegin());

        let manager: IOrganizationNetworkManager = 
            new OrganizationNetworkManager(apiUrlOrganization);

        getAccessToken()
            .then(
                (token) => {
                        manager.setAccessToken(token);
                        manager.delete({id})
                            .then(
                                (deleteResponse: IProcessedResponse) => {
                                    manager.getAll({search})
                                        .then((getAllResponse: IProcessedResponse) => {
                                            if (getAllResponse.error)
                                                dispatch(deleteOrganizationFailure(getAllResponse.error))
                                            else
                                                dispatch(deleteOrganizationSuccess(getAllResponse));
                                        }, (reject: any) => {
                                            dispatch(deleteOrganizationFailure(new Error(reject)));
                                        })
                                        .catch((error: any) => {
                                            dispatch(deleteOrganizationFailure(new Error(error)));
                                        });
                            }, (reject: any) => {
                                dispatch(deleteOrganizationFailure(new Error(reject)));
                            })
                            .catch((error: any) => {
                                dispatch(deleteOrganizationFailure(new Error(error)));
                            });
                    }
                )        
    }
}

/*
Clear Organization Dashboard Error
*/

function clearOrganizationDashboardErrorState(): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.CLEAR_ORGANIZATION_DASHBOARD_ERROR,
        payload: {} as TYPES.OrganizationDashboardPayload
    };
}

export let clearOrganizationDashboardError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().organizationDashboard.error !== null) {
        dispatch(clearOrganizationDashboardErrorState());
    }
}

/*
Uninitialize Organization Dashboard
*/

function uninitializeOrganizationDashboardState(): TYPES.OrganizationDashboardTypes {
    return {
        type: TYPES.UNINITIALIZE_ORGANIZATION_DASHBOARD,
        payload: {} as TYPES.OrganizationDashboardPayload
    };
}

export let uninitializeOrganizationDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeOrganizationDashboardState());
}