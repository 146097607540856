//libraries
import * as React from 'react';
import {
    EAspectRatio,
    getElementWidthByClass,
    calculateHeightFor
} // @ts-ignore
from "@algo/window-sizer/WindowSizer";
//components
import { IATCameraDevice } from "@algo/network-manager/models/v3";
import { Pill } from '@algo/pill';
import { GoogleMap } from "@algo/google-map";
//styled components
import { Body1, Caption1 } from '@algo/algo-styles';

//constants
declare var __API_URL__: string;
const snapshotURI: string = `${__API_URL__}/v3.0/Cameras/`;
//interfaces

export interface IPillObject {
    [key: string]: React.CSSProperties;
}

export interface IPillClass {
    [key: string]: string;
}

interface IProps {
    cameraDevice: IATCameraDevice;
    gmap?: any;
    pillClasses?: IPillClass;
    newStream?: boolean;
    backgroundClass?: string;
    labelClass?: string;
    iconClass?: string;
}

interface IState { }


export class CameraPreview extends React.Component<IProps, IState>{

    width: number | null;
    height: number | null;

    constructor(props: IProps) {
        super(props);
        this.width = null;
        this.height = null;
    }

    componentDidMount = () => {
        let setFlag: boolean = false;
        if (!this.width || !this.height) {
            setFlag = true;
        }
        this.width = getElementWidthByClass("algo-camera-preview");
        this.height = this.props.gmap
            ? this.props.gmap.height
                ? this.props.gmap.height
                : 225
            : calculateHeightFor(this.width, EAspectRatio["16x9"]);

        if (setFlag) this.setState({ ...this.state });
    }

    render = () => {
        let camera: IATCameraDevice = this.props.cameraDevice;                                                              //brevity

        if (this.props.newStream) return this.renderNewStreamButton();
        if (!camera || !camera.id || camera.id === 0) {                                                                     //confirm camera exists and id not 0
            return (<div> Error! No Valid Camera Object Provided! </div>)
        }

        let wrapperStyle: React.CSSProperties = { 
            width: "100%", borderRadius: "4px", border: 0 
        };

        let rowStyle: React.CSSProperties = { 
            height: `${this.height ? this.height : "144"}px`, 
            borderTopLeftRadius: "4px", borderTopRightRadius: "4px" 
        };

        let mapWrapperStyle: React.CSSProperties = { 
            backgroundColor: "#efeff0", width: "100%", 
            height: `${this.height ? this.height : "144"}px`, 
            borderTopLeftRadius: "4px", borderTopRightRadius: "4px" 
        };

        let infoStyle: React.CSSProperties = { 
            height: this.props.gmap ? "131px" : "11.68rem"
        };

        let roadNamesStyle: React.CSSProperties = { height: "4rem" };
        
        return (
            <div 
                className={`card container-fluid algo-camera-preview`} 
                style={wrapperStyle}
            >
                <div className="row" style={rowStyle}>
                    <div className="col m-0 p-0" style={mapWrapperStyle}>
                    <img
                        width={"100%"}
                        height={this.height || undefined}
                        src={`${snapshotURI}${this.props.cameraDevice.id}/snapshot.jpg`}
                        alt={`Camera Snapshot Preview for: ${this.props.cameraDevice.name}`}
                        style={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}
                    />
                    </div>
                </div>
                <div className="row border border-top-0" style={{borderRadius: "0 0 4px 4px"}}>
                    <div className="col w-100">
                        <div className="container-fluid px-3 py-3" style={infoStyle}>
                            <div className={`row mb-${this.props.gmap ? 1 : 4}`} style={roadNamesStyle}>
                                <div className="col p-0">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col p-0 mb-1">
                                                <div className="">
                                                    <Body1 
                                                        weight="bold" className="line-clamp m-0"
                                                    >
                                                        {camera.routeDesignator}
                                                    </Body1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col d-flex justify-content-left p-0">
                                                <Body1 style={{color: "#6C757D"}}>
                                                    {camera.crossStreet}
                                                </Body1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.renderPills()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderPills = () => {
        let pillsStyle: React.CSSProperties = {
            height: this.props.gmap ? "2rem" : "4rem",
            overflow: "auto",
        };

        let pills = this.props.pillClasses ? this.props.pillClasses : null;
        if (!pills) return null;
        return (
            <div className="row custom-scrollbar">
                <div className="col p-0 custom-scrollbar">
                    <div className="d-flex flex-wrap p-0 justify-content-start align-items-end custom-scrollbar" style={pillsStyle} >
                        {
                            Object.keys(pills).map(
                                (key) => {
                                    return <Pill
                                        key={Math.random()}
                                        pillClass={this.props.pillClasses ? this.props.pillClasses[key] : ""}
                                    ><Caption1>{key}</Caption1></Pill>
                                }
                            )
                        }
                        </div>
                </div>
            </div>
        );
    }

    renderNewStreamButton = () => {
        let newStreamButtonHeight: string = `${this.height ? (this.height + 186) : "250"}px`;

        let newStreamIconStyle: React.CSSProperties = {
            marginBottom: "16px",
            fontSize: "32px",
        };
        let newStreamTextStyle: React.CSSProperties = {
            fontSize: "18px",
            fontWeight: "bold",
        };

        return (
            <div 
                className={`algo-camera-preview ${this.props.backgroundClass ? this.props.backgroundClass : ""}`} 
                style={{ height: newStreamButtonHeight }}
            >
                <div className="row">
                    <div 
                        className="col d-flex justify-content-center align-items-center flex-column" 
                        style={{ height: `${this.height ? `${this.height + 186}px` : "100%"}` }}
                    >
                        <i 
                            className="fas fa-plus-circle text-primary" 
                            style={newStreamIconStyle}
                        >
                        </i>
                        <span 
                            className="text-primary" 
                            style={newStreamTextStyle}
                        >
                            Create Stream
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default CameraPreview;


/*************************************************************
    HELPER FUNCTIONS
************************************************************/
const getNewConfig = (device: IATCameraDevice) => {
    let coords: any = { lat: device.latitude, lng: device.longitude };

    let mapConfig: any = {
        center: coords,
        zoom: 16,
        disableDefaultUI: true,
        marker: {
            position: coords
        },
    }

    return mapConfig;
}