//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "./types"
//models
import { 
    AAOrganization, IAAOrganization, IAARole 
} from "@algo/network-manager/models/v3/admin";
import { CASUserProfile } from "@algo/network-manager/models/v3/cas";
import { IATLegacyDatasource } from "@algo/network-manager/models/v3";
import { 
    IDatasourceNetworkManager, DatasourceNetworkManager,
    IOrganizationNetworkManager, OrganizationNetworkManager,
    IRoleNetworkManager, RoleNetworkManager, 
    UserNetworkManager, IUserNetworkManager, 
    IProcessedResponse, IAssignUser, IUnassignUser
} from "@algo/network-manager/managers/v3";

//constants
import { DEFAULT_ORGANIZATION_ID } from "../../../utils/AppConstants";
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlUser: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).users}`;
const apiUrlDatasource: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).datasources}`;
const apiUrlOrganization: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).organizations}`;
const apiUrlRole: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).roles}`;

/*
Load Organization Editor
*/

function loadOrganizationBegin(): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.LOAD_ORGANIZATION_BEGIN,
        payload: {} as TYPES.OrganizationEditorPayload
    };
}

function loadOrganizationSuccess(
    organization: IAAOrganization, 
    organizationList: IAAOrganization[],
    roleList: IAARole[], 
    organizationUserList: CASUserProfile[],
    datasourceList: IATLegacyDatasource[]
): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.LOAD_ORGANIZATION_SUCCESS,
        payload: {
            organization: organization,
            organizationList: organizationList,
            roleList: roleList,
            organizationUserList: organizationUserList,
            datasourceList: datasourceList
        } as TYPES.OrganizationEditorPayload
    };
}

function loadOrganizationFailure(error: Error): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.LOAD_ORGANIZATION_FAILURE,
        payload: { 
            error: error
        } as TYPES.OrganizationEditorPayload
    };
}

export let initializeOrganizationEditor = (
    id: number = DEFAULT_ORGANIZATION_ID
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (!getState().organizationEditor.initializing) {
        dispatch(loadOrganizationBegin());

        getAccessToken().then(
            (token) => {
                let OrganizationManager: IOrganizationNetworkManager = 
                    new OrganizationNetworkManager(apiUrlOrganization);

                let DatasourceManager: IDatasourceNetworkManager = 
                    new DatasourceNetworkManager(apiUrlDatasource);

                let RoleManager: IRoleNetworkManager = 
                    new RoleNetworkManager(apiUrlRole);

                let UserManager: UserNetworkManager = 
                    new UserNetworkManager(apiUrlUser);

                OrganizationManager.setAccessToken(token);
                DatasourceManager.setAccessToken(token);
                RoleManager.setAccessToken(token);
                UserManager.setAccessToken(token);

                if (id === DEFAULT_ORGANIZATION_ID) {
                    Promise.all([
                        OrganizationManager.getAll(),
                        RoleManager.getAll(),
                        DatasourceManager.getAll(
                            getState().datasource.lastResponse
                        ),
                    ])
                        .then(response => {
                            dispatch(
                                loadOrganizationSuccess(
                                    new AAOrganization(), 
                                    response[0].data, 
                                    response[1].data,
                                    [], 
                                    response[2].data
                                )
                            )
                        }, reject => {
                            dispatch(loadOrganizationFailure(new Error(reject)));
                        })
                        .catch(error => {
                            dispatch(loadOrganizationFailure(new Error(error)));
                        });
                }
                else {
                    Promise.all([
                        OrganizationManager.getById({id}),
                        OrganizationManager.getAll(),
                        RoleManager.getAll(),
                        UserManager.getAllUsers({organizationId: id}),
                        DatasourceManager.getAll(getState().datasource.lastResponse),
                    ])
                        .then(response => {
                            dispatch(loadOrganizationSuccess(
                                response[0].data, 
                                response[1].data, 
                                response[2].data, 
                                response[3].data, 
                                response[4].data
                            )
                        );
                        }, reject => {
                            dispatch(loadOrganizationFailure(new Error(reject)));
                        })
                        .catch(error => {
                            dispatch(loadOrganizationFailure(new Error(error)));
                        });
                }
            }
        )
    }
}

/*
Load User Search
*/

function loadUserSearchBegin(): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.LOAD_USER_SEARCH_BEGIN,
        payload: {} as TYPES.OrganizationEditorPayload
    };
}

function loadUserSearchSuccess(userSearchList: CASUserProfile[]): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.LOAD_USER_SEARCH_SUCCESS,
        payload: {
            userSearchList: userSearchList
        } as TYPES.OrganizationEditorPayload
    };
}

function loadUserSearchFailure(error: Error): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.LOAD_USER_SEARCH_FAILURE,
        payload: { 
            error: error
        } as TYPES.OrganizationEditorPayload
    };
}

export let userSearch = (
    query: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(loadUserSearchBegin());

    getAccessToken().then(
        (token: string) => {

            let manager = new UserNetworkManager(apiUrlUser);
            manager.setAccessToken(token);
            manager.getAllUsers({search: query})
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(loadUserSearchFailure(response.error))
                        else
                            dispatch(loadUserSearchSuccess(response.data))
                    }
                ).catch(
                    (err: Error) => 
                        dispatch(loadUserSearchFailure(err))
                )
        }
    )
}

/*
Save Organization
*/

function saveOrganizationBegin(): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.SAVE_ORGANIZATION_BEGIN,
        payload: {} as TYPES.OrganizationEditorPayload
    };
}

function saveOrganizationSuccess(
    response: IProcessedResponse
): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.SAVE_ORGANIZATION_SUCCESS,
        payload: { 
            organization: response.data
        } as TYPES.OrganizationEditorPayload
    };
}

function saveOrganizationFailure(
    error: Error
): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.SAVE_ORGANIZATION_FAILURE,
        payload: { 
            error: error
        } as TYPES.OrganizationEditorPayload
    }
}

export let saveOrganization = (
    organization: IAAOrganization
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().organizationEditor.savingOrganization) {
        dispatch(saveOrganizationBegin());

        getAccessToken().then(
            (token: string) => {
                
                let manager: IOrganizationNetworkManager = 
                    new OrganizationNetworkManager(apiUrlOrganization);

                manager.setAccessToken(token);
                manager.save({organization}).then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(saveOrganizationFailure(response.error))
                        else
                            dispatch(saveOrganizationSuccess(response))
                    }
                ).catch(
                    (err: Error) => dispatch(saveOrganizationFailure(err))
                )
            }
        )
    }
}

/*
Add User to Organization
*/

function addUserBegin(): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.ADD_USER_TO_ORGANIZATION_BEGIN,
        payload: {} as TYPES.OrganizationEditorPayload
    };
}

function addUserSuccess(
    organizationUserList: CASUserProfile[]
): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.ADD_USER_TO_ORGANIZATION_SUCCESS,
        payload:  { 
            organizationUserList: organizationUserList
        } as TYPES.OrganizationEditorPayload
    };
}

function addUserFailure(error: Error): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.ADD_USER_TO_ORGANIZATION_FAILURE,
        payload: { 
            error: error
        } as TYPES.OrganizationEditorPayload
    };
}

export let addUserToOrganization = (
    organizationId: number, 
    subs: string[]
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().organizationEditor.addingUser) {
        dispatch(addUserBegin());

        let orgManager: IOrganizationNetworkManager = 
            new OrganizationNetworkManager(apiUrlOrganization);

        let userManager: IUserNetworkManager =
            new UserNetworkManager(apiUrlUser);

        
        getAccessToken()
            .then(
                (token: string) => {
                    orgManager.setAccessToken(token);
                    userManager.setAccessToken(token);

                    let assignUsersToOrg: IAssignUser = {
                        id: organizationId,
                        sub: subs
                    };

                    orgManager.assignUser(assignUsersToOrg)
                        .then(
                            (orgResponse: IProcessedResponse) => {
                                userManager.getAllUsers({organizationId})
                                    .then(
                                        (userResponse: IProcessedResponse) => {
                                            if (userResponse.error)
                                                addUserFailure(userResponse.error)
                                            else
                                                dispatch(addUserSuccess(userResponse.data))
                                        }
                                    ).catch(
                                        (err: Error) => dispatch(addUserFailure(err))
                                    )
                            }
                        ).catch(
                            (err: Error) => 
                                dispatch(addUserFailure(err))
                        )
                }
            );
    }
}

/*
Remove User from Organization
*/

function removeUserBegin(): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.REMOVE_USER_FROM_ORGANIZATION_BEGIN,
        payload: {} as TYPES.OrganizationEditorPayload
    };
}

function removeUserSuccess(
    organizationUserList: CASUserProfile[]
): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.REMOVE_USER_FROM_ORGANIZATION_SUCCESS,
        payload: { 
            organizationUserList: organizationUserList
        } as TYPES.OrganizationEditorPayload
    };
}

function removeUserFailure(
    error: Error
): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.REMOVE_USER_FROM_ORGANIZATION_FAILURE,
        payload: { 
            error: error
        } as TYPES.OrganizationEditorPayload
    };
}

export let removeUserFromOrganization = (
    organizationId: number, 
    sub: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().organizationEditor.removingUser) {
        dispatch(removeUserBegin());

        let orgManager: IOrganizationNetworkManager 
            = new OrganizationNetworkManager(apiUrlOrganization);

        let userManager: IUserNetworkManager
            = new UserNetworkManager(apiUrlUser);

        getAccessToken()
            .then(
                (token: string) => {
                    orgManager.setAccessToken(token);
                    userManager.setAccessToken(token);

                    let unassignUser: IUnassignUser = 
                        {id: organizationId, sub: [sub]};

                    orgManager.unassignUser(unassignUser)
                        .then(
                            (orgResponse: IProcessedResponse) => {
                                userManager.getAllUsers({organizationId})
                                    .then(
                                        (userResponse: IProcessedResponse) => {
                                            if (userResponse.error)
                                                dispatch(removeUserFailure(userResponse.error))
                                            else
                                                dispatch(removeUserSuccess(userResponse.data))
                                        }
                                    ).catch(
                                        (err: Error) => dispatch(removeUserFailure(err))
                                    )
                            }
                        ).catch(
                            (err: Error) => {
                                dispatch(removeUserFailure(err))
                            }
                        )
                }
            );
    }
}

/*
Clear Organization Error
*/

function clearOrganizationEditorErrorState(
): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.CLEAR_ORGANIZATION_EDITOR_ERROR,
        payload: {} as TYPES.OrganizationEditorPayload
    };
}

export let clearOrganizationEditorError = (
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().organizationEditor.error !== null) {
        dispatch(clearOrganizationEditorErrorState());
    }
} 

/*
Uninitialize Organization Editor
*/

function uninitializeOrganizationEditorState(): TYPES.OrganizationEditorTypes {
    return {
        type: TYPES.UNINITIALIZE_ORGANIZATION_EDITOR,
        payload: {} as TYPES.OrganizationEditorPayload
    };
}

export let uninitializeOrganizationEditor = (
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeOrganizationEditorState());
}
