//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../..";
import { Action } from "redux";
import { getAccessToken } from "../../../../components/template/authentication/oidcConfig";
//types
import * as TYPES from "../types/toolbarTypes";
//interfaces & models
import { IReduxData } from "../../../../utils/ReduxData";
import { 
    IDatasourceNetworkManager, DatasourceNetworkManager,
    IGetAll, D_GET_ALL, IProcessedResponse
} from "@algo/network-manager/managers/v3";

//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../../api-version-constants";
declare var __API_URL__: string;
const apiUrlDatasource: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).datasources}`;


/******************************************************************************
    LOAD DATASOURCES | ACTION CREATOR
 *****************************************************************************/

export const loadDatasources =(
    getDatasourcesRequest: IGetAll = D_GET_ALL
): ThunkAction<
    void, 
    AppState, 
    null, 
    Action<string>
> => async (dispatch, getState) => {
    
        const req: IGetAll = getDatasourcesRequest;

        let datasourceData: IReduxData = 
            getState().streamToolbar.datasourceData;

        let manager: IDatasourceNetworkManager = 
            new DatasourceNetworkManager(apiUrlDatasource);

        if (!datasourceData.isLoading) {
            dispatch(loadDatasourcesBegin());

            getAccessToken()
                .then(
                    (token: string) => {
                        manager.setAccessToken(token);
                        manager.getAll(getState().streamToolbar.lastResponse, {
                            region: req.region,
                            skip: req.skip,
                            take: req.take,
                            availableToUser: req.availableToUser
                        }).then(
                            (response: IProcessedResponse) => 
                                dispatch(loadDatasourcesSuccess(response))
                        ).catch(
                            (err: Error) => 
                                dispatch(loadDatasourcesFailure(err))
                        )
                    }
                ).catch(
                    (err: Error) => dispatch(loadDatasourcesFailure(err))
                )
        }
    };

/******************************************************************************
    LOAD DATASOURCES | ACTIONS
 *****************************************************************************/

function loadDatasourcesBegin(): TYPES.Actions {
    return {
        type: TYPES.LOAD_DATASOURCES_BEGIN,
        payload: {} as TYPES.Payload
    };
}

function loadDatasourcesSuccess(response: IProcessedResponse): TYPES.Actions {
    return {
        type: TYPES.LOAD_DATASOURCES_SUCCESS,
        payload: {
            datasources: response.data,
            response
        } as TYPES.Payload
    };
}

function loadDatasourcesFailure(error: Error): TYPES.Actions {
    return {
        type: TYPES.LOAD_DATASOURCES_FAILURE,
        payload: {
            error: error,
        } as TYPES.Payload
    };
}