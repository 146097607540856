import * as TYPES from "../types/pageStateTypes";
import {
    ReduxData,
    cloneReduxData as clone,
    IReduxData
} from "../../../../utils/ReduxData";
import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import { EATStreamAccessLevel } from "@algo/network-manager/models/v3";

let initialState: TYPES.State = {
    accessLevelData: new ReduxData({ name: "access-level-data" }),
    accessChange: false,
    selectingAll: false,
    selectedStreams: [],
    showAllSelectedStreams: true,
    pageCount: 1,
    pageIndex: 0,
    editMode: false,
    selectedRegion: undefined,
    selectedAccessLevel: EATStreamAccessLevel.Unknown,
    selectedRegionIndex: 0,
    search: "",
    showUpdateModal: false,
    showDeleteModal: false,
    error: null,
    lastResponse: new ProcessedResponse()
};

export function pageStateReducer(state = initialState, action: TYPES.Actions): TYPES.State {
    let tempData: IReduxData | null = null;
    switch (action.type) {

        /******************************************************************************
            UPDATE_PAGE_STATE | REDUCERS
        *****************************************************************************/
        case TYPES.UPDATE_PAGE_STATE:
            return {
                ...action.payload.pageState,
            }
        /******************************************************************************
            UPDATE ACCESS LEVEL | REDUCERS
        *****************************************************************************/
        case TYPES.UPDATE_ACCESS_LEVELS_BEGIN:
            tempData = clone(state.accessLevelData);
            tempData.setLoading(true);
            return {
                ...state,
                accessLevelData: tempData,
                showUpdateModal: false,
            };
        case TYPES.UPDATE_ACCESS_LEVELS_SUCCESS:
            tempData = clone(state.accessLevelData);
            tempData.setLoading(false);
            return {
                ...state,
                accessLevelData: tempData,
                selectedAccessLevel: EATStreamAccessLevel.Unknown,
                selectedStreams: [],
                showAllSelectedStreams: true,
                showUpdateModal: false,
            };
        case TYPES.UPDATE_ACCESS_LEVELS_FAILURE:
            tempData = clone(state.accessLevelData);
            tempData.setLoading(false);
            return {
                ...state,
                accessLevelData: tempData,
                selectedAccessLevel: EATStreamAccessLevel.Unknown,
                selectedStreams: [],
                showAllSelectedStreams: true,
                showUpdateModal: false,
                error: action.payload.error
            };

        /******************************************************************************
            SELECT ALL | REDUCERS
        *****************************************************************************/
        case TYPES.SELECT_ALL_BEGIN:
            return {
                ...state,
                selectingAll: true,
            };
        case TYPES.SELECT_ALL_SUCCESS:
            return {
                ...state,
                selectingAll: false,
                selectedStreams: action.payload.selectedStreams,
                showAllSelectedStreams: action.payload.showAllSelectedStreams
            };
        case TYPES.SELECT_ALL_DESELECT:
            return {
                ...state,
                selectingAll: false,
                selectedStreams: action.payload.selectedStreams,
                showAllSelectedStreams: action.payload.showAllSelectedStreams
            };
        case TYPES.SELECT_ALL_FAILURE:
            return {
                ...state,
                selectingAll: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}