// libraries
import { useEffect, useRef } from "react";

// used to track previous values for props or effect dependencies
export const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const hasChanged = (oldValue: any, newValue: any) => {
    if (oldValue !== newValue) return true;
    else return false;
}