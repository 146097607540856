// libraries
import * as React from "react";

// handles setting up service worker
export const useServiceWorker = () => {

    const [serviceWorker, setServiceWorker] = React.useState<any>(null);

    React.useEffect(
        () => {
            let promise = new Promise(resolve => {
                if (navigator.serviceWorker?.controller) {
                    return resolve(`navigator.serviceWorker.controller exists`);
                }
    
                navigator.serviceWorker?.addEventListener(
                    'controllerchange', 
                    e => resolve(`navigator.serviceWorker event: controllerchange`)
                );
            });
    
            promise.then(() => {
                setServiceWorker(navigator.serviceWorker?.controller);
            });


            return () => {
                // cleanup
            };

        },
        []
    );


    return serviceWorker;
};

export default useServiceWorker;