import * as React from 'react';
import { Modal } from "@algo/modal";
import { StreamAccessLevelOptions } from "../../../utils/AppConstants";
import { EATStreamAccessLevel } from '@algo/network-manager/models/v3';

interface IProps {
    renderOption: string;
    show: boolean;
    updateParentState: any;
    updateStore: any;
    selectedAccessLevel: EATStreamAccessLevel;
}

export let CameraDashboardModal = (props: IProps) => {
    let modalContent: React.ReactNode;                                                      //interior content of the modal
    let cancelButton: React.ReactNode;                                                      //button to close modal without success
    let confirmButton: React.ReactNode;                                                     //button to execute given logic and continue

    if (!props.show) return null;

    /* create the cancel button element */
    cancelButton =
        <div
            key={Math.random()}
            className="btn btn-dark"
            onClick={() => { props.updateParentState() }}
        >
            Cancel
        </div>

    /* create the confirm button element */
    confirmButton = 
        <div
        key={Math.random()}
            className="btn btn-primary ml-3"
            onClick={() => {
                props.updateStore();
        }}>
            {props.renderOption === "update" ? "Update" : "Delete"}
        </div>

    /* create the interior content of the modal body */
    switch (props.renderOption) {
        case "update":
            modalContent =
                <div key={ Math.random()}>
                    <span>Are you sure you want to</span>
                    <span className="text-primary font-weight-bold"> UPDATE </span>
                    <span>the selected streams to: </span>
                    <span className="text-primary font-weight-bold">
                    {props.selectedAccessLevel}
                    </span>
                </div>                
            break;
        case "delete":
            modalContent =
                <div>
                    <span className="text-danger"> WARNING! </span>
                    <span>Are you sure you want to </span>
                    <span className="text-danger font-weight-bold">DELETE</span>
                    <span> the selected streams? </span>
                </div>
            break;
        default: return null;
    }

    /* actually send the constructed Modal object back to the parent */
    return <Modal
        title="Delete Selected Streams"
        content={modalContent}
        controls={[cancelButton, confirmButton]}
    />;
}

export default CameraDashboardModal;