//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
// interfaces & models
import * as TYPES from "./types";
import { 
    IOrganizationNetworkManager, OrganizationNetworkManager,
    IRoleNetworkManager, RoleNetworkManager, 
    IUserNetworkManager, UserNetworkManager, IProcessedResponse
} from "@algo/network-manager/managers/v3";
import { 
    AAPasswordForm,
    AAUserForm, IAAOrganization, IAARole, IAAUserForm 
} from '@algo/network-manager/models/v3/admin';
import {
    CASPasswordValidation, CASComplexityRequirement 
} from "@algo/network-manager/models/v3/cas";
//constants
import { DEFAULT_USER_SUB } from "../../../utils/AppConstants";
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlUser: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).users}`;
const apiUrlRole: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).roles}`;
const apiUrlOrganization: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).organizations}`;

/*
Initialize and Uninitialize User
*/

function loadUserEditorBegin(): TYPES.UserEditorTypes {
    return {
        type: TYPES.LOAD_USER_EDITOR_BEGIN,
        payload: {} as TYPES.UserEditorPayload
    };
}

function loadUserEditorSuccess(
    userForm: IAAUserForm, 
    organizationList: IAAOrganization[], 
    roleList: IAARole[], 
    complexityRequirements: CASComplexityRequirement
): TYPES.UserEditorTypes {
    return {
        type: TYPES.LOAD_USER_EDITOR_SUCCESS,
        payload: {
            userForm: userForm,
            organizationList: organizationList,
            roleList: roleList,
            complexityRequirements: complexityRequirements
        } as TYPES.UserEditorPayload
    };
}

function loadUserEditorFailure(error: Error): TYPES.UserEditorTypes {
    return {
        type: TYPES.LOAD_USER_EDITOR_FAILURE,
        payload: {
            error: error
        } as TYPES.UserEditorPayload
    };
}

function uninitializeUserEditorState(): TYPES.UserEditorTypes {
    return {
        type: TYPES.UNINITIALIZE_USER_EDITOR,
        payload: {} as TYPES.UserEditorPayload
    };
}

export let initializeUserEditor = (
    sub: string = DEFAULT_USER_SUB
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().userEditor.initializing) {
        dispatch(uninitializeUserEditorState());
        dispatch(loadUserEditorBegin());

        getAccessToken().then(
            (token) => {
                let orgManager: IOrganizationNetworkManager = 
                    new OrganizationNetworkManager(apiUrlOrganization);

                let roleManager: IRoleNetworkManager = 
                    new RoleNetworkManager(apiUrlRole);

                let userManager: IUserNetworkManager = 
                    new UserNetworkManager(apiUrlUser);

                roleManager.setAccessToken(token);
                orgManager.setAccessToken(token);
                userManager.setAccessToken(token);

                if (sub === DEFAULT_USER_SUB) {
                    Promise.all([
                        orgManager.getAll(),
                        roleManager.getAll(),
                        userManager.getRequirements()
                    ])
                        .then(response => {
                            var userForm = new AAUserForm();

                            dispatch(
                                loadUserEditorSuccess(
                                    userForm, 
                                    response[0].data, 
                                    response[1].data, 
                                    response[2].data
                                )
                            );
                        }, reject => {
                            dispatch(loadUserEditorFailure(new Error(reject)));
                        })
                        .catch(error => {
                            dispatch(loadUserEditorFailure(new Error(error)));
                        });
                }
                else {
                    Promise.all([
                        userManager.getUserById({sub}),
                        orgManager.getAll({sub}),
                        roleManager.getAll({sub}),
                        orgManager.getAll(),
                        roleManager.getAll(),
                        userManager.getRequirements()
                    ])
                        .then(response => {
                            var userForm = new AAUserForm();

                            userForm.copy(
                                response[0].data, 
                                response[1].data, 
                                response[2].data
                            );

                            dispatch(
                                loadUserEditorSuccess(
                                    userForm, 
                                    response[3].data, 
                                    response[4].data, 
                                    response[5].data
                                )
                            );
                        }, reject => {
                            dispatch(loadUserEditorFailure(new Error(reject)));
                        })
                        .catch(error => {
                            dispatch(loadUserEditorFailure(new Error(error)));
                        });
                }
            }
        )
    }
}

export let uninitializeUserEditor = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeUserEditorState());
}

/*
Save User
*/

function saveUserBegin(): TYPES.UserEditorTypes {
    return {
        type: TYPES.SAVE_USER_BEGIN,
        payload: {} as TYPES.UserEditorPayload
    };
}

function saveUserSuccess(userForm: AAUserForm): TYPES.UserEditorTypes {
    return {
        type: TYPES.SAVE_USER_SUCCESS,
        payload: { 
            userForm: userForm
        } as TYPES.UserEditorPayload
    };
}

function saveUserFailure(error: Error): TYPES.UserEditorTypes {
    return {
        type: TYPES.SAVE_USER_FAILURE,
        payload: { 
            error: error
        } as TYPES.UserEditorPayload
    };
}

export let saveUser = (
    userForm: IAAUserForm, 
    sub: string = DEFAULT_USER_SUB
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (!getState().userEditor.savingUser) {
        dispatch(saveUserBegin());

        let orgManager: IOrganizationNetworkManager
            = new OrganizationNetworkManager(apiUrlOrganization);

        let roleManager: IRoleNetworkManager
            = new RoleNetworkManager(apiUrlRole);

        let userManager: IUserNetworkManager
            = new UserNetworkManager(apiUrlUser);

        getAccessToken()
            .then(
                (token: string) => {
                    orgManager.setAccessToken(token);
                    roleManager.setAccessToken(token);
                    userManager.setAccessToken(token);

                    if (sub !== DEFAULT_USER_SUB){
                        userManager.updateUser({user: userForm})
                            .then(
                                (updateResponse: IProcessedResponse) => {
                                    Promise.all(
                                        [
                                            orgManager.getAll({sub: updateResponse.data.sub}),
                                            roleManager.getAll({sub: updateResponse.data.sub})
                                        ]
                                    ).then(
                                        (responseList: IProcessedResponse[]) => {
                                            var userForm = new AAUserForm();
                                            userForm.copy(
                                                updateResponse.data, 
                                                responseList[0].data, 
                                                responseList[1].data
                                            );

                                            dispatch(
                                                saveUserSuccess(userForm)
                                            );
                                        }
                                    )
                                }
                            ).catch(
                                (err: Error) => dispatch(
                                    saveUserFailure(err)
                                )
                            )
                    }
                    else {
                        userManager.createUser({user: {...userForm, sub}})
                            .then(
                                (userResponse: IProcessedResponse) => {
                                    Promise.all(
                                        [
                                            orgManager.getAll({sub: userResponse.data.sub}),
                                            roleManager.getAll({sub: userResponse.data.sub})
                                        ]
                                    ).then(
                                        (responseList: IProcessedResponse[]) => {
                                            var userForm = new AAUserForm();
                                            userForm.copy(
                                                userResponse.data, 
                                                responseList[0].data, 
                                                responseList[1].data
                                            );
                                            dispatch(saveUserSuccess(userForm));
                                        }
                                    ).catch(
                                        (err: Error) => dispatch(saveUserFailure(err))
                                    )
                                }
                            ).catch(
                                (err: Error) => {
                                    dispatch(saveUserFailure(err))
                                }
                            )
                    }
                }
            ).catch(
                (err: Error) => 
                    dispatch(saveUserFailure(err))
            )
    }
}

/*
Validate Password
*/

function validatePasswordBegin(): TYPES.UserEditorTypes {
    return {
        type: TYPES.VALIDATE_PASSWORD_BEGIN,
        payload: {} as TYPES.UserEditorPayload
    };
}

function validatePasswordSuccess(
    passwordValidation: CASPasswordValidation
): TYPES.UserEditorTypes {
    return {
        type: TYPES.VALIDATE_PASSWORD_SUCCESS,
        payload: {
            passwordValidation: passwordValidation
        } as TYPES.UserEditorPayload
    };
}

function validatePasswordFailure(error: Error): TYPES.UserEditorTypes {
    return {
        type: TYPES.VALIDATE_PASSWORD_FAILURE,
        payload: {
            error: error
        } as TYPES.UserEditorPayload
    };
}

// export let validatePassword = (
//     password: string
// ): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
//     dispatch(validatePasswordBegin());

//     let manager: IUserNetworkManager = 
//         new UserNetworkManager(apiUrlUser);

//     getAccessToken()
//         .then(
//             (token: string) => {
//                 manager.setAccessToken(token);
//                 manager.validatePassword({passwordForm: password})
//                     .then(
//                         (response: IProcessedResponse) => 
//                             dispatch(validatePasswordSuccess(response.data))
//                     ).catch(
//                         (err: Error) => 
//                             dispatch(validatePasswordFailure(response.data))
//                     )
//             }
//         )
//     let NetworkManager: UserNetworkManager = new UserNetworkManager(apiUrlUser);
//     let actionFun = () => NetworkManager.validatePassword(password);
//     getAccessTokenThen(
//         NetworkManager, actionFun, dispatch,
//         validatePasswordSuccess, validatePasswordFailure, validatePasswordFailure
//     ) 
// }

/*
Reset Password
*/

function changePasswordBegin(): TYPES.UserEditorTypes {
    return {
        type: TYPES.CHANGE_PASSWORD_BEGIN,
        payload: {} as TYPES.UserEditorPayload
    };
}

function changePasswordSuccess(): TYPES.UserEditorTypes {
    return {
        type: TYPES.CHANGE_PASSWORD_SUCCESS,
        payload: {} as TYPES.UserEditorPayload
    };
}

function changePasswordFailure(error: Error): TYPES.UserEditorTypes {
    return {
        type: TYPES.CHANGE_PASSWORD_FAILURE,
        payload: {
            error: error
        } as TYPES.UserEditorPayload
    };
}

export let resetUserPassword = (
    sub: string, 
    password: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(changePasswordBegin());

    let manager: IUserNetworkManager =
        new UserNetworkManager(apiUrlUser);

    getAccessToken()
        .then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.changePassword({
                    sub, 
                    passwordForm: new AAPasswordForm({password, currentPassword: ""}), 
                    reset: true
                })
                    .then(
                        (response: IProcessedResponse) => 
                            dispatch(changePasswordSuccess())
                    ).catch(
                        (err: Error) => 
                            dispatch(changePasswordFailure(err))
                    )
            }
        ).catch(
            (err: Error) => dispatch(changePasswordFailure(err))
        )
}

function sendResetPasswordClearState(): TYPES.UserEditorTypes {
    return {
        type: TYPES.CHANGE_PASSWORD_CLEAR,
        payload: {} as TYPES.UserEditorPayload
    }
}

export let clearResetUserPassword = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(sendResetPasswordClearState());
}

/*
Send Reset Password Email
*/

function sendResetPasswordBegin(): TYPES.UserEditorTypes {
    return {
        type: TYPES.RESET_PASSWORD_EMAIL_BEGIN,
        payload: {} as TYPES.UserEditorPayload
    };
}

function sendResetPasswordSuccess(): TYPES.UserEditorTypes {
    return {
        type: TYPES.RESET_PASSWORD_EMAIL_SUCCESS,
        payload: {} as TYPES.UserEditorPayload
    };
}

function sendResetPasswordFailure(error: Error): TYPES.UserEditorTypes {
    return {
        type: TYPES.RESET_PASSWORD_EMAIL_FAILURE,
        payload: {
            error: error
        } as TYPES.UserEditorPayload
    };
}

export let sendUserResetPasswordEmail = (
    sub: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(sendResetPasswordBegin());

    let manager: IUserNetworkManager =
        new UserNetworkManager(apiUrlUser);

    getAccessToken()
        .then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.sendResetEmail({sub})
                    .then(
                        (response: IProcessedResponse) => {
                            dispatch(sendResetPasswordSuccess())
                        }
                    ).catch(
                        (err: Error) => 
                            dispatch(sendResetPasswordFailure(err))
                    )
            }
        ).catch(
            (err: Error) => dispatch(sendResetPasswordFailure(err))
        )
}

function sendUserResetPasswordEmailClearState(): TYPES.UserEditorTypes {
    return {
        type: TYPES.RESET_PASSWORD_EMAIL_CLEAR,
        payload: {} as TYPES.UserEditorPayload
    };
}

export let clearSendUserResetPasswordEmail = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => { 
    dispatch(sendUserResetPasswordEmailClearState());
}

/*
Clear User Editor Error
*/

function clearUserEditorErrorState(): TYPES.UserEditorTypes {
    return {
        type: TYPES.CLEAR_USER_EDITOR_ERROR,
        payload: {} as TYPES.UserEditorPayload
    };
}

export let clearUserEditorError = (

): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().userEditor.error !== null) {
        dispatch(clearUserEditorErrorState());
    }
}