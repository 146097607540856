import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { IATFacility } from "@algo/network-manager/models/v3";

export let INITIALIZE_FACILITY_DASHBOARD_BEGIN = 'INITIALIZE_FACILITY_DASHBOARD_BEGIN';
export let INITIALIZE_FACILITY_DASHBOARD_SUCCESS = 'INITIALIZE_FACILITY_DASHBOARD_SUCCESS';
export let INITIALIZE_FACILITY_DASHBOARD_FAILURE = 'INITIALIZE_FACILITY_DASHBOARD_FAILURE';

export let SEARCH_FACILITIES_BEGIN = 'SEARCH_FACILITIES_BEGIN';
export let SEARCH_FACILITIES_SUCCESS = 'SEARCH_FACILITIES_SUCCESS';
export let SEARCH_FACILITIES_FAILURE = 'SEARCH_FACILITIES_FAILURE';

export let DELETE_FACILITY_BEGIN = 'DELETE_FACILITY_BEGIN';
export let DELETE_FACILITY_SUCCESS = 'DELETE_FACILITY_SUCCESS';
export let DELETE_FACILITY_FAILURE = 'DELETE_FACILITY_FAILURE';

export let CLEAR_FACILITY_DASHBOARD_ERROR = 'CLEAR_FACILITY_DASHBOARD_ERROR';

export let UNINITIALIZE_FACILITY_DASHBOARD = 'UNINITIALIZE_FACILITY_DASHBOARD';

export interface FacilityDashboardState {
    initializing: boolean;
    loadingFacilities: boolean;
    deletingFacility: boolean;
    facilities: IATFacility[];
    error: Error | null;
    lastInitResponse: IProcessedResponse;
    lastSearchResponse: IProcessedResponse;
}

export interface FacilityDashboardPayload {
    facilities: IATFacility[];
    error: Error;
    response: IProcessedResponse;
}

interface InitializeFacilityDashboardBeginAction {
    type: typeof INITIALIZE_FACILITY_DASHBOARD_BEGIN,
    payload: FacilityDashboardPayload
}

interface InitializeFacilityDashboardSuccessAction {
    type: typeof INITIALIZE_FACILITY_DASHBOARD_SUCCESS,
    payload: FacilityDashboardPayload
}

interface InitializeFacilityDashboardFailureAction {
    type: typeof INITIALIZE_FACILITY_DASHBOARD_FAILURE,
    payload: FacilityDashboardPayload
}

interface SearchFacilitysBeginAction {
    type: typeof SEARCH_FACILITIES_BEGIN,
    payload: FacilityDashboardPayload
}

interface SearchFacilitysSuccessAction {
    type: typeof SEARCH_FACILITIES_SUCCESS,
    payload: FacilityDashboardPayload
}

interface SearchFacilitysFailureAction {
    type: typeof SEARCH_FACILITIES_FAILURE,
    payload: FacilityDashboardPayload
}

interface DeleteFacilityBeginAction {
    type: typeof DELETE_FACILITY_BEGIN,
    payload: FacilityDashboardPayload
}

interface DeleteFacilitySuccessAction {
    type: typeof DELETE_FACILITY_SUCCESS,
    payload: FacilityDashboardPayload
}

interface DeleteFacilityFailureAction {
    type: typeof DELETE_FACILITY_FAILURE,
    payload: FacilityDashboardPayload
}

interface ClearFacilityDashboardErrorAction {
    type: typeof CLEAR_FACILITY_DASHBOARD_ERROR,
    payload: FacilityDashboardPayload
}

interface UninitializeFacilityDashboardAction {
    type: typeof UNINITIALIZE_FACILITY_DASHBOARD,
    payload: FacilityDashboardPayload
}

export type FacilityDashboardTypes = 
    InitializeFacilityDashboardBeginAction |
    InitializeFacilityDashboardSuccessAction |
    InitializeFacilityDashboardFailureAction |
    SearchFacilitysBeginAction |
    SearchFacilitysSuccessAction |
    SearchFacilitysFailureAction |
    DeleteFacilityBeginAction |
    DeleteFacilitySuccessAction |
    DeleteFacilityFailureAction |
    ClearFacilityDashboardErrorAction |
    UninitializeFacilityDashboardAction;
