// libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../..";
import { Action } from "redux";
import { getAccessToken } from "../../../../components/template/authentication/oidcConfig";
// interfaces & models
import { IReduxData } from "../../../../utils/ReduxData";
import * as TYPES from "../types/streamPreviewsTypes";
import {
    ICameraDeviceNetworkManager, CameraDeviceNetworkManager,
    IGetAllCameraDevices, D_GET_ALL_CAMERA_DEVICES, IProcessedResponse,
} from "@algo/network-manager/managers/v3";
// constants
import { 
    CUR_API_VERSION, CUR_API_ENDPOINTS
} from "../../../api-version-constants";
declare var __API_URL__: string;
const apiUrlDevice: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).devices}`;


/******************************************************************************
    LOAD CAMERA DEVICES | ACTION CREATOR
 *****************************************************************************/

export const loadCameraDevices =(
    getCameraDeviceRequest: IGetAllCameraDevices = D_GET_ALL_CAMERA_DEVICES
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

        const req: IGetAllCameraDevices = getCameraDeviceRequest;
        let cameraData: IReduxData = getState().streamPreviews.cameraData;

        if (req.search)
            localStorage.setItem("cameraSearchString", JSON.stringify(req.search));
        else
            localStorage.removeItem("cameraSearchString");

        if (!cameraData.isLoading) {
            dispatch(loadCameraDevicesBegin());

            const manager: ICameraDeviceNetworkManager = 
                new CameraDeviceNetworkManager(apiUrlDevice);

            getAccessToken()
                .then(
                    (token: string) => {
                        manager.setAccessToken(token);
                        manager.getAll(
                            getState().streamPreviews.lastResponse, 
                            { 
                                ...getCameraDeviceRequest,
                                region: req.region ? req.region : undefined
                            }
                        ).then(
                            (response: IProcessedResponse) => {
                                dispatch(loadCameraDevicesSuccess(response))
                            }
                        ).catch(
                            (err: Error) => {
                                dispatch(loadCameraDevicesFailure(err))
                            }
                        )
                    }
                )
        }
    };

/******************************************************************************
    LOAD CAMERA DEVICES | ACTIONS
 *****************************************************************************/

function loadCameraDevicesBegin(): TYPES.Actions {
    return {
        type: TYPES.LOAD_CAMERA_DEVICES_BEGIN,
        payload: {} as TYPES.Payload
    };
}

function loadCameraDevicesSuccess(response: IProcessedResponse): TYPES.Actions {
    return {
        type: TYPES.LOAD_CAMERA_DEVICES_SUCCESS,
        payload: {
            devices: response.data,
            response
        } as TYPES.Payload
    };
}

function loadCameraDevicesFailure(error: Error): TYPES.Actions {
    return {
        type: TYPES.LOAD_CAMERA_DEVICES_FAILURE,
        payload: {
            error: error,
        } as TYPES.Payload
    };
}