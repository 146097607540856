//libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
//components
import { CameraDeviceDetails } from './CameraDeviceDetails';
import { Loading } from "@algo/loading";
import { Center } from "@algo/capsstrap";
import { Body1 } from "@algo/algo-styles";
//redux-store
import { 
    State as PageState 
} from "../../../store/cameraDevice/dashboard/types/pageStateTypes";
import { 
    CameraDetailsState 
} from "../../../store/cameraDevice/details/types/types";
import { AppState } from "../../../store";
//redux-action-creators
import { 
    initializeDetails, loadCameraDevice 
} from "../../../store/cameraDevice/details/actions/loadActions";
import { 
    updatePageState 
} from "../../../store/cameraDevice/dashboard/actions/pageStateActions";
import { 
    patchCloudStreamTarget 
} from "../../../store/cameraDevice/details/actions/updateActions";


interface IStateProps { 
    details: CameraDetailsState;
    pageState: PageState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        details: state.cameraDetails,
        pageState: state.streamPageState
    }
};

interface IDispatchProps {
    initialize: typeof initializeDetails;
    loadCameraDevice: typeof loadCameraDevice;
    patchCloudStreamTarget: typeof patchCloudStreamTarget;
    updatePageState: typeof updatePageState;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeDetails,
        loadCameraDevice: loadCameraDevice,
        patchCloudStreamTarget: patchCloudStreamTarget,
        updatePageState: updatePageState
    }, dispatch);
};

type StreamCreationWrapperProps = IStateProps & IDispatchProps & RouteComponentProps<any>;

export const StreamCreationWrapper: React.FC<StreamCreationWrapperProps> = (props) => {

    const {
        details, match, loadCameraDevice
    } = {...props};

    const {
        patchingCloudStreamTarget
    } = {...details};

    const matchDeviceId = match?.params?.deviceId;

    /**************************************************************************
        CALCULATED VALUES & BREVITY NAMES 
     *************************************************************************/
    let paramsId: string = props.match.params.deviceId;
    let currentId: string = ( props.details.device ) 
        ? props.details.device.id.toString() 
        : "-1";

    /**************************************************************************
        EFFECTS 
     *************************************************************************/
    // confirm there is no device, and devices are not loading | if so, initialize
    React.useEffect(() => {
        if (!props.details.loadingCameraDevice) {
            if (!props.details.device || (paramsId !== currentId)) {
                props.initialize(props.match.params.deviceId);
            }
        }
    });
    // if patch action for CST just completed, re-load the current device 
    React.useEffect(() => {
        (!patchingCloudStreamTarget)
            && loadCameraDevice(matchDeviceId);
    }, [patchingCloudStreamTarget, loadCameraDevice, matchDeviceId]);

    /**************************************************************************
        PRIMARY RENDER 
     *************************************************************************/
    //if initializing isnt finished
    if (props.details.loadingCameraDevice || paramsId !== currentId) {
        return (
            <Center style={{ height: window.innerHeight - 64 }}>
                <Loading />
            </Center>
        );
    }
    else if (
        (!props.details.device && !props.details.loadingCameraDevice) ||
        (!props.details.logs && !props.details.loadingLogs)
    )
        {
        return (
            <Center>
                <Body1>
                    No Camera Device Loaded
                </Body1>
            </Center>  
        );
    }
    //if initializing finished successfully, render the StreamCreationPage
    else return (
        <CameraDeviceDetails
            //data
            device={props.details.device}
            //store functions
            updateAccessLevel={props.patchCloudStreamTarget}
            pageState={props.pageState}
            updatePageState={props.updatePageState}
            //store locks
            updatingAccessLevel={props.details.patchingCloudStreamTarget}
        />
    );
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(StreamCreationWrapper));