// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// store
import { AppState } from "../../../store";
// components
import Content from '../../base/layout/Content';
import FeedbackDetailView from './FeedbackDetailView';
// constants
import { FEEDBACK_DEFAULT_ID } from "../../../utils/AppConstants";

interface StateProps { 
}

let mapStateToProps = (state: AppState) => {
    return { 
    };
};

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
    }, dispatch);
};

type FeedbackDetailProps =  
    StateProps & 
    RouteComponentProps<any>;

interface FeedbackDetailState {
    id: number;
}

class FeedbackDetail extends React.Component<FeedbackDetailProps, FeedbackDetailState> {
    constructor(props: Readonly<FeedbackDetailProps>) {
        super(props);

        this.state = {
            id: ( this.props.match.params.feedbackId ) 
                ? this.props.match.params.feedbackId 
                : FEEDBACK_DEFAULT_ID
        } as FeedbackDetailState;
    }

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    goToDashboard = (): void => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        <FeedbackDetailView id={this.state.id} />
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(FeedbackDetail)); 