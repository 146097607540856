//libraries
import * as React from "react";
import { nuon } from "@caps-mobile/common-lib";
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// interfaces & models
import { EATStreamAccessLevel } from "@algo/network-manager/models/v3"
import { isMaxSize } from "@algo/window-sizer";
// hooks
import { useWatchSizeClass } from "@algo/window-sizer";
// enums
import { EBreakpoint } from "@algo/window-sizer";
// redux-state-types
import {
    State as PageState
} from "../../../../store/cameraDevice/dashboard/types/pageStateTypes";
import {
    State as StreamPreviewsState
} from "../../../../store/cameraDevice/dashboard/types/streamPreviewsTypes";
import {
    State as ToolbarState
} from "../../../../store/cameraDevice/dashboard/types/toolbarTypes";
import { AppState } from '../../../../store';
//redux-actions
import {
    loadDatasources,
} from "../../../../store/cameraDevice/dashboard/actions/toolbarActions";
import {
    updateAccessLevels,
    updatePageState,
} from "../../../../store/cameraDevice/dashboard/actions/pageStateActions";
//components
import { ThemedDropdown } from "@caps-mobile/themed-dropdown";
import { Center } from "@algo/capsstrap";
import { IconLabel } from "@algo/icon-label";
import { Row, Col } from "reactstrap";
// constants
import { 
    StreamAccessLevelOptions, ACCESS_LEVEL_TOOLTIP 
} from "../../../../utils/AppConstants";

/**************************************************************************
    COMPONENT PROPS INTERFACES
*************************************************************************/
// redux store props
interface StateProps {
    pageState: PageState;
    previewsState: StreamPreviewsState;
    toolbarState: ToolbarState;
}
// redux action props
interface DispatchProps {
    updatePageState: typeof updatePageState;
    loadDatasources: typeof loadDatasources;
    //updateAccessLevels: typeof updateAccessLevels;
}
// redux store mapper
let mapStateToProps = (state: AppState) => {
    return {
        pageState: state.streamPageState,
        previewsState: state.streamPreviews,
        toolbarState: state.streamToolbar
    }
}
// redux action mapper
let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            updatePageState: updatePageState,
            loadDatasources: loadDatasources,
            //updateAccessLevels: updateAccessLevels
        },
        dispatch
    );
}

type IProps = StateProps & DispatchProps & RouteComponentProps;

export let AccessLevelRow: React.FC<IProps> = (props) => {

    /*************************************************************************|
        CALCULATED VALUES & BREVITY NAMES                                 
    |*************************************************************************/
    const editMode: boolean = props.pageState.editMode;

    const sizeClass: EBreakpoint = useWatchSizeClass();
    const isSmallMax: boolean = isMaxSize(sizeClass, EBreakpoint.SM);

    const camsLoading: boolean = props.previewsState.cameraData.isLoading;

    let accessLevelOptions: string[] = StreamAccessLevelOptions.map(
        (value: string) => {
            return value;
        }
    )

    let accessLevelSelected: boolean = nuon(props.pageState.selectedAccessLevel);                       // check if an access level has been selected
        
    let hasSelected: boolean = props.pageState.selectedStreams.length > 0;                              // confirm some cameras are selected

    let updateCamsOk: boolean =                                                                         // used to toggle update button between active/disabled
        !camsLoading &&
        accessLevelSelected &&
        hasSelected;

    /*************************************************************************|
        EFFECTS                                 
    |*************************************************************************/
    React.useEffect(
        () => {
            // intialize bootstrap/popper tooltips
            if(editMode) $('[data-toggle="tooltip"]').tooltip();

            // ensure that all tooltips are hidden when component UnMounts
            return () => { $('[data-toggle="tooltip"]').tooltip('hide'); }
        }, [editMode]
    );

    /*************************************************************************|
        PRIMARY RENDER
    |*************************************************************************/
    return (
        <Row className="my-2" id="access-level-row">
            <Col
                xs={12} sm={4} md={3} lg={3} xl={2}
                className="mb-1 mb-sm-0 px-0 pt-2"
            >
                <Center>
                    <IconLabel
                        iconClass={"fas fa-info-circle"}
                        iconStyle={{ color: "#22A7F0" }}
                        label={"Access Level"} labelFirst={true}
                        iconAttributes={{
                            "data-toggle": "tooltip",
                            "data-position": "bottom",
                            "title": ACCESS_LEVEL_TOOLTIP,
                        }}
                    />
                </Center>
            </Col>
            <Col
                xs={6} sm={4} md={5} lg={6} xl={4}
                className={"pl-0 pl-xl-3"}
            >
                <Center>
                    <ThemedDropdown
                        options={accessLevelOptions}
                        selectedIndex={accessLevelOptions.indexOf(props.pageState.selectedAccessLevel)}
                        placeholder={"Access Level..."}
                        disabled={camsLoading} 
                        noDropBorder={false} menuAttached={false}
                        searchable={true} scrollThreshold={7}
                        styleObject={{"StyledSelectionInput": "font-size: 1rem;"}}
                        callback={(newIndex: number) => {
                            props.updatePageState({
                                ...props.pageState,
                                selectedAccessLevel: 
                                    EATStreamAccessLevel[
                                        accessLevelOptions[newIndex] as keyof typeof EATStreamAccessLevel
                                    ]
                            })
                        }}
                    />
                </Center>
            </Col>
            <Col className="pr-0">
                <Center>
                    <button
                        className="btn btn-outline-primary"
                        style={{ width: "100%", borderRadius: "4px" }}
                        onClick={() => {
                            (updateCamsOk) && props.updatePageState({
                                ...props.pageState,
                                showUpdateModal: true,
                            })
                        }}
                        disabled={!updateCamsOk}
                    >
                        <IconLabel
                            label={!isSmallMax ? "Update Selected" : undefined}
                            labelFirst={false} iconClass={"fas fa-save"}
                        />
                    </button>
                </Center>
            </Col>
        </Row>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccessLevelRow));