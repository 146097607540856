import { 
    IAAOrganization, IAARole, IAAUserForm 
} from "@algo/network-manager/models/v3/admin";
import { 
    ICASComplexityRequirement, ICASPasswordValidation 
} from "@algo/network-manager/models/v3/cas";

export let LOAD_USER_EDITOR_BEGIN = 'LOAD_USER_EDITOR_BEGIN';
export let LOAD_USER_EDITOR_SUCCESS = 'LOAD_USER_EDITOR_SUCCESS';
export let LOAD_USER_EDITOR_FAILURE = 'LOAD_USER_EDITOR_FAILURE';

export let SAVE_USER_BEGIN = 'SAVE_USER_BEGIN';
export let SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export let SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

export let CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export let CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export let CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export let CHANGE_PASSWORD_CLEAR = 'CHANGE_PASSWORD_CLEAR';

export let VALIDATE_PASSWORD_BEGIN = 'VALIDATE_PASSWORD_BEGIN';
export let VALIDATE_PASSWORD_SUCCESS = 'VALIDATE_PASSWORD_SUCCESS';
export let VALIDATE_PASSWORD_FAILURE = 'VALIDATE_PASSWORD_FAILURE';

export let CLEAR_USER_EDITOR_ERROR = 'CLEAR_USER_EDITOR_ERROR';

export let UNINITIALIZE_USER_EDITOR = 'UNINITIALIZE_USER_EDITOR';

export let RESET_PASSWORD_EMAIL_BEGIN = 'RESET_PASSWORD_EMAIL_BEGIN';
export let RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';
export let RESET_PASSWORD_EMAIL_FAILURE = 'RESET_PASSWORD_EMAIL_FAILURE';
export let RESET_PASSWORD_EMAIL_CLEAR = 'RESET_PASSWORD_EMAIL_CLEAR';

export interface UserEditorState {
    initializing: boolean;
    userLoaded: boolean;
    savingUser: boolean;
    validatingPassword: boolean;
    changingPassword: boolean;
    changePasswordSuccess: boolean;
    sendingResetPasswordEmail: boolean;
    sendResetPasswordEmailSuccess: boolean;
    userForm: IAAUserForm;
    roleList: IAARole[];
    organizationList: IAAOrganization[];
    complexityRequirements: ICASComplexityRequirement;
    passwordValidation: ICASPasswordValidation;
    error: Error | null;
}

export interface UserEditorPayload {
    userForm: IAAUserForm;
    roleList: IAARole[];
    organizationList: IAAOrganization[];
    complexityRequirements: ICASComplexityRequirement;
    passwordValidation: ICASPasswordValidation;
    error: Error | null;
}

interface LoadUserEditorBeginAction {
    type: typeof LOAD_USER_EDITOR_BEGIN;
    payload: UserEditorPayload;
}

interface LoadUserEditorSuccessAction {
    type: typeof LOAD_USER_EDITOR_SUCCESS;
    payload: UserEditorPayload;
}

interface LoadUserEditorFailureAction {
    type: typeof LOAD_USER_EDITOR_FAILURE;
    payload: UserEditorPayload;
}

interface SaveUserBeginAction {
    type: typeof SAVE_USER_BEGIN;
    payload: UserEditorPayload;
}

interface SaveUserSuccessAction {
    type: typeof SAVE_USER_FAILURE;
    payload: UserEditorPayload;
}

interface SaveUserFailureAction {
    type: typeof SAVE_USER_FAILURE;
    payload: UserEditorPayload;
}

interface ChangePasswordBeginAction {
    type: typeof CHANGE_PASSWORD_BEGIN;
    payload: UserEditorPayload;
}

interface ChangePasswordSuccessAction {
    type: typeof CHANGE_PASSWORD_SUCCESS;
    payload: UserEditorPayload;
}

interface ChangePasswordFailureAction {
    type: typeof CHANGE_PASSWORD_FAILURE;
    payload: UserEditorPayload;
}

interface ChangePasswordClearAction {
    type: typeof CHANGE_PASSWORD_CLEAR;
    payload: UserEditorPayload;
}

interface ValidatePasswordBeginAction {
    type: typeof VALIDATE_PASSWORD_BEGIN;
    payload: UserEditorPayload;
}

interface ValidatePasswordSuccessAction {
    type: typeof VALIDATE_PASSWORD_SUCCESS;
    payload: UserEditorPayload;
}

interface ValidatePasswordFailureAction {
    type: typeof VALIDATE_PASSWORD_FAILURE;
    payload: UserEditorPayload;
}

interface ResetPasswordBeginAction {
    type: typeof RESET_PASSWORD_EMAIL_BEGIN;
    payload: UserEditorPayload;
}

interface ResetPasswordSuccessAction {
    type: typeof RESET_PASSWORD_EMAIL_SUCCESS;
    payload: UserEditorPayload;
}

interface ResetPasswordFailureAction {
    type: typeof RESET_PASSWORD_EMAIL_FAILURE;
    payload: UserEditorPayload;
}

interface ResetPasswordClearAction {
    type: typeof RESET_PASSWORD_EMAIL_CLEAR;
    payload: UserEditorPayload;
}

interface ClearUserEditorErrorAction {
    type: typeof CLEAR_USER_EDITOR_ERROR;
    payload: UserEditorPayload;
}

interface UninitializeUserEditorAction {
    type: typeof UNINITIALIZE_USER_EDITOR;
    payload: UserEditorPayload;
}

export type UserEditorTypes = 
    LoadUserEditorBeginAction | 
    LoadUserEditorSuccessAction | 
    LoadUserEditorFailureAction | 
    SaveUserBeginAction | 
    SaveUserSuccessAction | 
    SaveUserFailureAction | 
    ChangePasswordBeginAction | 
    ChangePasswordSuccessAction | 
    ChangePasswordFailureAction | 
    ChangePasswordClearAction | 
    ValidatePasswordBeginAction | 
    ValidatePasswordSuccessAction | 
    ValidatePasswordFailureAction | 
    ResetPasswordBeginAction | 
    ResetPasswordSuccessAction | 
    ResetPasswordFailureAction | 
    ResetPasswordClearAction | 
    ClearUserEditorErrorAction | 
    UninitializeUserEditorAction;