// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// interfaces & models
import { 
    AARole, IAARole, IAAPrivilege, IAAOrganization
} from "@algo/network-manager/models/v3/admin";
import { 
    ReactSelectNumberKeyListItem 
} from '../../../models/ReactSelectListItems';
// styles
import reactSelectStyles from '../../../utils/reactSelectStyles';
// components
import Select from 'react-select';
import Content from '../../base/layout/Content';
import Loading from '../Loading';
// store
import {
    initializeRoleEditor, saveRole, uninitializeRoleEditor
} from '../../../store/role/editor/actions';
// constants
import {
    ROLE_DEFAULT_ID, ROLE_DESCRIPTION, ROLE_PRIVILEGES,
    ROLE_ORGANIZATIONS
} from '../../../utils/AppConstants';
// store
import { RoleEditorState } from '../../../store/role/editor/types';
import { AppState } from '../../../store';

interface StateProps { 
    editor: RoleEditorState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        editor: state.roleEditor
    };
};

interface DispatchProps {
    initialize: typeof initializeRoleEditor;
    save: typeof saveRole;
    uninitialize: typeof uninitializeRoleEditor;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeRoleEditor,
        save: saveRole,
        uninitialize: uninitializeRoleEditor
    }, dispatch);
};

type RoleEditorPageProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface RoleEditorPageState {
    roleId: number;
    initialized: boolean;
    role: IAARole;
    selectedPrivileges: ReactSelectNumberKeyListItem[];
    selectedOrganizations: ReactSelectNumberKeyListItem[];
}

class RoleEditor extends React.Component<RoleEditorPageProps, RoleEditorPageState> {
    constructor(props: Readonly<RoleEditorPageProps>) {
        super(props);

        this.state = {
            roleId: this.props.match.params.roleId ? this.props.match.params.roleId : ROLE_DEFAULT_ID,
            initialized: !this.props.match.params.roleId,
            role: new AARole(),
            selectedPrivileges: [],
            selectedOrganizations: []
        } as RoleEditorPageState;
    }

    componentDidMount() {
        this.props.initialize(this.state.roleId);
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (prevProps.editor.savingRole && !this.props.editor.savingRole && this.props.editor.error === null) {
            this.props.history.replace(`/role/${this.props.editor.role.id}`);
        }

        if ((this.props.editor.roleLoaded && !this.state.initialized)) {
            this.setState((prevState, props) => {
                let selectedPrivilege = 
                    props.editor.privilegeList.filter((privilege: IAAPrivilege) => {
                        return props.editor.role.privilegeId?.includes(privilege.id);
                    })
                        .map((privilege: IAAPrivilege) => {
                            return {
                                value: privilege.id,
                                label: privilege.description
                            } as ReactSelectNumberKeyListItem;
                        });

                let selectedOrganization = 
                    props.editor.organizationList.filter(
                        (organization: IAAOrganization) => {
                            return props.editor.role.organizationId?.includes(organization.organizationId);
                        }
                    ).map(
                        (organization: IAAOrganization) => {
                            return {
                                value: organization.organizationId,
                                label: organization.name
                            } as ReactSelectNumberKeyListItem;
                        }
                    );

                return {
                    roleId: prevState.roleId === ROLE_DEFAULT_ID ? props.editor.role.id : prevState.roleId,
                    role: props.editor.role,
                    selectedPrivileges: selectedPrivilege,
                    selectedOrganizations: selectedOrganization,
                    initialized: true
                };
            });
        }

    }

    componentWillUnmount() {
        this.props.uninitialize();
    }

    setReactSelectFormItemValue = (
        property: string,
        event: any
    ): void => {

        var role = this.state.role;

        this.setState((state, props) => {
            switch (property) {
                case ROLE_PRIVILEGES:
                    let privilegeValues = 
                        event as ReactSelectNumberKeyListItem[];

                    if (privilegeValues !== null) {
                        role.privilegeId = privilegeValues
                        .map(item => {
                            return item.value;
                        });
        
                    }
    
                    return {
                        ...state,
                        role: role,
                        selectedPrivileges: privilegeValues
                    };
                case ROLE_ORGANIZATIONS:
                    let organizationValues = 
                        event as ReactSelectNumberKeyListItem[];

                    if (organizationValues !== null) {
                        role.organizationId = organizationValues
                        .map(item => {
                            return item.value;
                        });
                    }
    
                    return {
                        ...state,
                        role: role,
                        selectedOrganizations: organizationValues
                    };
                default:
                    break;
            }
        });
    }

    setFormItemValue = (
        property: string, 
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        
        event.preventDefault();

        let currentValue = event.currentTarget.value;

        this.setState((state, props) => {
            var role = state.role;

            switch (property) {
                case ROLE_DESCRIPTION:
                    role.description = currentValue;
                    role.name = currentValue.replace(/\s/g, '');

                    return {
                        ...state,
                        role: role
                    };
                default:
                    break;
            }
        });
    }

    submit = (event?: React.FormEvent<HTMLFormElement>): void => {
        if (event) {
            event.preventDefault();
        }

        this.props.save(this.state.role); 
    }

    back = (): void => {
        this.props.history.goBack();
    }

    render() {
        let { editor } = this.props;

        let privOptions = editor.privilegeList.map((priv, index) => {
            return {
                value: priv.id,
                label: priv.description
            } as ReactSelectNumberKeyListItem;
        });

        let orgOptions = editor.organizationList.map((org, index) => {
            return {
                value: org.organizationId,
                label: org.name
            } as ReactSelectNumberKeyListItem;
        });


        let content = !editor.initializing ? (
            <div className='av-admin-editor-content'>
                <h1 className='sr-only'>Role Form</h1>
                <div className='av-user-editor'>
                    <div className='av-feedback-detail-section titlebar'>
                        <div className='av-back-action'>
                            <div className='col-2'>
                                <button 
                                    className='btn btn-outline-primary' 
                                    onClick={this.back}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </button>
                            </div>
                            <div className='av-toolbar-title'>
                                <h1>
                                    {
                                        ( this.state.role.id === ROLE_DEFAULT_ID ) 
                                            ? `New Role` 
                                            : `Edit Role`
                                    }
                                </h1>
                            </div>
                            <div className='col-2 text-right'>

                            </div>
                        </div>
                    </div>
                    <div className='av-admin-form'>
                        <form onSubmit={this.submit}>
                            <h2>Role Information</h2>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='txtDescription' 
                                    className='av-admin-editor-form-label'
                                >
                                    Description
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <input
                                        id='txtDescription'
                                        type='text'
                                        className='form-control'
                                        placeholder='Description'
                                        value={this.state.role.description}
                                        onChange={
                                            (event) => 
                                                this.setFormItemValue(
                                                    ROLE_DESCRIPTION, 
                                                    event
                                                )
                                        }
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label 
                                    htmlFor='ddlPrivilege' 
                                    className='av-admin-editor-form-label'
                                >
                                    Privileges
                                </label>
                                <div className='av-admin-editor-form-item'>
                                    <Select
                                        id='ddlPrivilege'
                                        isMulti
                                        className={`av-selector-container`}
                                        classNamePrefix={`av-selector`}
                                        styles={reactSelectStyles}
                                        options={privOptions}
                                        placeholder={`Privileges`}
                                        value={this.state.selectedPrivileges}
                                        onChange={(event) => this.setReactSelectFormItemValue(ROLE_PRIVILEGES, event)}
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <label htmlFor='ddlPrivilege' className='av-admin-editor-form-label'>Available to Organizations</label>
                                <div className='av-admin-editor-form-item'>
                                    <Select
                                        id='ddlOrganizations'
                                        isMulti
                                        className={`av-selector-container`}
                                        classNamePrefix={`av-selector`}
                                        styles={reactSelectStyles}
                                        options={orgOptions}
                                        placeholder={`Available to Organizations`}
                                        value={this.state.selectedOrganizations}
                                        onChange={(event) => this.setReactSelectFormItemValue(ROLE_ORGANIZATIONS, event)}
                                    />
                                </div>
                            </div>
                            <div className='av-admin-editor-form-row'>
                                <button
                                    type={`submit`}
                                    className='btn btn-block btn-outline-primary'
                                    disabled={editor.savingRole}
                                >
                                    <span className={`save-icon ${editor.savingRole ? 'saving' : ''}`}></span> Save
                                </button>
                            </div>
                        </form>                        
                    </div>
                </div>
            </div>
        ) : (<Loading />)

        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        {content}
                    </Content>
                </div>
            </div>
        );
    }

}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(RoleEditor)); 