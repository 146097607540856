//libraries
import * as React from "react";
import { hasChanged, usePrevious } from "../../../utils/hooks/usePrevious";
import { nuon } from "../../../utils/data/nuon";
//enum
import { ELogType } from "./LogSummary";
//interfaces and models
import { 
    IATIncomingStreamLog, IATPushPublishEntryLog
 } from "@algo/network-manager/models/v3";
// enums
import { EATStreamServerLogSource } from "@algo/network-manager/models/v3";
//components
import { Container, Row, Col } from "reactstrap";
import { Center } from "@algo/capsstrap";
import { RemoveScrollbar } from "@algo/algo-styles";
import { Loading } from "@algo/loading";
import { Pager } from "@algo/pager";
import { LogSummary } from "./LogSummary";
import { StreamLogMessage } from "./StreamLogMessage";
//constants
import { D_GET_CAMERA_DEVICE_LOGS } from "@algo/network-manager/managers/v3";
const NO_LOGS = "No Logs";

interface IPagedTab {
    logs: Array<IATIncomingStreamLog | IATPushPublishEntryLog>;
    loadLogs: any;
    navIndex: EATStreamServerLogSource;
    deviceId: number;
    loadingLogs: boolean;
    error: Error | null;
    noData: boolean;
    type: ELogType;
    pageCount: number;
    pageIndex: number;
    perPage: number;
    updatePageIndex: (newIndex: number) => void;
}

export const PagedTab: React.FC<IPagedTab> = (props) => {

    const {
        logs, loadLogs, navIndex,
        deviceId, loadingLogs, error, noData,
        type, pageCount, pageIndex, perPage,
        updatePageIndex
    } = {...props};

    const prevProps = usePrevious(props);

    /**************************************
        EFFECTS
    **************************************/
    // Load Streaming Logs
    React.useEffect(
        () => {
            if ( // if there are no logs, and no error 
                (!nuon(logs) && !error && !noData) ||
                ( // or if the perPage or pageIndex or navIndex values changed
                    hasChanged(perPage, prevProps?.perPage) || 
                    hasChanged(pageIndex, prevProps?.pageIndex) ||
                    navIndex !== prevProps?.navIndex
                )
            ){  // and only if you aren't already loading logs
                if (!loadingLogs) {
                    loadLogs(
                        {
                            ...D_GET_CAMERA_DEVICE_LOGS,
                            source: navIndex,
                            id: deviceId,
                            skip: (pageIndex * perPage),
                            take: perPage
                        }
                    );
                }
            }
        }, [
            logs, error, loadingLogs, navIndex, 
            deviceId, pageIndex, perPage, loadLogs,
            prevProps?.perPage, prevProps?.pageIndex
        ]
    );

    /**************************************
        ERROR RENDER
    **************************************/
    if (error) {
        if (error && error.toString() === NO_LOGS)
            return <StreamLogMessage type="null" />
        else
            return <StreamLogMessage type="error" message={`${error.message}`} />
    }

    /**************************************
        LOADING RENDER
    **************************************/
    if (loadingLogs) {
        return (
            <Center className="h-100">
                <Loading />
            </Center>
        );
    }

    /**************************************
        NULL RENDER
    **************************************/
    if ((!logs || noData) && !loadingLogs) {
        return <StreamLogMessage type="null" />
    }

    /**************************************
        PRIMARY RENDER
    **************************************/
    
    return (
        <Container fluid={true} className="h-100" id="logContainer">
            <RemoveScrollbar 
                className="row" 
                style={{ maxHeight: `${window.innerHeight - 668}px` }}
            >
                <Col xs={12} className="p-0">
                    {
                        logs.map(
                            (log, mapIndex) => {
                                return (
                                    <div className="" key={Math.random()}>
                                        <LogSummary
                                            log={log}
                                            type={type}
                                            last={mapIndex === logs.length - 1}
                                        />
                                    </div>
                                )
                            }
                        )
                    }
                </Col>
            </RemoveScrollbar>

            <Row className="row mt-2 d-flex justify-content-end" style={{ height: "42px" }}>
                <Col xs="12" className="d-flex justify-content-center p-0">
                    <Pager
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        updatePageIndex={
                            (newIndex: number) => { 
                                updatePageIndex(newIndex) 
                            }
                        }
                        ellipsisThreshold={10}
                        ellipsisOffset={2}
                        size={"xs"}
                        backgroundColorActive={"old-algo-green"}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default PagedTab;