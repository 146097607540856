// libraries
import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// store
import { unloadUserProfile } from '../../../store/profile/actions';
import { ProfileState } from '../../../store/profile/types';
import { AppState } from '../../../store';
import { AuthContextProps } from 'react-oidc-context';
// components

declare var __AUTHORITY_URL__: string;
declare var __CLIENT_URL__: string;

interface StateProps {
    profile: ProfileState;
}

interface AuthProps {
    auth: AuthContextProps;
}

let mapStateToProps = (state: AppState) => {
    return {
        profile: state.profile
    };
}

interface DispatchProps {
    unloadUserProfile: typeof unloadUserProfile;
}

let  mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        unloadUserProfile: unloadUserProfile
    }, dispatch);
}

type AuthenticationButtonProps = 
    StateProps & 
    DispatchProps & 
    AuthProps &
    RouteComponentProps<any>;

interface AuthenticationButtonState {
    isLoading: boolean;
};

class AuthenticationButton 
    extends React.Component<
        AuthenticationButtonProps, 
        AuthenticationButtonState
> {

    constructor(props: Readonly<AuthenticationButtonProps>) {
        super(props);

        this.state = {
            isLoading: false
        } as AuthenticationButtonState;
    }

    signIn = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        this.props.auth.signinRedirect();
    }

    signOut = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        this.props.auth.signoutRedirect();
    }

    render() {
        const { location } = this.props;
        const signInClassName: string = 
            `av-auth-btn av-auth-signin-btn my-2 my-sm-0 mr-lg-0 mr-sm-0` + 
                `${( this.state.isLoading ) ? 'loading' : ''}`;

        if (this.props.auth.user && !this.props.auth.user.expired) {
            return (
                <div className={`btn-group my-2 my-sm-0 mr-lg-0 mr-sm-0`}>
                    <div className="btn-group dropdown" role="group">
                        <button 
                            type="button" data-toggle="dropdown" 
                            className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" 
                            aria-haspopup="true" aria-expanded="false"
                        >
                            <span className="sr-only">
                                Toggle user menu
                            </span>
                        </button>
                        <div className="dropdown-menu">
                            <button 
                                className={`dropdown-item`} 
                                onClick={this.signOut}
                            >
                                Sign Out
                            </button>
                        </div>
                    </div>
                    <button 
                        type="button" 
                        className={`av-auth-btn av-auth-signout-btn`}
                    >
                        <span className={`av-auth-btn-label`}></span> 
                        {this.props.auth.user.profile.preferred_username}
                    </button>
                </div>
            );
        }
        else if (location.pathname !== '/callback') {
            return (
                <button className={signInClassName} onClick={this.signIn}>
                    <span className='av-auth-btn-label'></span>
                    Sign In
                </button>
            );
        }
        else {
            return null;
        }
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(AuthenticationButton));