import * as TYPES from "./types";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";

let initialState: TYPES.ProfileState = {
    userProfile: new AAUserAuthorizationProfile(),
    loading: false,
    initialized: false,
    error: null
}

export function profileReducer(
    state = initialState, 
    action: TYPES.ProfileTypes
): TYPES.ProfileState {

    switch(action.type) {
        case TYPES.LOAD_PROFILE_BEGIN:
            return {
                ...state,
                loading: true
            };
        case TYPES.LOAD_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                initialized: true,
                userProfile: new AAUserAuthorizationProfile(action.payload.userProfile)
            };
        case TYPES.LOAD_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_PROFILE_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNLOAD_PROFILE:
            return initialState;
        default:
            return state;
    }
}