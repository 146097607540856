// libraries
import * as React from "react";
import styled from "styled-components";
// components
import { Body1 } from "@algo/algo-styles";
import { getSiteValue, siteColors } from "@algo/algo-styles";

export interface IProps {
    backgroundColor?: string;
    borderRadius?: string;
}

export const NoCameras: React.FC<IProps> = (props) => {

    /*************************************************************************
        CALCULATED VALUES & BREVITY NAMES
     ************************************************************************/
    const grey: string = getSiteValue(siteColors, "default-grey");                  // default background color
    const bgColor: string = props.backgroundColor ? props.backgroundColor : grey;   // override if User provided background color
    const bRadius: string = props.borderRadius ? props.borderRadius : "4px";        // override if User provided border radius

    /*************************************************************************
        PRIMARY RENDER
     ************************************************************************/
    return (
        <StyledNoCameras
            backgroundColor={bgColor}
            borderRadius={bRadius}
        >
            <Body1 weight={"bold"}>
                No Cameras
            </Body1>
        </StyledNoCameras>
    );
}

/*************************************************************************
    STYLES
************************************************************************/
interface IStyledNoCameras {
    backgroundColor: string;
    borderRadius: string;
}

const StyledNoCameras = styled.div<IStyledNoCameras>`
    background-color: ${props => props.backgroundColor};
    border-radius: ${props => props.borderRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;