// libraries
import * as React from "react";
import styled from "styled-components";
// hooks
// @ts-ignore
import { useWatchWindowSize } from "@algo/window-sizer/WindowSizer";
import { SidebarContext as _SidebarContext } from "../../../store/context/SidebarContext";
// components
// @ts-ignore
import { Center } from "@algo/capsstrap";
import { Pill } from "@algo/pill";
// constants
const selfWidth: number = 165;
const sidebarWidth: number = 256;

export interface IToTopBotton {
    show?: boolean;
    onClick?: () => void;
}

export const ToTopButton: React.FC<IToTopBotton> = (props) => {

    /******************************************************** 
        CALCULATED VALUES & BREVITY NAMES
     *******************************************************/
    const clickAttribute: any = (props.onClick)
        ? { onClick: props.onClick }
        : {};

    /******************************************************** 
        STATE
     *******************************************************/
    const windowSize = useWatchWindowSize(                                          // watch the window width / height
        {
            width: window.innerWidth,
            height: window.innerHeight
        });

    const SidebarContext = React.useContext(_SidebarContext);

    /******************************************************** 
        UTILITIES & HANDLERS
     *******************************************************/
    const left =                                                                    // left offset for absolute position
        (windowSize.width / 2) 
        - (selfWidth / 2)                                                           // account for width of self div
        + (SidebarContext.showSidebar ? (sidebarWidth / 2) : 0);                    // conditionally account for sidebar open/close

    if (!props.show) return null;

    return (
        <StyledToTop bottom={75} left={left}>
            <Center>
                <div {...clickAttribute}>
                    <Pill
                        label="Back to top"
                        pillClass="to-top-pill"
                        iconForegroundClass="fas fa-chevron-up to-top-pill-icon"
                    />
                </div>
            </Center>
        </StyledToTop>
    );


}

export default ToTopButton;

/********************************************************
    STYLES
*******************************************************/

interface IStyledToTop {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}

const StyledToTop = styled.div<IStyledToTop>`
    position: absolute;
    top: ${props => props.top ? `${props.top}px` : "auto"};
    right: ${props => props.right ? `${props.right}px` : "auto"};
    bottom: ${props => props.bottom ? `${props.bottom}px` : "auto"};
    left: ${props => props.left ? `${props.left}px` : "auto"};

    transition: all .5s ease-out;
`;