import { IATLegacyDatasource } from "@algo/network-manager/models/v3";
import { IAARole, IAAOrganization } from "@algo/network-manager/models/v3/admin";
import { ICASUserProfile } from "@algo/network-manager/models/v3/cas";

export let LOAD_ORGANIZATION_BEGIN = 'LOAD_ORGANIZATION_BEGIN';
export let LOAD_ORGANIZATION_SUCCESS = 'LOAD_ORGANIZATION_SUCCESS';
export let LOAD_ORGANIZATION_FAILURE = 'LOAD_ORGANIZATION_FAILURE';

export let SAVE_ORGANIZATION_BEGIN = 'SAVE_ORGANIZATION_BEGIN';
export let SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS';
export let SAVE_ORGANIZATION_FAILURE = 'SAVE_ORGANIZATION_FAILURE';

export let LOAD_USER_SEARCH_BEGIN = 'LOAD_USER_SEARCH_BEGIN';
export let LOAD_USER_SEARCH_SUCCESS = 'LOAD_USER_SEARCH_SUCCESS';
export let LOAD_USER_SEARCH_FAILURE = 'LOAD_USER_SEARCH_FAILURE';

export let ADD_USER_TO_ORGANIZATION_BEGIN = 'ADD_USER_TO_ORGANIZATION_BEGIN';
export let ADD_USER_TO_ORGANIZATION_SUCCESS = 'ADD_USER_TO_ORGANIZATION_SUCCESS';
export let ADD_USER_TO_ORGANIZATION_FAILURE = 'ADD_USER_TO_ORGANIZATION_FAILURE';

export let REMOVE_USER_FROM_ORGANIZATION_BEGIN = 'REMOVE_USER_FROM_ORGANIZATION_BEGIN';
export let REMOVE_USER_FROM_ORGANIZATION_SUCCESS = 'REMOVE_USER_FROM_ORGANIZATION_SUCCESS';
export let REMOVE_USER_FROM_ORGANIZATION_FAILURE = 'REMOVE_USER_FROM_ORGANIZATION_FAILURE';

export let CLEAR_ORGANIZATION_EDITOR_ERROR = 'CLEAR_ORGANIZATION_EDITOR_ERROR';

export let UNINITIALIZE_ORGANIZATION_EDITOR = 'UNINITIALIZE_ORGANIZATION_EDITOR';

export interface OrganizationEditorState {
    initializing: boolean;
    organizationLoaded: boolean;
    loadingUserSearch: boolean;
    savingOrganization: boolean;
    addingUser: boolean,
    removingUser: boolean;
    organization: IAAOrganization;
    organizationList: IAAOrganization[];
    roleList: IAARole[];
    organizationUserList: ICASUserProfile[];
    userSearchList: ICASUserProfile[];
    datasourceList: IATLegacyDatasource[];
    error: Error | null;
}

export interface OrganizationEditorPayload {
    organization: IAAOrganization;
    organizationList: IAAOrganization[];
    roleList: IAARole[];
    organizationUserList: ICASUserProfile[];
    userSearchList: ICASUserProfile[];
    datasourceList: IATLegacyDatasource[];
    error: Error | null;
}

interface LoadOrganizationEditorBeginAction {
    type: typeof LOAD_ORGANIZATION_BEGIN;
    payload: OrganizationEditorPayload;
}

interface LoadOrganizationEditorSuccessAction {
    type: typeof LOAD_ORGANIZATION_SUCCESS;
    payload: OrganizationEditorPayload;
}

interface LoadOrganizationEditorFailureAction {
    type: typeof LOAD_ORGANIZATION_SUCCESS;
    payload: OrganizationEditorPayload;
}

interface LoadUserSearchBeginAction {
    type: typeof LOAD_USER_SEARCH_BEGIN;
    payload: OrganizationEditorPayload;
}

interface LoadUserSearchSuccessAction {
    type: typeof LOAD_USER_SEARCH_SUCCESS;
    payload: OrganizationEditorPayload;
}

interface LoadUserSearchFailureAction {
    type: typeof LOAD_USER_SEARCH_FAILURE;
    payload: OrganizationEditorPayload;
}

interface SaveOrganizationBeginAction {
    type: typeof SAVE_ORGANIZATION_BEGIN;
    payload: OrganizationEditorPayload;
}

interface SaveOrganizationSuccessAction {
    type: typeof SAVE_ORGANIZATION_SUCCESS;
    payload: OrganizationEditorPayload;
}

interface SaveOrganizationFailureAction {
    type: typeof SAVE_ORGANIZATION_FAILURE;
    payload: OrganizationEditorPayload;
}

interface AddUserToOrganizationBeginAction {
    type: typeof ADD_USER_TO_ORGANIZATION_BEGIN;
    payload: OrganizationEditorPayload;
}

interface AddUserToOrganizationSuccessAction {
    type: typeof ADD_USER_TO_ORGANIZATION_SUCCESS;
    payload: OrganizationEditorPayload;
}

interface AddUserToOrganizationFailureAction {
    type: typeof ADD_USER_TO_ORGANIZATION_FAILURE;
    payload: OrganizationEditorPayload;
}

interface RemoveUserFromOrganizationBeginAction {
    type: typeof REMOVE_USER_FROM_ORGANIZATION_BEGIN;
    payload: OrganizationEditorPayload;
}

interface RemoveUserFromOrganizationSuccessAction {
    type: typeof REMOVE_USER_FROM_ORGANIZATION_SUCCESS;
    payload: OrganizationEditorPayload;
}

interface RemoveUserFromOrganizationFailureAction {
    type: typeof REMOVE_USER_FROM_ORGANIZATION_FAILURE;
    payload: OrganizationEditorPayload;
}

interface ClearOrganizationEditorErrorAction {
    type: typeof CLEAR_ORGANIZATION_EDITOR_ERROR;
    payload: OrganizationEditorPayload;
}

interface UninitializeOrganizationEditorAction {
    type: typeof UNINITIALIZE_ORGANIZATION_EDITOR;
    payload: OrganizationEditorPayload;
}

export type OrganizationEditorTypes = 
    LoadOrganizationEditorBeginAction | 
    LoadOrganizationEditorSuccessAction | 
    LoadOrganizationEditorFailureAction | 
    LoadUserSearchBeginAction | 
    LoadUserSearchSuccessAction | 
    LoadUserSearchFailureAction | 
    SaveOrganizationBeginAction | 
    SaveOrganizationSuccessAction | 
    SaveOrganizationFailureAction | 
    AddUserToOrganizationBeginAction | 
    AddUserToOrganizationSuccessAction | 
    AddUserToOrganizationFailureAction | 
    RemoveUserFromOrganizationBeginAction | 
    RemoveUserFromOrganizationSuccessAction | 
    RemoveUserFromOrganizationFailureAction | 
    ClearOrganizationEditorErrorAction | 
    UninitializeOrganizationEditorAction;