// libraries
import * as React from "react";
// constants
declare var __API_URL__: string;
const API_VERSION: string = "v3.0";

export const useGetData = (apiString: string) => {

    const [data, setData] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [error, setError] = React.useState(null);

    React.useEffect(
        () => {

            setLoading(true);
            fetch(`${__API_URL__}/${API_VERSION}/${apiString}`)
                .then( response => response.json())
                .then( (data) => {
                    setData(data);
                    setLoading(false);
                    setLoaded(true);
                })
                .catch( (error) => {
                    setLoading(false);
                    setError(error);
                });

            // cleanup
            return () => {}
        }, []
    )

    return {data, loading, loaded, error};
};

export default useGetData;