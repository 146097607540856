//libraries
import * as React from "react";
//interfaces and models
import { 
    IATCameraDevice, IATCloudStreamTarget
} from "@algo/network-manager/models/v3";
//enums
import { EATStreamAccessLevel } from "@algo/network-manager/models/v3";
//components
import { Container, Row, Col } from "reactstrap";
import { CameraPreview } from "./CameraPreview";
import { CloudStreamTarget } from "./CloudStreamTarget/CloudStreamTarget";
import StreamLogNavigator from "./StreamLogNavigator";
import { H3, FixedHeight } from "@algo/algo-styles";

interface IProps {
    //store data
    device: IATCameraDevice;
    pageState: any;
    //store actions
    updateAccessLevel: (
        cstId: IATCloudStreamTarget, 
        accessLevel: EATStreamAccessLevel
    ) => any;
    updatePageState: any;
    //store action locks
    updatingAccessLevel: boolean;
}

export const CameraDeviceDetails: React.FC<IProps> = (props) => {

    /*************************************************************
            CALCULATED VALUES & BREVITY NAMES
     ************************************************************/

    const device: IATCameraDevice = props.device;

    const cloudStreamTarget: IATCloudStreamTarget | undefined =
        (
            device.cloudStreamTargets &&
            device.cloudStreamTargets.length > 0
        )
            ? device.cloudStreamTargets[0]
            : undefined

    const accessLevel: EATStreamAccessLevel =
        (device.cloudStreamTargets.length > 0)
            ? device.cloudStreamTargets[0].accessLevel
            : EATStreamAccessLevel.Unknown

    /*************************************************************
            CALLBACKS AND HANDLERS
     ************************************************************/

    const updateAccessLevel = (newLevel: EATStreamAccessLevel): void => {
        props.updatePageState({ ...props.pageState, accessChange: true });
        props.updateAccessLevel(props.device.cloudStreamTargets[0], newLevel);
    }

    /*************************************************************
            PRIMARY RENDER
     ************************************************************/
    return (
        <Container fluid={true} className="">

            {/* Header Row */}
            <Row className="mt-3 mb-2">
                <Col xs="6">
                    <H3>Device</H3>
                </Col>
                <Col xs="6">
                    <H3>Public Stream</H3>
                </Col>
            </Row>

            {/* Summary Row */}
            <Row className="mb-2">
                <Col xs="6" className="pr-3">
                    <FixedHeight height={358}>
                        <CameraPreview
                            cameraDevice={props.device}
                            gmap={{mapHeight: 225}}
                            pillClasses={{
                                [`${device.name}`]: `pill-secondary`,
                                [accessLevel]: 'pill-secondary',
                            }}
                        />
                    </FixedHeight>
                </Col>
                <Col xs="6" className="pl-3">
                    <FixedHeight height={358}>
                        <CloudStreamTarget
                            cloudStreamTarget={cloudStreamTarget}
                            videoHeight={225}
                            updateAccessLevel={
                                (newLevel: EATStreamAccessLevel) => {
                                    updateAccessLevel(newLevel)
                                }
                            }
                            updatingAccessLevel={props.updatingAccessLevel}
                        />
                    </FixedHeight>
                </Col>
            </Row>

            {/* Stream Logs Header */}
            <Row className="mt-3 mb-2">
                <Col xs="12" className="d-flex justify-content-start">
                    <H3>Streaming Logs</H3>
                </Col>
            </Row>

            <Row className="row" id="stream-log-row">
                <Col xs="12">
                    <StreamLogNavigator />
                </Col>
            </Row>

        </Container>
    );
}

export default CameraDeviceDetails;