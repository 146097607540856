import * as TYPES from "../types/streamPreviewsTypes";
import { ReduxData, cloneReduxData, IReduxData } from "../../../../utils/ReduxData";
import { ProcessedResponse } from "@algo/network-manager/managers/v3";

let initialState: TYPES.State = {
    cameraData: new ReduxData({ name: "cameraDevices" }),
    error: null,
    lastResponse: new ProcessedResponse()
};

export function streamPreviewsReducer(state = initialState, action: TYPES.Actions): TYPES.State {
    let tempData: ReduxData | null = null;
    switch (action.type) {

        /******************************************************************************
            LOAD CAMERA DEVICES | REDUCERS
        *****************************************************************************/
        case TYPES.LOAD_CAMERA_DEVICES_BEGIN:
            tempData = cloneReduxData(state.cameraData);
            tempData.setLoading(true);
            return {
                ...state,
                cameraData: tempData,
            };
        case TYPES.LOAD_CAMERA_DEVICES_SUCCESS:
            tempData = cloneReduxData(state.cameraData);
            tempData.data = action.payload.devices;
            tempData.checksum = parseInt(action.payload.response.getChecksum());
            tempData.totalCount = action.payload.response.getCount()
            tempData.setLoading(false);
            return {
                ...state,
                cameraData: tempData,
                lastResponse: action.payload.response
            };
        case TYPES.LOAD_CAMERA_DEVICES_FAILURE:
            tempData = cloneReduxData(state.cameraData);
            tempData.setLoading(false);
            return {
                ...state,
                cameraData: tempData,
                error: action.payload.error
            };
        default:
            return state;
    }
}