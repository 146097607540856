// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledEditorTitleRow = styled.div`

    height: 48px;
    width: 100%;

    padding: .25rem 1.5rem .25rem 1.5rem;

    ${flexSmart("center center")}

    border-radius: 4px;
    box-shadow: 2px 4px 5px 0px #00000040;
`;

export const SideSection = styled.div`
    width: 33%;
    height: 100%;
    ${flexSmart("flex-start center")}
`;

export const StyledTitle = styled.div`
    width: 33%;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;

    color: #1e824c; 
`;