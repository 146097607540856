// #region libraries
import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { checkRouteAccess, checkForRedirect } from "./helpers";
import { useAuth } from "react-oidc-context";
// #endregion
// #region styles
import * as SC from "./Styled";
// #endregion
// #region types & interfaces
import { ITemplatePage, ITemplateErrorObject } from "./types";
// #endregion
// #region hooks
import { NavigationContext } from "@caps-mobile/themed-nav";
import { SidebarContext } from "../../store/context/SidebarContext"; 
import { useHistory } from "react-router-dom";
import { useServiceWorker } from "./useServiceWorker";
import { useRefresher } from "./useRefresher";
// #endregion
// #region package components
import {ThemedNav} from "@caps-mobile/themed-nav";
// #endregion
// #region local components
import { CSSTransition } from 'react-transition-group';
import Header from '../base/layout/header/Header';
import Welcome from '../pages/Welcome';
import Redirect from '../pages/login-cycle/Redirect';
import NotFound from '../pages/NotFound';
import AccessDenied from '../pages/login-cycle/AccessDenied';
import Loading from '../pages/Loading';
import UserEditor from '../pages/user/UserEditor';
import UserList from '../pages/user/UserList';
import OrganizationEditor from '../pages/organization/OrganizationEditor';
import OrganizationList from '../pages/organization/OrganizationList';
import FeedbackList from '../pages/feedback/FeedbackList';
import FeedbackDetail from '../pages/feedback/FeedbackDetail';
import CameraDashboard from "../pages/cameraDashboard/CameraDashboard";
import 
    CameraDeviceDetailsWrapper 
from "../pages/cameraDetails/CameraDeviceDetailsWrapper";
import MessageList from '../pages/messages/MessageList';
import MessageEditor from '../pages/messages/MessageEditor';
import RoleList from '../pages/roles/RoleList';
import RoleEditor from '../pages/roles/RoleEditor';
import FacilityList from "../pages/facility/list/FacilityList";
import FacilityEditor from "../pages/facility/editor/FacilityEditor";
import Synchronization from "../pages/synchronization/Synchronization";
import ErrorModal from '../base/modals/ErrorModal';
import SilentRenew from "./authentication/silent-renew/SilentRenew";
import SessionEnded from "./authentication/session-ended/SessionEnded";
// #endregion
// #region store
import { 
    loadUserProfile, clearUserProfileError
} from '../../store/profile/actions';
import { clearDatasourceError } from '../../store/datasource/actions';
import { clearUserEditorError } from '../../store/user/editor/actions';
import { clearUserDashboardError } from '../../store/user/dashboard/actions';
import { clearOrganizationEditorError } from '../../store/organization/editor/actions';
import { clearOrganizationDashboardError } from '../../store/organization/dashboard/actions';
import { clearFeedbackDashboardError } from '../../store/feedback/dashboard/actions';
import { clearFeedbackDetailError } from '../../store/feedback/detail/actions';
import { clearMessageDashboardError } from '../../store/message/dashboard/actions';
import { clearMessageEditorError } from '../../store/message/editor/actions';
import { clearRoleDashboardError } from '../../store/role/dashboard/actions';
import { clearRoleEditorError } from '../../store/role/editor/actions';
import { clearSynchronizationError } from '../../store/synchronization/actions';
import { AppState } from '../../store';
import { userManager } from "./authentication/oidcConfig";
// #endregion
// #region constants
import { PROFILE_REFRESH_INTERVAL } from "../../utils/AppConstants";
import * as PRIVS from '../../models/Privileges';
import * as CONST from "./constants";
import { IAAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
// #endregion

// #region CONNECT BOILERPLATE
const mapStateToProps = (state: AppState) => {
    return {
        datasource: state.datasource,
        profile: state.profile,
        organizationDashboard: state.organizationDashboard,
        organizationEditor: state.organizationEditor,
        userDashboard: state.userDashboard,
        userEditor: state.userEditor,
        feedbackDashboard: state.feedbackDashboard,
        feedbackDetail: state.feedbackDetail,
        cameraDetails: state.cameraDetails,
        messageDashboard: state.messageDashboard,
        messageEditor: state.messageEditor,
        roleDashboard: state.roleDashboard,
        roleEditor: state.roleEditor,
        synchronization: state.synchronization
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getUserProfile: loadUserProfile,
        clearDatasourceError: clearDatasourceError,
        clearUserProfileError: clearUserProfileError,
        clearUserEditorError: clearUserEditorError,
        clearUserDashboardError: clearUserDashboardError,
        clearOrganizationEditorError: clearOrganizationEditorError,
        clearOrganizationDashboardError: clearOrganizationDashboardError,
        clearFeedbackDashboardError: clearFeedbackDashboardError,
        clearFeedbackDetailError: clearFeedbackDetailError,
        clearMessageDashboardError: clearMessageDashboardError,
        clearMessageEditorError: clearMessageEditorError,
        clearRoleDashboardError: clearRoleDashboardError,
        clearRoleEditorError: clearRoleEditorError,
        clearSynchronizationError: clearSynchronizationError
    }, dispatch);
};

// #endregion

export const TemplatePage: React.FC<ITemplatePage> = (props) => {

    /**************************************************************************
        STATE
    **************************************************************************/
    // #region STATE
    const { profile, location, getUserProfile } = props;

    // privileges and navStrings are used to control which nav items are visible in the 
    // nav menu based on which privileges the current userProfile has
    const [privileges, setPrivileges] = 
        React.useState<string[]>(
            profile.userProfile.privileges || 
            []
        );

    const [navStrings, setNavStrings] = 
        React.useState<string[][]>(
            CONST.navStringsPrivilegeFilter(privileges) || 
            [[]]
        );

    // (currently unimplemented, was used to track React.OnCatch)
    const [ errorObject, setErrorObject ] = 
        React.useState<ITemplateErrorObject>({
            hasError: false, error: null, info: null
        });

    // track whether the sidebar should be open or not
    const [ showSidebar, setShowSidebar ] = 
        React.useState<boolean>(shouldShowSidebar(profile.userProfile, navStrings));

    const SidebarContextValue = {
        showSidebar: showSidebar,
        setShowSidebar: (newValue: boolean) => setShowSidebar(newValue)
    };

    // tracks the currently selected page
    const [selectedNavIndex, setSelectedNavIndex] = 
        React.useState<number[]>([-1,0]);

    const NavigationContextValue = {
        selectedNavIndex: selectedNavIndex,
        setSelectedNavIndex: (newValue: number[]) => setSelectedNavIndex(newValue)
    };

    // get a handle for navigating
    const history = useHistory();

    // setup service worker
    const serviceWorker = useServiceWorker();

    // start a page refresher
    const pageRefresher = useRefresher(getUserProfile, PROFILE_REFRESH_INTERVAL);

    // #endregion STATE
    /**************************************************************************
        EFFECTS
    **************************************************************************/
    // #region EFFECTS
    
    // onMount
    React.useEffect(
        () => {
            if (!showSidebar && shouldShowSidebar(profile.userProfile, navStrings))
                setShowSidebar(true)
        }, [profile.userProfile, navStrings]
    )

    // onUpdate
    React.useEffect(
        () => {
            // check for redirect
            checkForRedirect(history.location, userManager, serviceWorker);

            return () => {
                // cleanup
            }
        },
        [history.location]
    );

    // check for navigation
    React.useEffect(
        () => {
            // do not navigate if current pathname is one of oidc's process pages
            const doNotNav = ['/callback', '/silent_renew', '/session_ended']

            if (history.location.pathname === CONST.getNavString(selectedNavIndex, navStrings)) return;
            else if (doNotNav.includes(history.location.pathname)) return;
            else if (history.location.pathname !== CONST.getNavString(selectedNavIndex, navStrings)){
                history.push(CONST.getNavString(selectedNavIndex, navStrings));
            }
                
        },
        [selectedNavIndex]
    )

    // if user profile updates we need to add or remove nav menu links according to privilege
    React.useEffect(
        () => {
            setPrivileges(profile.userProfile.privileges || []);
            setNavStrings(
                CONST.navStringsPrivilegeFilter(
                    profile.userProfile.privileges || []
                ) || 
                [[]]
            );
            return () => {}
        }, [profile.userProfile]
    );
    // #endregion

    // handle clearing a page error
    const dismissError: {[key: string]: () => any} = {
        "datasource": props.clearDatasourceError,
        "userProfile": props.clearUserProfileError,
        "userEditor": props.clearUserEditorError,
        "userDashboard": props.clearUserDashboardError,
        "organizationDashboard": props.clearOrganizationDashboardError,
        "organizationEditor": props.clearOrganizationEditorError,
        "feedbackDashboard": props.clearFeedbackDashboardError,
        "feedbackEditor": props.clearFeedbackDetailError,
        "messageDashboard": props.clearMessageDashboardError,
        "messageEditor": props.clearMessageEditorError,
        "roleDashboard": props.clearRoleDashboardError,
        "roleEditor": props.clearRoleEditorError,
        "sync": props.clearSynchronizationError
    };

    // handle creating a page error
    const injectErrorModal = () => {

        const potentialErrors = [
            props.datasource.error, props.profile.error, props.organizationDashboard.error,
            props.organizationEditor.error, props.userDashboard.error, props.userEditor.error,
            props.feedbackDashboard.error, props.feedbackDetail.error, props.messageDashboard.error,
            props.messageEditor.error, props.roleDashboard.error, props.roleEditor.error, 
            props.synchronization.error
        ];

        potentialErrors.map(
            (error: any, mapIndex: number) => {
                if (error) 
                    return <ErrorModal dismissCallback={dismissError[mapIndex]} error={error} />
                else return null;
            }
        );
    };

    const auth = useAuth();
    const user = auth.user;

    const defaultLanding = (!user || user.expired) 
        ? Redirect 
        : (
            profile.initialized 
                ? AccessDenied 
                : Loading
        );

    /**************************************************************************
            ERROR RENDER
     *************************************************************************/
    // #region ERROR RENDER
    if (errorObject.hasError){
        return (
            <div className='av-fatal-error-container'>
                <div className='av-error-content'>
                    <h1>Something went wrong</h1>
                    <p>Please refresh the page. If problems persist, please contact the system administrator.</p>
                    <div>
                        <button className={`btn btn-primary`} 
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();

                            window.history.go();
                        }}>Reload page</button>
                    </div>
                </div>
            </div>
        );
    }
    // #endregion

    /**************************************************************************
            PRIMARY RENDER
     *************************************************************************/
    // #region PRIMARY RENDER
    try{

        return (
            <SC.StyledTemplate>
                
                <Header showSidebar={showSidebar} toggleShowSidebar={ () => setShowSidebar(!showSidebar)} setRootNavIndex={() => setSelectedNavIndex([-1,0])} />
    
                <SC.StyledContentWrapper>
    
                    <SC.StyledSideNav showSidebar={showSidebar}>
                        <NavigationContext.Provider value={NavigationContextValue}>
                            <ThemedNav navSectionConfig={CONST.navConfigPrivilegeFilter(privileges)} />
                        </NavigationContext.Provider>
                    </SC.StyledSideNav>
    
                    <SidebarContext.Provider value={SidebarContextValue}>
                        <SC.StyledContent showSidebar={showSidebar}>
                            
                                <CSSTransition key={location.key} classNames='fade' timeout={{ enter: 500, exit: 300 }}>
                                    <Switch location={location}>
                                        <Route exact path='/' component={checkRouteAccess(user, profile, []) ? Welcome : defaultLanding} />
                                        <Route exact path='/user' component={checkRouteAccess(user, profile, [PRIVS.VIEW_USER]) ? UserList : defaultLanding} />
                                        <Route exact path='/user/new' component={checkRouteAccess(user, profile, [PRIVS.CREATE_USER]) ? UserEditor : defaultLanding} />
                                        <Route exact path='/user/:sub' component={checkRouteAccess(user, profile, [PRIVS.CREATE_USER]) ? UserEditor : defaultLanding} />
                                        <Route exact path='/organization' component={checkRouteAccess(user, profile, [PRIVS.VIEW_ORGANIZATION]) ? OrganizationList : defaultLanding} />
                                        <Route exact path='/organization/new' component={checkRouteAccess(user, profile, [PRIVS.CREATE_ORGANIZATION]) ? OrganizationEditor : defaultLanding} />
                                        <Route exact path='/organization/:organizationId' component={checkRouteAccess(user, profile, [PRIVS.CREATE_ORGANIZATION]) ? OrganizationEditor : defaultLanding} />
                                        <Route exact path='/role' component={checkRouteAccess(user, profile, [PRIVS.VIEW_ROLE]) ? RoleList : defaultLanding} />
                                        <Route exact path='/role/new' component={checkRouteAccess(user, profile, [PRIVS.CREATE_ROLE]) ? RoleEditor : defaultLanding} />
                                        <Route exact path='/role/:roleId' component={checkRouteAccess(user, profile, [PRIVS.CREATE_ROLE]) ? RoleEditor : defaultLanding} />
                                        <Route exact path='/feedback' component={checkRouteAccess(user, profile, [PRIVS.VIEW_FEEDBACK]) ? FeedbackList : defaultLanding} />
                                        <Route exact path='/feedback/:feedbackId' component={checkRouteAccess(user, profile, [PRIVS.VIEW_FEEDBACK]) ? FeedbackDetail : defaultLanding} />
                                        <Route exact path='/camera' component={checkRouteAccess(user, profile, [PRIVS.EDIT_CAMERA]) ? CameraDashboard : defaultLanding} />
                                        <Route exact path='/camera/:deviceId' component={checkRouteAccess(user, profile, [PRIVS.EDIT_CAMERA]) ? CameraDeviceDetailsWrapper : defaultLanding} />
                                        <Route exact path='/message' component={checkRouteAccess(user, profile, [PRIVS.VIEW_MESSAGE]) ? MessageList : defaultLanding} />
                                        <Route exact path='/message/new' component={checkRouteAccess(user, profile, [PRIVS.CREATE_MESSAGE]) ? MessageEditor : defaultLanding} />
                                        <Route exact path='/message/:messageId' component={checkRouteAccess(user, profile, [PRIVS.CREATE_MESSAGE]) ? MessageEditor : defaultLanding} />
                                        <Route exact path='/facility' component={checkRouteAccess(user, profile, [PRIVS.CREATE_FACILITY, PRIVS.UPDATE_FACILITY, PRIVS.DELETE_FACILITY]) ? FacilityList : defaultLanding} />
                                        <Route exact path='/facility/new' component={checkRouteAccess(user, profile, [PRIVS.CREATE_FACILITY]) ? FacilityEditor : defaultLanding} />
                                        <Route exact path='/facility/:facilityId' component={checkRouteAccess(user, profile, [PRIVS.UPDATE_FACILITY]) ? FacilityEditor : defaultLanding} />
                                        <Route exact path='/synchronization' component={checkRouteAccess(user, profile, [PRIVS.VIEW_SERVICE_STATUS]) ? Synchronization : defaultLanding} />
                                        <Route path='/silent_renew' component={SilentRenew} />
                                        <Route path='/session_ended' component={SessionEnded} />
                                        <Route path='/access_denied' component={AccessDenied} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </CSSTransition>
        
                            {injectErrorModal()}
        
                        </SC.StyledContent>
                        
                    </SidebarContext.Provider>
    
                </SC.StyledContentWrapper>
    
            </SC.StyledTemplate>
        );
    }catch(err){
        console.error("Template Error: \n", err);

        setErrorObject({
            hasError: true,
            error: err,
            info: null
        });
        
        return null;
    }
    //#endregion
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(TemplatePage));


const shouldShowSidebar = (userProfile: IAAUserAuthorizationProfile, navStrings: string[][]) => {
    return (
        userProfile && 
        navStrings[0] && 
        navStrings[0].length > 0
    );
}