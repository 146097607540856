import { IProcessedResponse } from "@algo/network-manager/managers/v3";
import { 
    IAAOrganization, IAARole 
} from "@algo/network-manager/models/v3/admin";

export let INITIALIZE_ROLE_DASHBOARD_BEGIN = 'INITIALIZE_ROLE_DASHBOARD_BEGIN';
export let INITIALIZE_ROLE_DASHBOARD_SUCCESS = 'INITIALIZE_ROLE_DASHBOARD_SUCCESS';
export let INITIALIZE_ROLE_DASHBOARD_FAILURE = 'INITIALIZE_ROLE_DASHBOARD_FAILURE';

export let LOAD_ROLES_BEGIN = 'LOAD_ROLES_BEGIN';
export let LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';
export let LOAD_ROLES_FAILURE = 'LOAD_ROLES_FAILURE';

export let DELETE_ROLE_BEGIN = 'DELETE_ROLE_BEGIN';
export let DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export let DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export let CLEAR_ROLE_DASHBOARD_ERROR = 'CLEAR_ROLE_DASHBOARD_ERROR';

export let UNINITIALIZE_ROLE_DASHBOARD = 'UNINITIALIZE_ROLE_DASHBOARD';

export interface RoleDashboardState {
    initializing: boolean;
    loadingRoles: boolean;
    deletingRole: boolean;
    roles: IAARole[];
    organizations: IAAOrganization[];
    error: Error | null;
    lastResponse: IProcessedResponse;
}

export interface RoleDashboardPayload {
    roles: IAARole[];
    organizations: IAAOrganization[];
    error: Error | null;
    response: IProcessedResponse;
}

interface InitializeRoleDashboardBeginAction {
    type: typeof INITIALIZE_ROLE_DASHBOARD_BEGIN;
    payload: RoleDashboardPayload;
}

interface InitializeRoleDashboardSuccessAction {
    type: typeof INITIALIZE_ROLE_DASHBOARD_SUCCESS;
    payload: RoleDashboardPayload;
}

interface InitializeRoleDashboardFailureAction {
    type: typeof INITIALIZE_ROLE_DASHBOARD_FAILURE;
    payload: RoleDashboardPayload;
}

interface LoadRoleBeginAction {
    type: typeof LOAD_ROLES_BEGIN;
    payload: RoleDashboardPayload;
}

interface LoadRoleSuccessAction {
    type: typeof LOAD_ROLES_SUCCESS;
    payload: RoleDashboardPayload;
}

interface LoadRoleFailureAction {
    type: typeof LOAD_ROLES_FAILURE;
    payload: RoleDashboardPayload;
}

interface DeleteRoleBeginAction {
    type: typeof DELETE_ROLE_BEGIN;
    payload: RoleDashboardPayload;
}

interface DeleteRoleSuccessAction {
    type: typeof DELETE_ROLE_SUCCESS;
    payload: RoleDashboardPayload;
}

interface DeleteRoleFailureAction {
    type: typeof DELETE_ROLE_FAILURE;
    payload: RoleDashboardPayload;
}

interface ClearRoleDashboardErrorAction {
    type: typeof CLEAR_ROLE_DASHBOARD_ERROR;
    payload: RoleDashboardPayload;
}

interface UninitializeRoleDashboardAction {
    type: typeof UNINITIALIZE_ROLE_DASHBOARD;
    payload: RoleDashboardPayload;
}

export type RoleDashboardTypes = 
    InitializeRoleDashboardBeginAction | 
    InitializeRoleDashboardSuccessAction | 
    InitializeRoleDashboardFailureAction | 
    LoadRoleBeginAction | 
    LoadRoleSuccessAction | 
    LoadRoleFailureAction | 
    DeleteRoleBeginAction | 
    DeleteRoleSuccessAction | 
    DeleteRoleFailureAction | 
    ClearRoleDashboardErrorAction | 
    UninitializeRoleDashboardAction;