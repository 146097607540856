let reactSelectStyles = {
    control: (base: any, state: any) => ({
        ...base,
        backgroundColor: state.isDisabled ? '#e9ecef' : 'white',
        borderColor: state.isFocused ? '#4ad58a !important' : '#ced4da',
        boxShadow: state.isFocused ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(30, 130, 76, 0.25)' : 'inset 0 1px 1px rgba(0, 0, 0, 0.075)'
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected, isActive }: any) => {
        return {
            ...styles,
            backgroundColor: isFocused
                ? 'rgba(30, 130, 76, .25)'
                : isSelected
                    ? 'rgba(30, 130, 76, 1)'
                    : 'transparent',
            color: isDisabled
                ? '#ccc'
                : isFocused
                    ? '#104428' 
                    : isSelected
                        ? 'white'
                        : 'black',
            cursor: isDisabled
                ? 'not-allowed'
                : 'default',
            ':active': {
                backgroundColor: isSelected ? 'rgba(30, 130, 76, 1)' : 'rgba(30, 130, 76, .5)',
                color: 'white'
            }
        };
    },
};

export default reactSelectStyles;