import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledMessageContainer = styled.div`
    height: 100%;
    width: 100%;
    ${flexSmart("center center")}
`;

export const StyledAlertContainer = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("center center")}
    flex-direction: column;
`;

export const StyledSubtext = styled.div`
    font-size: 14px;
    line-height: 16px;
`;

export const StyledRootLink = styled.a`
    font-weight: 700;
    color: rgba(30, 130, 76, .85);

    :hover{
        color: rgba(30, 130, 76, 1);
    }
`;