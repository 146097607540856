// libraries
import * as React from 'react';
import { Moment } from 'moment';
import { isMomentEventValue } from '../../../utils/typeCheckers';
// components
import {Pager} from "@algo/pager";
// constants
import { 
    DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_QUERY, 
    DEFAULT_SELECTOR_KEY, DATE_STRING_FORMAT 
} from '../../../utils/AppConstants';

export interface GenericTableViewColumnDefinition {
    propertyName: string;
    displayName?: string;
    isKey: boolean;
    headerColumnClass?: string;
}

export interface GenericTableViewSelectFilterItem {
    value: number;
    label: string;
}

export interface GenericTableViewSettings {
    tableClassName?: string;
    columnDefinitions: GenericTableViewColumnDefinition[],
    selectFilterItems?: GenericTableViewSelectFilterItem[],
    selectFilterPlaceholder?: string;
    hideFilterPlaceholder?: boolean;                      
    emptyTableMessage?: string;
    pageSize?: number;
    showPageSizeSelector?: boolean;
    actionButtonClass?: string;
    actionButtonGlyphClass?: string;
    newItemDisabled: boolean;
    actionDisabled: boolean;
    rowClickDisabled: boolean;
    booleanIndicatorClass?: string;
}

interface GenericTableViewProps {
    items: any[];   
    settings: GenericTableViewSettings;
    searchBoxChangeCallback?: ((text: string) => void);
    selectorChangeCallback?: ((key: number) => void);
    clearFilterCallback?: (() => void);
    applyFilterCallback?: ((query: string, selectorKey: number) => void);
    newItemCallback?: (() => void);
    actionCallback?: ((key: any) => void);
    rowClickCallback?: ((key: any) => void);
    isProcessingAction: boolean;
    isLoading: boolean;
}

interface GenericTableViewState {
    currentPageIndex: number;
    pageSize: number;
    totalPages: number;
    selectorKey: number;
    query: string;
    showFilterBar: boolean;
    showSelectFilter: boolean;
    showItemAction: boolean;
}

export class GenericTableView extends React.Component<GenericTableViewProps, GenericTableViewState> {
    searchBoxRef: React.RefObject<HTMLInputElement>;

    constructor(props: Readonly<GenericTableViewProps>) {
        super(props);

        this.searchBoxRef = React.createRef();

        let initialPageSize: number = props.settings.pageSize ? props.settings.pageSize : DEFAULT_PAGE_SIZE;
        let initialTotalPages: number = props.items.length / initialPageSize;

        let defaultSelection = this.props.settings.hideFilterPlaceholder;

        this.state = {
            currentPageIndex: DEFAULT_PAGE_INDEX,
            pageSize: initialPageSize,
            totalPages: initialTotalPages,
            selectorKey: defaultSelection ? 0 : DEFAULT_SELECTOR_KEY,
            query: DEFAULT_QUERY,
            showFilterBar: props.searchBoxChangeCallback ? true : false,
            showSelectFilter: props.selectorChangeCallback ? true : false,
            showItemAction: props.actionCallback ? true : false
        } as GenericTableViewState;
    }
    
    componentDidMount(){
        this.setState((state, props) => {
            return {
                ...state,
                totalPages: props.items.length / state.pageSize,
                currentPageIndex: 0
            };
        })
    }

    componentDidUpdate(
        prevProps: GenericTableViewProps,
        prevState: GenericTableViewState, snapshot: any
    ) {

        let hasChanged: boolean =
            this.props.items?.length !== prevProps.items?.length ||
            this.state.pageSize !== prevState.pageSize || 
            prevProps.isLoading !== this.props.isLoading ||
            prevProps.isProcessingAction !== this.props.isProcessingAction;

        if (hasChanged) {
            this.setState((state, props) => {
                return {
                    ...state,
                    totalPages: (props.items?.length / state.pageSize) || 0,       
                    currentPageIndex: 0
                };
            })
        }    
    }

    updatePageIndex = (newPageIndex: number) => {
        this.setState({
            ...this.state,
            currentPageIndex: newPageIndex,
        })
    }

    changePageSize = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        event.persist();

        this.setState((state, props) => {
            return {
                ...state,
                pageSize: parseInt(event.target.value),
                currentPageIndex: DEFAULT_PAGE_INDEX
            };
        });
    }

    searchBoxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();

        this.setState((state, props) => {
            return {
                ...state,
                query: event.target.value
            };
        });

        let { searchBoxChangeCallback } = this.props;

        if (searchBoxChangeCallback) {
            searchBoxChangeCallback(event.target.value);
        }
    }

    selectorChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        event.persist();

        let key: number = parseInt(event.target.value);

        this.setState((state, props) =>{
            return {
                ...state,
                selectorKey: key
            };
        });

        let { selectorChangeCallback } = this.props;

        if (selectorChangeCallback) {
            selectorChangeCallback(key);
        }
    }

    clearFilter = (event?: React.FormEvent<HTMLFormElement>): void => {
        if (event) {
            event.preventDefault();
        }

        if (this.searchBoxRef.current)
            this.searchBoxRef.current.value = DEFAULT_QUERY;

        this.setState((state, props) => {
            return {
                ...state,
                query: DEFAULT_QUERY
            };
        });

        let { clearFilterCallback } = this.props;

        if (clearFilterCallback) {
            clearFilterCallback();
        }
    }

    applyFilter = (event?: React.FormEvent<HTMLFormElement>): void => {
        if (event) {
            event.preventDefault();
        }

        let { applyFilterCallback } = this.props;
        
        if (applyFilterCallback) {
            applyFilterCallback(this.state.query, this.state.selectorKey);
        }
    }

    newItem = (): void => {
        let { newItemCallback, settings } = this.props;

        if (newItemCallback && !settings.newItemDisabled) {
            newItemCallback();
        }
    }

    itemAction = (item: any): void => {
        let { actionCallback, settings } = this.props;

        if (actionCallback && !settings.actionDisabled) {
            let keyDef = settings.columnDefinitions
            .find((def: GenericTableViewColumnDefinition) => {
                return def.isKey;
            });

            if (keyDef) {
                actionCallback(item[keyDef.propertyName]);
            }
        }
    }

    rowClick = (item: any): void => {
        let { rowClickCallback, settings } = this.props;

        if (rowClickCallback && !settings.rowClickDisabled) {
            let keyDef = settings.columnDefinitions
            .find((def: GenericTableViewColumnDefinition) => {
                return def.isKey;
            });

            if (keyDef) {
                rowClickCallback(item[keyDef.propertyName]);
            }
        }
    }

    renderTableCell = (obj: any, propertyName: string): React.ReactNode => {
        let { settings } = this.props;
        let value = obj[propertyName];

        if (typeof value === "undefined" || value === null) {
            return (
                <td key={Math.random()}>
                    Undefined
                </td>
            );
        }
        
        else if (
            typeof value === "boolean" ||
            isTrue(value) ||
            isFalse(value)
        ) {

            let spanClass: string = 
                (settings.booleanIndicatorClass)
                    ? settings.booleanIndicatorClass
                    : "av-bool-indicator";

            spanClass += (isTrue(value)) 
                ? " on" 
                : " off";

            return (
                <td key={Math.random()} className={`text-center`}>
                    <span 
                        className={spanClass} 
                        style={{display: "flex", justifyContent: "center"}}
                    >
                    </span>
                </td>
            );
        }
        else if (isMomentEventValue(value)) {
            return (
                <td key={Math.random()}>
                    {(value as Moment).format(DATE_STRING_FORMAT)}
                </td>
            );
        }
        else {
            return (
                <td key={Math.random()}>
                    {value}
                </td>
            );
        }
    }
    
    renderTableRows = (pagedItems: any[]): React.ReactNode => {
        let { 
            settings, 
            isLoading, 
            rowClickCallback, 
            newItemCallback 
        } = this.props;
        
        if (pagedItems.length === 0) {
            return (
                <tr>
                    <td 
                        className='av-admin-empty-table' 
                        colSpan={settings.columnDefinitions.length}
                    >
                        {isLoading 
                            ? 'Loading...' 
                            : (
                                settings.emptyTableMessage 
                                    ? settings.emptyTableMessage 
                                    : 'No items'
                            )
                        }
                    </td>
                </tr>
            );
        }
        else {
            let tableRows = (
                pagedItems
                .map((item:any) => {
                    return (
                        <tr 
                            key={Math.random()} 
                            className={`av-admin-table-row ${rowClickCallback ? 'clickable': ''}`} 
                            onClick={() => this.rowClick(item)}
                        >
                            {
                                this.renderActionTableCell(
                                    item, 
                                    (settings.showPageSizeSelector || typeof newItemCallback === "function")
                                )
                            }
                            {
                                settings.columnDefinitions
                                .filter(definition => {
                                    return !definition.isKey;
                                })
                                .map(defintion => {
                                    return this.renderTableCell(item, defintion.propertyName);
                                })
                            }
                        </tr>
                    );
                })
            );

            return tableRows;
        }
    }

    renderActionButton = (item: any): React.ReactNode => {
        let { settings, isProcessingAction } = this.props;

        let btnClassName: string =
            settings.actionButtonClass 
                ? settings.actionButtonClass 
                : 'btn btn-outline-primary';

        let iconClassName: string = 
            settings.actionButtonGlyphClass 
                ? settings.actionButtonGlyphClass 
                : 'fas fa-bell';

        if (this.state.showItemAction) {
            return (
                <button
                    className={btnClassName}
                    disabled={settings.actionDisabled || isProcessingAction}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();

                        this.itemAction(item);
                    }}
                >
                    <span className={iconClassName}></span>
                </button>
            );
        }
        else { 
            return null;
        }
    }

    renderActionTableCell = (
        item: any, 
        display: boolean
    ): React.ReactNode => {

        if (display) {
            return (
                <td className='text-center'>
                {
                    this.renderActionButton(item)
                }
                </td>
            );
        }
        else {
            return null;
        }
    }

    renderTableHeaders = (): React.ReactNode => {
        let { settings, newItemCallback } = this.props;

        let columnHeaders = settings.columnDefinitions
        .filter(def => { 
            return !def.isKey; 
        })
        .map(def => {
            return (
                <th 
                    key={Math.random()} scope='col' 
                    className={`av-admin-col ${def.headerColumnClass ? def.headerColumnClass : ''}`}
                >
                    {def.displayName}
                </th>
            );
        });

        var actionHeader: React.ReactNode = null;

        if (settings.showPageSizeSelector || typeof newItemCallback === "function") {
            actionHeader = (
                <th scope='col' className='av-admin-col av-admin-actions-col'>
                    <div className='av-admin-table-actions'>
                        {
                            this.renderPageSizeSelector(settings.showPageSizeSelector)
                        }
                        <div className={`${newItemCallback ? '' : 'd-none'}`}>
                            <button
                                className='av-admin-add-btn'
                                onClick={this.newItem}
                                disabled={settings.newItemDisabled}
                            >
                                <i className="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </th>
            );
        }

        return (
            <tr>
                {actionHeader}
                {columnHeaders}
            </tr>
        );
    }

    renderPageSizeSelector = (display?: boolean): React.ReactNode => {
        if (display) {
            return (
                <div>
                <select 
                    className='av-admin-table-page-size' 
                    defaultValue={this.state.pageSize.toString()} 
                    onChange={this.changePageSize}
                >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
            );
        }
        else {
            return null;
        }
    }

    renderSelectFilter = (): React.ReactNode => {
        let { settings } = this.props;

        var items: React.ReactNode = [];
        var defaultSelector: React.ReactNode;

        let defaultSelect = this.props.settings.hideFilterPlaceholder;
        if (defaultSelect) {
            //you have a default already, so dont create one
            defaultSelector = null;
        }
        else {
            defaultSelector = 
                <option 
                    key={`selectorFilterDDL_${DEFAULT_SELECTOR_KEY}`} 
                    value={DEFAULT_SELECTOR_KEY} 
                    disabled={true}
                >
                    {settings.selectFilterPlaceholder 
                        ? settings.selectFilterPlaceholder 
                        : 'Select'
                    }
                </option>
        }

        if (Array.isArray(settings.selectFilterItems)) {
            items = settings.selectFilterItems.map(
                (item) => 
                    (
                        <option 
                            key={`selectFilterDDL_${item.value}`} 
                            value={item.value}
                        >
                            {item.label}
                        </option>
                    )
            );
        }

        if (this.state.showSelectFilter) {
            return (
                <div className={`av-admin-search-group`}>
                    <select 
                        tabIndex={1} className='form-control' 
                        value={this.state.selectorKey} onChange={this.selectorChange}
                    >
                        {defaultSelector}
                        {items}
                    </select>
                </div>
            );
        }
        else {
            return null;
        }
    }

    renderFilterBar = (): React.ReactNode => {

        let searchBarClass: string = 
            `av-admin-toolbar ` + 
            `${this.state.showSelectFilter 
                ? 'av-select-search-toolbar' 
                : 'av-search-toolbar'
            }`;

        if (this.state.showFilterBar) {
            return (
                <form onSubmit={this.applyFilter} onReset={this.clearFilter}>
                    <div 
                        className={searchBarClass}>
                        {this.renderSelectFilter()}
                        <div className='av-admin-search-group'>
                            <input
                                data-toggle='tooltip' data-html='true' title='Enter a search value'
                                tabIndex={2}
                                className='form-control'
                                type='text'
                                onChange={this.searchBoxChange}
                                ref={this.searchBoxRef}
                            />
                            <div className='input-group-append'>
                                <button 
                                    className='btn btn-outline-danger' type='reset' 
                                    data-toggle='tooltip' data-html='true' title='Clear search filter'
                                >
                                    <i className='fas fa-eraser'></i>
                                </button>
                            </div>
                            <div className='input-group-append'>
                                <button 
                                    className='btn btn-outline-primary' type='submit'
                                    data-toggle='tooltip' data-html='true' title='Apply search filter'
                                >
                                    <i className='fas fa-search'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            );
        }
        else {
            return null;
        }
    }

    render() {
        let { items, settings } = this.props;

        let pagedItems = 
            (items && items.length > 0) 
                ?   items.slice(
                        (this.state.currentPageIndex * this.state.pageSize), 
                        ((this.state.currentPageIndex + 1) * this.state.pageSize)
                    ) 
                :   [];

        let tableWrapperClass: string =
            `av-admin-table-container` + 
            `${this.state.showFilterBar 
                ? ' searchable' 
                : ''
            } ` +
            `${this.props.children 
                ? ' children' 
                : ''
            }`;

        let tableClass: string =
            `av-admin-table ` + 
            `${settings.tableClassName 
                ? settings.tableClassName 
                : ''}
            `;

        let pagerWrapStyle = {
            display: 'flex',
            justifyContent: "center",
            alignItems:"center",
            padding: "6px"
        };

        return (
            <div className={tableWrapperClass}>
                {this.props.children ? this.props.children : null}
                {this.renderFilterBar()}
                <div className='av-admin-table-scroll-container'>
                    <table 
                        className={tableClass}
                    >
                        <thead className='thead-light'>
                            {this.renderTableHeaders()}
                        </thead>
                        <tbody>
                            {this.renderTableRows(pagedItems)}
                        </tbody>
                    </table>
                </div>
                <div style={pagerWrapStyle}>
                    {this.state.totalPages > 0 && 
                        <Pager
                            pageCount={this.state.totalPages} 
                            pageIndex={this.state.currentPageIndex}
                            updatePageIndex={this.updatePageIndex}
                            minimalNav={true} size={"xs"} backgroundColorActive={"old-algo-green"}
                            ellipsisThreshold={10} ellipsisOffset={1}
                        />
                    }
                </div>
            </div>
        );
    }
}

const isTrue = (value: string | boolean) => {
    
    if (
        value === true ||
        value === "true" ||
        value === "True" || 
        value === "TRUE"    
    ) return true;

    else return false;
}

const isFalse = (value: string | boolean) => {
    if (
        value === false ||
        value === "false" ||
        value === "False" ||
        value === "FALSE"
    ) return true;

    else return false;
}