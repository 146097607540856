//types
import * as TYPES from "./types";
//models
import { ATFeedback } from "@algo/network-manager/models/v3";

let initialState: TYPES.FeedbackDetailState = {
    loading: false,
    sendingResponse: false,
    feedback: new ATFeedback(),
    error: null
};

export function feedbackDetailReducer(
    state = initialState, 
    action: TYPES.FeedbackDetailTypes): TYPES.FeedbackDetailState {

    switch(action.type) {
        case TYPES.LOAD_FEEDBACK_DETAIL_BEGIN:
            return {
                ...state,
                loading: true
            };
        case TYPES.LOAD_FEEDBACK_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                feedback: new ATFeedback(action.payload.feedback)
            };
        case TYPES.LOAD_FEEDBACK_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case TYPES.SEND_RESPONSE_BEGIN:
            return {
                ...state,
                sendingResponse: true
            };
        case TYPES.SEND_RESPONSE_SUCCESS:
            return {
                ...state,
                sendingResponse: false
            };
        case TYPES.SEND_RESPONSE_FAILURE:
            return {
                ...state,
                sendingResponse: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_FEEDBACK_DETAIL_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_FEEDBACK_DETAIL:
            return initialState;
        default:
            return state;
    }
}