//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "..";
import { Action } from "redux";
import { getAccessToken } from "../../components/template/authentication/oidcConfig";
//types
import {
    DatasourceTypes, DatasourcePayload,
    FETCH_DATASOURCES_BEGIN, FETCH_DATASOURCES_SUCCESS,
    FETCH_DATASOURCES_FAILURE, CLEAR_DATASOURCES_ERROR
} from "./types";
//models
import { IATLegacyDatasource, ATLegacyDatasource } from "@algo/network-manager/models/v3";
import { DatasourceNetworkManager, IDatasourceNetworkManager, IProcessedResponse } from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../api-version-constants";

declare var __API_URL__: string;
const apiUrlDatasource: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).datasources}`;

/*
Fetch Datasources
*/
function fetchDatasourcesBegin(): DatasourceTypes {
    return {
        type: FETCH_DATASOURCES_BEGIN,
        payload: {} as DatasourcePayload
    };
}

function fetchDatasourcesSuccess(
    response: IProcessedResponse
): DatasourceTypes {
    return {
        type: FETCH_DATASOURCES_SUCCESS,
        payload: {
            datasources: response.data.map( (datasource: IATLegacyDatasource) => new ATLegacyDatasource(datasource)),
            response: response,
        } as DatasourcePayload
    };
}

function fetchDatasourcesFailure(error: Error): DatasourceTypes {
    return {
        type: FETCH_DATASOURCES_FAILURE,
        payload: {
            error: error
        } as DatasourcePayload
    };
}

export let loadDatasources = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().datasource.loading) {
        dispatch(fetchDatasourcesBegin());

        let manager: IDatasourceNetworkManager = new DatasourceNetworkManager(apiUrlDatasource);
        
        getAccessToken()
            .then(
                (token) => {
                    manager.setAccessToken(token);
                    manager.getAll(getState().datasource.lastResponse)
                        .then(
                            (response: IProcessedResponse) =>
                                dispatch(fetchDatasourcesSuccess(response))
                        )
                        .catch(
                            (err: any) => 
                                dispatch(fetchDatasourcesFailure(err))
                        )
                }
            )
            .catch(
                (err: any) => 
                    dispatch(fetchDatasourcesFailure(err))
            )
    }
}

/*
Clear Datasource Error
*/

function clearDatasourceErrorState(): DatasourceTypes {
    return {
        type: CLEAR_DATASOURCES_ERROR,
        payload: {} as DatasourcePayload
    };
}

export let clearDatasourceError = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().datasource.error !== null) {
        dispatch(clearDatasourceErrorState());
    }
}