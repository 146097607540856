// libraries
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
// components
import Content from '../../../base/layout/Content';
import FacilityTableView from './FacilityTableView';
// store
import { AppState } from "../../../../store";

interface StateProps { 
}

let mapStateToProps = (state: AppState) => {
    return { 
    };
};

type FacilityListProps = StateProps & RouteComponentProps<any>;

export const FacilityList: React.FC<FacilityListProps> = (props) => {

    React.useEffect(
        () => {
            $('[data-toggle="tooltip"]').tooltip();
        }, []
    );

    return (
        <div>
            <div className='av-flex-container'>
                <Content pageHasSidebar={false}>
                    <div className='av-admin-dashboard-content fluid'>
                        <div className='av-feedback-dashboard'>
                            <FacilityTableView />
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default connect(
    mapStateToProps,
)(withRouter(FacilityList));