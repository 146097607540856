// libraries
import * as React from "react";
import * as HELP from "./library";
// styles
import * as SC from "./Styled";
// types & models
import { 
    ATFacilityMapSetting 
} from "@algo/network-manager/models/v3";
// components
import { 
    HereMap, addMarkerAtPointer, removeCurrentMarkers, 
    IHereMarkerInit, IHereMapListener 
} from "@algo/here-maps";
import { IHereMapConfig } from "@algo/here-maps";
import { FormButton, FormCard, FormTextInputLabeled } from "@caps-mobile/forms";
import { FASave, FACancel, FADone } from "@caps-mobile/faicon";
import { SuccessBanner } from "@caps-mobile/banner";
// constants
import * as CONST from "./constants";

export type IEditMapSettings = {
    mapSetting: ATFacilityMapSetting;
    mapSettingIndex: number;
    cancelCallback: () => any;
    saveCallback: (
        mapSettings: ATFacilityMapSetting, 
        mapSettingIndex: number
    ) => any;
} & React.HTMLAttributes<HTMLElement>;

export const EditMapSettings: React.FC<IEditMapSettings> = (props) => {

    /**************************************************************************
        VALIDATION & BREVITY NAMES
     *************************************************************************/
    const { mapSetting, mapSettingIndex } = props;

    // check to see if mapSetting already has a legitimate center point
    // if not, use default
    let mapSettingCoords = (mapSetting.centerLatitude || mapSetting.centerLongitude)
        ? {lat: mapSetting.centerLatitude, lng: mapSetting.centerLongitude}
        : CONST.D_MAP_CONFIG.center;

    /**************************************************************************
        STATE
     *************************************************************************/
    // Track "Zoom Level" input field string value
    const [zoomLevelValue, setZoomLevelValue] = 
        React.useState<string>(
            mapSetting.zoomLevel 
                ? `${mapSetting.zoomLevel}` 
                : `${CONST.D_MAP_CONFIG.zoom}`
        );

    // List of markers for the HereMap to render
    // This component is only concerned with current map centroid
    const [existingMarkers, setExistingMarkers] =
        React.useState<IHereMarkerInit[][]>([[{
            ...CONST.mapPinOptions, 
            position: mapSettingCoords
        }]]);

    // Track the coordinates for the currently selected centroid
    const [coords, setCoords] = React.useState<H.geo.IPoint>(mapSettingCoords);

    // The configuration data that tells HereMap how to render/orient
    // Does not include listeners or markers
    const [mapConfig, setMapConfig] = React.useState<IHereMapConfig>(
        {
            ...CONST.D_MAP_CONFIG,
            center: coords,
            zoom: parseFloat(zoomLevelValue),
            showSpeeds: false
        }
    );

    /**************************************************************************
        EFFECTS
     *************************************************************************/
    // monitor coords change to re-center map 
    // (also re-zooms if current zoom valid)
    React.useEffect(() => {
        let newZoom = 
            HELP.isInvalidZoomLevel(zoomLevelValue) 
                ? mapConfig.zoom 
                : parseFloat(zoomLevelValue);

        setMapConfig({
            ...mapConfig, 
            center: coords,
            zoom: newZoom
        });
    }, [coords]);

    /**************************************************************************
        HERE MAP LISTENER OBJECTS (STATE-DEPENDANT)
     *************************************************************************/
    // What the HereMap should do when User clicks the map:
    // remove any existing markers; add new marker at click
    const addMarkerEventObject: IHereMapListener = {
        type: "tap",
        action: (evt: H.mapevents.Event, map: H.Map) => {
            removeCurrentMarkers(map); 
            addMarkerAtPointer(map, evt, CONST.mapPinOptions);
            let eventX: number = evt.currentPointer.viewportX;
            let eventY: number = evt.currentPointer.viewportY;
            let coords = map.screenToGeo(eventX, eventY);
            setCoords(coords);
        }
    };

    // What the HereMap should do at the end of a map change:
    // Update state value for zoom level to the current map zoom
    const mapViewChangeObject: IHereMapListener = {
        type: "mapviewchangeend",
        action: (evt: H.mapevents.Event, map: H.Map) => {
            setZoomLevelValue(`${map.getZoom()}`);
        }
    };

    /**************************************************************************
        PRIMARY RENDER
     *************************************************************************/
    return (
        <SC.StyledEditMapSettings>
             <SC.StyledContent>

                <FormCard noBorder={true} style={SC.formCardStyles}>
                    <SC.StyledZoomWrapper>
                        <FormTextInputLabeled
                            label={CONST.zoomLabel} labelWidth={"30%"} 
                            inputWidth={"calc(70% - 12px)"} inputHeight={"41px"} 
                            value={zoomLevelValue}
                            style={{marginRight: "8px"}} tabIndex={0}
                            onKeyDown={(e: any) => 
                                HELP.handleZoomKeyPress(
                                    e, coords, 
                                    mapConfig, zoomLevelValue, setMapConfig
                                )}
                            onChange={(e: any) => 
                                HELP.handleUpdateZoomState(e, setZoomLevelValue)}
                        />
                        <FormButton 
                            disabled={HELP.isInvalidZoomLevel(zoomLevelValue)}
                            color={"#1E824C"} width={"198px"}
                            onClick={(e: any) => HELP.handleUpdateAndCenter(
                                    coords, mapConfig, zoomLevelValue, setMapConfig)} 
                        >
                            <FADone style={SC.faDoneStyles} />
                            <span>{CONST.updateZoomLabel}</span>
                        </FormButton>
                    </SC.StyledZoomWrapper>
                </FormCard>

                <SC.StyledMapAndBannerWrapper>

                    <SuccessBanner style={SC.successBannerStyles}>
                        <div>{CONST.tipString1}</div>
                        <div>{CONST.tipString2}</div>
                    </SuccessBanner>

                    <SC.StyledMapWrapper style={SC.styledMapWrapperStyles}>
                        <HereMap
                            mapConfig={mapConfig} rounded={"4px"}
                            listeners={[addMarkerEventObject, mapViewChangeObject]}
                            markers={existingMarkers}
                        />
                    </SC.StyledMapWrapper>

                </SC.StyledMapAndBannerWrapper>

            </SC.StyledContent>

            <SC.StyledFooter>

                <FormButton onClick={() => props.cancelCallback()} 
                    color={"#DF3143"} width={"350px"}>
                    <FACancel style={{marginRight: "8px"}} />
                    <span>{CONST.cancel}</span>
                </FormButton>

                <FormButton
                    disabled={HELP.isInvalidZoomLevel(zoomLevelValue)}
                    onClick={() => props.saveCallback(
                        HELP.buildReturnValue(zoomLevelValue, coords), 
                        mapSettingIndex)} 
                    color={"#1E824C"} width={"350px"}>
                    <FASave style={{marginRight: "8px"}} />
                    <span>{CONST.save}</span>
                </FormButton>

            </SC.StyledFooter>

        </SC.StyledEditMapSettings>
    )
};

export default EditMapSettings;