// libraries
import * as React from "react";
import { getEnumStrings, nuon }  from "@caps-mobile/common-lib";
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
//redux-actions
import { 
    loadFacility, saveFacility, cleanupFacility 
} from "../../../../store/facility/editor/actions";
// hooks
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { usePrevious } from "@algo/hooks";
import { ThemeContext } from "@caps-mobile/algo-theme";
// styles
import * as SC from "./Styled";
// models & types
import { 
    ATFacility, ATFacilityAmenity, ATFacilityMapSetting, 
    ATFacilityParking, ATFacilityPhone, ATFacilityRoute,
    ATLocation,
    EATAmenityType, EATParkingType, EATPhoneType,
    EFacilityPropertyType, IATLocation
} from "@algo/network-manager/models/v3";
// store-state
import { AppState } from "../../../../store";
import { FacilityEditorState } from "../../../../store/facility/editor/types";
// components
import { IToast, ToastContext, ToastManager } from "@caps-mobile/toast";
import { Loading } from "@algo/loading";
import { EditorTitleRow } from "./editor-title-row/EditorTitleRow";
import { FASave, FACancel} from "@caps-mobile/faicon";
import { FormButton } from "@caps-mobile/forms";
import GeneralInformation from "./property-forms/GeneralInformation";
import {
    ParkingSpacesForm, AmenitiesForm, PhonesForm, findNextUnselected
} from "./property-forms/SelectAndInputForm";
import {
    LocationsForm, MapSettingsForm, 
    RoutesOfInterestForm, TravelTimesForm
} from "./property-forms/StringAndModalForm";
import FullPageModal from "../../../modal/FullPageModal";
import EditRoute from "./edit-route/EditRoute";
import EditLocation from "./edit-location/EditLocation";
import EditMapSettings from "./edit-map-settings/EditMapSettings";
// constants
import * as CONST from "../constants";
import ConfirmationModal from "../../../base/modals/ConfirmationModal";
import { 
    FACILITY_CONSTANTS as FAC_CONST 
} from "../../../../utils/AppConstants";
import VisitDashboardRow from "./visit-dashboard-row/VisitDashboardRow";

const parkingTypeStrings = getEnumStrings(EATParkingType);
const amenityTypeStrings = getEnumStrings(EATAmenityType);
const phoneTypeStrings = getEnumStrings(EATPhoneType);

interface IStateProps { editor?: FacilityEditorState; }

interface IDispatchProps {
    loadFacility: typeof loadFacility,
    cleanupFacility: typeof cleanupFacility,
    saveFacility: typeof saveFacility
}

let mapStateToProps = (state: AppState) => {
    return { editor: state.facilityEditor }
};

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loadFacility: loadFacility,
        saveFacility: saveFacility,
        cleanupFacility: cleanupFacility
    }, dispatch);
};

export type IProps = {
    
} & IStateProps & IDispatchProps;

export const FacilityEditor: React.FC<IProps> = (props) => {

    /**************************************************************************
        STATE
     *************************************************************************/
    const { facilityId } = useParams<{[key: string]: string}>();

    const [facilityObject, setFacilityObject] = 
        React.useState<ATFacility>(new ATFacility());

    const [showEditModal, setShowEditModal] = 
        React.useState<{[key: string]: boolean}>(CONST.propertyBooleanMap);

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
        React.useState<boolean>(false);

    const [selectedIndex, setSelectedIndex] = 
        React.useState<{[key: string]: number}>(CONST.propertyNumberMap);

    /**************************************************************************
        EFFECTS
     *************************************************************************/

    const Theme: any = React.useContext(ThemeContext);

    const c_success: string = Theme.colors["bs-green"];
    const c_failure: string = Theme.colors["bs-red"];

    const { addToast, removeToast, updateList } = React.useContext(ToastContext);

    const history = useHistory();

    const prevSaving = usePrevious(props.editor?.savingFacility);

    React.useEffect(
        () => {
            if (facilityId){
                // get the data
                props.loadFacility(parseInt(facilityId));
            }

            return () => {
                props.cleanupFacility();
            }

        }, [facilityId]
    );

    React.useEffect(
        () => {
            if (props.editor && !props.editor.initializing && props.editor.facility){ 
                setFacilityObject(new ATFacility(props.editor.facility));
            }

        }, [props.editor?.initializing]
    );

    React.useEffect(
        () => {
            // if a save action just finished and 
            // there is no current facilityId
            if ( props.editor && !props.editor?.savingFacility && prevSaving){
                if (props.editor.error){
                    addToast({
                        message: `Error: Save Failed!`,
                        color: Theme.getColorAtAlpha(c_failure, 1), 
                        backgroundColor: Theme.getColorAtAlpha(c_failure, 0.25),
                        climbHeight: 1,
                        height: 75,
                        whenExpire: (object: IToast) => {removeToast(object)},
                        whenTriggered: (object: IToast) => {updateList()}
                    });
                }
                else {
                    addToast({
                        message: "Save Successful.",
                        color: Theme.getColorAtAlpha(c_success, 1), 
                        backgroundColor: Theme.getColorAtAlpha(c_success, 0.25),
                        climbHeight: 1,
                        height: 75,
                        whenExpire: (object: IToast) => {removeToast(object)},
                        whenTriggered: (object: IToast) => {updateList()}
                    });

                    if (!facilityId && props.editor?.facility.id !== 0){
                        history.push(`/facility/${props.editor?.facility.id}`);
                    }
                }
            }
        }, [props.editor?.savingFacility]
    );

    let disableUI: boolean = 
        (props.editor?.initializing || props.editor?.savingFacility || false);

    /**************************************************************************
        HELPERS
     *************************************************************************/
    // handles clicking the edit button for any of the stringAndModal dynamic rows
    // (EntryPoints, MapViewSettings, RoutesOfInterest)
    const handleEditClick = (type: EFacilityPropertyType, mapIndex: number) => {
        switch(type){
            case EFacilityPropertyType.routesOfInterest:
                setSelectedIndex({...selectedIndex, "routesOfInterest": mapIndex});
                !showEditModal.routesOfInterest && 
                    setShowEditModal({...showEditModal, "routesOfInterest": true});
                break;
            case EFacilityPropertyType.locations:
                setSelectedIndex({...selectedIndex, "locations": mapIndex});
                !showEditModal.locations && 
                setShowEditModal({...showEditModal, "locations": true});
                break;
            case EFacilityPropertyType.mapSettings:
                setSelectedIndex({...selectedIndex, "mapSettings": mapIndex});
                !showEditModal.mapSettings && 
                    setShowEditModal({
                        ...showEditModal, 
                        "mapSettings": true
                    });
                break;
            default:
                return;
        }
    };

    // called when Reset button is clicked, should cause reset modal window to appear
    const handleResetFacility = () => {
        setShowDeleteConfirmModal(true);
    }

    // called when Save button is clicked, should initiate the put/post api call
    const handleSaveFacility = () => {
        props.saveFacility(facilityObject);
    };

    // called when confirm button is clicked in the reset facility modal window
    // will reload the current facility object if EDIT or reset to blank object if NEW
    const comfirmResetFacility = (): void => {
        facilityId 
            ? props.loadFacility(parseInt(facilityId)) 
            : setFacilityObject(new ATFacility())

        setShowDeleteConfirmModal(false);
    }

    // component config objects that tell Form components how to render

    let disableAddButton = (propertyName: string) => {
        switch(propertyName){
            case "parkingSpaces":
                return (
                    facilityObject[propertyName] && 
                    (facilityObject[propertyName].length >= getEnumStrings(EATParkingType).length)
                )
            case "amenities":
                return (
                    facilityObject[propertyName] && 
                    (facilityObject[propertyName].length >= getEnumStrings(EATAmenityType).length)
                )
            case "phones":
                return (
                    facilityObject[propertyName] && 
                    (facilityObject[propertyName].length >= getEnumStrings(EATPhoneType).length)
                )
            case "mapSettings":
                return (
                    facilityObject[propertyName] && 
                    (facilityObject[propertyName] && facilityObject[propertyName].length > 0)
                )
            default: 
                return false;
        }
    }

    const formConfigs = [
        {   title: "General Information"},
        {   title: "Parking",
            addButton: {
                label: "Add Parking",
                disabled: disableAddButton("parkingSpaces"),
                callback: () => {
                    if (disableAddButton("parkingSpaces")) { 
                        alert(FAC_CONST.WARN_DUPLICATE_PROP); return; 
                    }
                    else {
                        facilityObject.addParking(
                            new ATFacilityParking({
                                type: parkingTypeStrings[
                                    findNextUnselected(
                                        facilityObject, 
                                        "parkingSpaces", 
                                        parkingTypeStrings
                                    ) || 0
                                ] as EATParkingType,
                            })
                        );
                        setFacilityObject(new ATFacility(facilityObject));
                    }
                }
            }
        },
        {   title: "Amenities",
            addButton: {
                label: "Add Amenity",
                disabled: disableAddButton("amenities"),
                callback: () => {
                    if ( disableAddButton("amenities")) { 
                        alert(FAC_CONST.WARN_DUPLICATE_PROP); return; 
                    }
                    else {
                        facilityObject.addAmenity(
                            new ATFacilityAmenity({
                                type: amenityTypeStrings[
                                    findNextUnselected(
                                        facilityObject, 
                                        "amenities", 
                                        amenityTypeStrings
                                    ) || 0
                                ] as EATAmenityType,
                            })
                        );
                        setFacilityObject(new ATFacility(facilityObject)); 
                    }
                }
            }
        },
        {   title: "Phone",
            addButton: {
                label: "Add Phone",
                disabled: disableAddButton("phones"),
                callback: () => {
                    if (disableAddButton("phones")) { 
                        alert(FAC_CONST.WARN_DUPLICATE_PROP); return; 
                    }
                    facilityObject.addPhone(
                        new ATFacilityPhone({
                            type: phoneTypeStrings[
                                findNextUnselected(
                                    facilityObject, 
                                    "phones", 
                                    phoneTypeStrings
                                ) || 0
                            ] as EATPhoneType,
                        })
                    );
                    setFacilityObject(new ATFacility(facilityObject));
                }
            }
        },
        {   title: "Entry Points",
            addButton: {
                label: "Add Entry Points", 
                callback: () => {
                    facilityObject.addLocation(new ATLocation());
                    setFacilityObject(new ATFacility(facilityObject));
                }
            }
        },
        {   title: "Map View Settings",
            addButton: {
                label: "Add Map View Settings",
                disabled: disableAddButton("mapSettings"),
                callback: () => {
                    if (disableAddButton("mapSettings")) { 
                        alert(FAC_CONST.WARN_DUPLICATE_MAP_SETTING); 
                        return; 
                    }
                    facilityObject.addMapSetting(new ATFacilityMapSetting());
                    setFacilityObject(new ATFacility(facilityObject));
                }
            }
        },
        {   title: "Routes of Interest",
            addButton: {
                label: "Add Routes of Interest", 
                callback: () => {
                    facilityObject.addListProperty(EFacilityPropertyType["routesOfInterest"]);
                    setFacilityObject(new ATFacility(facilityObject));
                }
            }
        },
        {   title: "Travel Time Routes"}
    
    ];

    /**************************************************************************
        RENDER PRIMARY
     *************************************************************************/
    return (
        <SC.StyledFacilityEditor>

            { showDeleteConfirmModal && 
                <ConfirmationModal 
                    confirmCallback={comfirmResetFacility} 
                    dismissCallback={() => setShowDeleteConfirmModal(false)} 
                    title={`Reset this Facility?`} type={`warning`}
                    message={FAC_CONST.WARN_RESET}  /> 
            }

            { showEditModal.locations && 
                <FullPageModal 
                    showCloseButton={true} 
                    closeCallback={
                        () => setShowEditModal({
                            ...showEditModal, 
                            "locations": false}
                        )
                    }
                >

                    { 
                        ( 
                            nuon(selectedIndex.locations) && 
                            facilityObject.locations
                        ) &&
                        <EditLocation
                            location={facilityObject.locations[selectedIndex.locations]}
                            locationIndex={selectedIndex.locations}
                            cancelCallback={
                                () => setShowEditModal({
                                    ...showEditModal, 
                                    "locations": false}
                                )
                            } 
                            saveCallback={
                                ( location: IATLocation, locationIndex: number ) => {
                                    if (facilityObject.locations)
                                        facilityObject.locations[locationIndex] = location;
                                setFacilityObject(new ATFacility(facilityObject));
                                setShowEditModal({...showEditModal, "locations": false});
                            }} 
                        />
                    }

                </FullPageModal>

            }

            { showEditModal.mapSettings && 
                <FullPageModal 
                    showCloseButton={true} 
                    closeCallback={
                        () => setShowEditModal({
                            ...showEditModal, 
                            "mapSettings": false}
                        )
                    }
                >
                    
                    { 
                        (   
                            nuon(selectedIndex.mapSettings) && 
                            facilityObject.mapSettings
                        ) &&
                        <EditMapSettings
                            mapSetting={facilityObject.mapSettings[selectedIndex.mapSettings]}
                            mapSettingIndex={selectedIndex.mapSettings}
                            cancelCallback={() => setShowEditModal({...showEditModal, "mapSettings": false})} 
                            saveCallback={
                                (mapSetting: ATFacilityMapSetting, mapSettingIndex: number) => {
                                    if (facilityObject.mapSettings)
                                        facilityObject.mapSettings[mapSettingIndex] = mapSetting;

                                    setFacilityObject(new ATFacility(facilityObject));
                                    setShowEditModal({...showEditModal, "mapSettings": false});
                                } 
                            } 
                        />
                    }

                </FullPageModal>
            }

            { showEditModal.routesOfInterest && 
                <FullPageModal 
                    showCloseButton={true} 
                    closeCallback={() => setShowEditModal({
                        ...showEditModal, 
                        "routesOfInterest": false
                    })}
                >
                    
                    {   nuon(selectedIndex.routesOfInterest) && 
                        facilityObject.routesOfInterest &&
                        
                        <EditRoute
                            route={facilityObject.routesOfInterest[selectedIndex.routesOfInterest]}
                            routeIndex={selectedIndex.routesOfInterest}
                            currentRoutes={facilityObject.routesOfInterest}
                            cancelCallback={() => setShowEditModal({...showEditModal, "routesOfInterest": false})} 
                            saveCallback={
                                (route: ATFacilityRoute, routeIndex: number) => {
                                    if (facilityObject.routesOfInterest)
                                        facilityObject.routesOfInterest[routeIndex] = route;

                                    setFacilityObject(new ATFacility(facilityObject));
                                    setShowEditModal({...showEditModal, "routesOfInterest": false});
                                } 
                            } 
                        />
                    }

                </FullPageModal>
            }

            <SC.ContentRow>

                <SC.TitleContent>
                    <EditorTitleRow 
                        title={facilityId ? "Edit Facility" : "New Facility"}
                        action={() => history.push("/facility")}
                        style={{marginBottom: "12px"}} />
                </SC.TitleContent>

                { (props.editor?.savingFacility) && 
                    <SC.DisabledOverlay>
                        <Loading />
                    </SC.DisabledOverlay> 
                }
                {(props.editor?.initializing)
                    ?   <Loading />
                    :   <SC.FormsContent>

                            <SC.LeftColumn>

                                <VisitDashboardRow />

                                <GeneralInformation 
                                    formConfig={formConfigs[0]} 
                                    facilityObject={facilityObject} updateFacility={setFacilityObject} />

                                <ParkingSpacesForm 
                                    formConfig={formConfigs[1]}
                                    facilityObject={facilityObject} updateFacility={setFacilityObject} />

                                <AmenitiesForm 
                                    formConfig={formConfigs[2]}
                                    facilityObject={facilityObject} updateFacility={setFacilityObject} />

                                <PhonesForm 
                                    formConfig={formConfigs[3]} style={{marginBottom: "12px"}}
                                    facilityObject={facilityObject} updateFacility={setFacilityObject} />

                            </SC.LeftColumn>
                            
                            <SC.RightColumn>

                                <LocationsForm 
                                    formConfig={formConfigs[4]} facilityObject={facilityObject}
                                    editClick={handleEditClick} updateFacility={setFacilityObject} />

                                <MapSettingsForm 
                                    formConfig={formConfigs[5]} facilityObject={facilityObject}
                                    editClick={handleEditClick} updateFacility={setFacilityObject} />

                                <RoutesOfInterestForm 
                                    formConfig={formConfigs[6]} facilityObject={facilityObject}
                                    editClick={handleEditClick} updateFacility={setFacilityObject} />

                                <TravelTimesForm 
                                    formConfig={formConfigs[7]} facilityObject={facilityObject} />

                            </SC.RightColumn>

                        </SC.FormsContent>
                }
            </SC.ContentRow>

            <SC.FooterRow>
                <FormButton 
                    color={"#DF3143"} width={"350px"} disabled={disableUI}
                    onClick={handleResetFacility}
                >
                    <FACancel style={{marginRight: "8px"}} />
                    <span>{"Reset"}</span>
                </FormButton>

                <FormButton 
                    color={"#1E824C"} width={"350px"} disabled={disableUI}
                    onClick={() => handleSaveFacility()}>
                    <FASave style={{marginRight: "8px"}} />
                    <span>{"Save"}</span>
                </FormButton>
            </SC.FooterRow>

            <ToastManager />

        </SC.StyledFacilityEditor>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FacilityEditor);