import * as TYPES from "./types";

let initialState: TYPES.OrganizationDashboardState = {
    initializing: false,
    loadingOrganizations: false,
    deletingOrganization: false,
    organizations: [],
    error: null
};

export function organizationDashboardReducer(
    state = initialState, 
    action: TYPES.OrganizationDashboardTypes
): TYPES.OrganizationDashboardState {
    switch(action.type) {
        case TYPES.INITIALIZE_ORGANIZATION_DASHBOARD_BEGIN:
            return {
                ...state,
                initializing: true,
                loadingOrganizations: true
            };
        case TYPES.INITIALIZE_ORGANIZATION_DASHBOARD_SUCCESS:
            return {
                ...state,
                initializing: false,
                loadingOrganizations: false,
                organizations: action.payload.organizations
            };
        case TYPES.INITIALIZE_ORGANIZATION_DASHBOARD_FAILURE:
            return {
                ...state,
                initializing: false,
                loadingOrganizations: false,
                error: action.payload.error
            };
        case TYPES.LOAD_ORGANIZATIONS_BEGIN:
            return {
                ...state,
                loadingOrganizations: true
            };
        case TYPES.LOAD_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                loadingOrganizations: false,
                organizations: action.payload.organizations
            };
        case TYPES.LOAD_ORGANIZATIONS_FAILURE:
            return {
                ...state,
                loadingOrganizations: false,
                error: action.payload.error
            };
        case TYPES.DELETE_ORGANIZATION_BEGIN:
            return {
                ...state,
                deletingOrganization: true
            };
        case TYPES.DELETE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                deletingOrganization: false,
                organizations: action.payload.organizations
            };
        case TYPES.DELETE_ORGANIZATION_FAILURE:
            return {
                ...state,
                deletingOrganization: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_ORGANIZATION_DASHBOARD_ERROR:
            return {
                ...state,
                error: null
            }
        case TYPES.UNINITIALIZE_ORGANIZATION_DASHBOARD:
            return initialState;
        default:
            return state;
    }
}