// libraries
import styled from "styled-components";

export const StyledTemplate = styled.div`
    
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    height: calc(100% - 64px);
`;

export const StyledSideNav = styled.div<{showSidebar: boolean}>`
    width: ${ props => props.showSidebar ? "16rem" : "0px" };
    height: 100%;

    > :first-child{
        //width: ${ props => props.showSidebar ? "248px" : "0px" };
        width: ${ props => props.showSidebar ? "16rem" : "0px" };
        padding: ${ props => props.showSidebar ? "32px" : "0px" };
        transition: width .5s ease-out, padding .5s ease-out;

        > div{
            opacity: ${ props => props.showSidebar ? "1" : "0" };
            transition: ${ props => props.showSidebar ? ".5s" : ".0s" };
            transition-delay: ${ props => props.showSidebar ? ".4s" : "0s" };
        }
        
    }

    transition: width .5s ease-out;
`;

export const StyledContent = styled.div<{showSidebar: boolean}>`
    width: ${ props => props.showSidebar ? "calc(100% - 16rem)" : "100%" };
    height: 100%;
    overflow: auto;

    transition: width .5s ease-out;
`;