//types
import { ProcessedResponse } from "@algo/network-manager/managers/v3";
import * as TYPES from "./types";

let initialState: TYPES.SynchronizationState = {
    loadingLatest: false,
    loadingHistory: false,
    latest: [],
    history: [],
    error: null,
    lastResponse: new ProcessedResponse()
}

export function synchronizationReducer(
    state = initialState, 
    action: TYPES.SynchronizationTypes
): TYPES.SynchronizationState {

    switch (action.type) {
        case TYPES.FETCH_LATEST_SYNCHRONIZATION_BEGIN:
            return {
                ...state,
                loadingLatest: true
            };
        case TYPES.FETCH_LATEST_SYNCHRONIZATION_SUCCESS:
            return {
                ...state,
                loadingLatest: false,
                latest: action.payload.latest,
                lastResponse: action.payload.response
            };
        case TYPES.FETCH_LATEST_SYNCHRONIZATION_FAILURE:
            return {
                ...state,
                loadingLatest: false,
                error: action.payload.error
            };
        case TYPES.FETCH_HISTORY_SYNCHRONIZATION_BEGIN:
            return {
                ...state,
                loadingHistory: true
            };
        case TYPES.FETCH_HISTORY_SYNCHRONIZATION_SUCCESS:
            return {
                ...state,
                loadingHistory: false,
                history: action.payload.history
            };
        case TYPES.FETCH_HISTORY_SYNCHRONIZATION_FAILURE:
            return {
                ...state,
                loadingHistory: false,
                error: action.payload.error
            };
        case TYPES.CLEAR_SYNCHRONIZATION_ERROR:
            return {
                ...state,
                error: null
            };
        case TYPES.UNINITIALIZE_SYNCHRONIZATION_STATE:
            return initialState;
        default:
            return state;
    }
}