// libraries
import * as React from "react";
import { getEnumStrings }  from "@caps-mobile/common-lib";
// types & models
import {
    ATFacility, EATFacilityType, EATKioskSizeClass
} from "@algo/network-manager/models/v3";
// components
import { FormCard, FormTextInputLabeled, FormSelectLabeled } from "@caps-mobile/forms";
// constants
import { FACILITY_CONSTANTS } from "../../../../../utils/AppConstants";
const CONST = FACILITY_CONSTANTS.formInputStyles;
const facilityTypeStrings = getEnumStrings(EATFacilityType);
const facilitySizeClassStrings = getEnumStrings(EATKioskSizeClass);

export type IGeneralInformationProps = {
    formConfig: any;
    facilityObject: any;
    updateFacility: any;
};

export const GeneralInformation: React.FC<IGeneralInformationProps> = (props) => {

    const { formConfig, facilityObject, updateFacility } = props;

    return(
        <FormCard config={formConfig} style={{width: "100%"}}>
            <FormTextInputLabeled 
                label={"Name"} labelWidth={CONST.labelWidth} 
                inputWidth={CONST.inputWidth} inputHeight={CONST.inputHeight} 
                value={facilityObject.name}
                onChange={
                    (newValue: any) => {
                        facilityObject.name = newValue.currentTarget.value;
                        updateFacility(new ATFacility(facilityObject));
                    }
                }
            />
            <FormTextInputLabeled 
                label={"Route Prefix"} labelWidth={CONST.labelWidth} 
                inputWidth={CONST.inputWidth} inputHeight={CONST.inputHeight} 
                value={facilityObject.code}
                onChange={
                    (newValue: any) => {
                        facilityObject.code = newValue.currentTarget.value;
                        updateFacility(new ATFacility(facilityObject));
                    }
                }
            />
            <FormSelectLabeled 
                options={facilityTypeStrings}
                label={"Type"}
                selectedIndex={facilityTypeStrings.indexOf(facilityObject.type)}
                callback={
                    (newIndex: number) => {
                        facilityObject.type = 
                            EATFacilityType[facilityTypeStrings[newIndex] as keyof typeof EATFacilityType];
                        updateFacility(new ATFacility(facilityObject))
                    }
                }
            />
            <FormSelectLabeled 
                options={facilitySizeClassStrings}
                label={"Size Class"}
                selectedIndex={facilitySizeClassStrings.indexOf(facilityObject.kioskSizeClass)}
                callback={
                    (newIndex: number) => {
                        facilityObject.kioskSizeClass = 
                            EATKioskSizeClass[facilitySizeClassStrings[newIndex] as keyof typeof EATKioskSizeClass];
                        updateFacility(new ATFacility(facilityObject))
                    }
                }
            />
        </FormCard>
    );
};

export default GeneralInformation;