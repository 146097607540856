// libraries
import * as React from "react";
// types & models
import { IATPlace } from "@algo/network-manager/models/v3";
// constants
declare var __API_URL__: string;
const API_VERSION: string = "v3.0";

export const useGetRoadways = () => {

    const [roadways, setRoadways] = React.useState<IATPlace[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [error, setError] = React.useState(null);

    React.useEffect(
        () => {

            setLoading(true);
            fetch(`${__API_URL__}/${API_VERSION}/Places?type=Interstate&type=USHighway&type=StateHighway`)
                .then( response => response.json())
                .then( (data) => {
                    setRoadways(filterInvalidRoads(data));
                    setLoading(false);
                    setLoaded(true);
                })
                .catch( (error) => {
                    setLoading(false);
                    setError(error);
                });

            // cleanup
            return () => {}
        }, []
    )

    return {roadways, loading, loaded, error};
}

const filterInvalidRoads = (roadways: IATPlace[]) => {

    let filteredList: IATPlace[] = [];

    roadways.forEach(
        (roadway: IATPlace) => {
            if (roadway.name && roadway.name.charAt(roadway.name.length - 1) !== "-")
                filteredList.push(roadway);
        }
    )

    return filteredList;
}