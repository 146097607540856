//libraries
import { ThunkAction } from "redux-thunk";
import { AppState } from "../..";
import { Action } from "redux";
import { getAccessToken } from "../../../components/template/authentication/oidcConfig";
// interfaces & models
import * as TYPES from "./types";
import { 
    IOrganizationNetworkManager, OrganizationNetworkManager,
    IUserNetworkManager,UserNetworkManager , IProcessedResponse
} from "@algo/network-manager/managers/v3";
//constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-version-constants";

declare var __API_URL__: string;
const apiUrlOrganization: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).organizations}`;
const apiUrlUser: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).users}`;

/*
Initialize User Dashboard
*/

function initializeUserDashboardBegin(): TYPES.UserDashboardTypes {
    return {
        type: TYPES.INITIALIZE_USER_DASHBOARD_BEGIN,
        payload: {} as TYPES.UserDashboardPayload
    };
}

function initializeUserDashboardSuccess(response: IProcessedResponse): TYPES.UserDashboardTypes {
    return {
        type: TYPES.INITIALIZE_USER_DASHBOARD_SUCCESS,
        payload: {
            organizations: response.data
        } as TYPES.UserDashboardPayload
    };
}

function initializeUserDashboardFailure(error: Error): TYPES.UserDashboardTypes {
    return {
        type: TYPES.INITIALIZE_USER_DASHBOARD_FAILURE,
        payload: { 
            error: error
        } as TYPES.UserDashboardPayload
    };
}

export let initializeUserDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (!getState().userDashboard.initializing) {
        dispatch(initializeUserDashboardBegin());

        let manager: IOrganizationNetworkManager
            = new OrganizationNetworkManager(apiUrlOrganization);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAll()
                        .then(
                            (response: IProcessedResponse) => 
                                dispatch(initializeUserDashboardSuccess(response))
                        ).catch(
                            (err: Error) => 
                                dispatch(initializeUserDashboardFailure(err))
                        )
                }
            ).catch(
                (err: Error) => dispatch(initializeUserDashboardFailure(err))
            )
    }
}

/*
Load Users
*/

function loadUsersBegin(): TYPES.UserDashboardTypes {
    return {
        type: TYPES.LOAD_USERS_BEGIN,
        payload: {} as TYPES.UserDashboardPayload
    };
}

function loadUsersSuccess(response: IProcessedResponse): TYPES.UserDashboardTypes {
    return {
        type: TYPES.LOAD_USERS_SUCCESS,
        payload: { 
            users: response.data
         } as TYPES.UserDashboardPayload
    };
}

function loadUsersFailure(error: Error): TYPES.UserDashboardTypes {
    return {
        type: TYPES.LOAD_USERS_FAILURE,
        payload: { 
            error: error
         } as TYPES.UserDashboardPayload
    };
}

export let loadUsers = (
    search: string = "", 
    organizationId: number = 0
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (!getState().userDashboard.loadingUsers) {
        dispatch(loadUsersBegin());

        let manager: IUserNetworkManager = 
            new UserNetworkManager(apiUrlUser);

        getAccessToken()
            .then(
                (token: string) => {
                    manager.setAccessToken(token);
                    manager.getAllUsers({search, organizationId})
                        .then(
                            (response: IProcessedResponse) => 
                                dispatch(loadUsersSuccess(response))
                        ).catch(
                            (err: Error) =>
                                dispatch(loadUsersFailure(err))
                        )
                }
            ).catch(
                (err:Error) => dispatch(loadUsersFailure(err))
            )
    }
}

/*
Delete User
*/

function deleteUserBegin(): TYPES.UserDashboardTypes {
    return {
        type: TYPES.DELETE_USER_BEGIN,
        payload: {} as TYPES.UserDashboardPayload
    };
}

function deleteUserSuccess(response: IProcessedResponse): TYPES.UserDashboardTypes {
    return {
        type: TYPES.DELETE_USER_SUCCESS,
        payload: {
            users: response.data
        } as TYPES.UserDashboardPayload
    };
}

function deleteUserFailure(error: Error): TYPES.UserDashboardTypes {
    return {
        type: TYPES.DELETE_USER_FAILURE,
        payload: {
            error: error
        } as TYPES.UserDashboardPayload
    };
}

export let removeUser = (
    sub: string, 
    search: string = "", 
    organizationId: number = 0
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {

    if (!getState().userDashboard.deletingUser) {
        dispatch(deleteUserBegin());

        getAccessToken()
            .then(
                (token) => {
                        let manager: IUserNetworkManager = 
                            new UserNetworkManager(apiUrlUser);

                        manager.setAccessToken(token);

                        manager.deleteUser({sub})
                            .then(
                                (deleteResponse: IProcessedResponse) => {
                                    manager.getAllUsers({search, organizationId})
                                        .then((getAllResponse: IProcessedResponse) => {
                                            dispatch(deleteUserSuccess(getAllResponse));
                                        }, (reject: any) => {
                                            dispatch(deleteUserFailure(new Error(reject)));
                                        })
                                        .catch((error: any) => {
                                            dispatch(deleteUserFailure(new Error(error)));
                                        });
                            }, (reject: any) => {
                                dispatch(deleteUserFailure(new Error(reject)));
                            })
                            .catch((error: any) => {
                                dispatch(deleteUserFailure(new Error(error)));
                            });
                    }
                )        
    }
}

/*
Clear User Dashboard State
*/

function clearUserDashboardErrorState(): TYPES.UserDashboardTypes {
    return {
        type: TYPES.CLEAR_USER_DASHBOARD_ERROR,
        payload: {} as TYPES.UserDashboardPayload
    }
}

export let clearUserDashboardError = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (getState().userDashboard.error !== null) {
        dispatch(clearUserDashboardErrorState());
    }
}

/*
Uninitialize User Dashboard
*/

function uninitializeUserDashboardState(): TYPES.UserDashboardTypes {
    return {
        type: TYPES.UNINITIALIZE_USER_DASHBOARD,
        payload: {} as TYPES.UserDashboardPayload
    }
}

export let uninitializeUserDashboard = (
    //
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(uninitializeUserDashboardState());
}