// libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
// styles
import reactSelectStyles from '../../../utils/reactSelectStyles';
// interfaces & models
import { 
    AAUserForm, IAAUserForm, IAAOrganization, IAARole 
} from "@algo/network-manager/models/v3/admin";
import { CASPasswordValidation } from "@algo/network-manager/models/v3/cas";
import { ReactSelectNumberKeyListItem } from '../../../models/ReactSelectListItems';
// components
import Select from 'react-select';
import Content from '../../base/layout/Content';
import Loading from '../Loading';
import DismissableModal from '../../base/modals/DismissableModal';
// store
import { AppState } from '../../../store';
import { UserEditorState } from '../../../store/user/editor/types';
// constants
import {
    initializeUserEditor, saveUser, 
    resetUserPassword, clearResetUserPassword,
    sendUserResetPasswordEmail, clearSendUserResetPasswordEmail,
    uninitializeUserEditor
} from '../../../store/user/editor/actions';
import { isNewMode, USER_CONS } from '../../../utils/AppConstants';

interface StateProps { 
    editor: UserEditorState;
}

let mapStateToProps = (state: AppState) => {
    return { 
        editor: state.userEditor
    };
};

interface DispatchProps {
    initialize: typeof initializeUserEditor;
    save: typeof saveUser;
    resetPassword: typeof resetUserPassword;
    clearResetPassword: typeof clearResetUserPassword;
    sendResetPasswordEmail: typeof sendUserResetPasswordEmail;
    clearResetPasswordEmail: typeof clearSendUserResetPasswordEmail;
    uninitialize: typeof uninitializeUserEditor;
}

let mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        initialize: initializeUserEditor,
        save: saveUser,
        resetPassword: resetUserPassword,
        clearResetPassword: clearResetUserPassword,
        sendResetPasswordEmail: sendUserResetPasswordEmail,
        clearResetPasswordEmail: clearSendUserResetPasswordEmail,
        uninitialize: uninitializeUserEditor
    }, dispatch);
};


type UserEditorPageProps = 
    StateProps & 
    DispatchProps & 
    RouteComponentProps<any>;

interface UserEditorPageState {
    sub: string;
    userForm: IAAUserForm;
    initialized: boolean;
    selectedOrgOption: ReactSelectNumberKeyListItem | null;
    selectedRoleOption: ReactSelectNumberKeyListItem[];
    selectedStatusOption: ReactSelectNumberKeyListItem | null;
    selectedOrgRoles: number[];
    passwordFocus: boolean;
    isValid: boolean;
    isMinLength: boolean;
    isMinDigit: boolean;
    isMinLower: boolean;
    isMinUpper: boolean;
    isMinSpecial: boolean;
    isMinCharacterType: boolean;
}

class UserEditor extends React.Component<UserEditorPageProps, UserEditorPageState> {
    validator: CASPasswordValidation;

    constructor(props: any) {
        super(props);

        this.validator = new CASPasswordValidation();

        this.state = {
            sub: ( this.props.match.params.sub ) 
                ? this.props.match.params.sub 
                : USER_CONS.sub,
            userForm: new AAUserForm(),
            initialized: !this.props.match.params.sub,
            selectedOrgOption: null,
            selectedRoleOption: [],
            selectedStatusOption: null,
            selectedOrgRoles: [],
            passwordFocus: false,
            isValid: false,
            isMinLength: false,
            isMinDigit: false,
            isMinLower: false,
            isMinUpper: false,
            isMinSpecial: false,
            isMinCharacterType: false
        } as UserEditorPageState;
    }

    componentDidMount() {
        this.props.initialize(this.state.sub);
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate(
        prevProps: any, 
        prevState: any, 
        snapshot: any
    ) {
        if (
            prevProps.editor.savingUser && 
            !this.props.editor.savingUser && 
            ( this.props.editor.error === null )
        )   this.props.history.replace(`/user/${this.props.editor.userForm.sub}`);

        if ((this.props.editor.userLoaded && !this.state.initialized)) {

            let selectedOrgObj: IAAOrganization | null = 
                this.props.editor.organizationList
                    .find((org: IAAOrganization) => { 
                        return org.organizationId === this.props.editor.userForm.organizationId; 
                    }) || null;

            var selectedOrgRoles: number[] = [];

            if (selectedOrgObj) {
                selectedOrgRoles = 
                    (!selectedOrgObj.roleId) 
                        ?   [] 
                        :   selectedOrgObj.roleId?.map((rId: number) => {
                                return rId;
                            }); 
            }


            let selectedOrgItem: ReactSelectNumberKeyListItem | undefined = 
                this.props.editor.organizationList
                    .map((org: IAAOrganization) => {
                        return {
                            value: org.organizationId,
                            label: org.name
                        } as ReactSelectNumberKeyListItem;
                    })
                    .find((item: ReactSelectNumberKeyListItem) => {
                        return item.value === this.props.editor.userForm.organizationId;
                    });

            var selectedRole: ReactSelectNumberKeyListItem[] = [];

            if (this.props.editor.userForm.roleId) {
                selectedRole = 
                    this.props.editor.userForm.roleId
                        .map((rId: number) => {
                            return this.props.editor.roleList.find(
                                role => { return role.id === rId; }
                            );
                        })
                            .map(
                                (role: IAARole | null | undefined) => {
                                    return {
                                        value: role?.id,
                                        label: role?.description
                                    } as ReactSelectNumberKeyListItem;
                            });
            }

            

            let selectedStatus: ReactSelectNumberKeyListItem | undefined = 
                USER_CONS.statusOptions.find(
                    (item: ReactSelectNumberKeyListItem) => {
                        return item.value === this.props.editor.userForm.status;
                    }
                );

            this.setState((state: any, props: any) => {
                return {
                    ...state,
                    sub: ( state.sub === USER_CONS.sub )  
                        ? props.editor.userForm.sub 
                        : state.sub,
                    userForm: props.editor.userForm,
                    selectedOrgOption: selectedOrgItem,
                    selectedRoleOption: selectedRole,
                    selectedOrgRoles: selectedOrgRoles,
                    selectedStatusOption: selectedStatus,
                    initialized: true
                };
            });
        }

        if (
            this.props.editor.changePasswordSuccess && 
            ( this.props.editor.changingPassword !== prevProps.editor.changingPassword)
        ) {
            this.setState((state, props) => {
                var userForm = state.userForm;

                userForm.password = '';
                userForm.passwordConfirm = '';

                return {
                    ...state,
                    userForm: userForm,
                    isValid: false,
                    isMinLength: false,
                    isMinDigit: false,
                    isMinLower: false,
                    isMinUpper: false,
                    isMinSpecial: false,
                    isMinCharacterType: false
                };
            });
        }
    }

    setFormItemValue = (
        property: string, 
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ): void => {

        event.preventDefault();

        let currentValue = event.currentTarget.value;

        this.setState((state, props) => {
            var userForm = state.userForm;

            switch (property) {
                case USER_CONS.givenName:
                    userForm.givenName = currentValue;
                    break;
                case USER_CONS.middleName:
                    userForm.middleName = currentValue;
                    break;
                case USER_CONS.familyName:
                    userForm.familyName = currentValue;
                    break;
                case USER_CONS.userName:
                    userForm.userName = currentValue;
                    break;
                case USER_CONS.password:
                    userForm.password = currentValue;

                    if (currentValue.length === 0) {
                        userForm.passwordConfirm = '';
                    }

                    this.validator.validate(currentValue, props.editor.complexityRequirements);

                    break;
                case USER_CONS.passwordConfirm:
                    userForm.passwordConfirm = currentValue;
                    break;
                case USER_CONS.email:
                    userForm.email = currentValue;
                    break;          
            }

            return {
                ...state,
                userForm: userForm,
                isValid: this.validator.isValid,
                isMinLength: this.validator.isMinLength,
                isMinDigit: this.validator.isMinDigit,
                isMinLower: this.validator.isMinLower,
                isMinUpper: this.validator.isMinUpper,
                isMinSpecial: this.validator.isMinSpecial,
                isMinCharacterType: this.validator.isMinCharacterType
            };
        });
    }

    setReactSelectFormItemValue = (property: string, event: any): void => {
        var userForm = this.state.userForm;

        this.setState((state, props) => {
            switch (property) {
                case USER_CONS.orgId:
                    let organizationValue = event as ReactSelectNumberKeyListItem;

                    if (organizationValue !== null) {
                        userForm.organizationId = organizationValue.value;
                    }

                    let selectedOrgObj: IAAOrganization | null = 
                        organizationValue !== null 
                            ?   (this.props.editor.organizationList
                                    .find(
                                        (org: IAAOrganization) => { 
                                            return org.organizationId === organizationValue.value; 
                                        }
                                    ) || null
                                )
                            : null;

                    let selectedOrgRoles: number[] = 
                        (selectedOrgObj && selectedOrgObj.roleId)
                            ? selectedOrgObj.roleId.map(
                                (rId: number) => {
                                    return rId;
                                })
                            : [];

                    if (selectedOrgObj) {
                        return {
                            ...state,
                            userForm: userForm,
                            selectedOrgOption: organizationValue,
                            selectedRoleOption: [],
                            selectedOrgRoles: selectedOrgRoles
                        };
                    }
                    else {
                        return {
                            ...state,
                            userForm: userForm,
                            selectedOrgOption: organizationValue
                        };
                    }
                case USER_CONS.roleId:
                    let roleValues = event as ReactSelectNumberKeyListItem[];

                    if (roleValues !== null) {
                        userForm.roleId = roleValues
                        .map((item: ReactSelectNumberKeyListItem) => {
                            return item.value;
                        });
                    }

                    return {
                        ...state,
                        userForm: userForm,
                        selectedRoleOption: roleValues
                    };
                case USER_CONS.statusId:
                    let statusValue = event as ReactSelectNumberKeyListItem;

                    if (statusValue !== null) {
                        userForm.status = statusValue.value;
                    }

                    return {
                        ...state,
                        userForm: userForm,
                        selectedStatusOption: statusValue
                    };  
                default:
                    break;
            }
        });
    }

    saveUserForm = (): void => {
        this.props.save(this.state.userForm, this.state.sub);
    }

    resetPasswordForm = (): void => {
        if (this.state.userForm.password === this.state.userForm.passwordConfirm) {
            this.props.resetPassword(this.state.sub, (this.state.userForm.password || ""));
        }
    }

    sendResetEmail = (): void => {
        if (!this.props.editor.sendingResetPasswordEmail) {
            this.props.sendResetPasswordEmail(this.state.sub);
        }
    }

    resetPasswordModalDismiss = (): void => {
        this.props.clearResetPassword();
    }

    resetPasswordEmailModalDismiss = (): void => {
        this.props.clearResetPasswordEmail();
    }

    togglePasswordCursorState = (): void => {
        this.setState((state, props) => {
            return {
                ...state,
                passwordFocus: !state.passwordFocus
            };
        });
    }

    back = (): void => {
        this.props.history.goBack();
    }

    render() {
        let { editor } = this.props;

        let organizationOptions = editor.organizationList.map((org, index) => {
                return {
                    value: org.organizationId,
                    label: org.name
                } as ReactSelectNumberKeyListItem;
            });

        let roleOptions = editor.roleList.map((role, index) => {
            return {
                value: role.id,
                label: role.description
            } as ReactSelectNumberKeyListItem;
        });

        let minLengthReq = editor.complexityRequirements.minLength > 0 ? (
            <li
                className={`${
                    this.state.passwordFocus
                        ? this.state.isMinLength ?'is-valid' : 'is-invalid'
                        : ''
                    }`}
            >
                Password must be at least {editor.complexityRequirements.minLength} character{editor.complexityRequirements.minLength > 1 ? 's' : ''}
            </li>
        ) : (null);

        let minDigitReq = editor.complexityRequirements.minNumberOfDigits > 0 ? (
            <li
                className={`${
                    this.state.passwordFocus
                        ? this.state.isMinDigit ? 'is-valid' : 'is-invalid'
                        : ''
                    }`}
            >Password must have at least {editor.complexityRequirements.minNumberOfDigits} digit{editor.complexityRequirements.minNumberOfDigits > 1 ? 's' : ''}
            </li>
        ) : (null);

        let minLowerReq = editor.complexityRequirements.minNumberOfLower > 0 ? (
            <li
                className={`${
                    this.state.passwordFocus
                        ? this.state.isMinLower ? 'is-valid' : 'is-invalid'
                        : ''
                    }`}
            >
                Password must have at least {editor.complexityRequirements.minNumberOfLower} lowercase alphabetic character{editor.complexityRequirements.minNumberOfLower > 1 ? 's' : ''}
            </li>
        ) : (null);

        let minUpperReq = editor.complexityRequirements.minNumberOfUpper > 0 ? (
            <li
                className={`${
                    this.state.passwordFocus
                        ? this.state.isMinUpper ? 'is-valid' : 'is-invalid'
                        : ''
                    }`}
            >
                Password must have at least {editor.complexityRequirements.minNumberOfUpper} uppercase alphabetic character{editor.complexityRequirements.minNumberOfUpper > 1 ? 's' : ''}
            </li>
        ): (null);

        let minSpecialReq = editor.complexityRequirements.minNumberOfSpecial > 0 ? (
            <li
                className={`${
                    this.state.passwordFocus
                        ? this.state.isMinSpecial ? 'is-valid' : 'is-invalid'
                        : ''
                    }`}
            >
                Password must have at least {editor.complexityRequirements.minNumberOfSpecial} special character{editor.complexityRequirements.minNumberOfSpecial > 1 ? 's' : ''}
            </li>
        ): (null);

        let minTypeReq = editor.complexityRequirements.minNumberOfCharacterTypes > 0 ? (
            <li
                className={`${
                    this.state.passwordFocus
                        ? this.state.isMinCharacterType ? 'is-valid' : 'is-invalid'
                        : ''
                    }`}
            >
                Password must have at least {editor.complexityRequirements.minNumberOfCharacterTypes} character types
                </li>
        ) : (null);

        let passwordHistoryReq = editor.complexityRequirements.numberOfHistoricalPasswords > 0 ? (
            <li>Cannot use one of the user's last {editor.complexityRequirements.numberOfHistoricalPasswords} password{editor.complexityRequirements.numberOfHistoricalPasswords > 1 ? 's' : ''}</li>
        ) : (null);

        let passwordAgeLength = editor.complexityRequirements.passwordAgeInDays > 0 ? (
            <li>Password will last for {editor.complexityRequirements.passwordAgeInDays} days</li>
        ) : (null);

        let passwordRequirementAlert = (
            <div className='av-password-requirement-alert'>
                <div className="av-admin-editor-alert-header"><i className="fas fa-info-circle"></i>&nbsp;&nbsp;Password Requirements:</div>
                <hr />

                <ul>
                    {minLengthReq}
                    {minDigitReq}
                    {minLowerReq}
                    {minUpperReq}
                    {minSpecialReq}
                    {minTypeReq}
                    {passwordHistoryReq}
                    {passwordAgeLength}
                </ul>
            </div>    
        );

        let passwordMismatchLabel = this.state.userForm.password !== this.state.userForm.passwordConfirm ? (
            <label htmlFor='txtPasswordConfirm' className='text-danger'>Passwords do not match</label>
        ) : (null);

        let passwordInvalidLabel = !this.state.isValid && (this.state.userForm.password && this.state.userForm.password.length > 0) ? (
            <label htmlFor='txtPassword' className='text-danger'>Password is invalid</label>
        ): (null);

        let passwordSection = this.state.sub !== USER_CONS.sub ? (
            <div>
                <h2>Account Password</h2>
                <div className='av-admin-editor-form-row'>
                    <button
                        className='btn btn-sm btn-block btn-outline-danger'
                        onClick={this.sendResetEmail}
                        disabled={editor.sendingResetPasswordEmail}
                    >
                        Send Reset Password Email
                    </button>
                </div>
                {passwordRequirementAlert}
                <div className='av-admin-editor-form-row'>
                    <label htmlFor='txtPassword' className='av-admin-editor-form-label'>Password</label>
                    <div className='av-admin-editor-form-item'>
                        <input
                            id='txtPassword'
                            type='password'
                            className='form-control'
                            placeholder='Password'
                            value={this.state.userForm.password}
                            onChange={(event) => this.setFormItemValue(USER_CONS.password, event)}
                            onFocus={this.togglePasswordCursorState}
                            onBlur={this.togglePasswordCursorState}
                        />
                        {passwordInvalidLabel}
                    </div>
                </div>
                <div className='av-admin-editor-form-row'>
                    <label htmlFor='txtPasswordConfirm' className='av-admin-editor-form-label'>Confirm Password</label>
                    <div className='av-admin-editor-form-item'>
                        <input
                            id='txtPasswordConfirm'
                            type='password'
                            className='form-control'
                            placeholder='Confirm Password'
                            value={this.state.userForm.passwordConfirm}
                            onChange={(event) => this.setFormItemValue(USER_CONS.passwordConfirm, event)}
                        />
                        {passwordMismatchLabel}
                    </div>
                </div>
                <div className='av-reset-password-alert'>
                    <i className="fas fa-exclamation-triangle d-inline-block"></i>&nbsp;&nbsp;
                    <p className="d-inline-block">
                        <strong>The account password can only be changed by clicking the Reset Password button.</strong>
                    </p>
                    <p>Saving the user profile <strong>will not</strong> change the user password.</p>
                </div>
                <div className='av-admin-editor-form-row'>
                    <button
                        className='btn btn-sm btn-block btn-outline-danger'
                        disabled={this.state.userForm.password !== this.state.userForm.passwordConfirm || this.state.userForm.password === ''}
                        onClick={this.resetPasswordForm}
                    >
                        Reset Password
                    </button>
                </div>
            </div>
        ) : (null);

        let statusInformation = this.state.sub !== USER_CONS.sub ? (
            <div className='av-admin-editor-form-row'>
                <label htmlFor='ddlStatus' className='av-admin-editor-form-label'>Status</label>
                <div className='av-admin-editor-form-item'>
                    <Select
                        id='ddlStatus'
                        className={`av-selector-container`}
                        classNamePrefix={`av-selector`}
                        styles={reactSelectStyles}
                        menuPlacement={`top`}
                        options={USER_CONS.statusOptions}
                        placeholder={`Status`}
                        value={this.state.selectedStatusOption}
                        onChange={(event) => this.setReactSelectFormItemValue(USER_CONS.statusId, event)}
                    />
                </div>
            </div>
        ): (null);

        let resetPasswordSuccessModal = editor.changePasswordSuccess ? (
            <DismissableModal
                title='Password Reset Successful'
                message={`User ${this.state.userForm.userName}'s password has been set.`}
                type='success'
                dismissCallback={this.resetPasswordModalDismiss}
            />) : (null);

        let resetPasswordEmailSuccessModal = editor.sendResetPasswordEmailSuccess ? (
            <DismissableModal
                title='Sent Password Reset Email'
                message={`User ${this.state.userForm.userName}'s password reset email has been sent.`}
                type='success'
                dismissCallback={this.resetPasswordEmailModalDismiss}
            />) : (null);

        let content = !editor.initializing ? (
            <div className='av-admin-editor-content'>
                <h1 className='sr-only'>User Form</h1>
                <div className='av-user-editor'>
                    <div className='av-feedback-detail-section titlebar'>
                        <div className='av-back-action'>
                            <div className='col-2'>
                                <button 
                                    className='btn btn-outline-primary' 
                                    onClick={this.back}>
                                        <i className="fas fa-arrow-left"></i>
                                </button>
                            </div>
                            <div className='av-toolbar-title'>
                                <h1>{
                                    (isNewMode(this.state.userForm.sub, USER_CONS.sub)) 
                                        ? `New User` 
                                        : `Edit User`
                                    }
                                </h1>
                            </div>
                            <div className='col-2 text-right'>

                            </div>
                        </div>
                    </div>
                    <div className='av-admin-form av-user-identity-info'>
                        <h2>Account Information</h2>
                        <div className='av-admin-editor-form-row'>
                            <label htmlFor='txtUserName' className='av-admin-editor-form-label'>User Name</label>
                            <div className='av-admin-editor-form-item'>
                                <input
                                    id='txtUserName'
                                    type='text'
                                    className='form-control'
                                    placeholder='User Name'
                                    value={this.state.userForm.userName}
                                    onChange={(event) => this.setFormItemValue(USER_CONS.userName, event)}
                                />
                            </div>
                        </div>
                        <div className='av-admin-editor-form-row'>
                            <label htmlFor='txtEmail' className='av-admin-editor-form-label'>Email Address</label>
                            <div className='av-admin-editor-form-item'>
                                <input
                                    id='txtEmail'
                                    type='email'
                                    className='form-control'
                                    placeholder='Email'
                                    value={this.state.userForm.email}
                                    onChange={(event) => this.setFormItemValue(USER_CONS.email, event)}
                                />
                            </div>
                        </div>
                        {passwordSection}
                        <h2>User Information</h2>
                        <div className='av-admin-editor-form-row'>
                            <label htmlFor='txtFirstName' className='av-admin-editor-form-label'>First Name</label>
                            <div className='av-admin-editor-form-item'>
                                <input
                                    id='txtFirstName'
                                    type='text'
                                    className='form-control'
                                    placeholder='First Name'
                                    value={this.state.userForm.givenName}
                                    onChange={(event) => this.setFormItemValue(USER_CONS.givenName, event)}
                                />
                            </div>
                        </div>
                        <div className='av-admin-editor-form-row'>
                            <label htmlFor='txtMiddleName' className='av-admin-editor-form-label'>Middle Name</label>
                            <div className='av-admin-editor-form-item'>
                                <input
                                    id='txtMiddleName'
                                    type='text'
                                    className='form-control'
                                    placeholder='Middle Name'
                                    value={this.state.userForm.middleName}
                                    onChange={(event) => this.setFormItemValue(USER_CONS.middleName, event)}
                                />
                            </div>
                        </div>
                        <div className='av-admin-editor-form-row'>
                            <label htmlFor='txtLastName' className='av-admin-editor-form-label'>Last Name</label>
                            <div className='av-admin-editor-form-item'>
                                <input
                                    id='txtLastName'
                                    type='text'
                                    className='form-control'
                                    placeholder='Last Name'
                                    value={this.state.userForm.familyName}
                                    onChange={(event) => this.setFormItemValue(USER_CONS.familyName, event)}
                                />
                            </div>
                        </div>
                        <div className='av-admin-editor-form-row'>
                            <label htmlFor='ddlOrganization' className='av-admin-editor-form-label'>Organization</label>
                            <div className='av-admin-editor-form-item'>
                                <Select
                                    id='ddlOrganization'
                                    className={`av-selector-container`}
                                    classNamePrefix={`av-selector`}
                                    styles={reactSelectStyles}
                                    menuPlacement={`top`}
                                    options={organizationOptions}
                                    placeholder={`Organization`}
                                    value={this.state.selectedOrgOption}
                                    onChange={(event) => this.setReactSelectFormItemValue(USER_CONS.orgId, event)}
                                />
                            </div>
                        </div>
                        <div className='av-admin-editor-form-row'>
                            <label htmlFor='ddlRole' className='av-admin-editor-form-label'>Roles</label>
                            <div className='av-admin-editor-form-item'>
                                <Select
                                    id='ddlRole'
                                    isMulti
                                    className={`av-selector-container`}
                                    classNamePrefix={`av-selector`}
                                    styles={reactSelectStyles}
                                    menuPlacement={`top`}
                                    options={roleOptions}
                                    placeholder={`Roles`}
                                    value={this.state.selectedRoleOption}
                                    onChange={(event) => this.setReactSelectFormItemValue(USER_CONS.roleId, event)}
                                    isOptionDisabled={(option) => !this.state.selectedOrgRoles.includes(option.value)}
                                />
                            </div>
                        </div>
                        {statusInformation}
                        <div className='av-admin-editor-form-row'>
                            <button
                                className='btn btn-block btn-outline-primary'
                                disabled={editor.savingUser || this.state.userForm.password !== this.state.userForm.passwordConfirm}
                                onClick={this.saveUserForm}
                            >
                                <span className={`save-icon ${editor.savingUser ? 'saving' : ''}`}></span> Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ): (<Loading />)

        return (
            <div>
                <div className='av-flex-container'>
                    <Content pageHasSidebar={false}>
                        {content}
                        {resetPasswordSuccessModal}
                        {resetPasswordEmailSuccessModal}
                    </Content>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(withRouter(UserEditor)); 